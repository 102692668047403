import clsx from "clsx";

export const Coating = ({ onClick, className, children }) => {
    return (
        <div
            onClick={onClick}
            className={clsx("fixed top-0 left-0 bottom-0 right-0 z-[1] h-full w-full bg-[#00000061]", className)}
        >
            {children}
        </div>
    );
};
