import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NoData, WrapperScroll } from "layout";

export const ModalAmbassador = ({ messages, detail }) => {
    return (
        <div>
            {detail && <DetailComponent data={detail} />}
            {messages && <MessagesComponent data={messages} />}
        </div>
    );
};

const DetailComponent = ({ data }) => {
    const { phone, product_line_id, team_id, user_id, contact_name } = data;
    return (
        <div className="w-full divide-y divide-[#eaeaea] dark:divide-[#424b64]">
            <ItemInfo title="Opportunity name" value={contact_name} />
            {product_line_id.length && <ItemInfo title="Product line" value={product_line_id[1]} />}
            {team_id.length && <ItemInfo title="Team" value={team_id[1]} />}
            {user_id.length && <ItemInfo title="Staff" value={user_id[1]} />}
            <ItemInfo title="Phone" value={phone} />
        </div>
    );
};

const MessagesComponent = ({ data }) => {
    if (!data.length) return <NoData title="No data available yet" noData />;
    return (
        <WrapperScroll className="globals__display_block max-h-96">
            <div className="w-full divide-y divide-[#eaeaea] dark:divide-[#424b64]">
                {data.map((item) => (
                    <div key={item.id}>
                        <ItemInfo title="ID" value={item.id} />
                        <ItemInfo title="Content" value={<div dangerouslySetInnerHTML={{ __html: item.body }} />} />
                        {item?.author_id && <ItemInfo title="Author" value={item?.author_id[1]} />}
                        <ItemInfo
                            title="Date create"
                            value={moment(item.create_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                        />
                    </div>
                ))}
            </div>
        </WrapperScroll>
    );
};

const ItemInfo = ({ title, value }) => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-between gap-x-4 py-1 dark:hover:bg-[#1c1f2b]">
            <h6 className="font-semibold">{t(title)}:</h6>
            <p className="flex-1 text-right">{value}</p>
        </div>
    );
};
