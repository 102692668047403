import * as React from "react";

import { WrapperScroll, CommonDetailEvent } from "layout";

export const ModalDetailEvent = ({ _uid }) => {
    return (
        <WrapperScroll className="hover max-h-[75vh] pr-4">
            <CommonDetailEvent _uid={_uid} />
        </WrapperScroll>
    );
};
