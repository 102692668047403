export const LoadingEvent = () => {
    return (
        <div className="grid gap-y-6">
            <div className="flex justify-center gap-4">
                <div className="basis-6/12">
                    <div className="m-auto h-4 w-full max-w-[228px] animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                </div>
                <div className="basis-6/12">
                    <div className="m-auto h-4 w-full max-w-[228px] animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                </div>
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-2/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-3/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-4/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-2/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-3/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-4/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="flex gap-4">
                    {[...new Array(5)].map((_, index) => (
                        <div className="h-8 w-8 rounded-full bg-slate-300 dark:bg-slate-500" key={index.toString()} />
                    ))}
                </div>
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-3/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-4/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="flex gap-4">
                <div className="h-4 w-full basis-1/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
                <div className="h-4 w-full basis-2/12 animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="h-16 max-w-xs animate-pulse rounded-[0.25rem] bg-slate-300 dark:bg-slate-500" />
        </div>
    );
};
