import { createSlice } from "@reduxjs/toolkit";
import { onGetMenuKnowLedgesById, onGetDetailKnowLedgesId, onGetMenuListKnowLedges } from "./path-api";

const initialState = {
    dataKnowledgeId: null,
    dataKnowledgeDetailId: null,
    dataMenuListKnowLedges: null,
    loadingDataKnowledgeId: true,
    loadingDataKnowledgeDetailId: true,
};

export const sliceKnowLedge = createSlice({
    name: "know-ledge",
    initialState: initialState,
    extraReducers: {
        [onGetMenuKnowLedgesById.pending]: (state) => {
            state.loadingDataKnowledgeId = true;
        },
        [onGetMenuKnowLedgesById.fulfilled]: (state, action) => {
            state.dataKnowledgeId = action.payload;
            state.loadingDataKnowledgeId = false;
        },
        // get detail know-ledge
        [onGetDetailKnowLedgesId.pending]: (state) => {
            state.loadingDataKnowledgeDetailId = true;
        },
        [onGetDetailKnowLedgesId.fulfilled]: (state, action) => {
            state.dataKnowledgeDetailId = action.payload;
            state.loadingDataKnowledgeDetailId = false;
        },
        // get menu list know-ledge

        [onGetMenuListKnowLedges.fulfilled]: (state, action) => {
            state.dataMenuListKnowLedges = action.payload;
        },
    },
});

const { reducer } = sliceKnowLedge;
export default reducer;
