import * as React from "react";
import clsx from "clsx";
import moment from "moment";
import { Dropdown } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { useModal, useContextControllers } from "hooks";
import { urlWebsite } from "constant";
import { ConfirmStatus } from ".";
import { WrapperScroll } from "layout";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const TitleCalendarDate = ({ data, listEventMonth }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { ModalTypeEnum, toggleModal } = useModal();
    const { settingAccount, setCopyEvent } = useContextControllers();

    const day = moment(data.date).format("DD");
    const month = moment(data.date).format("MM");
    const year = moment(data.date).format("YYYY");
    const date = `${day}/${month}/${year}`;

    const eventInMonth = React.useMemo(() => {
        const date = moment(data.date, "YYYY-MM-DD").valueOf();
        const eventsDay = listEventMonth?.filter(({ day }) => moment(day, "YYYY-MM-DD").valueOf() === date);
        return eventsDay;
    }, [data.date, listEventMonth]);

    return (
        <div className="flex items-center justify-between">
            {eventInMonth.length > 5 && (
                <Dropdown
                    placement="bottomLeft"
                    overlay={() => {
                        const date = moment(data.date, "YYYY-MM-DD").valueOf();
                        const eventsDay = listEventMonth?.filter(
                            ({ day }) => moment(day, "YYYY-MM-DD").valueOf() === date
                        );
                        return (
                            <div className="rounded-[0.25rem] bg-white py-4 text-[#495057] shadow-dropdown-menu dark:bg-[#262b3c] dark:text-slate-200">
                                <WrapperScroll className="globals__display_block max-h-64">
                                    {eventsDay.slice(5, eventsDay.length).map((item) =>
                                        item.uid ? (
                                            <div
                                                onClick={() => {
                                                    if (settingAccount.is_modal_event) {
                                                        toggleModal({
                                                            title: t("Event Detail {{value}}", {
                                                                value: `#${item._id}`,
                                                            }),
                                                            type: ModalTypeEnum.DETAIL_EVENT,
                                                            data: {
                                                                _uid: item.uid,
                                                                url: `${window.location.href}/${item.uid}`,
                                                                cancel: true,
                                                                targetLink: true,
                                                            },
                                                            modal: {
                                                                width: 1500,
                                                            },
                                                        });
                                                        if (location.pathname === urlWebsite.EVENTS) {
                                                            navigate(`${urlWebsite.EVENTS}?_uid=${item.uid}`);
                                                        }
                                                    } else {
                                                        navigate(`${urlWebsite.EVENTS}/${item.uid}`);
                                                    }
                                                }}
                                                className={clsx(
                                                    "flex cursor-pointer items-center gap-x-1 px-4 hover:bg-[#ebeff3] dark:hover:bg-[#1c1f2b]",
                                                    item.isMember &&
                                                        "text-[#22a447] hover:text-[#22a447] dark:text-[#5bf814]",
                                                    item.type === "1" &&
                                                        !item.isMember &&
                                                        "text-[#0264af] hover:text-[#0264af] dark:text-[#0090ff]", //event
                                                    item.type === "2" &&
                                                        !item.isMember &&
                                                        "text-[#8f81fe] hover:text-[#8f81fe]", //training
                                                    item.type === "0" &&
                                                        !item.isMember &&
                                                        "text-[#f57c96] hover:text-[#f57c96]" //birthday
                                                )}
                                            >
                                                <div className="grid">
                                                    <p className="flex items-center">
                                                        {item.name}
                                                        {item.status && (
                                                            <div className="ml-1">
                                                                <ConfirmStatus
                                                                    status={item.confirm_status}
                                                                    title={item.confirm_reason}
                                                                />
                                                            </div>
                                                        )}
                                                    </p>
                                                    <span className="relative bottom-1 truncate text-[0.75rem] text-[#76828e] opacity-70">
                                                        {item.hourStart} - {item.hourEnd}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    toggleModal({
                                                        type: ModalTypeEnum.HAPPY_BIRTHDAY,
                                                        title: "Happy Birthday",
                                                        data: {
                                                            username: item.username,
                                                        },
                                                        modal: {
                                                            width: 700,
                                                        },
                                                    });
                                                }}
                                                className="ml-2 flex cursor-pointer items-center gap-x-2 truncate rounded px-2 text-[#f57c96] hover:text-[#f57c96] hover:underline"
                                            >
                                                <p>{item.name}</p>
                                            </div>
                                        )
                                    )}
                                </WrapperScroll>
                            </div>
                        );
                    }}
                >
                    <p className="class-global-title-calendar text-[0.75rem] text-[#aaa]">
                        + {t("More {{value}}", { value: eventInMonth.length - 5 })}
                    </p>
                </Dropdown>
            )}
            <div className="mr-2 flex w-full flex-1 justify-end gap-2 font-semibold">
                {moment(data.date).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD") && (
                    <PlusOutlined
                        style={{
                            display: "flex",
                        }}
                        className="class-global-icon-calendar h-6 w-6 items-center justify-center rounded-[0.25rem] text-sm font-semibold text-[#03942c] hover:bg-[#03942c] hover:text-white"
                        onClick={() => {
                            setCopyEvent(null);
                            if (settingAccount.is_modal_event) {
                                toggleModal({
                                    title: t("Add day event {{date}}", { date: date }),
                                    type: ModalTypeEnum.ADD_EVENT,
                                    data: {
                                        cancel: true,
                                        date: date,
                                    },
                                    modal: {
                                        width: 1500,
                                    },
                                });
                            } else {
                                navigate(`${urlWebsite.EVENTS_ADD}?_day=${day}&_month=${month}&_year=${year}`);
                            }
                        }}
                    />
                )}
                <p className="class-global-day-calendar cursor-auto">{data.dayNumberText.slice(-2)}</p>
            </div>
        </div>
    );
};
