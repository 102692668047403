import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { storeSales, useAccount } from "hooks";
import {
    onGetQuoteGroupListSales,
    onGetLeads,
    onPostCreateLead,
    onGetSearchLeads,
    onGetSearchApproverPrice,
    onGetSearchApproverTech,
    onGetQuotationCategory,
    onGetSubQuoteCategory,
    onGetDiscountList,
    onPostCreateSkuQuotation,
    addSKUQuotationReducers,
    addQuotationDiscountReducers,
    onGetOrderLineQuotation,
    onGetSkuQuotation,
    onPatchUpdateSkuQuotation,
    onDeleteQuotation,
    onPatchApprovedQuotationState,
    patchApprovedQuotationStateReducers,
    onGetAbsLead,
    onGetAbsProductLine,
    onPostAbsLead,
    onPatchAbsLead,
} from "features";

export const useSales = () => {
    const dispatch = useDispatch();

    const { access_token } = useAccount();

    const resultStoreSales = useSelector(storeSales);
    const {
        dataListSales,
        dataLeads,
        isLoadingSales,
        pagination,
        loadingSearchApproverPrice,
        dataSearchApproverPrice,
        loadingSearchApproverTech,
        dataSearchApproverTech,
        dataQuotationCategory,
        loadingQuotationCategory,
        loadingSubQuoteCategory,
        dataSubQuoteCategory,
        dataDiscountList,
        dataAddSKUQuotationReducers,
        dataQuotationDiscount,
        dataOrderLineQuotation,
        dataGetSkuQuotation,
        dataAbsLead,
        dataAbsProductLine,
    } = resultStoreSales;

    const handleGetQuoteGroupListSales = React.useCallback(
        () => access_token && dispatch(onGetQuoteGroupListSales()),
        [access_token, dispatch]
    );

    const handleGetLeads = React.useCallback(
        (data) => access_token && dispatch(onGetLeads(data)),
        [dispatch, access_token]
    );

    const handlePostCreateLead = React.useCallback(
        (payload) => access_token && dispatch(onPostCreateLead(payload)),
        [access_token, dispatch]
    );

    const handleGetSearchLeads = React.useCallback(
        (keyword) => access_token && dispatch(onGetSearchLeads(keyword)),
        [access_token, dispatch]
    );

    const handleGetSearchApproverPrice = React.useCallback(
        (keyword) => access_token && dispatch(onGetSearchApproverTech(keyword)),
        [access_token, dispatch]
    );

    const handleGetSearchApproverTech = React.useCallback(
        (keyword) => access_token && dispatch(onGetSearchApproverPrice(keyword)),
        [access_token, dispatch]
    );

    const handleGetQuotationCategory = React.useCallback(
        () => access_token && dispatch(onGetQuotationCategory()),
        [access_token, dispatch]
    );

    const handleGetSubQuoteCategory = React.useCallback(
        (id) => access_token && dispatch(onGetSubQuoteCategory(id)),
        [access_token, dispatch]
    );

    const handleAddSKUQuotationReducers = React.useCallback(
        (payload) => dispatch(addSKUQuotationReducers(payload)),
        [dispatch]
    );

    const handleGetDiscountList = React.useCallback(
        () => access_token && dispatch(onGetDiscountList()),
        [access_token, dispatch]
    );

    const handleAddQuotationDiscountReducers = React.useCallback(
        (payload) => dispatch(addQuotationDiscountReducers(payload)),
        [dispatch]
    );

    const handlePostCreateSkuQuotation = React.useCallback(
        (payload) => access_token && dispatch(onPostCreateSkuQuotation(payload)),
        [access_token, dispatch]
    );

    const handleGetOrderLineQuotation = React.useCallback(
        (payload) => access_token && dispatch(onGetOrderLineQuotation(payload)),
        [dispatch, access_token]
    );

    const handleGetSkuQuotation = React.useCallback(
        (payload) => access_token && dispatch(onGetSkuQuotation(payload)),
        [dispatch, access_token]
    );

    const handlePatchUpdateSkuQuotation = React.useCallback(
        (data) => access_token && dispatch(onPatchUpdateSkuQuotation(data)),
        [access_token, dispatch]
    );

    const handleDeleteQuotation = React.useCallback(
        (id) => access_token && dispatch(onDeleteQuotation(id)),
        [access_token, dispatch]
    );

    const handlePatchApprovedQuotationState = React.useCallback(
        (data) => access_token && dispatch(onPatchApprovedQuotationState(data)),
        [access_token, dispatch]
    );

    const handlePatchApprovedQuotationStateReducers = React.useCallback(
        (data) => dispatch(patchApprovedQuotationStateReducers(data)),
        [dispatch]
    );

    const handleOnGetAbsLead = React.useCallback(
        () => access_token && dispatch(onGetAbsLead()),
        [access_token, dispatch]
    );

    const handleOnGetAbsProductLine = React.useCallback(
        () => access_token && dispatch(onGetAbsProductLine()),
        [access_token, dispatch]
    );

    const handleOnPostAbsLead = React.useCallback(
        (payload) => access_token && dispatch(onPostAbsLead(payload)),
        [access_token, dispatch]
    );

    const handleOnPatchAbsLead = React.useCallback(
        (payload) => access_token && dispatch(onPatchAbsLead(payload)),
        [access_token, dispatch]
    );

    return {
        handleGetQuoteGroupListSales,
        handleGetOrderLineQuotation,
        handleGetLeads,
        handlePostCreateLead,
        handleGetSearchLeads,
        handleGetSearchApproverPrice,
        handleGetSearchApproverTech,
        handleGetQuotationCategory,
        handleGetSubQuoteCategory,
        handleGetDiscountList,
        handlePostCreateSkuQuotation,
        handleAddSKUQuotationReducers,
        handleAddQuotationDiscountReducers,
        handleGetSkuQuotation,
        handlePatchUpdateSkuQuotation,
        handleDeleteQuotation,
        handlePatchApprovedQuotationState,
        handlePatchApprovedQuotationStateReducers,
        handleOnGetAbsLead,
        handleOnGetAbsProductLine,
        handleOnPostAbsLead,
        handleOnPatchAbsLead,
        dataQuotationDiscount,
        dataAddSKUQuotationReducers,
        dataQuotationCategory,
        loadingQuotationCategory,
        loadingSubQuoteCategory,
        dataSubQuoteCategory,
        pagination,
        dataListSales,
        dataLeads,
        isLoadingSales,
        loadingSearchApproverPrice,
        dataSearchApproverPrice,
        loadingSearchApproverTech,
        dataSearchApproverTech,
        dataDiscountList,
        dataOrderLineQuotation,
        dataGetSkuQuotation,
        dataAbsLead,
        dataAbsProductLine,
    };
};
