export const Loading = () => {
    return (
        <div className="grid gap-1">
            {[...Array(6)].map((_, index) => (
                <div className="m-2 flex rounded">
                    <div className="h-5 w-full animate-pulse rounded bg-slate-600" key={index.toString()} />
                </div>
            ))}
        </div>
    );
};
