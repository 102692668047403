import { Input } from "antd";
import * as React from "react";
import Highlighter from "react-highlight-words";
import vnmToAlphabet from "vnm-to-alphabet";
import { useTranslation } from "react-i18next";

import { Button } from "customize";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";

export const useSearchTable = ({ placeholder }) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = React.useState("");
    const [searchedColumn, setSearchedColumn] = React.useState("");
    const searchInput = React.useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={t(placeholder)}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    allowClear={true}
                    style={{
                        marginBottom: 8,
                        display: "flex",
                    }}
                />
                <div className="flex gap-2">
                    <Button
                        label="Search"
                        BgPrimary
                        size="sm"
                        Icon={SearchOutlined}
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    />
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                        size="sm"
                        Icon={UndoOutlined}
                        label="Reset"
                        BgSecondary
                    />
                </div>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            vnmToAlphabet(record[dataIndex].toString().toLowerCase(), "capitalize").includes(
                vnmToAlphabet(value.toLowerCase(), "capitalize")
            ),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    return { getColumnSearchProps };
};
