import { useNavigate } from "react-router-dom";

import { MainPage, CardProfile, WrapperProfile } from "layout";

import { useAccount, useDrawer, useTasks } from "hooks";
import { urlWebsite } from "constant";

export const DrawerProfile = () => {
    const navigate = useNavigate();

    const { profile } = useAccount();
    const { dataTaskList } = useTasks();
    const { toggleDrawer, DrawerTypeEnum } = useDrawer();

    return (
        <MainPage goBack={false}>
            {profile ? (
                <div className="grid gap-4">
                    <CardProfile
                        onClick={() => {
                            toggleDrawer({
                                type: DrawerTypeEnum.NULL,
                            });
                            navigate(urlWebsite.TASKS_ME);
                        }}
                        {...profile}
                        lengthProject={dataTaskList?.length}
                        titleViewMore="See plan"
                        hrefViewMore={urlWebsite.TASKS_ME}
                        avatarClassName="m-0 relative w-[80px] h-[80px]"
                        wrapperClassName="flex gap-1 justify-between py-4"
                        detailsClassName="grid"
                        isShowWork={false}
                        className=""
                    />
                    <WrapperProfile {...profile} />
                </div>
            ) : (
                <div>loadding...</div>
            )}
        </MainPage>
    );
};
