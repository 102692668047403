import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiKnowLedGe } from "api";

export const onGetMenuKnowLedgesById = createAsyncThunk("getMenuKnowLedgesById", async (id) => {
    const response = await apiKnowLedGe.getMenuKnowLedgesById(id);
    return response;
});

export const onGetDetailKnowLedgesId = createAsyncThunk("getDetailKnowLedgesId", async (id) => {
    const response = await apiKnowLedGe.getDetailKnowLedgesId(id);
    return response;
});

export const onGetMenuListKnowLedges = createAsyncThunk("getMenuListKnowLedges", async () => {
    const response = await apiKnowLedGe.getMenuListKnowLedges();
    return response;
});
