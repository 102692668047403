import { Tooltip } from "antd";
import clsx from "clsx";
import { colorTooltip } from "constant";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const IconAddEvent = ({ Icon, onClick, title, url, className, colorDefault = true, iconClassName, style }) => {
    const { t } = useTranslation();
    return url ? (
        <Link to={url} className={className}>
            <Tooltip placement="bottomRight" color={colorTooltip} title={t(title)}>
                <div
                    style={style}
                    className={clsx(
                        "flex h-[38px] w-[38px] items-center justify-center rounded-[0.25rem] bg-[#eff2f7] hover:bg-[#03942c] dark:bg-[#222736] hover:dark:bg-[#03942c]",
                        className
                    )}
                    onClick={onClick && onClick}
                >
                    <Icon
                        style={style}
                        className={clsx(
                            "flex h-full w-full p-2 text-[1.25rem]",
                            colorDefault
                                ? "text-[#555b6d] hover:text-white dark:text-slate-200"
                                : "text-[#556ee6] hover:text-white dark:text-[#f6f6f6]",
                            iconClassName ? iconClassName : "cursor-pointer"
                        )}
                    />
                </div>
            </Tooltip>
        </Link>
    ) : (
        <Tooltip placement="bottomRight" color={colorTooltip} title={t(title)}>
            <div
                className={clsx(
                    "flex h-[38px] w-[38px] cursor-pointer items-center justify-center rounded-[0.25rem] bg-[#eff2f7] hover:bg-[#03942c] dark:bg-[#222736] hover:dark:bg-[#03942c]",
                    className
                )}
                onClick={onClick && onClick}
                style={style}
            >
                <Icon
                    style={style}
                    className={clsx(
                        "flex h-full w-full p-2 text-[1.25rem]",
                        colorDefault
                            ? "text-[#555b6d] hover:text-white dark:text-slate-200"
                            : "text-[#556ee6] hover:text-white dark:text-[#556ee6]",
                        iconClassName ? iconClassName : "cursor-pointer"
                    )}
                />
            </div>
        </Tooltip>
    );
};
