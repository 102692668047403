import * as React from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { Button, Image, InputField, LinkHref } from "customize";
import { useAccount } from "hooks";
import { BiLogInCircle } from "react-icons/bi";
import { DropdownTranslate } from "layout";
import { imageHelp } from "assets/images";
import { iconLogo } from "assets/icon";

const schema = yup
    .object()
    .shape({
        username: yup.string().required("Please input your Username!"),
        password: yup.string().required("Please enter password!"),
    })
    .required();

export const MobileApp = () => {
    const { t } = useTranslation();
    const { handleLogin, resultStoreAccount } = useAccount();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const handleLoginApp = async (value) => {
        const result = await handleLogin(value);
        if (result?.error) {
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("Incorrect username or password")}</p>,
            });
        }
    };

    return (
        <div>
            <LinkHref outWeb path="https://solarbk.vn/" className="flex justify-center">
                <Image src={iconLogo} className="mt-3 h-full w-full" />
            </LinkHref>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                }}
            >
                <div className="m-auto grid w-full bg-white px-5 pt-[50px] pb-[20px] text-center lg:max-w-[400px] lg:py-[50px] lg:px-8">
                    <Image src={imageHelp} alt="logo" className="h-full w-full" />
                    <div className="mb-8 text-gray-50 lg:mb-0">
                        <h6 className="text-[1.5rem] font-semibold text-slate-700">{t("Welcome back")}</h6>
                    </div>
                    <div className="min-h-[248px] text-left">
                        <form onSubmit={handleSubmit(handleLoginApp)}>
                            <InputField
                                validate={register}
                                validateOptions={{
                                    required: true,
                                }}
                                inputClassName="text-slate-600 placeholder:text-slate-600 block border-[rgb(234, 236, 239)] bg-white w-full border-b py-2 pl-9 pr-3 focus:outline-none focus:border-[#0d6efd] focus:ring-0"
                                iconClassName="absolute text-[rgb(183, 189, 198)]"
                                wrapperClassName="bg-white rounded-[0px]"
                                placeholder="Username"
                                name="username"
                                className="mt-0"
                                Icon={UserOutlined}
                                messageError={errors.username?.message}
                            />
                            <InputField
                                validate={register}
                                validateOptions={{
                                    required: true,
                                }}
                                inputClassName="text-slate-600 placeholder:text-slate-600 block border-[rgb(234, 236, 239)] bg-white w-full border-b py-2 pl-9 pr-3 focus:outline-none focus:border-[#0d6efd] focus:ring-0"
                                iconClassName="absolute text-[rgb(183, 189, 198)]"
                                wrapperClassName="bg-white rounded-[0px] relative"
                                iconShowPasswordClassName="absolute text-[rgb(183, 189, 198)] right-3"
                                placeholder="Password"
                                Icon={LockOutlined}
                                messageError={errors.password?.message}
                                isShowIconPassword
                                name="password"
                            />
                            <Button
                                BgPrimary
                                Icon={BiLogInCircle}
                                label="Login"
                                className="mt-6 px-8 py-2 font-semibold"
                                loading={resultStoreAccount.loading}
                            />
                            <div className="mt-4 flex justify-center">
                                <DropdownTranslate />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
