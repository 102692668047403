import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiSearch } from "api";

export const onGetSearchKnowLedges = createAsyncThunk("onGetSearchKnowLedges", async (keyword) => {
    const response = await apiSearch.getSearchKnowLedges(keyword);
    return response;
});

export const onGetDetailKnowLedges = createAsyncThunk("onGetDetailKnowLedges", async (_id) => {
    const response = await apiSearch.getDetailKnowLedges(_id);
    return response;
});
