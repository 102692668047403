import { Tooltip } from "antd";
import clsx from "clsx";
import moment from "moment";

import { colorTooltip } from "constant";
import { useContextControllers } from "hooks";
import { useTranslation } from "react-i18next";

export const WrapperKnowLedgeDetail = ({ data }) => {
    const { settingAccount } = useContextControllers();
    const { t } = useTranslation();

    return (
        <div>
            <div className="mb-4 flex flex-col items-start justify-between sm:flex-row sm:items-center">
                <div className="flex items-center gap-2">
                    <p className="text-[0.75rem] dark:text-slate-200 lg:text-[0.938rem]">{t("Creator")}:</p>
                    <h6 className="text-[#74788d] dark:text-[#c3cbe4]">{data.create_uid && data.create_uid[1]}</h6>
                </div>
                <div className="flex items-center gap-1">
                    <p className="text-[0.75rem] dark:text-slate-200 lg:text-[0.938rem]">{t("Date create")}:</p>
                    <Tooltip title={moment(data.create_date).format("DD-MM-YYYY")} color={colorTooltip}>
                        <h6 className="text-[#74788d] dark:text-[#c3cbe4]">{moment(data.create_date).fromNow()}</h6>
                    </Tooltip>
                </div>
            </div>
            <div
                className={clsx(
                    "text-[0.938rem]",
                    settingAccount.theme === "dark" && "container-know-ledge",
                    "globals__style__knowledge"
                )}
                dangerouslySetInnerHTML={{ __html: data?.content }}
            />
        </div>
    );
};
