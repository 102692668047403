import * as React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";

import { Input, Form, message } from "antd";

import { useEvents, useAccount } from "hooks";
import { Button } from "customize";
import { FiSend } from "react-icons/fi";

export const FormComment = ({ idEvent, dataCommentListEvent, setCommentListEvent }) => {
    const { handlePostEventComment } = useEvents();
    const { t } = useTranslation();
    const { profile } = useAccount();
    const [form] = Form.useForm();

    const [loading, setLoading] = React.useState(false);

    const onFinishEventComment = async ({ comment }) => {
        try {
            setLoading(true);
            const request = await handlePostEventComment({ idEvent, comment });
            const result = unwrapResult(request);
            if (result) {
                setLoading(false);
                const commentSusses = {
                    comment: comment,
                    create_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                    id: Math.random(),
                    user_id: profile.id,
                    user_info: profile,
                };
                setCommentListEvent([commentSusses, ...dataCommentListEvent]);
                form.resetFields();
            }
        } catch (error) {
            setLoading(false);
            const commentSusses = {
                comment: comment,
                create_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                id: Math.random(),
                user_id: profile.id,
                user_info: profile,
            };
            if (dataCommentListEvent?.length > 0 || dataCommentListEvent) {
                setCommentListEvent([commentSusses, ...dataCommentListEvent]);
            } else {
                setCommentListEvent([commentSusses]);
            }
            form.resetFields();
            message.success(t("Feedback successful"));
        }
    };

    return (
        <div>
            <Form onFinish={onFinishEventComment} form={form}>
                <div className="rounded-[0.25rem] border border-[#ced4da] p-3 dark:border-[#424b64]">
                    <Form.Item name="comment" rules={[{ required: true, message: t("Please add feedback") }]}>
                        <Input.TextArea
                            className="placeholder:text-[0.813rem]"
                            placeholder={`${t("Write your review")}...`}
                            autoSize={{ minRows: 3, maxRows: 8 }}
                            size="large"
                            allowClear
                            bordered={false}
                            disabled={loading}
                        />
                    </Form.Item>
                </div>
                <Button
                    loading={loading}
                    label="Add feedback"
                    className="mt-[1rem] max-w-[140px] rounded-[.25rem] px-[0.75rem] py-[0.47rem]"
                    BgSuccess
                    Icon={FiSend}
                />
            </Form>
        </div>
    );
};
