import * as React from "react";
import { useTranslation } from "react-i18next";

import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const SearchPageProducts = ({
    className,
    keyword = "",
    onSetKeyword,
    onClearKeywords,
    is_validity_keyword = true,
}) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <div className="flex items-center gap-2 rounded-[0.25rem] border px-2 py-2 dark:border-[2px] dark:border-[#32394e]">
                <SearchOutlined className="ml-1 text-[#555b6d]" />
                <input
                    value={keyword}
                    className="w-full bg-transparent text-sm font-normal text-[#737579] placeholder:text-xs placeholder:font-normal focus:outline-none"
                    placeholder={`${t("Search")}...`}
                    onChange={(e) => onSetKeyword(e.target.value)}
                />
                {keyword.trim().length > 0 && (
                    <CloseCircleOutlined className="mr-2 cursor-pointer text-[#555b6d]" onClick={onClearKeywords} />
                )}
            </div>

            {keyword.trim().length > 0 && keyword.trim().length < 3 && is_validity_keyword && (
                <p className="top-full mt-1 text-[#dc3545] dark:text-[#fff]">{t("Enter at least 3 characters")}</p>
            )}
        </div>
    );
};
