import { notification } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const useError = () => {
    const { t } = useTranslation();

    const [message, setMessage] = React.useState("");

    const callBackApi = React.useCallback(
        async (api) => {
            if (api) {
                const { error } = await api;
                if (error) {
                    const { message } = JSON.parse(error.message);
                    setMessage(message);
                    notification.error({
                        message: <h6>{t("Notification")}</h6>,
                        description: <p>{t(message)}</p>,
                    });
                    return message;
                }
            }
        },
        [t]
    );

    return {
        callBackApi,
        message,
    };
};
