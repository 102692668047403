import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiQuotation } from "api";
import { apiSales } from "api";

export const onGetAllQuotations = createAsyncThunk("getAllQuotations", async (payload) => {
    const response = await apiQuotation.getAllQuotations(payload);
    return response;
});

export const onGetSearchQuotations = createAsyncThunk("getSearchQuotations", async (keyword) => {
    const response = await apiSales.getSearchLeads(keyword);
    return response;
});
