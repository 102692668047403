export const reducers = {
    addToProductListProduct: (state, action) => {
        const { dataSearchProducts, productListProduct } = state;
        const item = action.payload;
        const indexState = dataSearchProducts.findIndex(({ id }) => id === item.id);
        if (indexState !== -1) {
            dataSearchProducts.splice(indexState, 1);
            const checkItem = productListProduct.find(({ product }) => product.id === item.id);
            if (!checkItem) {
                productListProduct.unshift({ product: item, quantity: 1 });
            }
        }
    },
    removeProductListProduct: (state, action) => {
        const { dataSearchProducts, productListProduct } = state;
        const item = action.payload;
        const indexProductList = productListProduct.findIndex(({ product }) => product.id === item.id);
        const checkItem = dataSearchProducts.find(({ id }) => id === item.id);
        if (indexProductList !== -1) {
            productListProduct.splice(indexProductList, 1);
            if (!checkItem) {
                dataSearchProducts.unshift(item);
            }
        }
    },
    updateQuantityProductListProduct: (state, action) => {
        const { productListProduct } = state;
        const { _id, quantity } = action.payload;
        const indexProductList = productListProduct.findIndex(({ product }) => product.id === _id);
        if (indexProductList !== -1) {
            productListProduct[indexProductList].quantity = quantity;
        }
    },
    updateEditSalesProduct: (state, action) => {
        const product_sku_ids = action.payload;
        const result = product_sku_ids.map(({ id, product_id, quantity }) => ({
            product: {
                id: product_id[0],
                name: product_id[1],
            },
            quantity: quantity,
            _id: id,
        }));
        state.productListProduct = result;
    },
    //
    reducersUpdateProductCost: (state, action) => {
        const { dataProducts } = state;
        const { id, cost } = action.payload;
        const indexProductList = dataProducts.findIndex((product) => product.id === id);
        if (indexProductList !== -1) {
            dataProducts[indexProductList].fc_ratio = cost.fc_ratio;
            dataProducts[indexProductList].price_to_warehouse = cost.price_to_warehouse;
            dataProducts[indexProductList].ptw_expire_time = cost.ptw_expire_time;
        }
    },
};
