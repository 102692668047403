import * as React from "react";
import { Menu } from "antd";
import clsx from "clsx";
import { WrapperScroll } from "layout";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

import { navigateMenu, storeLocalStorage, urlWebsite } from "constant";

import { useAccount, useContextControllers, useKnowLedge, useTranslate, useTypeProject } from "hooks";
import { Coating, Logo } from "layout";
import { iconLogoIcon, iconLogoWords } from "assets/icon";
import { Loading } from "./loading";
import { VscFileSubmodule } from "react-icons/vsc";

import "./style.scss";

export const LeftSideBar = ({ dropDownMenu }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { checkLanguage } = useTranslate();
    const { isAppMobile } = useTypeProject();

    const { handleGetMenuListKnowLedges, dataMenuListKnowLedges } = useKnowLedge();
    const { openMenu, setOpenMenu, settingAccount } = useContextControllers();
    const { handleGetNotification, handleGetMenuSidebarUser, MenuSidebarUser } = useAccount();

    React.useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        handleGetNotification();
    }, [pathname, handleGetNotification]);

    React.useEffect(() => {
        if (!dataMenuListKnowLedges) {
            handleGetMenuListKnowLedges();
        }
    }, [dataMenuListKnowLedges, handleGetMenuListKnowLedges]);

    React.useEffect(() => {
        if (!MenuSidebarUser) {
            handleGetMenuSidebarUser();
        }
    }, [MenuSidebarUser, handleGetMenuSidebarUser]);

    const filterMenu = React.useMemo(() => {
        const temp = [];
        if (MenuSidebarUser) {
            MenuSidebarUser.forEach((menu) => {
                navigateMenu.forEach((sidebar) => {
                    if (menu === sidebar.alias) {
                        temp.push(sidebar);
                    }
                });
            });
        }
        return temp;
    }, [MenuSidebarUser]);

    const wrapperClassNameMenu = settingAccount.horizontal
        ? ""
        : clsx(
              "transition-globals fixed left-0 top-0 z-10 h-full w-full lg:left-0",
              openMenu ? "left-0" : "-left-[270px]",
              dropDownMenu ? "max-w-[80px]" : "max-w-[270px]",
              isAppMobile ? "bg-white dark:bg-[#262b3c]" : "bg-[#11192a] dark:bg-[#262b3c]"
          );

    const wrapperClassNameMenuMobile = settingAccount.horizontal
        ? ""
        : clsx(
              "relative w-full duration-300 ease-out",
              dropDownMenu ? "max-w-0 md:max-w-[80px]" : "max-w-0 lg:max-w-[270px]"
          );

    return (
        <>
            <div className={wrapperClassNameMenuMobile}>
                <div className={wrapperClassNameMenu}>
                    {!settingAccount.horizontal && (
                        <Logo
                            src={dropDownMenu ? iconLogoIcon : iconLogoWords}
                            className={clsx(
                                "px-5 py-6 text-center",
                                isAppMobile ? "bg-white dark:bg-[#262b3c]" : "bg-[#11192a] dark:bg-[#262b3c]"
                            )}
                            imageClassName={clsx("w-full", dropDownMenu ? "max-w-[20px]" : "max-w-[200px]")}
                        />
                    )}
                    <WrapperScroll
                        className={clsx(!settingAccount.horizontal && "absolute -left-[1px] w-full overflow-y-auto")}
                        style={{
                            maxHeight: "calc(100vh - 120px)",
                        }}
                    >
                        <Menu
                            className={clsx(
                                settingAccount.horizontal && settingAccount.theme === "dark" && "class-horizontal-dark",
                                settingAccount.horizontal &&
                                    settingAccount.theme === "light" &&
                                    "class-horizontal-light",
                                !settingAccount.horizontal && "not-class-horizontal"
                            )}
                            mode={settingAccount.horizontal ? "horizontal" : "inline"}
                            inlineCollapsed={dropDownMenu}
                            onClick={() => setOpenMenu(false)}
                        >
                            {!MenuSidebarUser && <Loading />}
                            {filterMenu.map((menu) => (
                                <>
                                    {!menu?.subMenu && (
                                        <Menu.Item key={menu.key} icon={<menu.icon />}>
                                            <LinkHref outWeb={menu.outWebsite} path={menu.path} title={t(menu.title)} />
                                        </Menu.Item>
                                    )}
                                    {menu?.subMenu && (
                                        <Menu.SubMenu
                                            popupClassName={
                                                settingAccount.horizontal && settingAccount.theme !== "dark"
                                                    ? "globals-class-horizontal"
                                                    : ""
                                            }
                                            key={menu.key}
                                            icon={<menu.icon />}
                                            title={t(menu.title)}
                                        >
                                            {menu?.subMenu?.map((sub) => (
                                                <Menu.Item key={sub.key}>
                                                    <LinkHref
                                                        path={sub.path}
                                                        outWeb={sub.outWebsite}
                                                        title={t(sub.title)}
                                                    />
                                                </Menu.Item>
                                            ))}
                                        </Menu.SubMenu>
                                    )}
                                </>
                            ))}

                            {!settingAccount.sub_menu ? (
                                <Menu.Item icon={<VscFileSubmodule />}>
                                    <Link className="flex w-full" to={urlWebsite.TREE_KNOWLEDGE}>
                                        {t("Knowledges")}
                                    </Link>
                                </Menu.Item>
                            ) : (
                                dataMenuListKnowLedges && (
                                    <Menu.SubMenu
                                        popupClassName={
                                            settingAccount.horizontal && settingAccount.theme !== "dark"
                                                ? "globals-class-horizontal"
                                                : ""
                                        }
                                        title={
                                            <Link className="flex w-full" to={urlWebsite.TREE_KNOWLEDGE}>
                                                {t("Knowledges")}
                                            </Link>
                                        }
                                        icon={<VscFileSubmodule />}
                                    >
                                        {dataMenuListKnowLedges.map((sub1) => (
                                            <Menu.SubMenu
                                                popupClassName={
                                                    settingAccount.horizontal && settingAccount.theme !== "dark"
                                                        ? "globals-class-horizontal"
                                                        : ""
                                                }
                                                key={sub1.id}
                                                title={t(checkLanguage(sub1.name))}
                                            >
                                                {sub1.sub_menu.map((sub2) => (
                                                    <>
                                                        {sub2.sub_menu.length === 0 && (
                                                            <Menu.Item key={sub2.id}>
                                                                <LinkHref
                                                                    onClick={() => {
                                                                        localStorage.setItem(
                                                                            storeLocalStorage.BREAD_CRUMB,
                                                                            JSON.stringify([
                                                                                {
                                                                                    title: checkLanguage(sub1.name),
                                                                                    id: null,
                                                                                },
                                                                                {
                                                                                    title: checkLanguage(sub2.name),
                                                                                    id: sub2.id,
                                                                                },
                                                                            ])
                                                                        );
                                                                    }}
                                                                    path={`${urlWebsite.PAGE_KNOWLEDGE}/${
                                                                        sub2.id
                                                                    }?_title=${checkLanguage(sub2.name).replace(
                                                                        / /g,
                                                                        "-"
                                                                    )}`}
                                                                    title={t(checkLanguage(sub2.name))}
                                                                />
                                                            </Menu.Item>
                                                        )}
                                                        {sub2.sub_menu.length > 0 && (
                                                            <Menu.SubMenu
                                                                popupClassName={
                                                                    settingAccount.horizontal &&
                                                                    settingAccount.theme !== "dark"
                                                                        ? "globals-class-horizontal"
                                                                        : ""
                                                                }
                                                                key={sub2.id}
                                                                title={t(checkLanguage(sub2.name))}
                                                            >
                                                                {sub2?.sub_menu?.map((sub3) => (
                                                                    <>
                                                                        {sub3.sub_menu.length === 0 && (
                                                                            <Menu.Item key={sub3.id}>
                                                                                <LinkHref
                                                                                    onClick={() => {
                                                                                        localStorage.setItem(
                                                                                            storeLocalStorage.BREAD_CRUMB,
                                                                                            JSON.stringify([
                                                                                                {
                                                                                                    title: checkLanguage(
                                                                                                        sub1.name
                                                                                                    ),
                                                                                                    id: null,
                                                                                                },
                                                                                                {
                                                                                                    title: checkLanguage(
                                                                                                        sub2.name
                                                                                                    ),
                                                                                                    id: null,
                                                                                                },
                                                                                                {
                                                                                                    title: checkLanguage(
                                                                                                        sub3.name
                                                                                                    ),
                                                                                                    id: sub3.id,
                                                                                                },
                                                                                            ])
                                                                                        );
                                                                                    }}
                                                                                    path={`${
                                                                                        urlWebsite.PAGE_KNOWLEDGE
                                                                                    }/${sub3.id}?_title=${checkLanguage(
                                                                                        sub3.name
                                                                                    ).replace(/ /g, "-")}`}
                                                                                    title={t(checkLanguage(sub3.name))}
                                                                                />
                                                                            </Menu.Item>
                                                                        )}
                                                                        {sub3.sub_menu.length > 0 && (
                                                                            <Menu.SubMenu
                                                                                popupClassName={
                                                                                    settingAccount.horizontal &&
                                                                                    settingAccount.theme !== "dark"
                                                                                        ? "globals-class-horizontal"
                                                                                        : ""
                                                                                }
                                                                                key={sub3.id}
                                                                                title={t(checkLanguage(sub3.name))}
                                                                            >
                                                                                {sub3.sub_menu.map((sub4) => (
                                                                                    <>
                                                                                        {sub4.sub_menu.length === 0 && (
                                                                                            <Menu.Item key={sub4.id}>
                                                                                                <LinkHref
                                                                                                    onClick={() => {
                                                                                                        localStorage.setItem(
                                                                                                            storeLocalStorage.BREAD_CRUMB,
                                                                                                            JSON.stringify(
                                                                                                                [
                                                                                                                    {
                                                                                                                        title: checkLanguage(
                                                                                                                            sub1.name
                                                                                                                        ),
                                                                                                                        id: null,
                                                                                                                    },
                                                                                                                    {
                                                                                                                        title: checkLanguage(
                                                                                                                            sub2.name
                                                                                                                        ),
                                                                                                                        id: null,
                                                                                                                    },
                                                                                                                    {
                                                                                                                        title: checkLanguage(
                                                                                                                            sub3.name
                                                                                                                        ),
                                                                                                                        id: null,
                                                                                                                    },
                                                                                                                    {
                                                                                                                        title: checkLanguage(
                                                                                                                            sub4.name
                                                                                                                        ),
                                                                                                                        id: sub4.id,
                                                                                                                    },
                                                                                                                ]
                                                                                                            )
                                                                                                        );
                                                                                                    }}
                                                                                                    path={`${
                                                                                                        urlWebsite.PAGE_KNOWLEDGE
                                                                                                    }/${
                                                                                                        sub4.id
                                                                                                    }?_title=${checkLanguage(
                                                                                                        sub4.name
                                                                                                    ).replace(
                                                                                                        / /g,
                                                                                                        "-"
                                                                                                    )}`}
                                                                                                    title={t(
                                                                                                        checkLanguage(
                                                                                                            sub4.name
                                                                                                        )
                                                                                                    )}
                                                                                                />
                                                                                            </Menu.Item>
                                                                                        )}
                                                                                        {sub4.sub_menu.length > 0 && (
                                                                                            <Menu.SubMenu
                                                                                                popupClassName={
                                                                                                    settingAccount.horizontal &&
                                                                                                    settingAccount.theme !==
                                                                                                        "dark"
                                                                                                        ? "globals-class-horizontal"
                                                                                                        : ""
                                                                                                }
                                                                                                key={sub4.id}
                                                                                                title={t(
                                                                                                    checkLanguage(
                                                                                                        sub4.name
                                                                                                    )
                                                                                                )}
                                                                                            >
                                                                                                {sub4.sub_menu.map(
                                                                                                    (sub5) => (
                                                                                                        <Menu.Item
                                                                                                            key={
                                                                                                                sub5.id
                                                                                                            }
                                                                                                        >
                                                                                                            <LinkHref
                                                                                                                onClick={() => {
                                                                                                                    localStorage.setItem(
                                                                                                                        storeLocalStorage.BREAD_CRUMB,
                                                                                                                        JSON.stringify(
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    title: checkLanguage(
                                                                                                                                        sub1.name
                                                                                                                                    ),
                                                                                                                                    id: null,
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    title: checkLanguage(
                                                                                                                                        sub2.name
                                                                                                                                    ),
                                                                                                                                    id: null,
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    title: checkLanguage(
                                                                                                                                        sub3.name
                                                                                                                                    ),
                                                                                                                                    id: null,
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    title: checkLanguage(
                                                                                                                                        sub4.name
                                                                                                                                    ),
                                                                                                                                    id: null,
                                                                                                                                },
                                                                                                                                {
                                                                                                                                    title: checkLanguage(
                                                                                                                                        sub5.name
                                                                                                                                    ),
                                                                                                                                    id: sub5.id,
                                                                                                                                },
                                                                                                                            ]
                                                                                                                        )
                                                                                                                    );
                                                                                                                }}
                                                                                                                path={`${
                                                                                                                    urlWebsite.PAGE_KNOWLEDGE
                                                                                                                }/${
                                                                                                                    sub5.id
                                                                                                                }?_title=${checkLanguage(
                                                                                                                    sub5.name
                                                                                                                ).replace(
                                                                                                                    / /g,
                                                                                                                    "-"
                                                                                                                )}`}
                                                                                                                title={t(
                                                                                                                    checkLanguage(
                                                                                                                        sub5.name
                                                                                                                    )
                                                                                                                )}
                                                                                                            />
                                                                                                        </Menu.Item>
                                                                                                    )
                                                                                                )}
                                                                                            </Menu.SubMenu>
                                                                                        )}
                                                                                    </>
                                                                                ))}
                                                                            </Menu.SubMenu>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </Menu.SubMenu>
                                                        )}
                                                    </>
                                                ))}
                                            </Menu.SubMenu>
                                        ))}
                                    </Menu.SubMenu>
                                )
                            )}
                        </Menu>
                    </WrapperScroll>
                </div>
            </div>
            {openMenu && <Coating onClick={() => setOpenMenu(!openMenu)} />}
        </>
    );
};

const LinkHref = ({ title, path, onClick, outWeb }) => {
    const { t } = useTranslation();
    return outWeb ? (
        <a href={path} target="_blank" rel="noopener noreferrer">
            {t(title)}
        </a>
    ) : (
        <NavLink to={path} onClick={onClick} end>
            {t(title)}
        </NavLink>
    );
};
