import { createSlice } from "@reduxjs/toolkit";

import { onGetSearchKnowLedges, onGetDetailKnowLedges } from "./path-api";

export const sliceSearch = createSlice({
    name: "search",
    initialState: {
        data: null,
        loading: false,
        dataDetail: null,
        loadingDetail: false,
    },
    extraReducers: {
        [onGetSearchKnowLedges.pending]: (state) => {
            state.loading = true;
        },
        [onGetSearchKnowLedges.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        [onGetSearchKnowLedges.rejected]: (state) => {
            state.loading = false;
            state.data = [];
        },
        // get detail knowledges
        [onGetDetailKnowLedges.pending]: (state) => {
            state.loadingDetail = true;
        },
        [onGetDetailKnowLedges.fulfilled]: (state, action) => {
            state.dataDetail = action.payload;
            state.loadingDetail = false;
        },
    },
});
const { reducer } = sliceSearch;
export default reducer;
