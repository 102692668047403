import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const LinkHref = ({ outWeb, path, title, className, children, onClick }) => {
    const { t } = useTranslation();
    return outWeb ? (
        <a href={path} target="_blank" rel="noopener noreferrer" className={className}>
            {t(title)} {children}
        </a>
    ) : (
        <Link to={path} className={className} onClick={onClick && onClick}>
            {t(title)} {children}
        </Link>
    );
};
