import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { VscHome } from "react-icons/vsc";
import { BiChevronRight } from "react-icons/bi";

export const PageHeader = ({ sub, className }) => {
    const { t } = useTranslation();
    return sub ? (
        <div className={clsx("flex items-center", className)}>
            <Link to="/">
                <VscHome className="text-lg" />
            </Link>
            {sub.map(({ className, title, url, font }, index) => (
                <p className="text-basis flex items-center" key={index.toString()}>
                    <BiChevronRight className="text-[1.125rem]" />
                    {url ? (
                        <Link to={url} className="text-[#007bff] hover:text-[#007bff] hover:underline">
                            {t(title)}
                        </Link>
                    ) : (
                        <p className={clsx(className, font && "w-full max-w-[300px] truncate font-semibold")}>
                            {t(title)}
                        </p>
                    )}
                </p>
            ))}
        </div>
    ) : null;
};
