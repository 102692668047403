import * as React from "react";

import { WrapperScroll } from "layout";
import { FormAddEditEvent } from "page/events/components";

export const ModalEditEvent = ({ _uid }) => {
    return (
        <WrapperScroll className="hover max-h-[80vh]">
            <FormAddEditEvent _uid={_uid} isEdit={true} />
        </WrapperScroll>
    );
};
