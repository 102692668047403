import { useTranslation } from "react-i18next";
import { Form, Input, Spin } from "antd";

import { WrapperScroll, NoData } from "layout";

import { SearchOutlined } from "@ant-design/icons";

export const AvailableProducts = ({ data, onSetKeyword, keyword, loading, onAddToProductListProduct }) => {
    const { t } = useTranslation();
    return (
        <Form.Item label={t("Available products {{value}}", { value: data.length })} className="items-input mt-2 grid">
            <div className="rounded-[2px] border border-[#d9d9d9]  dark:divide-[#424b64] dark:border-[#424b64]">
                <div className="flex items-center gap-1 border-b border-[#d9d9d9] dark:border-[#424b64] dark:bg-[#2e3446]">
                    <SearchOutlined className="ml-2 text-[0.813rem]" />
                    <Input
                        placeholder={`${t("Search")}...`}
                        bordered={false}
                        onChange={(e) => onSetKeyword(e.target.value)}
                        allowClear
                    />
                </div>
                <WrapperScroll className="hover relative h-full max-h-[158px] min-h-[158px] p-1">
                    {data.length > 0
                        ? data.map((item) => (
                              <div
                                  onClick={() => onAddToProductListProduct(item)}
                                  key={item.id}
                                  className="flex cursor-pointer flex-row items-center gap-2 rounded p-1 hover:bg-[#d9d9d9] dark:hover:bg-[#1c1f2b]"
                              >
                                  <h6>{item.name}</h6>
                              </div>
                          ))
                        : !loading &&
                          keyword.length > 2 && (
                              <div className="absolute h-full w-full">
                                  <NoData />
                              </div>
                          )}
                    {loading && (
                        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[#f3f3f936] dark:bg-[#2e3446]">
                            <Spin className="absolute left-2/4 top-[40%]" />
                        </div>
                    )}
                </WrapperScroll>
            </div>
        </Form.Item>
    );
};
