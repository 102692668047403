import clsx from "clsx";
import moment from "moment";

export const FormatTime = ({
    start_time,
    end_time,
    className = "2xl:flex-col 2xl:gap-[2px]",
    is_set_time = true,
    display_format_fate = "DD/MM/YYYY",
    is_height_color_time = true,
}) => {
    const datetime_start = moment(new Date(`${start_time} +00:00`)).format("DD/MM/YYYY");
    const datetime_stop = moment(new Date(`${end_time} +00:00`)).format("DD/MM/YYYY");

    return moment(datetime_start, "DD/MM/YYYY").valueOf() === moment(datetime_stop, "DD/MM/YYYY").valueOf() ? (
        <div className={clsx("flex flex-row items-center gap-1", className)}>
            {is_set_time ? (
                <p> {moment(new Date(`${start_time} +00:00`)).format(display_format_fate)}</p>
            ) : (
                <p> {moment(start_time).format(display_format_fate)}</p>
            )}
            <div className="flex gap-1 2xl:gap-[2px]">
                {is_set_time ? (
                    <>
                        <p className="text-[#03942c]"> {moment(new Date(`${start_time} +00:00`)).format("HH:mm")}</p>
                        <p>-</p>
                        <p className="text-[#dc3545]"> {moment(new Date(`${end_time} +00:00`)).format("HH:mm")}</p>
                    </>
                ) : (
                    <>
                        <p className="text-[#03942c]"> {moment(start_time).format("HH:mm")}</p>
                        <p>-</p>
                        <p className="text-[#dc3545]"> {moment(end_time).format("HH:mm")}</p>
                    </>
                )}
            </div>
        </div>
    ) : is_set_time ? (
        <div className={clsx("flex flex-row gap-1", className)}>
            <div className="flex gap-[2px]">
                <p className={clsx("text-center", !is_height_color_time && "text-[#03942c]")}>
                    {moment(new Date(`${start_time} +00:00`)).format(display_format_fate)}
                </p>
                <p className={is_height_color_time && "text-[#03942c]"}>
                    {moment(new Date(`${start_time} +00:00`)).format("HH:mm")}
                </p>
            </div>
            <div className="flex gap-[2px]">
                <p lassName={clsx("text-center", !is_height_color_time && "text-[#dc3545]")}>
                    {moment(new Date(`${end_time} +00:00`)).format(display_format_fate)}
                </p>
                <p className={is_height_color_time && "text-[#dc3545]"}>
                    {moment(new Date(`${end_time} +00:00`)).format("HH:mm")}
                </p>
            </div>
        </div>
    ) : (
        <div className={clsx("flex flex-row gap-1", className)}>
            <div className="flex gap-[2px]">
                <p className={clsx("text-center", !is_height_color_time && "text-[#03942c]")}>
                    {moment(start_time).format(display_format_fate)}
                </p>
                <p className={is_height_color_time && "text-[#03942c]"}>{moment(start_time).format("HH:mm")}</p>
            </div>
            <div className="flex gap-[2px]">
                <p className={clsx("text-center", !is_height_color_time && "text-[#dc3545]")}>
                    {moment(end_time).format(display_format_fate)}
                </p>
                <p className={is_height_color_time && "text-[#dc3545]"}>{moment(end_time).format("HH:mm")}</p>
            </div>
        </div>
    );
};
