import { createSlice } from "@reduxjs/toolkit";

import { ModalTypeEnum } from "constant";

const initialStateModal = {
    type: ModalTypeEnum,
    open: false,
    title: "",
    modal: {},
    data: {},
};

export const sliceModal = createSlice({
    name: "modal",
    initialState: initialStateModal,
    reducers: {
        openModal: (state, action) => {
            const { type, title, data, modal } = action.payload;
            return {
                ...state,
                open: true,
                type,
                title,
                data,
                modal,
            };
        },
    },
});
const { actions, reducer } = sliceModal;
export const { openModal } = actions;
export default reducer;
