import { urlWebsite } from "constant";
import { NoData } from "./no-data";

import { Section } from "./section";
import { LinkHref } from "customize";
import { useModal } from "hooks";

export const WrapperKnowLedge = ({ data, loading, openModal }) => {
    const { toggleModal, ModalTypeEnum } = useModal();

    if (loading) {
        return (
            <Section>
                <Loading />
            </Section>
        );
    }
    if (data.length === 0 && !openModal) return <NoData />;

    return (
        <div className="grid grid-cols-1 gap-x-5 sm:grid-cols-2">
            {data.map((item) => (
                <div key={item.id}>
                    {openModal ? (
                        <p
                            className="flex cursor-pointer items-center gap-2 rounded-md p-2 font-medium hover:bg-[#f8f8fb] hover:text-[#007bff] hover:underline dark:hover:bg-[#1c1f2b] dark:hover:text-[#f8f8fb]"
                            data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom"
                            onClick={() => {
                                toggleModal({
                                    title: item.name,
                                    type: ModalTypeEnum.MODAL_KNOWLEDGE_DETAIL,
                                    data: {
                                        id: item.id,
                                    },
                                    modal: {
                                        width: 1200,
                                    },
                                });
                            }}
                        >
                            <div className="h-[5px] w-[5px] rounded-full bg-[#495057]" />
                            {item.name}
                        </p>
                    ) : (
                        <LinkHref
                            path={`${urlWebsite.PAGE_KNOW_LEDGE_ID}/${item.id}`}
                            className="flex items-center gap-2 rounded-md p-2 font-medium hover:bg-[#f8f8fb] hover:text-[#007bff] hover:underline dark:hover:bg-[#1c1f2b] dark:hover:text-[#f8f8fb]"
                            data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom"
                        >
                            <div className="h-[5px] w-[5px] rounded-full bg-[#495057]" />
                            {item.name}
                        </LinkHref>
                    )}
                </div>
            ))}
        </div>
    );
};

const Loading = () => {
    return (
        <div className="grid gap-4">
            {[...Array(5)].map((_, index) => (
                <div className="flex justify-between gap-4" key={index.toString()}>
                    <div className="h-4 basis-6/12 animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                    <div className="h-4 basis-6/12 animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                </div>
            ))}
        </div>
    );
};
