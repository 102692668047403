import { axiosClient } from "./axios-client";

export const apiFeedback = {
    getAllFeedbacks: (page) => {
        const url = `/feedbacks/page/${page}`;
        return axiosClient.get(url);
    },
    getFeedbackDetailsId: (id) => {
        const url = `/feedbacks/uid/${id}`;
        return axiosClient.get(url);
    },
    patchSaveFeedback: (payload) => {
        const { data, uid } = payload;
        const url = `/feedbacks/uid/${uid}`;
        return axiosClient.patch(url, data);
    },
    patchIgnoreFeedback: (payload) => {
        const { data, uid } = payload;
        const url = `/feedbacks/uid/${uid}`;
        return axiosClient.patch(url, data);
    },
};
