import clsx from "clsx";
import { HeadingTitle } from "layout";
import { useTranslation } from "react-i18next";

export const ItemInfo = ({ children, title, className = "sm:max-w-[200px]", option, style, childrenClassName }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-x-4 gap-y-2 py-2 md:flex-row" style={style}>
            <HeadingTitle
                title={t(title)}
                className={clsx(
                    "mb-0 w-full max-w-full self-start dark:text-slate-200 sm:max-w-[160px] md:self-center",
                    className
                )}
                style={{ fontSize: "0.938rem" }}
                option={option}
            />
            <div className={clsx("sm:w-full", childrenClassName)}>{children}</div>
        </div>
    );
};
