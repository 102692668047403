import * as React from "react";
import clsx from "clsx";

import { EyeOutlined, EyeInvisibleOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export const InputField = ({
    iconClassName = "text-white",
    name,
    label,
    type,
    placeholder,
    messageError,
    isShowIconPassword = false,
    iconShowPasswordClassName = "text-white",
    Icon = UserOutlined,
    className = "mt-6",
    inputClassName = "py-[10px] px-[12px] text-white placeholder:text-[0.813rem] placeholder:text-[#8b91ca]",
    wrapperClassName = "bg-[#3d46a7] rounded-[0.25rem]",
    register,
    validateOptions,
    validate,
    ...props
}) => {
    const { t } = useTranslation();
    const [isShowPassword, setIsShowPassword] = React.useState(false);
    const IconShowPassword = isShowPassword ? EyeOutlined : EyeInvisibleOutlined;
    return (
        <div className={clsx("w-full", className)}>
            {label && <label className="mb-2 block font-medium text-white">{label}</label>}
            <div className={clsx("flex w-full items-center", wrapperClassName)}>
                {isShowIconPassword ? (
                    <LockOutlined className={clsx("py-[6px] px-3 text-[1.063rem]", iconClassName)} />
                ) : (
                    <Icon className={clsx("py-[6px] px-3 text-[1.063rem]", iconClassName)} />
                )}
                <input
                    {...validate(name, { ...validateOptions })}
                    {...props}
                    type={isShowPassword ? type : !isShowIconPassword ? type : "password"}
                    label={label}
                    name={name}
                    placeholder={t(placeholder)}
                    className={clsx("w-full bg-transparent focus:outline-none", inputClassName)}
                />
                {isShowIconPassword && (
                    <IconShowPassword
                        onClick={() => setIsShowPassword(!isShowPassword)}
                        className={clsx("py-[6px] px-3 text-[1.063rem]", iconShowPasswordClassName)}
                    />
                )}
            </div>
            {messageError && <p className={"mt-1 text-[0.75rem] text-[#f46a6a]"}>{t(messageError)}</p>}
        </div>
    );
};
