import * as React from "react";
import { Switch, Form, Radio, notification } from "antd";
import styled from "styled-components";

import { Section } from "layout";
import { useAccount, useContextControllers } from "hooks";
import { Button } from "customize";
import { storeLocalStorage } from "constant";
import { VscSaveAs } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

export const ModalSettingAccount = () => {
    const { t } = useTranslation();
    const { settingAccount, setSettingAccount } = useContextControllers();
    const { handleGetNotificationChannel, handlePatchNotificationChannel } = useAccount();
    const [channelNotification, setChannelNotification] = React.useState(null);
    const [loadingGetChannel, setLoadingGetChannel] = React.useState(true);
    const [loadingPatchChannel, setLoadingPatchChannel] = React.useState(false);

    const { isCalendar, theme, is_modal_event, sub_menu } = settingAccount;

    const handleChangeSettingAccount = async (value) => {
        const { isCalendar, isDarkMode, isModalEvent, horizontal, channel, sub_menu } = value;
        if (channelNotification !== channel) {
            setLoadingPatchChannel(true);
            const result = await handlePatchNotificationChannel(channel);
            setLoadingPatchChannel(false);
            if (result.payload) {
                setChannelNotification(channel);
            }
            if (result?.error) {
                const error = JSON.stringify(result?.error);
                const paresJon = JSON.parse(error);
                const message = JSON.stringify(paresJon.message);
                const messageParse = JSON.parse(message);
                const messageStringify = JSON.parse(messageParse);
                switch (messageStringify.message) {
                    case "INTERNAL_ERROR":
                        return notification.error({
                            message: <h6>{t("Notification")}</h6>,
                            description: <p>{t("Backend error, contact system admin")}</p>,
                        });
                    case "CHANNEL_NOT_CONNECT":
                        return notification.error({
                            message: <h6>{t("Notification")}</h6>,
                            description: <p>{t("Is not link with user account")}</p>,
                        });
                    case "CHANNEL_NOT_SUPPORT":
                        return notification.error({
                            message: <h6>{t("Notification")}</h6>,
                            description: <p>{t("Is not support push notification")}</p>,
                        });
                    default:
                        return notification.error({
                            message: <h6>{t("Notification")}</h6>,
                            description: <p>{t("Backend error, contact system admin")}</p>,
                        });
                }
            }
        }
        const payload = {
            ...settingAccount,
            isCalendar: isCalendar,
            theme: isDarkMode ? "dark" : "light",
            is_modal_event: isModalEvent,
            horizontal: horizontal,
            sub_menu: sub_menu,
        };
        notification.success({
            message: <h6>{t("Notification")}</h6>,
            description: <p>{t("Update successfully")}</p>,
        });
        setSettingAccount(payload);
        localStorage.setItem(storeLocalStorage.SETTING_ACCOUNT, JSON.stringify(payload));
    };

    React.useEffect(() => {
        (async () => {
            const result = await handleGetNotificationChannel();
            if (result.payload) {
                setChannelNotification(result.payload.channel_name);
                setLoadingGetChannel(false);
            }
            if (result.error) {
                setLoadingGetChannel(false);
                setChannelNotification(null);
            }
        })();
    }, [handleGetNotificationChannel]);

    if (loadingGetChannel)
        return (
            <div>
                <div className="grid gap-3">
                    {[...Array(4)].map((_, index) => (
                        <div key={index.toString()} className="flex justify-between gap-4">
                            <div className="h-5 w-full max-w-[330px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                            <div className="h-5 w-full max-w-[20px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                        </div>
                    ))}
                </div>
            </div>
        );

    return (
        <Section className="p-[0px]">
            <Form
                layout="vertical"
                onFinish={handleChangeSettingAccount}
                initialValues={{
                    isCalendar: isCalendar,
                    isDarkMode: theme === "dark" ? true : false,
                    isModalEvent: is_modal_event,
                    horizontal: settingAccount.horizontal,
                    channel: channelNotification,
                    sub_menu: sub_menu,
                }}
            >
                <FormStyled name="channel" label={t("Notification push channel")}>
                    <RadioRadio>
                        <RadioItem value="viber">Viber</RadioItem>
                        <RadioItem value="telegram">Telegram</RadioItem>
                    </RadioRadio>
                </FormStyled>
                <FormStyled name="isCalendar" label={t("Display Calender")} valuePropName="checked">
                    <Switch size="small" />
                </FormStyled>
                <FormStyled name="isModalEvent" label={t("Modal Events")} valuePropName="checked">
                    <Switch size="small" />
                </FormStyled>
                <FormStyled name="isDarkMode" label={t("Dark Mode")} valuePropName="checked">
                    <Switch size="small" />
                </FormStyled>
                <Horizontal name="horizontal" label={t("Horizontal")} valuePropName="checked">
                    <Switch size="small" />
                </Horizontal>
                <Horizontal name="sub_menu" label={t("Sub Knowledges")} valuePropName="checked">
                    <Switch size="small" />
                </Horizontal>
                <Button
                    label="Save changes"
                    className="mt-4"
                    BgSuccess
                    Icon={VscSaveAs}
                    loading={loadingPatchChannel}
                />
            </Form>
        </Section>
    );
};

const RadioItem = styled(Radio)`
    margin: 0;
    span {
        padding: 0;
    }
    .ant-radio {
        padding-right: 5px;
    }
`;
const RadioRadio = styled(Radio.Group)`
    display: flex;
    gap: 1rem;
    justify-content: end;
`;

const FormStyled = styled(Form.Item)`
    .ant-row.ant-form-item-row {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
    }
    div.ant-form-item-control {
        text-align: right !important;
        display: block;
    }
    div.ant-form-item-label {
        padding: 0;
    }
    @media (max-width: 580px) {
        .ant-row.ant-form-item-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
        }
    }
`;

const Horizontal = styled(Form.Item)`
    flex-direction: inherit !important;
    .ant-row.ant-form-item-row {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
    }
    div.ant-form-item-control {
        text-align: right !important;
        display: block;
    }
    div.ant-form-item-label {
        padding: 0;
    }
    .ant-row.ant-form-item-row {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 1024px) {
        display: none !important;
    }
`;
