import {
    FileTextOutlined,
    ProfileOutlined,
    ReconciliationOutlined,
    MailOutlined,
    ProjectOutlined,
    CloudServerOutlined,
} from "@ant-design/icons";
import {
    VscBook,
    VscCalendar,
    VscDashboard,
    VscFeedback,
    VscHome,
    VscPieChart,
    VscTools,
    VscVerified,
} from "react-icons/vsc";

import { iconAngry, iconFlagUsa, iconFlagVn, iconHappy, iconLikes, iconLoveLikes, iconSad } from "assets/icon";

export const storeLocalStorage = {
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    TRANSLATION: "translation",
    LANGUAGE: "language",
    DROP_DOWN_MENU: "is_drop_down_menu",
    USERNAME_lOGIN: "solarbk_oauth2_id",
    BREAD_CRUMB: "breadcrumb",
    SETTING_ACCOUNT: "setting_account",
    VERSION: "version",
};

export const defaultFilterProducts = [
    {
        id: "_default",
        name: "Default",
        value: "true",
    },
    {
        id: "_sku",
        name: "SKU",
        value: "true",
    },
    {
        id: "_cost",
        name: "Goods receipt",
        value: "true",
    },
];

export const ModalTypeEnum = {
    NULL: "NULL",
    DETAIL_FEEDBACK: "DETAIL_FEEDBACK",
    HAPPY_BIRTHDAY: "HAPPY_BIRTHDAY",
    EVENT_DETAIL: "EVENT_DETAIL",
    EVENT_CONFIRM_LATER: "EVENT_CONFIRM_LATER",
    DELETE_EVENT: "DELETE_EVENT",
    BUSINESS_TRIPS_EVENT: "BUSINESS_TRIPS_EVENT",
    SALES_PRODUCTS_DETAIL: "SALES_PRODUCTS_DETAIL",
    CHECK_AVAILABLE_EVENTS: "CHECK_AVAILABLE_EVENTS",
    EDIT_PRODUCTS_COST_SALES: "EDIT_PRODUCTS_COST_SALES",
    PRODUCTS_SALES_CODE_BOOK: "PRODUCTS_SALES_CODE_BOOK",
    ADD_SALES_LEAD: "ADD_SALES_LEAD",
    SALES_LEADS_DETAIL: "SALES_LEADS_DETAIL",
    ADD_SKU_QUOTATION: "ADD_SKU_QUOTATION",
    ADD_QUOTATION_DISCOUNT: "ADD_QUOTATION_DISCOUNT",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    DELETE_QUOTATION: "DELETE_QUOTATION",
    REVIEW_QUOTATION: "REVIEW_QUOTATION",
    QUICK_CREATE_EVENT: "QUICK_CREATE_EVENT",
    SETTING_ACCOUNT: "SETTING_ACCOUNT",
    DETAIL_EVENT: "DETAIL_EVENT",
    CONFIRM_RESOURCE: "CONFIRM_RESOURCE",
    ADD_EVENT: "ADD_EVENT",
    EDIT_EVENT: "EDIT_EVENT",
    MODAL_ACCOUNT_BUSY: "MODAL_ACCOUNT_BUSY",
    MODAL_DETAIL_KPI_POINT: "MODAL_DETAIL_KPI_POINT",
    MODAL_RESOURCE_LIST: "MODAL_RESOURCE_LIST",
    MODAL_AMBASSADOR: "MODAL_AMBASSADOR",
    MODAL_ADD_AMBASSADOR: "MODAL_ADD_AMBASSADOR",
    MODAL_TREE_KNOWLEDGE: "MODAL_TREE_KNOWLEDGE",
    MODAL_KNOWLEDGE_DETAIL: "MODAL_KNOWLEDGE_DETAIL",
};

export const DrawerTypeEnum = {
    NULL: "NULL",
    VIEW_PROFILE: "VIEW_PROFILE",
};

export const urlWebsite = {
    HOME_PAGE: "/",
    ERP: "https://erp.solarbk.vn/",
    EMAIL: "https://mail.solarbk.vn/",
    ERP_NEXT: "https://erp-next.solarbk.vn",
    ELEARNING: "https://elearning.solarbk.vn",
    REDMINE: "https://project.solarbk.vn",
    PAGE_PROFILE: "/profile",
    PAGE_KNOWLEDGE: "/knowledge",
    PAGE_KNOW_LEDGE_ID: "/knowledge-id",
    FEED_BACKS: "/feedbacks",
    EVENTS: "/events",
    EVENTS_ADD: "/events/add",
    EVENTS_EDIT: "/events/edit",
    SALES_PRODUCTS: "/sales/products",
    SALES_LEADS: "/sales/leads",
    SALES_PRODUCTS_ADD: "/sales/products/add",
    SALES_PRODUCTS_EDIT: "/sales/products/edit",
    SALES_QUOTATION: "/sales/quotation",
    SALES_AMBASSADOR: "/sales/ambassador",
    SALES_QUOTATION_ADD: "/sales/quotation/add",
    SALES_QUOTATION_EDIT: "/sales/quotation/edit",
    NEXT_MEETING: "/next-meeting",
    TASKS_ME: "/tasks-me",
    MS_SERVICE: "/ms-service",
    HR_DASHBOARD: "/dashboard/hr",
    GDS_DASHBOARD: "https://gds.solarbk.vn/",
    MY_KPI: "/dashboard/my-kpi",
    TREE_KNOWLEDGE: "/tree-knowledge",
    OWNCLOUD: "https://owncloud.solarbk.vn",
};

export const colorTooltip = "#74788d";

export const listTranslations = [
    {
        id: "vi-VN",
        icon: iconFlagVn,
        name: "Tiếng Việt",
    },
    {
        id: "en-US",
        icon: iconFlagUsa,
        name: "English",
    },
];

export const navigateMenu = [
    {
        key: urlWebsite.HOME_PAGE,
        title: "Home",
        alias: "dashboard:*",
        path: urlWebsite.HOME_PAGE,
        icon: VscHome,
    },
    {
        key: "Dashboard",
        title: "Dashboard",
        alias: "dashboard:*",
        icon: VscDashboard,
        subMenu: [
            {
                key: urlWebsite.MY_KPI,
                title: "My KPI",
                path: urlWebsite.MY_KPI,
                outWebsite: false,
            },
            {
                key: urlWebsite.HR_DASHBOARD,
                title: "HR",
                path: urlWebsite.HR_DASHBOARD,
                outWebsite: false,
            },
            {
                key: "Corporate Dashboard",
                title: "Corporate Dashboard",
                path: urlWebsite.GDS_DASHBOARD,
                outWebsite: true,
            },
        ],
    },
    {
        key: urlWebsite.FEED_BACKS,
        title: "Feedbacks",
        alias: "feedbacks:*",
        path: urlWebsite.FEED_BACKS,
        icon: VscFeedback,
    },
    {
        key: urlWebsite.EVENTS,
        title: "Calendar",
        alias: "events:*",
        path: urlWebsite.EVENTS,
        icon: VscCalendar,
    },
    {
        key: "Sales",
        title: "Sales & CRM",
        alias: "sales:*",
        icon: VscPieChart,
        subMenu: [
            {
                key: urlWebsite.SALES_LEADS,
                title: "Chance",
                path: urlWebsite.SALES_LEADS,
                outWebsite: false,
            },
            {
                key: urlWebsite.SALES_PRODUCTS,
                title: "Products",
                path: urlWebsite.SALES_PRODUCTS,
                outWebsite: false,
            },
            {
                key: urlWebsite.SALES_QUOTATION,
                title: "Quotation",
                path: urlWebsite.SALES_QUOTATION,
                outWebsite: false,
            },
            {
                key: urlWebsite.SALES_AMBASSADOR,
                title: "SolarBK Ambassador",
                path: urlWebsite.SALES_AMBASSADOR,
                outWebsite: false,
            },
        ],
    },
];

export const emojiDefault = [
    {
        id: 1,
        icon: iconAngry,
    },
    {
        id: 2,
        icon: iconSad,
    },
    {
        id: 3,
        icon: iconLikes,
    },
    {
        id: 4,
        icon: iconHappy,
    },
    {
        id: 5,
        icon: iconLoveLikes,
    },
];

export const linkWebsite = [
    {
        title: "ERP Next",
        path: urlWebsite.ERP_NEXT,
        icon: ProfileOutlined,
        outWebsite: true,
    },
    {
        title: "ERP",
        path: urlWebsite.ERP,
        icon: ProjectOutlined,
        outWebsite: true,
    },
    {
        title: "Owncloud",
        path: urlWebsite.OWNCLOUD,
        icon: CloudServerOutlined,
        outWebsite: true,
    },
    {
        title: "Elearning",
        path: urlWebsite.ELEARNING,
        icon: ReconciliationOutlined,
        outWebsite: true,
    },
    {
        title: "Redmine",
        path: urlWebsite.REDMINE,
        icon: FileTextOutlined,
        outWebsite: true,
    },
    {
        title: "Email",
        path: urlWebsite.EMAIL,
        icon: MailOutlined,
        outWebsite: true,
    },
];

export const translationMenu = [
    {
        title: "Tài liệu hướng dẫn",
        value: "Document",
    },
    {
        title: "Bán hàng",
        value: "Sales",
    },
    {
        title: "Hướng dẫn chung",
        value: "General",
    },
    {
        title: "Văn Phòng IREX",
        value: "Office IREX",
    },
    {
        title: "Văn Phòng Lê Văn Thịnh",
        value: "Office Le Van Thinh",
    },
    {
        title: "Các module",
        value: "Modules",
    },
    {
        title: "Tổng quan ERP",
        value: "ERP Overview",
    },
    {
        title: "Hỗ trợ họp",
        value: "Support Meeting",
    },
    {
        title: "Tài liệu khác",
        value: "Other Documents",
    },
];

export const maxAllowRegister = ["5", "10", "15", "20", "30", "40", "50", "60", "70", "80", "90", "100"];

export const minutesDefault = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50"];

export const stateListQuotation = [
    {
        name: "Draft",
        value: "draft",
        Icon: VscBook,
    },
    {
        name: "Tech Approved",
        value: "tech_approved",
        Icon: VscTools,
    },
    {
        name: "SA Approved",
        value: "approved",
        Icon: VscVerified,
    },
];

export const optionsNextMeeting = [
    {
        title: "Today",
        value: "today",
    },
    {
        title: "Tomorrow",
        value: "tomorrow",
    },
    {
        title: "This week",
        value: "this_week",
    },
    {
        title: "Next week",
        value: "next_week",
    },
    {
        title: "This month",
        value: "this_month",
    },
];

export const colorSuccess = "#03942c"; // update, add
export const colorSecondary = "#6c757d"; // cancel
export const colorWarning = "#ffc107"; // warning
export const colorDanger = "#dc3545"; // delete
export const colorInfo = "#0dcaf0"; // info
export const colorPrimary = "#0d6efd"; //edit

export const TYPE_PROJECT = "WEB"; // APP or WEB
