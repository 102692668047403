import { createSlice } from "@reduxjs/toolkit";

import { onGetAllQuotations, onGetSearchQuotations } from "./path-api";
import { onDeleteQuotation } from "features";

const initialState = {
    dataQuotation: null,
    loading: false,
    pagination: {
        page: null,
        limit: null,
        total: null,
        sortable: null,
    },
};

export const sliceQuotation = createSlice({
    name: "quotation",
    initialState: initialState,
    extraReducers: {
        [onGetAllQuotations.pending]: (state) => {
            state.loading = true;
        },
        [onGetAllQuotations.fulfilled]: (state, action) => {
            const { items, page, limit, total, sortable } = action.payload;
            state.dataQuotation = items;
            state.loading = false;
            state.pagination = {
                page: page,
                limit: limit,
                total: total,
                sortable: sortable,
            };
        },
        //
        [onGetSearchQuotations.pending]: (state) => {
            state.loading = true;
        },
        [onGetSearchQuotations.fulfilled]: (state, action) => {
            const { items, page, limit, total, sortable } = action.payload;
            state.dataQuotation = items;
            state.loading = false;
            state.pagination = {
                page: page,
                limit: limit,
                total: total,
                sortable: sortable,
            };
        },
        // delete quotation
        [onDeleteQuotation.fulfilled]: (state, action) => {
            const { id } = action.payload;
            const { dataQuotation } = state;
            const index = dataQuotation?.findIndex((item) => item.id === id);
            if (index !== -1) {
                dataQuotation?.splice(index, 1);
            }
        },
    },
});

const { reducer } = sliceQuotation;
export default reducer;
