import { createSlice } from "@reduxjs/toolkit";
import { onGetStatic, onGetKpiReport, onGetLeavesBizStatic, onGetFeedbackStatic } from "./path-api";

const initialState = {
    dataStatic: null,
    dataKpiReport: null,
    dataLeavesBizStatic: null,
    dataFeedbackStatic: null,
};

export const sliceHrDashboard = createSlice({
    name: "hr-dashboard",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [onGetStatic.fulfilled]: (state, action) => {
            state.dataStatic = action.payload;
        },

        // get kpi report
        [onGetKpiReport.pending]: (state) => {
            state.dataKpiReport = null;
        },
        [onGetKpiReport.fulfilled]: (state, action) => {
            state.dataKpiReport = action.payload.data;
        },
        // get leaves biz static
        [onGetLeavesBizStatic.fulfilled]: (state, action) => {
            state.dataLeavesBizStatic = action.payload.data;
        },
        // get feedback static
        [onGetFeedbackStatic.fulfilled]: (state, action) => {
            state.dataFeedbackStatic = action.payload;
        },
    },
});

const { reducer } = sliceHrDashboard;
export default reducer;
