import { createSlice } from "@reduxjs/toolkit";
import { onSearchUserSelect, onSearchUserWatcher } from "./path-api";

const initialState = {
    loading: false,
    dataUser: null,
    dataWatcher: null,
    loadingDataUser: false,
};

export const sliceUser = createSlice({
    name: "user",
    initialState: initialState,
    extraReducers: {
        [onSearchUserSelect.pending]: (state, action) => {
            state.loading = true;
        },
        [onSearchUserSelect.fulfilled]: (state, action) => {
            state.dataUser = action.payload;
            state.loading = false;
        },
        // get use watcher
        [onSearchUserWatcher.pending]: (state, action) => {
            state.loadingDataUser = true;
        },
        [onSearchUserWatcher.fulfilled]: (state, action) => {
            state.dataWatcher = action.payload;
            state.loadingDataUser = false;
        },
    },
});

const { reducer } = sliceUser;
export default reducer;
