export const storeModal = (state) => state.storeModal;
export const storeAccount = (state) => state.storeAccount;
export const storeSearch = (state) => state.storeSearch;
export const storeTasks = (state) => state.storeTasks;
export const storeFeedback = (state) => state.storeFeedback;
export const storeEvent = (state) => state.storeEvent;
export const storeUser = (state) => state.storeUser;
export const storeEmail = (state) => state.storeEmail;
export const storeProducts = (state) => state.storeProducts;
export const storeSales = (state) => state.storeSales;
export const storeKnowLedge = (state) => state.storeKnowLedge;
export const storeQuotation = (state) => state.storeQuotation;
export const storeCompany = (state) => state.storeCompany;
export const storePartner = (state) => state.storePartner;
export const storeDrawer = (state) => state.storeDrawer;
export const storeHrDashboard = (state) => state.storeHrDashboard;
