import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { WrapperTag } from "layout";

export const ItemTableMobile = ({
    title,
    value,
    tag,
    url,
    valueClassName,
    className,
    outWeb,
    inWeb,
    dot,
    onClick,
    children,
    unit,
    unitClassName,
    transition = true,
    tagUrl,
    bgColorTagUrl,
    hoverTagUrl,
    roundedTagUrl,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={clsx(
                "flex items-center justify-between gap-[6px] py-[6px] hover:bg-[#e4e4e485] dark:hover:bg-[#1c1f2b]",
                className
            )}
        >
            <h6 className="self-center font-semibold dark:text-slate-200">
                {tag ? (
                    <WrapperTag title={value} bgColor="info" transition rounded={false}>
                        {dot && ":"}
                    </WrapperTag>
                ) : (
                    <>
                        {t(title)}
                        {dot && ":"}
                    </>
                )}
            </h6>
            {outWeb ? (
                <a
                    href={url}
                    target="_blank"
                    className={clsx("text-right capitalize hover:underline", valueClassName)}
                    rel="noopener noreferrer "
                >
                    {tagUrl ? (
                        <WrapperTag title={value} bgColor={bgColorTagUrl} hover={hoverTagUrl} rounded={roundedTagUrl} />
                    ) : (
                        t(value)
                    )}
                </a>
            ) : inWeb ? (
                <Link to={url} className={clsx("text-right hover:underline", valueClassName)}>
                    {t(value)}
                </Link>
            ) : children ? (
                children
            ) : (
                <h6 className={clsx("text-right capitalize", valueClassName)} onClick={onClick && onClick}>
                    {transition ? t(value) : value} {unit && <p className={unitClassName}>{unit}</p>}
                </h6>
            )}
        </div>
    );
};
