import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

import { CheckCircleOutlined, QuestionCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { colorTooltip } from "constant";

export const ConfirmStatus = ({ status, title }) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={status ? title : t("Unconfimred")} className="flex" zIndex={9999} color={colorTooltip}>
            {status === "1" && (
                <CheckCircleOutlined className="text-[.85rem] text-[#22a447] dark:text-[#22a447]" /> // 1: đã xác nhận
            )}
            {
                status === "2" && <MinusCircleOutlined className="text-[.85rem] text-[#dc3545] dark:text-[#dc3545]" /> // 2:Không tham dự
            }
            {status === "3" && (
                // 3: không xác nhận
                <QuestionCircleOutlined
                    className="text-[.85rem]"
                    style={{
                        color: "#ffc107",
                    }}
                />
            )}
            {!status && (
                <QuestionCircleOutlined className="text-[.85rem] text-[#424b64] dark:text-[#ffff]" /> // 3: chưa xác nhận
            )}
        </Tooltip>
    );
};
