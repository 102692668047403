import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

import { useAccount, storeFeedback } from "hooks";

import { onGetAllFeedback, onGetFeedbackDetailsId, onPatchSaveFeedback, onPatchIgnoreFeedback } from "features";

export const useFeedBack = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const resultFeedback = useSelector(storeFeedback);
    const { data, pagination, loading, detailFeedback, loadingDetailFeedback, loadingSaveFeedback } = resultFeedback;

    const { access_token } = useAccount();

    const handelGetAllFeedback = React.useCallback(
        (page) => access_token && dispatch(onGetAllFeedback(page)),
        [access_token, dispatch]
    );

    const handelGetFeedbackDetailsId = React.useCallback(
        (id) => access_token && dispatch(onGetFeedbackDetailsId(id)),
        [access_token, dispatch]
    );

    const handlePatchSaveFeedback = React.useCallback(
        (payload) => access_token && dispatch(onPatchSaveFeedback(payload)),
        [access_token, dispatch]
    );

    const handlePatchIgnoreFeedback = React.useCallback(
        (payload) => access_token && dispatch(onPatchIgnoreFeedback(payload)),
        [access_token, dispatch]
    );

    const handleSubmitFeedback = async (values) => {
        if (detailFeedback) {
            const userFeedBackList = detailFeedback.user_feedback.map(({ user_id }) => {
                for (const key in values) {
                    if (values[key].user_id) {
                        if (Number(user_id) === Number(values[key].user_id)) {
                            return values[key];
                        }
                    }
                }
            });
            const payload = {
                uid: detailFeedback.uid,
                data: {
                    comment: values.comment ? values.comment.trim() : values.comment,
                    fb_comunication: Number(values.Communication),
                    fb_quality: Number(values.Quality),
                    fb_time: Number(values.Time),
                    user_feedback: userFeedBackList,
                    is_ignore: false,
                },
            };
            const result = await handlePatchSaveFeedback(payload);
            if (result?.error) {
                notification.error({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("An error has occurred")}</p>,
                });
            }
            const req = unwrapResult(result);
            if (req) {
                notification.success({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("Feedback successful")}</p>,
                });
            }
        }
    };

    return {
        handlePatchSaveFeedback,
        handelGetAllFeedback,
        handelGetFeedbackDetailsId,
        handlePatchIgnoreFeedback,
        handleSubmitFeedback,
        loadingSaveFeedback,
        detailFeedback,
        loadingDetailFeedback,
        data,
        pagination,
        loading,
    };
};
