import * as React from "react";

import { UserContext } from "contexts";

export const useContextControllers = () => {
    const state = React.useContext(UserContext);
    const [openSearch, setOpenSearch] = state.isSearch;
    const [openMenu, setOpenMenu] = state.isMenu;
    const [translate, setTranslate] = state.translate;
    const [dropDownMenu, setDropDownMenu] = state.dropDownMenu;
    const [settingAccount, setSettingAccount] = state.settingAccount;
    const [uidEvent, setUidEvent] = state.uidEvent;
    const [statusEvent, setStatusEvent] = state.statusEvent;
    const [copyEvent, setCopyEvent] = state.copyEvent;

    return {
        openSearch,
        setOpenSearch,
        openMenu,
        setOpenMenu,
        translate,
        setTranslate,
        dropDownMenu,
        setDropDownMenu,
        settingAccount,
        setSettingAccount,
        uidEvent,
        setUidEvent,
        statusEvent,
        setStatusEvent,
        copyEvent,
        setCopyEvent,
    };
};
