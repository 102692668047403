import clsx from "clsx";

import { useContextControllers, useTypeProject } from "hooks";
import { HeaderRight } from "./header-right";
import { HeaderLeft } from "./header-left";

export const Header = ({ setDropDownMenu, dropDownMenu, isDropdown }) => {
    const { settingAccount } = useContextControllers();
    const { isAppMobile } = useTypeProject();
    return (
        <div
            className={clsx(
                "w-full",
                settingAccount.horizontal
                    ? "bg-[#222736] px-2"
                    : isAppMobile
                    ? "rounded-[0.25rem] bg-white dark:bg-[#262b3c]"
                    : "mx-0 mt-0 rounded-[0.25rem] bg-white dark:bg-[#262b3c] md:mx-6 md:mt-5"
            )}
        >
            <div className="flex justify-between px-4 py-3 shadow-heard-menu">
                <HeaderLeft setDropDownMenu={setDropDownMenu} dropDownMenu={dropDownMenu} isDropdown={isDropdown} />
                <HeaderRight />
            </div>
        </div>
    );
};
