import { useKnowLedge } from "hooks";
import { NoData, Section, WrapperKnowLedgeDetail, WrapperScroll } from "layout";
import React from "react";

export const ModalKnowLedgeDetail = ({ id }) => {
    const { handleGetDetailKnowLedgesId } = useKnowLedge();

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState(false);

    React.useLayoutEffect(() => {
        (async () => {
            if (id) {
                setLoading(true);
                setError(false);
                const { error, payload } = await handleGetDetailKnowLedgesId(id);
                setData(payload);
                if (error) {
                    setError(true);
                }
                setLoading(false);
            }
        })();
    }, [handleGetDetailKnowLedgesId, id]);

    if (loading) {
        return (
            <Section>
                <Loading />
            </Section>
        );
    }

    if (error) return <NoData />;

    return (
        <WrapperScroll className="globals__display_block max-h-[500px]">
            <WrapperKnowLedgeDetail data={data} />
        </WrapperScroll>
    );
};

const Loading = () => {
    return (
        <div className="grid gap-y-6">
            <div className="h-4 w-full max-w-[200px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-4 w-full max-w-[500px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-4 w-full max-w-[100px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-4 w-full max-w-[200px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-4 w-full max-w-[500px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-14 w-full max-w-[100px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-4 w-full max-w-[100px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-4 w-full max-w-[860px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-4 w-full max-w-[500px] animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
        </div>
    );
};
