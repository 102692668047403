import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { storeLocalStorage } from "constant";

const language = navigator.language;
const checkLanguageByBrowser = [language].filter((lg) => lg.includes("vi"));

const localCountry =
    localStorage.getItem(storeLocalStorage.LANGUAGE) || checkLanguageByBrowser.length > 0 ? "vi-VN" : "en-US";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: localCountry,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
