import { storeLocalStorage } from "constant";
import {
    onChangeAvatar,
    onGetBusinessTrip,
    onGetFinances,
    onGetInfoUser,
    onGetKpiScore,
    onGetLeaves,
    onGetMenuSidebarUser,
    onGetNotification,
    onGetOverTime,
    onGetProfileUSer,
    onGetProfileUserById,
    onLogin,
    onLoginPartner,
    onLogOut,
    onPatchApproveBusinessTrip,
    onPatchApproveFinances,
    onPatchApproveLeaves,
    onPatchApproveOverTime,
    onGetProfileIcms,
    onGetKpiDashboard,
    onGetKpiDashboardTable,
    onGetKpiListConfig,
} from "./path-api";

export const extraReducers = {
    [onLogin.pending]: (state) => {
        state.loading = true;
    },
    [onLogin.fulfilled]: (state, action) => {
        const { access_token, refresh_token } = action.payload;
        localStorage.setItem(storeLocalStorage.ACCESS_TOKEN, access_token);
        localStorage.setItem(storeLocalStorage.REFRESH_TOKEN, refresh_token);
        state.access_token = access_token;
        state.refresh_token = refresh_token;
        state.loading = false;
    },
    [onLogin.rejected]: (state) => {
        state.loading = false;
    },
    // login partner
    [onLoginPartner.pending]: (state) => {
        state.loading = true;
    },
    [onLoginPartner.fulfilled]: (state, action) => {
        const { access_token, refresh_token } = action.payload;
        localStorage.setItem(storeLocalStorage.ACCESS_TOKEN, access_token);
        localStorage.setItem(storeLocalStorage.REFRESH_TOKEN, refresh_token);
        state.access_token = access_token;
        state.refresh_token = refresh_token;
        state.loading = false;
    },
    [onLoginPartner.rejected]: (state) => {
        state.loading = false;
    },
    //  get info account
    [onGetInfoUser.pending]: (state) => {
        state.loading = true;
    },
    [onGetInfoUser.fulfilled]: (state, action) => {
        state.loading = false;
        state.infoUser = action.payload;
    },
    // logout action
    [onLogOut.pending]: (state) => {
        state.loading = true;
    },
    [onLogOut.fulfilled]: () => {
        window.location.reload();
        localStorage.removeItem(storeLocalStorage.ACCESS_TOKEN);
        localStorage.removeItem(storeLocalStorage.REFRESH_TOKEN);
    },
    [onLogOut.rejected]: (state) => {
        state.loading = false;
    },
    // get profile user
    [onGetProfileUSer.pending]: (state) => {
        state.loading = true;
    },
    [onGetProfileUSer.fulfilled]: (state, action) => {
        state.loading = false;
        state.profile = action.payload;
    },
    [onGetProfileUSer.rejected]: (state) => {
        state.loading = false;
    },
    // get kpi score
    [onGetKpiScore.fulfilled]: (state, action) => {
        state.kpiScore = action.payload;
    },
    //
    [onGetProfileUserById.pending]: (state) => {
        state.profileUserById = null;
    },
    [onGetProfileUserById.fulfilled]: (state, action) => {
        state.profileUserById = action.payload;
    },
    [onGetProfileIcms.pending]: (state) => {
        state.profileIcms = null;
    },
    [onGetProfileIcms.fulfilled]: (state, action) => {
        state.profileIcms = action.payload;
    },
    // change avatar
    [onChangeAvatar.fulfilled]: (state, action) => {
        const { infoUser, profile } = state;
        const { message } = action.payload;

        infoUser.avatar_url = message;
        profile.avatar_url = message;
    },
    // get notification
    [onGetNotification.fulfilled]: (state, action) => {
        state.dataNotification = action.payload.items;
    },
    // get menu sidebar user
    [onGetMenuSidebarUser.fulfilled]: (state, action) => {
        const { allow } = action.payload;
        state.MenuSidebarUser = allow;
    },
    // get business trip
    [onGetBusinessTrip.fulfilled]: (state, action) => {
        const data = action.payload;
        state.dataBusinessTrip = data;
    },
    // get leaves
    [onGetLeaves.fulfilled]: (state, action) => {
        const data = action.payload;
        state.dataLeaves = data;
    },
    // get over time
    [onGetOverTime.fulfilled]: (state, action) => {
        const data = action.payload;
        state.dataOverTime = data;
    },
    // get finances
    [onGetFinances.fulfilled]: (state, action) => {
        const data = action.payload;
        state.dataFinances = data;
    },
    [onPatchApproveOverTime.pending]: (state, action) => {
        const id = action.meta.arg.record_id;
        const { type } = action.meta.arg.params;
        const { dataOverTime } = state;
        const index = dataOverTime.findIndex((i) => i.id === id);
        if (index !== -1) {
            dataOverTime[index] = { ...dataOverTime[index], loading: true, type_btn: type };
        }
    },
    [onPatchApproveOverTime.fulfilled]: (state, action) => {
        const payload = action.payload;
        const { dataOverTime } = state;
        const id = action.meta.arg.record_id;
        const is_approve = action.meta.arg.params.is_approve;
        const index = dataOverTime.findIndex((i) => i.id === id);
        if (index !== -1) {
            dataOverTime[index].state = payload === true ? is_approve : payload;
            dataOverTime[index] = {
                ...dataOverTime[index],
                approve: is_approve ? "APPROVED" : "CANCEL",
                loading: false,
            };
        }
    },
    [onPatchApproveFinances.pending]: (state, action) => {
        const id = action.meta.arg.record_id;
        const { type } = action.meta.arg.params;
        const { dataFinances } = state;
        const index = dataFinances.findIndex((i) => i.id === id);
        if (index !== -1) {
            dataFinances[index] = { ...dataFinances[index], loading: true, type_btn: type };
        }
    },
    [onPatchApproveFinances.fulfilled]: (state, action) => {
        const payload = action.payload;
        const { dataFinances } = state;
        const id = action.meta.arg.record_id;
        const is_approve = action.meta.arg.params.is_approve;
        const index = dataFinances.findIndex((i) => i.id === id);
        if (index !== -1) {
            dataFinances[index].state = payload === true ? is_approve : payload;
            dataFinances[index] = {
                ...dataFinances[index],
                approve: is_approve ? "APPROVED" : "CANCEL",
                loading: false,
            };
        }
    },
    [onPatchApproveBusinessTrip.pending]: (state, action) => {
        const id = action.meta.arg.record_id;
        const { type } = action.meta.arg.params;
        const { dataBusinessTrip } = state;
        const index = dataBusinessTrip.findIndex((i) => i.id === id);
        if (index !== -1) {
            dataBusinessTrip[index] = { ...dataBusinessTrip[index], loading: true, type_btn: type };
        }
    },
    [onPatchApproveBusinessTrip.fulfilled]: (state, action) => {
        const payload = action.payload;
        const { dataBusinessTrip } = state;
        const is_approve = action.meta.arg.params.is_approve;
        const id = action.meta.arg.record_id;
        const index = state.dataBusinessTrip.findIndex((i) => i.id === id);
        if (index !== -1) {
            dataBusinessTrip[index].state = payload === true ? is_approve : payload;
            dataBusinessTrip[index] = {
                ...dataBusinessTrip[index],
                approve: is_approve ? "APPROVED" : "CANCEL",
                loading: false,
            };
        }
    },

    [onPatchApproveLeaves.pending]: (state, action) => {
        const id = action.meta.arg.record_id;
        const { type } = action.meta.arg.params;
        const { dataLeaves } = state;
        const index = dataLeaves.findIndex((i) => i.id === id);
        if (index !== -1) {
            dataLeaves[index] = { ...dataLeaves[index], loading: true, type_btn: type };
        }
    },
    [onPatchApproveLeaves.fulfilled]: (state, action) => {
        const payload = action.payload;
        const { dataLeaves } = state;
        const is_approve = action.meta.arg.params.is_approve;
        const id = action.meta.arg.record_id;
        const index = dataLeaves.findIndex((i) => i.id === id);
        if (index !== -1) {
            dataLeaves[index].state = payload === true ? is_approve : payload;
            dataLeaves[index] = { ...dataLeaves[index], approve: is_approve ? "APPROVED" : "CANCEL", loading: false };
        }
    },
    [onGetKpiDashboard.pending]: (state) => {
        state.dataKpiDashboard = null;
    },
    [onGetKpiDashboard.fulfilled]: (state, action) => {
        state.dataKpiDashboard = action.payload;
    },
    // get kpi table
    [onGetKpiDashboardTable.pending]: (state) => {
        const { dataKpiDashboardTable } = state;
        state.dataKpiDashboardTable = {
            ...dataKpiDashboardTable,
            loading: true,
        };
    },
    [onGetKpiDashboardTable.fulfilled]: (state, action) => {
        const { total_record, page, records, row_per_page } = action.payload;
        state.dataKpiDashboardTable = {
            total: total_record,
            page,
            data: records,
            page_size: row_per_page,
            loading: false,
        };
    },
    [onGetKpiListConfig.fulfilled]: (state, action) => {
        state.dataKpiListConfig = action.payload.records;
    },
};
