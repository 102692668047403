import { Button } from "customize";
import { useModal } from "hooks";
import { VscDebugContinueSmall } from "react-icons/vsc";
import { FiChevronsRight } from "react-icons/fi";

export const ModalConfirmResource = ({ setCheckResourceFirst }) => {
    const { toggleModal, ModalTypeEnum } = useModal();

    const handleChangeResource = () => {
        setCheckResourceFirst(false);
        toggleModal({
            type: ModalTypeEnum.NULL,
        });
    };
    return (
        <div className="grid grid-cols-2 justify-between gap-x-6">
            <Button label="Continue" BgSuccess onClick={handleChangeResource} Icon={FiChevronsRight} />
            <Button label="Skip" BgSecondary onClick={handleChangeResource} Icon={VscDebugContinueSmall} />
        </div>
    );
};
