import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { openModal } from "features";
import { storeModal } from "./use-selector";

import { ModalTypeEnum } from "constant";

export const useModal = () => {
    const resultModal = useSelector(storeModal);
    const dispatch = useDispatch();
    const toggleModal = React.useCallback((data) => dispatch(openModal(data)), [dispatch]);
    return { resultModal, ModalTypeEnum, toggleModal };
};
