import * as React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import moment from "moment";
import localeVi from "moment/locale/vi";
import localeEs from "moment/locale/es";
import AOS from "aos";

import { useAccount, useContextControllers } from "hooks";
import { Login } from "components";
import { ModalComponent } from "modal";
import { DrawerComponent } from "drawer";
import { PageRouter } from "page/index";
import { DefaultLayout } from "layout";

export const App = () => {
    const { translate } = useContextControllers();
    const { resultStoreAccount } = useAccount();

    moment.updateLocale(
        translate.language === "vi-VN" ? "vi" : "en",
        translate.language === "vi-VN" ? localeVi : localeEs
    );

    React.useEffect(() => {
        AOS.init({
            duration: 500,
            once: true,
            initClassName: "aos-init",
        });
    }, []);

    return (
        <ConfigProvider locale={translate.locale}>
            <BrowserRouter>
                <ModalComponent />
                <DrawerComponent />
                {resultStoreAccount.access_token ? (
                    <Routes>
                        {PageRouter.map((route, index) => {
                            const Page = route.component;
                            let Layout = DefaultLayout;
                            if (route.layout) {
                                Layout = route.layout;
                            } else if (route.layout === null) {
                                Layout = React.Fragment;
                            }
                            return (
                                <Route
                                    key={index.toString()}
                                    path={route.path}
                                    element={
                                        <React.Suspense fallback={<></>}>
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        </React.Suspense>
                                    }
                                />
                            );
                        })}
                    </Routes>
                ) : (
                    <Login />
                )}
            </BrowserRouter>
        </ConfigProvider>
    );
};
