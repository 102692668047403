import * as React from "react";
import { useTranslation } from "react-i18next";
import Microlink from "@microlink/react";
import styled from "styled-components";

import { InfoMember } from "./card-member";

export const ListComment = ({ dataCommentListEvent }) => {
    const { t } = useTranslation();
    const [sliceData, setSliceData] = React.useState(5);

    const contentClickHandler = (str) => {
        const match = str.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
        if (match) {
            return match.map((url, index) => <CustomMicroLink url={url} key={index.toString()} />);
        }
    };

    return dataCommentListEvent ? (
        <div className="flex flex-col">
            {dataCommentListEvent.slice(0, sliceData).map((item) => (
                <InfoMember
                    className="py-2"
                    profile={item.user_info}
                    key={item.id}
                    {...item.user_info}
                    titleClassName="font-semibold"
                    time={item.create_date}
                    imageClassName="place-items-center gap-2 self-start"
                    wrapperCmtClassName="dark:bg-[#2e3446] bg-[#ecf0f4] py-2 px-3"
                >
                    <div
                        className="break-all text-[#505050] dark:text-[#f0f2f5]"
                        dangerouslySetInnerHTML={{ __html: item.comment.replace(/(\r\n|\n|\r)/gm, "<br />") }}
                    />
                    {contentClickHandler(item.comment)}
                </InfoMember>
            ))}
            {sliceData < dataCommentListEvent.length && (
                <p
                    className="contents cursor-pointer gap-1 font-semibold hover:text-[#007bff] hover:underline dark:hover:text-[#f8f8fb]"
                    onClick={() => setSliceData(sliceData + 3)}
                >
                    {t("See {{value}} more feedbacks", { value: dataCommentListEvent.length - sliceData })}
                </p>
            )}
        </div>
    ) : (
        <h6>{t("There are no comments yet!")}</h6>
    );
};

const CustomMicroLink = styled(Microlink)`
    font-family: Poppins, sans-serif;
    border-radius: 0.42857em;
    margin-top: 4px;
    padding-left: 15px;
    & div > footer > span {
        display: none;
    }
    & div > footer > p {
        color: #007bff !important;
    }
    & div {
        background-size: contain;
    }
    @media (max-width: 650px) {
        display: grid;
    }
    @media (max-width: 650px) {
        & > div {
            width: 150px;
            height: 150px;
        }
    }
`;
