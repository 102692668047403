import { createSlice } from "@reduxjs/toolkit";

import { storeLocalStorage } from "constant";
import { extraReducers } from "./extra-reducers";

const initialState = {
    access_token: localStorage.getItem(storeLocalStorage.ACCESS_TOKEN),
    refresh_token: localStorage.getItem(storeLocalStorage.REFRESH_TOKEN),
    MenuSidebarUser: null,
    loading: false,
    infoUser: null,
    profile: null,
    kpiScore: null,
    profileUserById: null,
    profileIcms: null,
    dataNotification: [],
    dataBusinessTrip: null,
    dataOverTime: null,
    dataLeaves: null,
    dataFinances: null,
    dataKpiDashboard: null,
    dataKpiDashboardTable: null,
    dataKpiListConfig: null,
};

export const sliceAccount = createSlice({
    name: "account",
    initialState: initialState,
    extraReducers: extraReducers,
});

const { reducer } = sliceAccount;
export default reducer;
