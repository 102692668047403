import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { VscLoading } from "react-icons/vsc";

export const Button = ({
    style,
    Icon,
    Element = "button",
    label,
    className,
    loading = false,
    type = "button",
    children,
    disabled = false,
    border = true,
    size = "md",
    BgSuccess,
    BgWarning,
    BgSecondary,
    BgDanger,
    BgPrimary,
    BgInfo,
    BgDark,
    BgOrange,
    ...props
}) => {
    const { t } = useTranslation();
    return (
        <Element
            style={style}
            {...props}
            types={type}
            disabled={loading || disabled}
            className={clsx(
                "relative z-10 flex w-full items-center justify-center text-[0.9rem] font-normal leading-6 text-white transition duration-150 ease-in-out",
                className,
                loading && "disabled cursor-not-allowed",
                disabled && "disabled cursor-not-allowed",
                border && "rounded-[0.25rem]",
                { "px-[4px] py-[2px]": size === "sm" },
                { "p-1 md:px-3 md:py-[7px]": size === "md" },
                { "px-8 py-4": size === "lg" },
                BgSuccess &&
                    "bg bg-[#03942c] hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#03942c]/50",
                BgOrange &&
                    "bg bg-[#fa8c16] hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#fa8c16]/50",
                BgSecondary &&
                    "bg-[#6c757d] hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#6c757d]/50",
                BgWarning &&
                    "bg-[#ffc107] hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#ffc107]/50",
                BgDanger &&
                    "bg-[#dc3545] hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#dc3545]/50",
                BgInfo &&
                    "bg-[#0dcaf0] hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#0dcaf0]/50",
                BgPrimary &&
                    "bg-[#0d6efd] hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#0dcaf0]/50",
                BgDark &&
                    "bg-[#343a40] hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#343a40]/50"
            )}
        >
            {children}
            {!loading && Icon && <Icon className="relative mr-1 text-[0.9rem]" />}
            {loading && <VscLoading className="mr-1 animate-spin text-[0.9rem]" />}
            {t(label)}
        </Element>
    );
};
