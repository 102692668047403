import { Tooltip } from "antd";
import { colorTooltip } from "constant";
import { useTranslation } from "react-i18next";

export const WrapperTooltip = ({ children, className, title, props, color = { colorTooltip } }) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={t(title)} color={color} className={className} {...props}>
            {children}
        </Tooltip>
    );
};
