import * as React from "react";
import clsx from "clsx";
import { Image as ImageAntd } from "antd";
import { useTranslation } from "react-i18next";

import { ViewMore } from "layout";
import { Image } from "customize";
import { useAccount } from "hooks";

import { imageProfile } from "assets/images";
import { UploadAvatar } from "./upload-avatar";

export const CardProfile = ({
    isShowWork = true,
    fullname,
    job_title_en,
    lengthProject = 5,
    className = "dark:bg-[#262b3c] bg-white",
    titleViewMore = "View Profile",
    onClick,
    username,
    isDescription,
    avatarClassName = "relative bottom-[1.5rem] h-[72px] w-[72px]",
    wrapperClassName = "px-4 pb-5 sm:px-5 rounded-b bg-white dark:border-[#222736] dark:bg-[#262b3c]",
    detailsClassName = "grid grid-cols-2 gap-x-4",
}) => {
    const { t } = useTranslation();
    const { infoUser } = useAccount();

    const [imagePreview, setImagePreview] = React.useState(false);

    return (
        <>
            {username ? (
                <div className={clsx("rounded-md shadow-card-item", className)}>
                    <div className="flex justify-between rounded-t bg-[#d4dbf9] dark:bg-[#34406b]">
                        {isDescription && (
                            <div className="p-4">
                                <h3 className="font-medium text-[#556ee6] dark:text-slate-200">{t("Welcome back")}</h3>
                                <p className="mt-2 text-[#556ee6] dark:text-slate-200">{t("WorkPlace Dashboard")}</p>
                            </div>
                        )}
                        <ImageAntd
                            style={{ display: "none" }}
                            src={infoUser.avatar_url}
                            preview={{
                                visible: imagePreview,
                                src: infoUser.avatar_url,
                                onVisibleChange: (value) => setImagePreview(value),
                            }}
                        />
                        {isDescription && <Image src={imageProfile} className="h-24 w-full" />}
                    </div>
                    <div className={wrapperClassName}>
                        <div className={avatarClassName}>
                            <Image
                                onClick={() => setImagePreview(true)}
                                src={infoUser.avatar_url}
                                className="h-full w-full cursor-pointer rounded-full border-4 border-[#f8f8fb] dark:border-[#222736]"
                            >
                                <UploadAvatar />
                            </Image>
                        </div>
                        <div className={detailsClassName}>
                            <div className="w-full">
                                <h5 className="text-[0.938rem] font-semibold dark:text-[#f6f6f6]">{fullname}</h5>
                                <p className="text-[#74788d] dark:text-[#c3cbe4]">{job_title_en}</p>
                            </div>
                            <div className="mt-[-48px] flex w-full flex-col items-end justify-between gap-y-6 gap-x-2">
                                {lengthProject > 0 && isShowWork ? (
                                    <div className="w-full text-right">
                                        {/* <h5 className="text-[0.938rem] dark:text-[#f6f6f6]">{lengthProject}</h5>
                                        <p className="text-[#74788d] dark:text-[#c3cbe4]">{t("Work")}</p> */}
                                    </div>
                                ) : (
                                    <div className="w-full text-right" />
                                )}
                                <ViewMore onClick={onClick} title={titleViewMore} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
};

const Loading = () => {
    return (
        <div className="w-full animate-pulse rounded-b bg-white shadow-card-item dark:bg-[#262b3c]">
            <div className="flex flex-col gap-y-4 rounded-t bg-[#d4dbf9] p-6 dark:bg-[#262b3c]">
                <div className="h-4 w-full max-w-[120px] rounded bg-slate-300 dark:bg-slate-500" />
            </div>
            <div className="rounded-b bg-white px-5 pb-5 dark:bg-[#262b3c]">
                <div className="relative bottom-[1.5rem] mt-4 h-[72px] w-[72px] rounded-full bg-slate-300 dark:bg-slate-500" />
                <div className="grid grid-cols-2 gap-x-4">
                    <div className="grid w-full gap-y-4">
                        <div className="h-4 w-full  max-w-[50px] rounded bg-slate-300 dark:bg-slate-500" />
                        <div className="h-4 w-full  max-w-[100px] rounded bg-slate-300 dark:bg-slate-500" />
                    </div>
                    <div className="mt-[-48px] flex w-full flex-col justify-between gap-y-6 gap-x-2">
                        <div className="grid w-full gap-y-4">
                            <div className="h-4 w-2 rounded bg-slate-300 dark:bg-slate-500" />
                            <div className="h-4 w-4 rounded bg-slate-300 dark:bg-slate-500" />
                        </div>
                        <div className="flex max-w-[110px]">
                            <div className="h-4 w-full rounded bg-slate-300 dark:bg-slate-500" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
