import * as React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { urlWebsite } from "constant";
import { useAccount, useContextControllers, useTypeProject } from "hooks";
import { Header, LeftSideBar } from "components";

export const DefaultLayout = ({ children }) => {
    const navigate = useNavigate();
    const { infoUser } = useAccount();
    const { isAppMobile } = useTypeProject();
    const { dropDownMenu, setDropDownMenu, settingAccount } = useContextControllers();

    const classNameWrapperContainer = settingAccount.horizontal
        ? "ml-0 duration-300 ease-out"
        : clsx("ml-0 duration-300 ease-out", dropDownMenu ? "md:ml-[80px]" : "lg:ml-[270px]");

    React.useLayoutEffect(() => {
        if (infoUser) {
            if (!infoUser.is_internal) {
                navigate(urlWebsite.MS_SERVICE);
            }
        }
    }, [infoUser, navigate]);

    return (
        <>
            <div
                className={clsx(
                    "herder-style top-0 z-30 flex duration-300 ease-out dark:bg-[#222736]",
                    settingAccount.horizontal ? "relative flex-col-reverse" : " sticky bg-[#ebeff3] dark:bg-[#222736]"
                )}
            >
                <LeftSideBar dropDownMenu={dropDownMenu} />
                <Header setDropDownMenu={setDropDownMenu} dropDownMenu={dropDownMenu} />
            </div>
            <div className={classNameWrapperContainer}>
                <div className="wrapper-container">
                    <div
                        className={clsx(
                            isAppMobile ? "dark:bg-[#222736]" : "w-full py-4 px-2 dark:bg-[#222736] md:p-6"
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};
