import { WrapperScroll } from "layout";
import { FormAddEditEvent } from "page/events/components";

export const ModalAddEvent = ({ date }) => {
    return (
        <WrapperScroll className="hover max-h-[80vh]">
            <FormAddEditEvent
                dateUrl={date}
                style={{
                    padding: 0,
                }}
            />
        </WrapperScroll>
    );
};
