import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { storeKnowLedge, useAccount } from "hooks";
import { onGetMenuKnowLedgesById, onGetDetailKnowLedgesId, onGetMenuListKnowLedges } from "features";

export const useKnowLedge = () => {
    const dispatch = useDispatch();
    const { access_token } = useAccount();
    const resultStoreKnowLedge = useSelector(storeKnowLedge);

    const {
        dataKnowledgeId,
        dataKnowledgeDetailId,
        loadingDataKnowledgeId,
        loadingDataKnowledgeDetailId,
        dataMenuListKnowLedges,
    } = resultStoreKnowLedge;

    const handleGetMenuKnowLedgesById = React.useCallback(
        (id) => access_token && dispatch(onGetMenuKnowLedgesById(id)),
        [access_token, dispatch]
    );

    const handleGetDetailKnowLedgesId = React.useCallback(
        (id) => access_token && dispatch(onGetDetailKnowLedgesId(id)),
        [access_token, dispatch]
    );

    const handleGetMenuListKnowLedges = React.useCallback(
        () => access_token && dispatch(onGetMenuListKnowLedges()),
        [access_token, dispatch]
    );

    return {
        handleGetMenuKnowLedgesById,
        handleGetDetailKnowLedgesId,
        handleGetMenuListKnowLedges,
        dataKnowledgeId,
        dataKnowledgeDetailId,
        loadingDataKnowledgeId,
        loadingDataKnowledgeDetailId,
        dataMenuListKnowLedges,
    };
};
