import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiAccount } from "api";

export const onLogin = createAsyncThunk("onLogin", async (data) => {
    const response = await apiAccount.login(data);
    return response;
});

export const onLogOut = createAsyncThunk("onLogOut", async () => {
    const response = await apiAccount.logOut();
    return response;
});

export const onGetInfoUser = createAsyncThunk("onGetInfoUser", async () => {
    const response = await apiAccount.getInfoUser();
    return response;
});

export const onGetProfileUSer = createAsyncThunk("onGetProfileUSer", async (username) => {
    const response = await apiAccount.getProfileUSer(username);
    return response;
});

export const onGetKpiScore = createAsyncThunk("onGetKpiScore", async () => {
    const response = await apiAccount.getKpiScore();
    return response;
});

export const onGetProfileUserById = createAsyncThunk("onGetProfileUserById", async (id) => {
    const response = await apiAccount.getProfileUserById(id);
    return response;
});

export const onChangeAvatar = createAsyncThunk("onChangeAvatar", async (data) => {
    const response = await apiAccount.changeAvatar(data);
    return response;
});

export const onChangePassword = createAsyncThunk("onChangePassword", async (data) => {
    const response = await apiAccount.changePassword(data);
    return response;
});

export const onGetNotification = createAsyncThunk("onGetNotification", async () => {
    const response = await apiAccount.getNotification();
    return response;
});

export const onLoginPartner = createAsyncThunk("onLoginPartner", async (data) => {
    const response = await apiAccount.loginPartner(data);
    return response;
});

export const onGetMenuSidebarUser = createAsyncThunk("onGetMenuSidebarUser", async () => {
    const response = await apiAccount.getMenuSidebarUser();
    return response;
});

export const onGetBusinessTrip = createAsyncThunk("onGetBusinessTrip", async () => {
    const response = await apiAccount.getBusinessTrip();
    return response;
});

export const onGetLeaves = createAsyncThunk("onGetLeaves", async () => {
    const response = await apiAccount.getLeaves();
    return response;
});

export const onGetOverTime = createAsyncThunk("onGetOverTime", async () => {
    const response = await apiAccount.getOverTime();
    return response;
});

export const onGetFinances = createAsyncThunk("onGetFinances", async () => {
    const response = await apiAccount.getFinances();
    return response;
});

export const onPatchApproveLeaves = createAsyncThunk("onPatchApproveLeaves", async (data) => {
    const response = await apiAccount.patchApproveLeaves(data);
    return response;
});

export const onPatchApproveOverTime = createAsyncThunk("onPatchApproveOverTime", async (data) => {
    const response = await apiAccount.patchApproveOverTime(data);
    return response;
});

export const onPatchApproveFinances = createAsyncThunk("onPatchApproveFinances", async (data) => {
    const response = await apiAccount.patchApproveFinances(data);
    return response;
});

export const onPatchApproveBusinessTrip = createAsyncThunk("onPatchApproveBusinessTrip", async (data) => {
    const response = await apiAccount.patchApproveBusinessTrip(data);
    return response;
});

export const onGetProfileIcms = createAsyncThunk("onGetProfileIcms", async (username) => {
    const response = await apiAccount.getProfileIcms(username);
    return response;
});

export const onGetKpiDashboard = createAsyncThunk("onGetKpiDashboard", async (params) => {
    const response = await apiAccount.getKpiDashboard(params);
    return response;
});

export const onGetKpiDashboardTable = createAsyncThunk("onGetKpiDashboardTable", async (params) => {
    const response = await apiAccount.getKpiDashboardTable(params);
    return response;
});

export const onGetKpiListConfig = createAsyncThunk("onGetKpiListConfig", async () => {
    const response = await apiAccount.getKpiListConfig();
    return response;
});

export const onGetNotificationChannel = createAsyncThunk("onGetNotificationChannel", async () => {
    const response = await apiAccount.getNotificationChannel();
    return response;
});

export const onPatchNotificationChannel = createAsyncThunk("onPatchNotificationChannel", async (params) => {
    const response = await apiAccount.patchNotificationChannel(params);
    return response;
});
