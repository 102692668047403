import { Badge } from "antd";
import clsx from "clsx";

import { Section } from "./section";
import { LinkHref } from "customize";
import { useTranslation } from "react-i18next";
import { useTypeProject } from "hooks";

export const ItemKpiScore = ({ Icon, label, value, bgIcon, color, url }) => {
    const { t } = useTranslation();
    const { isAppMobile } = useTypeProject();

    return (
        <div className={isAppMobile && "mx-3"}>
            <Badge.Ribbon
                text={<h3 className="mt-[2px] text-[0.9rem] text-white">{t(label)}</h3>}
                color={color}
                placement="start"
            >
                <Section className="flex justify-between">
                    {url ? (
                        <div className="flex flex-1 items-end">
                            <LinkHref path={url}>
                                <h6 className="mt-2 text-[1.21875rem] font-medium hover:underline dark:text-[#f6f6f6]">
                                    {value}
                                </h6>
                            </LinkHref>
                        </div>
                    ) : (
                        <div className="flex flex-1 items-end">
                            <h6 className="mt-2 text-[1.21875rem] font-medium dark:text-[#f6f6f6]">{value}</h6>
                        </div>
                    )}

                    <div className={clsx("flex h-12 w-12 items-center justify-center rounded-full", bgIcon)}>
                        <Icon className="flex h-6 w-6 text-2xl text-white dark:text-white" />
                    </div>
                </Section>
            </Badge.Ribbon>
        </div>
    );
};
