import * as React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";

import { optionsNextMeeting } from "constant";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useEvents } from "hooks";

export const SelectNextMeeting = () => {
    const [check, setValue] = React.useState("this_month");
    const { t } = useTranslation();

    const {
        handleGetMeetingNextToday,
        handleGetMeetingNextTomorrow,
        handleGetMeetingNextThisWeek,
        handleGetMeetingNextNextWeek,
        handleGetMeetingNext,
    } = useEvents();

    const getMeetingNext = (value) => {
        setValue(value);
        switch (value) {
            case "today":
                return handleGetMeetingNextToday();
            case "tomorrow":
                return handleGetMeetingNextTomorrow();
            case "this_week":
                return handleGetMeetingNextThisWeek();
            case "next_week":
                return handleGetMeetingNextNextWeek();
            case "next_meeting":
                return handleGetMeetingNext();
            default:
                return handleGetMeetingNext();
        }
    };

    return (
        <div>
            <div className="mb-2 hidden basis-8/12 items-center justify-end gap-1 md:flex">
                {optionsNextMeeting.map(({ title, value }) => (
                    <h6
                        key={value}
                        className={clsx(
                            "cursor-pointer rounded-[0.25rem] p-1 text-center sm:p-2",
                            check === value
                                ? "bg-[#556ee6] text-white hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#556ee6]/50"
                                : "text-[#556ee6] hover:bg-[#556ee6] hover:text-white hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#556ee6]/50"
                        )}
                        onClick={() => getMeetingNext(value)}
                    >
                        {t(title)}
                    </h6>
                ))}
            </div>
            <div className="mb-2 block md:hidden">
                <Dropdown
                    overlay={() => (
                        <div className="relative grid gap-1 rounded-[0.25rem] border border-white bg-white py-2 text-[#495057] shadow-dropdown-menu dark:border-[#32394e] dark:bg-[#262b3c] dark:text-slate-200">
                            {optionsNextMeeting.map(({ title, value }) => (
                                <div
                                    className={clsx(
                                        "align-center flex cursor-pointer items-center gap-2 py-[6px] px-6 dark:hover:bg-[#1c1f2b]",
                                        value === check
                                            ? "bg-[#d8dadc] text-[#556ee6] dark:bg-[#1c1f2b]"
                                            : "hover:bg-[#d8dadc] dark:hover:bg-[#1c1f2b]"
                                    )}
                                    key={value}
                                >
                                    <h6
                                        className="text-[#555b6d] dark:text-slate-200"
                                        onClick={() => getMeetingNext(value)}
                                    >
                                        {t(title)}
                                    </h6>
                                </div>
                            ))}
                        </div>
                    )}
                    trigger={["click"]}
                    placement="bottom"
                >
                    <div className="flex items-center gap-2 rounded-[0.25rem] border dark:border-[#32394e]">
                        {optionsNextMeeting.map(
                            ({ title, value }) =>
                                value === check && (
                                    <h3 key={value} className="cursor-pointer py-[6px] px-3 text-center">
                                        {t(title)}
                                    </h3>
                                )
                        )}
                        <RiArrowDropDownLine className="text-[1.25rem] text-[#555b6d] dark:text-slate-200" />
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};
