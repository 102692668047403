import * as React from "react";
import { Table } from "antd";
import moment from "moment";
import clsx from "clsx";

import { LinkHref } from "customize";
import { ItemTableMobile, LoadingTable, NoData, TitleTable } from "layout";
import { useContextControllers, useTypeProject } from "hooks";
import { urlWebsite } from "constant";

export const WrapperDetailKpiPoint = ({ data, children, loading, dataKpiListConfig }) => {
    const { settingAccount } = useContextControllers();
    const { borderClassNameMobile } = useTypeProject();

    const resultFilter = React.useMemo(() => {
        const temp = [];
        if (data && dataKpiListConfig) {
            data.forEach((item) => {
                const tempItem = dataKpiListConfig.find((itemConfig) => itemConfig.id === item.kpi_id);
                if (tempItem) {
                    temp.push({
                        ...item,
                        kpi_name: tempItem,
                    });
                }
            });
        }
        return temp;
    }, [data, dataKpiListConfig]);
    if (!data) return <LoadingTable />;

    return (
        <React.Fragment>
            <div className="hidden lg:block">
                <Table
                    className={`table-nth-child-${settingAccount.theme}`}
                    loading={loading}
                    dataSource={resultFilter}
                    locale={{
                        emptyText: <NoData title="No data available yet" noData />,
                    }}
                    pagination={false}
                    columns={[
                        {
                            title: <TitleTable title="ID" />,
                            dataIndex: "id",
                            render: (text) => <span className="block text-center">{text}</span>,
                        },
                        {
                            title: <TitleTable title="KPI ID" />,
                            dataIndex: "full",
                            render: (_, record) => (
                                <div className="flex gap-1">
                                    <p className="text-left">{record.kpi_id}</p>
                                    <span>::</span>
                                    <p className="text-left">{record.kpi_name.name}</p>
                                </div>
                            ),
                        },
                        {
                            title: <TitleTable title="Source ID" />,
                            dataIndex: "full",
                            width: 100,
                            render: (_, record) =>
                                record.kpi_name.kpi_source !== "Redmine" ? (
                                    <p className="text-left">{record.kpi_source_id}</p>
                                ) : (
                                    <LinkHref
                                        className="text-[#0d6efd] hover:text-[#0d6efd] hover:underline"
                                        outWeb
                                        path={`${urlWebsite.REDMINE}/issues/${record.kpi_source_id}`}
                                        title={record.kpi_source_id}
                                    />
                                ),
                        },
                        {
                            title: <TitleTable title="Time" />,
                            dataIndex: "record_time",
                            width: 170,
                            render: (text) => <p> {moment(text).format("DD/MM/YYYY HH:mm:ss")}</p>,
                        },
                        {
                            title: <TitleTable title="KPI Score" />,
                            width: 100,
                            dataIndex: "kpi_score",
                            render: (text) => (
                                <p className={clsx("text-center", text > 0 ? "text-[#03942c]" : "text-[#dc3545]")}>
                                    {text}
                                </p>
                            ),
                        },
                        {
                            title: <TitleTable title="Description" />,
                            dataIndex: "description",
                            render: (text) => <p className="text-left">{text}</p>,
                        },
                    ]}
                />
            </div>

            <div className="block lg:hidden">
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                    {resultFilter &&
                        resultFilter.map((item) => (
                            <div
                                key={item.id}
                                className={borderClassNameMobile}
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                            >
                                <ItemTableMobile dot title="ID" value={item.id} valueClassName="font-semibold" />
                                <ItemTableMobile dot title="KPI ID" value={item.id}>
                                    <div className="flex flex-1">
                                        <p className="text-left">{item.kpi_id}</p>
                                        <span>::</span>
                                        <p className="text-left">{item.kpi_name.name}</p>
                                    </div>
                                </ItemTableMobile>
                                <ItemTableMobile dot title="Source ID" value={item.kpi_source_id} />
                                <ItemTableMobile dot title="Time">
                                    <p> {moment(item.record_time).format("DD/MM/YYYY HH:mm:ss")}</p>
                                </ItemTableMobile>
                                <ItemTableMobile dot title="KPI Score">
                                    <p
                                        className={clsx(
                                            "text-center font-semibold",
                                            item.kpi_score > 0 ? "text-[#03942c]" : "text-[#dc3545]"
                                        )}
                                    >
                                        {item.kpi_score}
                                    </p>
                                </ItemTableMobile>
                                <ItemTableMobile dot title="Description" value={item.description} />
                            </div>
                        ))}
                </div>
            </div>
            {children}
        </React.Fragment>
    );
};
