import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { onSearchEmailGroup } from "features";
import { useAccount, storeEmail } from "hooks";

export const useEmail = () => {
    const dispatch = useDispatch();
    const { access_token } = useAccount();
    const { dataEmailGroup, loading } = useSelector(storeEmail);

    const handleSearchEmailGroup = React.useCallback(
        (text) => access_token && dispatch(onSearchEmailGroup(text)),
        [dispatch, access_token]
    );

    return { handleSearchEmailGroup, dataEmailGroup, loading };
};
