import moment from "moment";
import { useTranslation } from "react-i18next";

export const ModalBusinessTripsEvent = ({ data }) => {
    const startDate = data.start_date.split(" ")[0];
    const startTime = data.start_date.split(" ")[1];
    const endDate = data.end_date.split(" ")[0];
    const endTime = data.end_date.split(" ")[1];
    return (
        <div>
            <div className="divide-y divide-[#eaeaea] dark:divide-[#424b64]">
                <ItemInfo title="Name" value={data.name} />
                <ItemInfo
                    title="Start day"
                    value={`${moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} ${startTime}`}
                />
                <ItemInfo title="End day" value={`${moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY")} ${endTime}`} />
                <ItemInfo title="Country" value={data.location_type} />
                <ItemInfo title="Location" value={data.location} />
            </div>
        </div>
    );
};

const ItemInfo = ({ title, value }) => {
    const { t } = useTranslation();
    return (
        <div className="flex gap-x-4 p-2">
            <h6 className="w-full max-w-[100px] font-semibold">{t(title)}:</h6>
            <p>{value}</p>
        </div>
    );
};
