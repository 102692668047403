import {
    onGetAllProducts,
    onGetProductDetails,
    onSearchAllProducts,
    onGetCategoryProduct,
    onGetUomProduct,
    onGetMadeInListProduct,
    onGetWarrantyListProduct,
    onGetCategorySkuProduct,
    onSearchProduct,
    onPostCreateProductSKU,
    onUpdateProductSku,
    onGetProductsCost,
    onSubmitApproveProduct,
} from "./path-api";

export const extraReducers = {
    [onGetAllProducts.pending]: (state) => {
        state.loading = true;
    },
    [onGetAllProducts.fulfilled]: (state, action) => {
        const { items, limit, page, sortable, total } = action.payload;
        state.loading = false;
        state.dataProducts = items;
        state.pagination = {
            page: page,
            limit: limit,
            total: total,
            sortable: sortable,
        };
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    },
    // get products Cost
    [onGetProductsCost.pending]: (state) => {
        state.loading = true;
    },
    [onGetProductsCost.fulfilled]: (state, action) => {
        const { items, limit, page, sortable, total } = action.payload;
        state.loading = false;
        state.dataProducts = items;
        state.pagination = {
            page: page,
            limit: limit,
            total: total,
            sortable: sortable,
        };
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    },
    // get product details sales
    [onGetProductDetails.pending]: (state) => {
        state.dataProductsDetails = null;
    },
    [onGetProductDetails.fulfilled]: (state, action) => {
        state.dataProductsDetails = action.payload;
    },
    // get search products
    [onSearchAllProducts.pending]: (state) => {
        state.loading = true;
    },
    [onSearchAllProducts.fulfilled]: (state, action) => {
        const { items, limit, page, sortable, total } = action.payload;
        state.loading = false;
        state.dataProducts = items;
        state.pagination = {
            page: page,
            limit: limit,
            total: total,
            sortable: sortable,
        };
    },
    // get category products
    [onGetCategoryProduct.fulfilled]: (state, action) => {
        state.dataCategory = action.payload;
    },
    // get uom products
    [onGetUomProduct.fulfilled]: (state, action) => {
        state.dataUom = action.payload;
    },
    // get made in list products
    [onGetMadeInListProduct.fulfilled]: (state, action) => {
        state.dataMadeIn = action.payload;
    },
    // get warranty list products
    [onGetWarrantyListProduct.fulfilled]: (state, action) => {
        state.dataWarranty = action.payload;
    },
    // get category sku products
    [onGetCategorySkuProduct.fulfilled]: (state, action) => {
        state.dataCategorySku = action.payload;
    },

    // search products
    [onSearchProduct.pending]: (state) => {
        state.loadingDataSearchProducts = true;
    },
    [onSearchProduct.fulfilled]: (state, action) => {
        state.loadingDataSearchProducts = false;
        state.dataSearchProducts = action.payload.items.map((item) => ({
            id: item.id,
            name: `[${item.default_code}] - ${item.name}`,
            item: item,
        }));
    },
    // create sku
    [onPostCreateProductSKU.pending]: (state) => {
        state.loadingCreateSKU = true;
    },
    [onPostCreateProductSKU.fulfilled]: (state) => {
        state.loadingCreateSKU = false;

        state.dataSearchProducts = [];
        state.productListProduct = [];
    },
    [onPostCreateProductSKU.rejected]: (state) => {
        state.loadingCreateSKU = false;
    },
    // update sku
    [onUpdateProductSku.pending]: (state) => {
        state.loadingCreateSKU = true;
    },
    [onUpdateProductSku.fulfilled]: (state) => {
        state.loadingCreateSKU = false;
        state.dataSearchProducts = [];
        state.productListProduct = [];
    },
    [onUpdateProductSku.rejected]: (state) => {
        state.loadingCreateSKU = false;
    },
    [onSubmitApproveProduct.pending]: (state) => {
        state.loadingSubmitApproveProduct = true;
    },
    [onSubmitApproveProduct.fulfilled]: (state) => {
        state.loadingSubmitApproveProduct = false;
        state.dataProductsDetails.is_sku_approve = true;
    },
    [onSubmitApproveProduct.rejected]: (state) => {
        state.loadingSubmitApproveProduct = false;
    },
};
