import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiHrDashboard } from "api";

export const onGetStatic = createAsyncThunk("onGetStatic", async () => {
    const response = await apiHrDashboard.getStatic();
    return response;
});

export const onGetKpiReport = createAsyncThunk("onGetKpiReport", async (params) => {
    const response = await apiHrDashboard.getKpiReport(params);
    return response;
});

export const onGetLeavesBizStatic = createAsyncThunk("onGetLeavesBizStatic", async () => {
    const response = await apiHrDashboard.getLeavesBizStatic();
    return response;
});

export const onGetFeedbackStatic = createAsyncThunk("onGetFeedbackStatic", async (params) => {
    const response = await apiHrDashboard.getFeedbackStatic(params);
    return response;
});
