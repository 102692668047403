import clsx from "clsx";
import moment from "moment";

import { Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { colorTooltip, urlWebsite } from "constant";
import { ItemTableMobile, NoData, WrapperTag } from "layout";
import { useFormatNumberTranslate, useModal, useTypeProject } from "hooks";

export const TableMobile = ({ data, paramsCost, className, Sku }) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { formatNumberTranslate } = useFormatNumberTranslate();
    const { borderClassNameMobile } = useTypeProject();

    return (
        <>
            {data?.length === 0 && <NoData />}
            <div className={clsx("grid grid-cols-1 gap-2 md:grid-cols-2", className)}>
                {data?.map((item) => (
                    <div
                        className={borderClassNameMobile}
                        key={item.id}
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                    >
                        {Sku && !paramsCost && (
                            <ItemTableMobile
                                className="flex-1 basis-10/12 gap-2"
                                title="SKU Code"
                                inWeb
                                url={`${urlWebsite.SALES_PRODUCTS}/${item.id}`}
                                value={item.default_code}
                                valueClassName="text-[#007bff] hover:text-[#007bff] dark:hover:text-[#f8f8fb] hover:underline font-semibold"
                            />
                        )}
                        {paramsCost && (
                            <ItemTableMobile className="flex-1 basis-10/12 gap-2" title="Codebook">
                                <p className="text-[#007bff] hover:text-[#007bff] dark:hover:text-[#f8f8fb]">
                                    {item.default_code}
                                </p>
                            </ItemTableMobile>
                        )}
                        {!Sku && !paramsCost && (
                            <ItemTableMobile className="flex-1 basis-10/12 gap-2" title="Codebook">
                                <p className="text-[#007bff] hover:text-[#007bff] dark:hover:text-[#f8f8fb]">
                                    {item.default_code}
                                </p>
                            </ItemTableMobile>
                        )}
                        <ItemTableMobile
                            title="Product's name"
                            dot
                            value={item.name}
                            className="gap-2"
                            valueClassName="flex-1 text-right"
                        />
                        <ItemTableMobile title="Unit" dot value={item.uom_id && item.uom_id[1]} className="gap-2" />
                        {item.sku_min_sale_price !== 0 && item.sku_min_sale_price && (
                            <ItemTableMobile
                                title="Minimum price"
                                dot
                                value={formatNumberTranslate({ number: item.sku_min_sale_price })}
                                className="gap-2"
                            />
                        )}
                        {item.sku_max_sale_price !== 0 && item.sku_max_sale_price && (
                            <ItemTableMobile
                                title="Maximum price"
                                dot
                                value={formatNumberTranslate({ number: item.sku_max_sale_price })}
                                className="gap-2"
                            />
                        )}
                        {item.sku_cm_min && (
                            <ItemTableMobile
                                title="Cm mix"
                                dot
                                value={formatNumberTranslate({ number: item.sku_cm_min })}
                                className="gap-2"
                                valueClassName="flex-1 text-right"
                            />
                        )}
                        {item.sku_cm_max && (
                            <ItemTableMobile
                                title="Cm max"
                                dot
                                value={formatNumberTranslate({ number: item.sku_cm_max })}
                                className="gap-2"
                                valueClassName="flex-1 text-right"
                            />
                        )}
                        {item.ptw_expire_time && (
                            <ItemTableMobile
                                title="Expire time"
                                dot
                                value={formatNumberTranslate({ number: item.ptw_expire_time })}
                                className="gap-2"
                                valueClassName="flex-1 text-right"
                            />
                        )}
                        <ItemTableMobile
                            title="Remain day"
                            dot
                            value={formatNumberTranslate({ number: item.ptw_remain_day })}
                            className="gap-2"
                            valueClassName="flex-1 text-right"
                        />
                        <ItemTableMobile title="Status" dot transition={false}>
                            <WrapperTag
                                title={
                                    Sku
                                        ? item.is_sku_approve
                                            ? "Approved"
                                            : "Pending"
                                        : item.product_state_id && item.product_state_id[1]
                                }
                                className="rounded-[1.563rem] text-white"
                                bgColor={
                                    Sku
                                        ? item.is_sku_approve
                                            ? "success"
                                            : "warning"
                                        : item.product_state_id && item.product_state_id[1] === "Normal"
                                        ? "success"
                                        : item.product_state_id && "warning"
                                }
                            />
                        </ItemTableMobile>
                        {paramsCost && item.permission_list.edit && (
                            <ItemTableMobile title="Options">
                                <div className="flex basis-2/12 justify-end text-white">
                                    <div
                                        className="rounded bg-[#343a40]"
                                        onClick={() =>
                                            toggleModal({
                                                type: ModalTypeEnum.EDIT_PRODUCTS_COST_SALES,
                                                title: "Update price to warehouse",
                                                data: {
                                                    ...item,
                                                },
                                                modal: {
                                                    width: 600,
                                                },
                                            })
                                        }
                                    >
                                        <EditOutlined className="cursor-pointer p-[6px]" />
                                    </div>
                                </div>
                            </ItemTableMobile>
                        )}
                        {Sku && !paramsCost && item.permission_list.edit && (
                            <ItemTableMobile title="Options">
                                <Link
                                    to={`${urlWebsite.SALES_PRODUCTS_EDIT}/${item.id}`}
                                    className="flex basis-2/12 justify-end text-white hover:text-white"
                                >
                                    <div className="rounded border-[1.5px] border-[#0d6efd]">
                                        <EditOutlined className="cursor-pointer p-[6px] text-[#0d6efd]" />
                                    </div>
                                </Link>
                            </ItemTableMobile>
                        )}

                        <ItemTableMobile
                            title="Date create"
                            dot
                            unit={
                                <Tooltip color={colorTooltip} title={moment(item.create_date).format("DD/MM/YYYY")}>
                                    {moment(item.create_date).fromNow()}
                                </Tooltip>
                            }
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
