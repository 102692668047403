import { axiosClient } from "./axios-client";

export const apiHrDashboard = {
    getStatic: () => {
        const url = "/hr-dashboard/static";
        return axiosClient.get(url);
    },
    getKpiReport: (params) => {
        const url = "/hr-dashboard/kpi-report";
        return axiosClient.get(url, { params });
    },
    getLeavesBizStatic: () => {
        const url = "/hr-dashboard/leave-biz-static";
        return axiosClient.get(url);
    },
    getFeedbackStatic: (params) => {
        const url = "/hr-dashboard/feedback-static";
        return axiosClient.get(url, { params });
    },
};
