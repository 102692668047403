import { createSlice } from "@reduxjs/toolkit";

import { onGetAllFeedback, onGetFeedbackDetailsId, onPatchSaveFeedback, onPatchIgnoreFeedback } from "./path-api";

const initialState = {
    loading: false,
    data: null,
    pagination: {
        _limit: null,
        _page: null,
        _total: null,
    },
    detailFeedback: null,
    loadingDetailFeedback: true,
    loadingSaveFeedback: false,
};

export const sliceFeedback = createSlice({
    name: "feedback",
    initialState: initialState,
    extraReducers: {
        [onGetAllFeedback.pending]: (state) => {
            state.loading = true;
        },
        [onGetAllFeedback.fulfilled]: (state, action) => {
            const { items, limit, page, total } = action.payload;
            state.data = items;
            state.pagination = {
                _limit: limit,
                _page: page,
                _total: total,
            };
            state.loading = false;
        },
        // get detail feedback
        [onGetFeedbackDetailsId.pending]: (state) => {
            state.loadingDetailFeedback = true;
        },
        [onGetFeedbackDetailsId.fulfilled]: (state, action) => {
            state.detailFeedback = action.payload;
            state.loadingDetailFeedback = false;
        },
        // patch save feedback
        [onPatchSaveFeedback.pending]: (state) => {
            state.loadingSaveFeedback = true;
        },
        [onPatchSaveFeedback.fulfilled]: (state) => {
            state.loadingSaveFeedback = false;
        },
        [onPatchSaveFeedback.rejected]: (state) => {
            state.loadingSaveFeedback = false;
        },
        // patch ignore feedback
        [onPatchIgnoreFeedback.pending]: (state) => {
            state.loadingSaveFeedback = true;
        },
        [onPatchIgnoreFeedback.fulfilled]: (state, action) => {
            const { message } = action.payload;
            const detailFeedbackOld = state.detailFeedback;

            if (message === "FEEDBACK_UNIGNORE") {
                state.detailFeedback = { ...detailFeedbackOld, is_ignore: false };
            } else {
                const task_feedback_new = detailFeedbackOld.task_feedback.map((item) => ({ ...item, value: null }));
                const user_feedback_new = detailFeedbackOld.user_feedback.map((item) => ({ ...item, value: null }));
                state.detailFeedback = {
                    ...detailFeedbackOld,
                    is_ignore: true,
                    comment: "",
                    task_feedback: task_feedback_new,
                    user_feedback: user_feedback_new,
                };
            }
            state.loadingSaveFeedback = false;
        },
        [onPatchIgnoreFeedback.rejected]: (state) => {
            state.loadingSaveFeedback = false;
        },
    },
});

const { reducer } = sliceFeedback;
export default reducer;
