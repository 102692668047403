import { createSlice } from "@reduxjs/toolkit";

import { onSearchPartner } from "./path-api";

const initialState = {
    loadingPartNer: false,
    dataPartner: null,
};

export const slicePartner = createSlice({
    name: "partner",
    initialState: initialState,
    extraReducers: {
        [onSearchPartner.pending]: (state) => {
            state.loadingPartNer = true;
        },
        [onSearchPartner.fulfilled]: (state, action) => {
            state.dataPartner = action.payload;
            state.loadingPartNer = false;
        },
    },
});

const { reducer } = slicePartner;
export default reducer;
