import { Button } from "customize";
import { urlWebsite } from "constant";
import { useContextControllers, useModal } from "hooks";
import moment from "moment";

import { BsFiles } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ButtonCopyEvent = ({ detailEvent, className }) => {
    const { t } = useTranslation();
    const { toggleModal, ModalTypeEnum } = useModal();
    const { settingAccount, setCopyEvent } = useContextControllers();
    const navigate = useNavigate();

    return (
        <Button
            label="Copy"
            Icon={BsFiles}
            className={className}
            BgSuccess
            border={false}
            onClick={() => {
                const day = moment(new Date()).format("DD");
                const month = moment(new Date()).format("MM");
                const year = moment(new Date()).format("YYYY");
                const date = `${day}/${month}/${year}`;
                setCopyEvent({
                    name: detailEvent.event.name,
                    type_txt: detailEvent.event.type_txt,
                    address: detailEvent.event.address,
                    is_external_meeting: detailEvent.event.is_external_meeting,
                    allow_register: detailEvent.event.allow_register,
                    detail: detailEvent.event.detail,
                    event_members: detailEvent.event_members,
                    watcher: detailEvent.watcher,
                    max_allow_register: detailEvent.event.max_allow_register,
                    send_to_group: detailEvent.event.send_to_group,
                    type: detailEvent.event.type,
                });

                if (settingAccount.is_modal_event) {
                    toggleModal({
                        title: t("Add day event {{date}}", { date: date }),
                        type: ModalTypeEnum.ADD_EVENT,
                        data: {
                            cancel: true,
                            date: date,
                        },
                        modal: {
                            width: 1500,
                        },
                    });
                } else {
                    navigate(`${urlWebsite.EVENTS_ADD}?_day=${day}&_month=${month}&_year=${year}`);
                    toggleModal({ type: ModalTypeEnum.NULL });
                }
            }}
        />
    );
};
