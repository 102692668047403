import * as React from "react";
import { Link } from "react-router-dom";

import { urlWebsite } from "constant";
import { Image } from "customize";
import { TitleTable, WrapperTag } from "layout";

import { EditOutlined } from "@ant-design/icons";
import { useFormatNumberTranslate } from "hooks";
import { useTranslation } from "react-i18next";

export const ColumnsTable = ({ paramsCost, Sku, toggleModal, ModalTypeEnum }) => {
    const { t } = useTranslation();
    const { formatNumberTranslate } = useFormatNumberTranslate();

    const columnsProductSkuTrue = [
        {
            title: <TitleTable title="STT" />,
            dataIndex: "stt",
            width: 50,
            render: (text, _, index) => (
                <div className="text-center">
                    <h6 className="font-semibold">{index + 1}</h6>
                </div>
            ),
        },
        {
            title: <TitleTable title="SKU Code" />,
            width: 140,
            dataIndex: "full",
            render: (_, record) => (
                <Link
                    to={`${urlWebsite.SALES_PRODUCTS}/${record.id}`}
                    className="text-left font-semibold text-[#007bff] hover:text-[#007bff] hover:underline dark:hover:text-[#f8f8fb]"
                >
                    {record.default_code}
                </Link>
            ),
        },
        {
            title: <TitleTable title="Product's name" className="text-left" />,
            dataIndex: "full",
            sorter: (a, b) => a.name.length - b.name.length,
            render: (_, record) => (
                <div className="flex items-center gap-4">
                    <h6 className="flex-1">{record.name}</h6>
                    {record.image_512 && <Image src={record.image_512} base64 className="h-8 w-8 rounded" />}
                </div>
            ),
        },
        {
            title: <TitleTable title="Unit" />,
            width: 100,
            dataIndex: "uom_id",
            render: (text) => (
                <div className="text-center">
                    <h6>{text ? text[1] : ""}</h6>
                </div>
            ),
        },
        {
            title: <TitleTable title="Minimum price" />,
            width: 150,
            sku: Sku,
            dataIndex: "sku_min_sale_price",
            sorter: (a, b) => a.sku_max_sale_price - b.sku_max_sale_price,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Maximum price" />,
            width: 150,
            dataIndex: "sku_max_sale_price",
            sorter: (a, b) => a.sku_min_sale_price - b.sku_min_sale_price,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Status" />,
            width: 130,
            sku: true,
            dataIndex: "is_sku_approve",
            render: (text) => (
                <div className="flex justify-center">
                    <WrapperTag title={text ? "Approved" : "Pending"} bgColor={text ? "success" : "warning"} />
                </div>
            ),
        },
        {
            title: <TitleTable title="Edit" />,
            width: 50,
            sku: true,
            dataIndex: "full",
            render: (_, record) => (
                <div className="flex items-center justify-center">
                    {record.permission_list.edit && (
                        <div>
                            <Link
                                to={`${urlWebsite.SALES_PRODUCTS_EDIT}/${record.id}`}
                                className="flex h-6 w-6 items-center justify-center rounded border-[1.5px] border-[#0d6efd]"
                            >
                                <EditOutlined className="cursor-pointer text-[#0d6efd]" />
                            </Link>
                        </div>
                    )}
                </div>
            ),
        },
    ];

    const columnsProductSkuFalse = [
        {
            title: <TitleTable title="STT" />,
            dataIndex: "stt",
            width: 50,
            render: (text, _, index) => (
                <div className="text-center">
                    <h6 className="font-semibold">{index + 1}</h6>
                </div>
            ),
        },
        {
            title: <TitleTable title="SKU Code" />,
            width: 140,
            dataIndex: "full",
            render: (_, record) => (
                <h6
                    className="cursor-pointer text-left font-semibold text-[#007bff] hover:text-[#007bff] dark:hover:text-[#f8f8fb]"
                    onClick={() =>
                        toggleModal({
                            type: ModalTypeEnum.SALES_PRODUCTS_DETAIL,
                            title: t("Product details {{value}}", { value: `#${record.id}` }),
                            data: {
                                ...record,
                            },
                            modal: {
                                width: 800,
                            },
                        })
                    }
                >
                    {record.default_code}
                </h6>
            ),
        },
        {
            title: <TitleTable title="Product's name" className="text-left" />,
            dataIndex: "full",
            sorter: (a, b) => a.name.length - b.name.length,
            render: (_, record) => (
                <div className="flex items-center gap-4">
                    <h6 className="flex-1">{record.name}</h6>
                    {record.image_512 && <Image src={record.image_512} base64 className="h-8 w-8 rounded" />}
                </div>
            ),
        },
        {
            title: <TitleTable title="Cm mix" />,
            cost: paramsCost,
            dataIndex: "sku_cm_min",
            sorter: (a, b) => a.sku_cm_min - b.sku_cm_min,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Cm max" />,
            cost: paramsCost,
            dataIndex: "sku_cm_max",
            sorter: (a, b) => a.sku_cm_max - b.sku_cm_max,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Expire time" />,
            dataIndex: "ptw_expire_time",
            sorter: (a, b) => a.ptw_expire_time - b.ptw_expire_time,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Remain day" />,
            dataIndex: "ptw_remain_day",
            sorter: (a, b) => a.ptw_remain_day - b.ptw_remain_day,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Unit" />,
            width: 100,
            dataIndex: "uom_id",
            render: (text) => (
                <div className="text-center">
                    <h6>{text ? text[1] : ""}</h6>
                </div>
            ),
        },
        {
            title: <TitleTable title="Status" />,
            width: 130,
            sku: true,
            dataIndex: "product_state_id",
            render: (text) => (
                <div className="flex justify-center">
                    {text && <WrapperTag title={text[1]} bgColor={text[1] === "Normal" ? "success" : "warning"} />}
                </div>
            ),
        },
    ];

    const columnsProductCost = [
        {
            title: <TitleTable title="STT" />,
            dataIndex: "stt",
            width: 50,
            render: (text, _, index) => (
                <div className="text-center">
                    <h6 className="font-semibold">{index + 1}</h6>
                </div>
            ),
        },
        {
            title: <TitleTable title="Codebook" />,
            width: 140,
            dataIndex: "full",
            render: (_, record) => (
                <h6
                    className="cursor-pointer text-left font-semibold text-[#007bff] hover:text-[#007bff] dark:hover:text-[#f8f8fb]"
                    onClick={() =>
                        toggleModal({
                            type: ModalTypeEnum.SALES_PRODUCTS_DETAIL,
                            title: t("Product details {{value}}", { value: `#${record.id}` }),
                            data: {
                                ...record,
                            },
                            modal: {
                                width: 800,
                            },
                        })
                    }
                >
                    {record.default_code}
                </h6>
            ),
        },
        {
            title: <TitleTable title="Product's name" className="text-left" />,
            dataIndex: "full",
            sorter: (a, b) => a.name.length - b.name.length,
            render: (_, record) => (
                <div className="flex items-center gap-4">
                    <h6 className="flex-1">{record.name}</h6>
                    {record.image_512 && <Image src={record.image_512} base64 className="h-8 w-8 rounded" />}
                </div>
            ),
        },
        {
            title: <TitleTable title="Unit" className="text-center" />,
            dataIndex: "uom_id",
            render: (text) => (
                <div className="text-center">
                    <h6>{text ? text[1] : ""}</h6>
                </div>
            ),
        },
        {
            title: <TitleTable title={t("Price to warehouse ({{value}})", { value: "VNĐ" })} />,
            dataIndex: "price_to_warehouse",
            sorter: (a, b) => a.price_to_warehouse - b.price_to_warehouse,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Expire time" />,
            dataIndex: "ptw_expire_time",
            sorter: (a, b) => a.ptw_expire_time - b.ptw_expire_time,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Remain day" />,
            dataIndex: "ptw_remain_day",
            sorter: (a, b) => a.ptw_remain_day - b.ptw_remain_day,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="%FC" />,
            dataIndex: "fc_ratio",
            sorter: (a, b) => a.fc_ratio - b.fc_ratio,
            render: (text) => (
                <h6 className="block text-center">{text ? formatNumberTranslate({ number: text }) : null}</h6>
            ),
        },
        {
            title: <TitleTable title="Edit" />,
            cost: paramsCost,
            dataIndex: "full",
            render: (_, record) => (
                <div className="flex items-center justify-center">
                    <div className="flex h-6 w-6 items-center justify-center rounded border-[1.5px] border-[#0d6efd]">
                        <EditOutlined
                            className="cursor-pointer text-[#0d6efd]"
                            onClick={() =>
                                toggleModal({
                                    type: ModalTypeEnum.EDIT_PRODUCTS_COST_SALES,
                                    title: "Update price to warehouse",
                                    data: {
                                        ...record,
                                    },
                                    modal: {
                                        width: 600,
                                    },
                                })
                            }
                        />
                    </div>
                </div>
            ),
        },
    ];

    const checkColumnsProduct = React.useMemo(() => {
        if (Sku && !paramsCost) {
            return columnsProductSkuTrue;
        }
        if (!Sku && !paramsCost) {
            return columnsProductSkuFalse;
        }
        if (paramsCost) {
            return columnsProductCost;
        }
    }, [Sku, paramsCost]);

    return { columnsProduct: checkColumnsProduct };
};
