import moment from "moment";
import { Image } from "customize";

import { Tooltip } from "antd";

import { colorTooltip } from "constant";
import { useFormatNumberTranslate } from "hooks";
import { AiOutlineDownload } from "react-icons/ai";
import { useTranslation } from "react-i18next";

export const ModalSalesProductsDetail = ({
    name,
    image_512,
    default_code,
    categ_id,
    create_date,
    create_uid,
    product_cost,
    description_sale,
    sku_description,
}) => {
    const { t } = useTranslation();
    const { formatNumberTranslate } = useFormatNumberTranslate();

    return (
        <div className="grid place-items-center gap-4 md:flex">
            {image_512 && (
                <div className="flex flex-col items-center gap-1">
                    <Image src={image_512} base64 className="h-full w-full max-w-[200px] rounded-sm md:max-w-[470px]" />

                    <a
                        href={`data:image;base64,${image_512}`}
                        download={`${name}.jpg`}
                        className="flex items-center gap-1 text-[#03942c] hover:text-[#03942c] hover:underline"
                    >
                        <AiOutlineDownload />
                        {t("Download image")}
                    </a>
                </div>
            )}
            <div className="w-full divide-y divide-[#eaeaea] dark:divide-[#424b64]">
                <ItemInfo title="Name" value={name} />
                <ItemInfo title="CodeBook" value={default_code} />
                <ItemInfo title="Category" value={categ_id[1]} />
                <ItemInfo
                    title="Date created"
                    value={
                        <Tooltip color={colorTooltip} title={moment(create_date).format("DD/MM/YYYY")}>
                            {moment(create_date).fromNow()}
                        </Tooltip>
                    }
                />
                <ItemInfo title="Creator" value={create_uid[1]} />
                {sku_description && <ItemInfo title="Description" value={sku_description} />}
                {product_cost && (
                    <ItemInfo title="Price cost" value={formatNumberTranslate({ number: product_cost })} />
                )}
                {description_sale && <ItemInfo title="Description" value={description_sale} />}
            </div>
        </div>
    );
};

const ItemInfo = ({ title, value }) => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-between gap-x-4 py-1 dark:hover:bg-[#1c1f2b]">
            <h6 className="font-semibold">{t(title)}</h6>
            <p className="flex-1 text-right">{value}</p>
        </div>
    );
};
