import { createSlice } from "@reduxjs/toolkit";
import { onSearchEmailGroup } from "./path-api";

const initialState = {
    loading: false,
    dataEmailGroup: null,
};

export const sliceEmail = createSlice({
    name: "email",
    initialState: initialState,
    extraReducers: {
        [onSearchEmailGroup.pending]: (state) => {
            state.loading = true;
        },
        [onSearchEmailGroup.fulfilled]: (state, action) => {
            state.dataEmailGroup = action.payload;
            state.loading = false;
        },
    },
});

const { reducer } = sliceEmail;
export default reducer;
