import { axiosClient } from "./axios-client";

export const apiAccount = {
    login: ({ username, password }) => {
        const url = "/token";
        const bodyFormData = new FormData();
        bodyFormData.append("username", username);
        bodyFormData.append("password", password);
        return axiosClient.post(url, bodyFormData);
    },
    loginPartner: ({ username, password }) => {
        const url = "/token";
        const bodyFormData = new FormData();
        bodyFormData.append("username", username);
        bodyFormData.append("password", password);
        return axiosClient.post(url, bodyFormData);
    },
    getInfoUser: () => {
        const url = "users/me";
        return axiosClient.get(url);
    },
    logOut: () => {
        const url = "/logout";
        return axiosClient.get(url);
    },
    getProfileIcms: (username) => {
        const url = `users/profile/icms/username/${username}`;
        return axiosClient.get(url);
    },
    getProfileUSer: (username) => {
        const url = `/users/profile/username/${username}`;
        return axiosClient.get(url);
    },
    getKpiScore: () => {
        const url = "/users/me/kpi-score";
        return axiosClient.get(url);
    },
    getProfileUserById: (id) => {
        const url = `/users/profile/icms/id/${id}`;
        return axiosClient.get(url);
    },
    changeAvatar: (data) => {
        const url = "/users/me/change-avatar?raise_error=true";
        return axiosClient.patch(url, data);
    },
    changePassword: (data) => {
        const url = "/users/me/change-password";
        return axiosClient.patch(url, data);
    },
    getNotification: () => {
        const url = "/users/me/notification";
        return axiosClient.get(url);
    },
    getMenuSidebarUser: () => {
        const url = "/users/me/web-ui-menu";
        return axiosClient.get(url);
    },
    getBusinessTrip: () => {
        const url = "/business-trip/me/to-approve";
        return axiosClient.get(url);
    },
    getLeaves: () => {
        const url = "/leaves/me/to-approve";
        return axiosClient.get(url);
    },
    getOverTime: () => {
        const url = "/overtime/me/to-approve";
        return axiosClient.get(url);
    },
    getFinances: () => {
        const url = "/finances/me/payment/to-approve";
        return axiosClient.get(url);
    },
    patchApproveLeaves: (data) => {
        const { record_id, params } = data;
        const url = `/leaves/me/approve/${record_id}`;
        return axiosClient.patch(url, null, { params });
    },
    patchApproveOverTime: (data) => {
        const { record_id, params } = data;
        const url = `/overtime/me/approve/${record_id}`;
        return axiosClient.patch(url, null, { params });
    },
    patchApproveFinances: (data) => {
        const { record_id, params } = data;
        const url = `/finances/me/payment/approve/${record_id}`;
        return axiosClient.patch(url, null, { params });
    },
    patchApproveBusinessTrip: (data) => {
        const { record_id, params } = data;
        const url = `/business-trip/me/approve/${record_id}`;
        return axiosClient.patch(url, null, { params });
    },
    getKpiDashboard: (params) => {
        const url = "/users/me/kpi-dashboard";
        return axiosClient.get(url, { params });
    },
    getKpiDashboardTable: (params) => {
        const url = "/users/me/kpi-dashboard/table";
        return axiosClient.get(url, { params });
    },
    getKpiListConfig: () => {
        const url = "users/kpi/list-config";
        return axiosClient.get(url);
    },
    getNotificationChannel: () => {
        const url = "users/me/push-channel";
        return axiosClient.get(url);
    },
    patchNotificationChannel: (params) => {
        const url = `users/me/push-channel?channel_name=${params}`;
        return axiosClient.patch(url);
    },
};
