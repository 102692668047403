import { Drawer } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DrawerTypeEnum } from "constant";
import { useDrawer } from "hooks";

import { DrawerProfile } from "./components";

const checkDrawer = (type) => {
    switch (type) {
        case DrawerTypeEnum.VIEW_PROFILE:
            return DrawerProfile;
        default:
            return null;
    }
};

export const DrawerComponent = () => {
    const { t } = useTranslation();

    const { toggleDrawer, resultDrawer } = useDrawer();

    const { type, open, title, data, drawer } = resultDrawer;

    const WrapperDrawer = React.useMemo(() => checkDrawer(type), [type]);

    if (!WrapperDrawer) return null;
    return (
        <Drawer
            title={<h5 className="text-[1.015625rem] font-semibold dark:text-white">{t(title)}</h5>}
            {...drawer}
            onClose={toggleDrawer}
            visible={open}
            bodyStyle={{ padding: "0" }}
            className="transition-globals"
        >
            <div className="px-4 py-2">
                <WrapperDrawer {...data} />
            </div>
        </Drawer>
    );
};
