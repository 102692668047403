import * as React from "react";
import { Link } from "react-router-dom";

import { WrapperSearch } from "layout";
import { useContextControllers, useTypeProject } from "hooks";
import { urlWebsite, storeLocalStorage } from "constant";
import { Image } from "customize";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { iconLogoWords } from "assets/icon";

export const HeaderLeft = ({ setDropDownMenu, dropDownMenu, isDropdown = true }) => {
    const { openMenu, setOpenMenu, settingAccount, setSettingAccount } = useContextControllers();
    const { isAppMobile } = useTypeProject();

    React.useLayoutEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleResize = () => {
        const { innerWidth: width } = window;
        if (width <= 1024) {
            const payload = { ...settingAccount, horizontal: false };
            setDropDownMenu(false);
            setSettingAccount(payload);
            localStorage.setItem(storeLocalStorage.DROP_DOWN_MENU, JSON.stringify({ open: false }));
            localStorage.setItem(storeLocalStorage.SETTING_ACCOUNT, JSON.stringify(payload));
        }
    };

    return (
        <div className="2lg:max-w-[400px] w-fll flex items-center gap-x-4 md:w-full md:max-w-[300px] lg:max-w-[453px]">
            {isDropdown && !settingAccount.horizontal && (
                <div className="hidden lg:block">
                    <MenuFoldOutlined
                        className="cursor-pointer text-lg text-[#555b6d]"
                        onClick={() => {
                            localStorage.setItem(
                                storeLocalStorage.DROP_DOWN_MENU,
                                JSON.stringify({ open: !dropDownMenu })
                            );
                            setDropDownMenu(!dropDownMenu);
                        }}
                    />
                </div>
            )}
            {isDropdown && (
                <div className="lg:hidden">
                    <MenuUnfoldOutlined className="cursor-pointer text-lg" onClick={() => setOpenMenu(!openMenu)} />
                </div>
            )}
            {settingAccount.horizontal && !isAppMobile && (
                <Link to={urlWebsite.HOME_PAGE}>
                    <Image src={iconLogoWords} className="h-8 w-full" />
                </Link>
            )}
            {isDropdown && <WrapperSearch />}
        </div>
    );
};
