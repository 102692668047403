import { translationMenu } from "constant";

import vnmToAlphabet from "vnm-to-alphabet";

export const useTranslate = () => {
    const checkLanguage = (subMenu) => {
        const result = translationMenu.find(
            (item) =>
                vnmToAlphabet(item.title.toLowerCase(), "capitalize") ===
                vnmToAlphabet(subMenu.toLowerCase(), "capitalize")
        );
        return result ? result.value : subMenu;
    };
    return { checkLanguage };
};
