import { Form, Input, notification, Tabs } from "antd";
import queryString from "query-string";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { storeLocalStorage } from "constant";
import { Button } from "customize";
import { useAccount } from "hooks";
import { BiLogInCircle } from "react-icons/bi";
import "./style.scss";
const { TabPane } = Tabs;

const { REACT_APP_OAUTH2_URL, REACT_APP_OAUTH2_CLIENT_ID } = process.env;

export const FormLogin = () => {
    const { handleLogin, handleLoginPartner, resultStoreAccount } = useAccount();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const baseUrl = window.location.href;

    const auth_token = queryString.parse(location.search).auth_token;

    React.useEffect(() => {
        (async () => {
            if (auth_token) {
                const result = await handleLogin({
                    username: storeLocalStorage.USERNAME_lOGIN,
                    password: auth_token,
                });
                if (result?.error) {
                    notification.error({
                        message: <h6>{t("Notification")}</h6>,
                        description: <p>{t("The system is under maintenance")}</p>,
                    });
                    navigate("/", { replace: true });
                }
                const request = unwrapResult(result);
                if (request) {
                    navigate(location.pathname, { replace: true });
                }
            }
        })();
    }, [auth_token, handleLogin, location.pathname, navigate, t]);

    const onLogin = () => {
        const oauth2_url = `${REACT_APP_OAUTH2_URL}/authorize/?redirect_uri=${escape(
            baseUrl
        )}&scope=read_profile&response_type=code&client_id=${REACT_APP_OAUTH2_CLIENT_ID}`;
        window.location.href = oauth2_url;
    };
    const handleSubmitPartner = async (values) => {
        const result = await handleLoginPartner(values);
        if (result?.error) {
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("Incorrect account or password")}</p>,
            });
        }
    };

    return (
        <div className="grid w-full rounded-r-md bg-[#4e59ce] px-5 py-[50px] text-center lg:max-w-[400px] lg:py-[50px] lg:px-8">
            <div className="mb-8 text-gray-50 lg:mb-0">
                <h6 className="mb-2 text-[1.015625rem] text-white">{t("Welcome back")}</h6>
                <p>{t("Log in to your account")}</p>
            </div>
            <Tabs defaultActiveKey="1" className="group-tab-login">
                <TabPane tab={<p className="font-semibold">SolarBK ID</p>} key="1">
                    <div className="flex min-h-[248px] w-full items-center justify-center">
                        {resultStoreAccount.loading ? (
                            <LoadingOutlined className="text-[1.375rem] font-semibold text-white" />
                        ) : (
                            <h3 className="title-login self-start" onClick={onLogin}>
                                {t("Login with")}
                                <p className="rounded bg-white p-1 font-semibold text-[#ed1c24]">
                                    Solar <span className="-ml-[4px] text-[#0072bc]">BK</span> ID
                                </p>
                            </h3>
                        )}
                    </div>
                </TabPane>
                <TabPane tab={<p className="font-semibold">{t("Partner")}</p>} key="2">
                    <div className="min-h-[248px] text-left">
                        <Form initialValues={{ remember: true }} onFinish={handleSubmitPartner} layout="vertical">
                            <Form.Item
                                label={<h6 className="text-white">{t("Email")}</h6>}
                                name="username"
                                rules={[
                                    {
                                        type: "email",
                                        message: (
                                            <p className="text-[0.9rem] text-[yellow]">
                                                {t("The input is not valid E-mail!")}
                                            </p>
                                        ),
                                    },
                                    {
                                        required: true,
                                        message: (
                                            <p className="text-[0.9rem] text-[yellow]">
                                                {t("Please input your E-mail!")}
                                            </p>
                                        ),
                                    },
                                ]}
                                className="mb-8"
                            >
                                <StyledInputText
                                    bordered={false}
                                    prefix={<UserOutlined className="text-white" />}
                                    placeholder={t("Please input your E-mail!")}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<h6 className="text-white">{t("Password")}</h6>}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <p className="text-[0.9rem] text-[yellow]">{t("Please enter password!")}</p>
                                        ),
                                    },
                                ]}
                            >
                                <StyledInputTextPassword
                                    bordered={false}
                                    prefix={<LockOutlined className="text-white" />}
                                    type="password"
                                    placeholder={t("Enter your password")}
                                />
                            </Form.Item>
                            <Button
                                Icon={BiLogInCircle}
                                label="Login"
                                className="mt-8 bg-white py-2 px-8 font-semibold text-[#3d46a7]"
                                loading={resultStoreAccount.loading}
                            />
                        </Form>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
};

const StyledInputText = styled(Input)`
    background: #3d46a7 !important;
    border: 1px solid #3d46a7 !important;
    padding: 8px 12px;
    border-radius: 0.25rem;
    & > input {
        background: transparent;
        font-size: 0.813rem;
        color: #fff;
    }
    & > span svg {
        color: #fff;
    }
`;

const StyledInputTextPassword = styled(Input.Password)`
    background: #3d46a7 !important;
    border: 1px solid #3d46a7 !important;
    padding: 8px 12px;
    border-radius: 0.25rem;
    & > input {
        background: transparent;
        font-size: 0.813rem;
        color: #fff;
    }
    & > span svg {
        color: #fff;
    }
`;
