import { Tag } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const WrapperTag = ({
    title,
    bgColor,
    className = "text-white",
    fontSize = "text-[0.7rem]",
    transition,
    rounded = true,
    hover,
    children,
    style,
    onClick,
    icon,
}) => {
    const { t } = useTranslation();
    const checkColor = (bgColor) => {
        switch (bgColor) {
            case "success":
                return "#03942c";
            case "secondary":
                return "#6c757d";
            case "warning":
                return "#ffc107";
            case "danger":
                return "#dc3545";
            case "info":
                return "#0dcaf0";
            case "primary":
                return "#0d6efd";
            case "events":
                return "#0264af";
            case "training":
                return "#8f81fe";
            case "erp":
                return "#875a7b";
            default:
                return "#03942c";
        }
    };
    return (
        <Tag
            style={style}
            color={checkColor(bgColor)}
            icon={icon}
            className={clsx(
                "mr-0 capitalize",
                className,
                fontSize,
                hover && "hover:underline",
                rounded && "rounded-dark rounded-[1.563rem]"
            )}
            onClick={onClick}
        >
            {transition ? t(title) : title} {children}
        </Tag>
    );
};
