import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useModal, useContextControllers } from "hooks";
import { Button } from "customize";

import { urlWebsite } from "constant";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ContainerModal } from "modal";
import { ModalDeleteEvent } from "modal/components";

import { ButtonCopyEvent } from "layout";

export const UserControl = ({ idEvent, uidEvent, detailEvent, eventBeginDay }) => {
    const navigate = useNavigate();
    const { _uid } = useParams();
    const [openDelete, setOpenDelete] = React.useState(false);

    const { toggleModal, ModalTypeEnum } = useModal();
    const { settingAccount } = useContextControllers();

    return (
        <>
            <div className="flex">
                <Button
                    border={false}
                    label="Delete"
                    BgDanger
                    className="h-8 min-w-[110px] rounded-l-[0.25rem]"
                    onClick={() => setOpenDelete(true)}
                    Icon={DeleteOutlined}
                />

                <Button
                    onClick={() => {
                        if (settingAccount.is_modal_event && !_uid) {
                            toggleModal({
                                title: "Edit events",
                                type: ModalTypeEnum.EDIT_EVENT,
                                data: {
                                    _uid: uidEvent,
                                    cancel: true,
                                },
                                modal: {
                                    width: 1500,
                                },
                            });
                        } else {
                            navigate(`${urlWebsite.EVENTS_EDIT}/${uidEvent}`);
                            toggleModal({
                                type: ModalTypeEnum.NULL,
                            });
                        }
                    }}
                    label="Edit"
                    Icon={EditOutlined}
                    className="h-8 min-w-[110px]"
                    BgPrimary
                    border={false}
                />

                <ButtonCopyEvent
                    detailEvent={detailEvent}
                    eventBeginDay={eventBeginDay}
                    className="h-8 min-w-[110px] rounded-r-[0.25rem]"
                />
            </div>
            <ContainerModal
                open={openDelete}
                closeDoubleModal={() => setOpenDelete(!openDelete)}
                title="Confirm delete this event?"
            >
                <ModalDeleteEvent idEvent={idEvent} uidEvent={uidEvent} />
            </ContainerModal>
        </>
    );
};
