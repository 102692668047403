import * as React from "react";
import { Form, Input, notification } from "antd";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";

import { Button } from "customize";
import { useSales, useModal } from "hooks";
import { VscSaveAs } from "react-icons/vsc";

export const ModalAddSalesLead = ({ my_lead }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);

    const { toggleModal, ModalTypeEnum } = useModal();
    const { handlePostCreateLead, handleGetLeads } = useSales();

    const onSubmitCreateLead = async (value) => {
        setLoading(true);
        const result = await handlePostCreateLead(value);
        if (result?.error) {
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("An error has occurred")}</p>,
            });
            setLoading(false);
        }
        const request = unwrapResult(result);
        if (request) {
            setLoading(false);
            notification.success({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("Create lead successfully")}</p>,
            });
            handleGetLeads({ page: 1, params: { my_lead: my_lead } });
            toggleModal({
                type: ModalTypeEnum.NULL,
            });
        }
    };

    return (
        <div>
            <Form layout="vertical" onFinish={onSubmitCreateLead} className="title-semibold">
                <Form.Item
                    label={t("Name lead")}
                    name="name"
                    className="items-input grid"
                    rules={[{ required: true, message: t("Please enter this field!") }]}
                >
                    <Input />
                </Form.Item>
                <Button
                    Icon={VscSaveAs}
                    loading={loading}
                    label="Save"
                    type="primary"
                    htmlType="submit"
                    className="mt-4"
                    BgSuccess
                />
            </Form>
        </div>
    );
};
