import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import { storeProducts, useAccount } from "hooks";
import {
    onGetAllProducts,
    onGetProductDetails,
    onSearchAllProducts,
    onGetCategoryProduct,
    onGetUomProduct,
    onGetMadeInListProduct,
    onGetWarrantyListProduct,
    onGetCategorySkuProduct,
    onSearchProduct,
    addToProductListProduct,
    removeProductListProduct,
    updateEditSalesProduct,
    updateQuantityProductListProduct,
    onPostCreateProductSKU,
    onUpdateProductSku,
    onGetProductsCost,
    onUpdateProductCost,
    reducersUpdateProductCost,
    onSubmitApproveProduct,
} from "features";

export const useProducts = () => {
    const dispatch = useDispatch();
    const { access_token } = useAccount();
    const resultStoreProducts = useSelector(storeProducts);

    const {
        dataProducts,
        dataProductsDetails,
        pagination,
        loading,
        dataSearchProducts,
        loadingDataSearchProducts,
        loadingSubmitApproveProduct,
    } = resultStoreProducts;

    const handleGetAllProducts = React.useCallback(
        (payload) => access_token && dispatch(onGetAllProducts(payload)),
        [access_token, dispatch]
    );

    const handleGetProductDetails = React.useCallback(
        (productId) => access_token && dispatch(onGetProductDetails(productId)),
        [access_token, dispatch]
    );

    const handleSearchAllProducts = React.useCallback(
        (payload) => access_token && dispatch(onSearchAllProducts(payload)),
        [access_token, dispatch]
    );

    const handleGetCategoryProduct = React.useCallback(
        () => access_token && dispatch(onGetCategoryProduct()),
        [access_token, dispatch]
    );

    const handleGetUomProduct = React.useCallback(
        () => access_token && dispatch(onGetUomProduct()),
        [access_token, dispatch]
    );

    const handleGetMadeInListProduct = React.useCallback(
        () => access_token && dispatch(onGetMadeInListProduct()),
        [access_token, dispatch]
    );

    const handleGetWarrantyListProduct = React.useCallback(
        () => access_token && dispatch(onGetWarrantyListProduct()),
        [access_token, dispatch]
    );

    const handleGetCategorySkuProduct = React.useCallback(
        () => access_token && dispatch(onGetCategorySkuProduct()),
        [access_token, dispatch]
    );

    const handleSearchProduct = React.useCallback(
        (keyword) => access_token && dispatch(onSearchProduct(keyword)),
        [access_token, dispatch]
    );

    const handleAddToProductListProduct = React.useCallback(
        (payload) => dispatch(addToProductListProduct(payload)),
        [dispatch]
    );

    const handleRemoveProductListProduct = React.useCallback(
        (payload) => dispatch(removeProductListProduct(payload)),
        [dispatch]
    );

    const handleUpdateQuantityProductListProduct = React.useCallback(
        (payload) => dispatch(updateQuantityProductListProduct(payload)),
        [dispatch]
    );

    const handleUpdateEditSalesProduct = React.useCallback(
        (payload) => dispatch(updateEditSalesProduct(payload)),
        [dispatch]
    );

    const handlePostCreateProductSKU = React.useCallback(
        (payload) => access_token && dispatch(onPostCreateProductSKU(payload)),
        [access_token, dispatch]
    );

    const handleUpdateProductSku = React.useCallback(
        (data) => access_token && dispatch(onUpdateProductSku(data)),
        [access_token, dispatch]
    );

    const handleGetProductsCost = React.useCallback(
        (payload) => access_token && dispatch(onGetProductsCost(payload)),
        [access_token, dispatch]
    );

    const handleUpdateProductCost = React.useCallback(
        (payload) => access_token && dispatch(onUpdateProductCost(payload)),
        [dispatch, access_token]
    );

    const updateProductCostReducers = React.useCallback(
        (payload) => dispatch(reducersUpdateProductCost(payload)),
        [dispatch]
    );

    const handleSubmitApproveProduct = React.useCallback(
        (payload) => access_token && dispatch(onSubmitApproveProduct(payload)),
        [access_token, dispatch]
    );

    return {
        handleGetAllProducts,
        handleGetProductDetails,
        handleSearchAllProducts,
        handleGetCategoryProduct,
        handleGetUomProduct,
        handleGetMadeInListProduct,
        handleGetWarrantyListProduct,
        handleGetCategorySkuProduct,
        handleSearchProduct,
        handleAddToProductListProduct,
        handleRemoveProductListProduct,
        handleUpdateQuantityProductListProduct,
        handlePostCreateProductSKU,
        handleUpdateEditSalesProduct,
        handleUpdateProductSku,
        handleGetProductsCost,
        handleUpdateProductCost,
        updateProductCostReducers,
        handleSubmitApproveProduct,
        dataSearchProducts,
        loadingDataSearchProducts,
        dataProductsDetails,
        dataProducts,
        pagination,
        loading,
        resultStoreProducts,
        loadingSubmitApproveProduct,
    };
};
