import * as React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Input, Form, message } from "antd";
import { useTranslation } from "react-i18next";

import { useEvents, useModal, useAccount, useContextControllers } from "hooks";
import { Button } from "customize";
import { FiSend } from "react-icons/fi";

export const ModalEventConfirmLater = ({
    eventUid,
    confirm_code,
    confirm_type,
    dataEventDetail,
    setDataEventDetail,
    confirm_status,
    confirmEventMeetingFast,
    closeDoubleModal,
}) => {
    const { t } = useTranslation();
    const { profile } = useAccount();
    const { handlePatchConfirmEvent, handlePatchConfirmEventMeetingFast, handleConfirmEventReducers } = useEvents();
    const { toggleModal, ModalTypeEnum } = useModal();
    const { setStatusEvent } = useContextControllers();

    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    const onSubmitConfirmEventLater = async ({ confirm_reason }) => {
        try {
            const payload = {
                eventUid,
                data: {
                    confirm_code,
                    confirm_reason,
                    confirm_type,
                },
            };
            if (confirmEventMeetingFast) {
                setLoading(true);
                const resultResult = await handlePatchConfirmEventMeetingFast(payload);
                const resultResultData = unwrapResult(resultResult);
                if (resultResultData) {
                    handleConfirmEventReducers({
                        id: dataEventDetail?.event?.id,
                        confirm_status: confirm_status,
                    });
                    setStatusEvent({
                        status: confirm_status,
                        uid: eventUid,
                    });
                    setLoading(false);
                    if (closeDoubleModal) {
                        closeDoubleModal(false);
                        form.resetFields();
                    } else {
                        toggleModal({
                            type: ModalTypeEnum.NULL,
                        });
                    }
                }
            } else {
                setLoading(true);
                const eventMembers = [...dataEventDetail.event_members];
                const checkEventMember = eventMembers.find(({ user_id }) => Number(user_id) === profile.id);
                const checkIndexEventMember = eventMembers.findIndex(({ user_id }) => Number(user_id) === profile.id);
                eventMembers[checkIndexEventMember] = {
                    ...checkEventMember,
                    confirm_status: confirm_status,
                    confirm_reason: confirm_reason,
                };

                const resultResult = await handlePatchConfirmEvent(payload);
                const resultResultData = unwrapResult(resultResult);
                if (resultResultData) {
                    handleConfirmEventReducers({
                        id: dataEventDetail?.event?.id,
                        confirm_status: confirm_status,
                    });
                    setStatusEvent({
                        status: confirm_status,
                        uid: eventUid,
                    });
                    setLoading(false);
                    setDataEventDetail({
                        ...dataEventDetail,
                        confirm_status: confirm_status,
                        event_members: eventMembers,
                    });
                    message.success(t("Successful confirmation"));
                    if (closeDoubleModal) {
                        closeDoubleModal(false);
                        form.resetFields();
                    } else {
                        toggleModal({
                            type: ModalTypeEnum.NULL,
                        });
                    }
                }
            }
        } catch (error) {
            message.error(t("An error occurred, please try again later"));
            setLoading(false);
        }
    };

    return (
        <div>
            <Form onFinish={onSubmitConfirmEventLater} form={form}>
                <div className="rounded-[0.25rem] border border-[#ced4da] p-3 dark:border-[#424b64]">
                    <Form.Item
                        name="confirm_reason"
                        rules={[
                            {
                                required: true,
                                message: `${t("Need a reason")}...`,
                            },
                            {
                                min: 3,
                                message: t("The content is too short at least 3 characters!"),
                            },
                        ]}
                    >
                        <Input.TextArea
                            placeholder={`${t("Write your reason")}...`}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            size="large"
                            allowClear
                            bordered={false}
                            disabled={loading}
                            className="placeholder:text-[0.813rem]"
                        />
                    </Form.Item>
                </div>
                <Button
                    Icon={FiSend}
                    loading={loading}
                    label="Send"
                    BgSuccess
                    className="mt-[1rem] max-w-[140px] rounded-[.25rem] px-[0.75rem] py-[0.47rem]"
                />
            </Form>
        </div>
    );
};
