import { useTranslation } from "react-i18next";
export const LoadingItem = ({ className, title }) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <h6>{t(title)}</h6>
            <div className="mb-[10px] h-8 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
        </div>
    );
};
