import { useSearch, useToggle } from "hooks";

import { InputSearch, SearchResult, DrawerSearch, WrapperError } from "./components";

import { SearchOutlined } from "@ant-design/icons";

export const WrapperSearch = () => {
    const { keyword, openSearch, dataSearch, handleOpenSearch, loading, onChangeKeyword, clearKeywords } = useSearch();

    const { setOpen, open } = useToggle();

    return (
        <div className="relative w-auto md:w-full">
            <InputSearch
                className="hidden md:block"
                keyword={keyword}
                loading={loading}
                onChangeKeyword={onChangeKeyword}
                clearKeywords={clearKeywords}
            />
            <SearchResult
                keyword={keyword}
                data={dataSearch}
                handleOpenSearch={handleOpenSearch}
                onOpenSearch={openSearch}
                className="absolute z-10 hidden max-h-[500px] rounded-lg bg-white p-2 shadow-lg dark:bg-[#2a3042] md:block"
            />
            <div className="block md:hidden">
                <SearchOutlined
                    className="cursor-pointer text-lg text-[#555b6d] dark:text-slate-200"
                    onClick={() => setOpen(!open)}
                />
            </div>
            <WrapperError className="hidden md:block" openSearch={openSearch} keyword={keyword} data={dataSearch} />
            <DrawerSearch
                isOpen={open}
                onSetOpen={() => setOpen(!open)}
                keyword={keyword}
                loading={loading}
                onChangeKeyword={onChangeKeyword}
                clearKeywords={clearKeywords}
                data={dataSearch}
                onOpenSearch={openSearch}
                handleOpenSearch={() => setOpen(!open)}
            />
        </div>
    );
};
