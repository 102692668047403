import * as React from "react";
import moment from "moment";
import { Table } from "antd";

import { apiEvent } from "api";
import { LoadingTable, NoData, TitleTable, WrapperScroll } from "layout";
import { useSearchTable } from "hooks";

export const ModalResourceList = ({ day, dataResourceList }) => {
    const dateUrl = moment(day, "DD-MM-YYYY").format("YYYY-MM-DD");
    const [resourceList, setResourceList] = React.useState();
    const { getColumnSearchProps } = useSearchTable({ placeholder: "Search name" });

    React.useEffect(() => {
        (async () => {
            const resource_list = [];
            dataResourceList.forEach(({ id }) =>
                resource_list.push(
                    apiEvent.checkAvailableResource({
                        resourceId: id,
                        payload: {
                            start_time: `${dateUrl} 00:05:00`,
                            end_time: `${dateUrl} 23:59:00`,
                            event_id: 0,
                        },
                    })
                )
            );

            const result_resource = await Promise.all(resource_list);

            const set_resource = result_resource.map((item, index) => {
                return {
                    ...dataResourceList[index],
                    available: item.available,
                    events_use: item.events_use.sort((a, b) =>
                        moment(a.start_time).format("HH:mm").localeCompare(moment(b.start_time).format("HH:mm"))
                    ),
                };
            });
            const resultFilter = set_resource.filter((item) => item.available === false);
            setResourceList(resultFilter);
        })();
    }, [dataResourceList, dateUrl]);

    if (!resourceList) return <LoadingTable />;

    return (
        <WrapperScroll className="globals__display_block max-h-96">
            <div className="block sm:hidden">
                {resourceList.map((item) => (
                    <div key={item.id} className="pt-3">
                        <h3 className="py-1 font-semibold">{item.name}</h3>
                        <div className="grid gap-1 divide-y divide-[#eaeaea] dark:divide-[#424b64] dark:border-[#424b64]">
                            {item.events_use.map((e, index) => (
                                <div key={index}>
                                    <div className="flex gap-2">
                                        <p className="text-[#03942c]">{moment(e.start_time).format("HH:mm")}</p>
                                        <p className="text-[#dc3545]">{moment(e.end_time).format("HH:mm")}</p>
                                        <p>{e.name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="hidden sm:block">
                <Table
                    locale={{
                        emptyText: <NoData title="No data available yet" noData />,
                    }}
                    columns={[
                        {
                            title: <TitleTable title="Booking" />,
                            dataIndex: "name",
                            ...getColumnSearchProps("name"),
                            sorter: (a, b) => a.name.length - b.name.length,
                            render: (text) => text,
                        },
                        {
                            title: <TitleTable title="Time" />,
                            dataIndex: "events_use",
                            render: (eventsUse) => (
                                <div>
                                    {eventsUse.map((item, index) => (
                                        <div key={index}>
                                            <div className="flex gap-2">
                                                <p className="text-[#03942c]">
                                                    {moment(item.start_time).format("HH:mm")}
                                                </p>
                                                <p className="text-[#dc3545]">
                                                    {moment(item.end_time).format("HH:mm")}
                                                </p>
                                                <p>{item.name}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ),
                        },
                    ]}
                    dataSource={resourceList}
                    pagination={false}
                />
            </div>
        </WrapperScroll>
    );
};
