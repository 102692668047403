import { createSlice } from "@reduxjs/toolkit";

import { extraReducers } from "./extra-reducers";
import { reducers } from "./reducers";

const initialState = {
    dataListSales: null,
    isLoadingSales: false,
    loadingSearchApproverPrice: false,
    loadingSearchApproverTech: false,
    loadingQuotationCategory: false,
    loadingSubQuoteCategory: false,
    loading: false,
    dataSearchApproverPrice: null,
    dataSearchApproverTech: null,
    dataQuotationCategory: null,
    dataSubQuoteCategory: null,
    dataAddSKUQuotationReducers: [],
    dataDiscountList: [],
    dataQuotationDiscount: [],
    dataOrderLineQuotation: null,
    dataGetSkuQuotation: null,
    dataLeads: null,
    dataAbsLead: {
        dataLead: null,
        loading: true,
    },
    dataAbsProductLine: null,
    pagination: {
        limit: 10,
        page: 1,
        sortable: 1,
        total: 1,
    },
};

export const sliceSales = createSlice({
    name: "sales",
    initialState: initialState,
    reducers: reducers,
    extraReducers: extraReducers,
});

const { reducer, actions } = sliceSales;
export const { addSKUQuotationReducers, addQuotationDiscountReducers, patchApprovedQuotationStateReducers } = actions;

export default reducer;
