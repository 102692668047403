import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { urlWebsite } from "constant";
import { WrapperScroll } from "layout";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const SearchResult = ({ keyword, data, handleOpenSearch, onOpenSearch, className }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onClickSearch = (id) => {
        navigate(`${urlWebsite.PAGE_KNOWLEDGE}?_id=${id}`);
        handleOpenSearch();
    };

    return (
        data?.length > 0 &&
        keyword &&
        onOpenSearch && (
            <WrapperScroll className={clsx("hover", className)}>
                {data.map((search) => (
                    <div
                        key={search.id}
                        className="cursor-pointer rounded p-2 hover:bg-[#f0f2f5] dark:hover:bg-[#1c1f2b]"
                        onClick={() => onClickSearch(search.id)}
                    >
                        <h4 className="font-medium dark:text-slate-200">{search.name}</h4>
                        <p className="mt-2 text-[0.75rem] dark:text-slate-200">
                            {t("Date created")} : {moment(search.create_date.split(" ")[0]).format("DD/MM/YYYY")}
                        </p>
                    </div>
                ))}
            </WrapperScroll>
        )
    );
};
