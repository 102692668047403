import { useNavigate } from "react-router-dom";
import { DatePicker, Form } from "antd";
import moment from "moment";
import styled from "styled-components";

import { urlWebsite } from "constant";
import { Button } from "customize";
import { useContextControllers, useModal } from "hooks";
import { VscCalendar } from "react-icons/vsc";
import { useTranslation } from "react-i18next";

export const ModalQuickCreateEvent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { toggleModal, ModalTypeEnum } = useModal();
    const { settingAccount, setCopyEvent } = useContextControllers();

    const handleAddEvent = ({ date_day }) => {
        const day = moment(date_day).format("DD");
        const month = moment(date_day).format("MM");
        const year = moment(date_day).format("YYYY");
        const date = `${day}/${month}/${year}`;
        setCopyEvent(null);
        if (settingAccount.is_modal_event) {
            toggleModal({
                title: t("Add day event {{date}}", { date: date }),
                type: ModalTypeEnum.ADD_EVENT,
                data: {
                    cancel: true,
                    date: date,
                },
                modal: {
                    width: 1500,
                },
            });
        } else {
            navigate(`${urlWebsite.EVENTS_ADD}?_day=${day}&_month=${month}&_year=${year}`);
            toggleModal({ type: ModalTypeEnum.NULL });
        }
    };

    return (
        <Form
            onFinish={handleAddEvent}
            initialValues={{
                date_day: moment(),
            }}
        >
            <ItemDate name="date_day" className="w-full" label={t("Day, month, Year")}>
                <DatePicker
                    className="w-full"
                    disabledDate={(current) => current && current < moment().add(-1, "days")}
                    format="DD/MM/YYYY"
                    allowClear={false}
                />
            </ItemDate>
            <Button
                label="More events"
                type="primary"
                htmlType="submit"
                className="mt-4"
                BgSuccess
                Icon={VscCalendar}
            />
        </Form>
    );
};

const ItemDate = styled(Form.Item)`
    .ant-form-item-control {
        width: 100%;
    }
    .ant-row.ant-form-item-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        place-items: start;
    }
`;
