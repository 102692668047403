import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { openDrawer } from "features";
import { storeDrawer } from "./use-selector";
import { DrawerTypeEnum } from "constant";

export const useDrawer = () => {
    const dispatch = useDispatch();

    const resultDrawer = useSelector(storeDrawer);
    const toggleDrawer = React.useCallback((data) => dispatch(openDrawer(data)), [dispatch]);

    return {
        toggleDrawer,
        resultDrawer,
        DrawerTypeEnum,
    };
};
