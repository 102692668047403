import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { RiArrowGoBackFill } from "react-icons/ri";

export const Section = ({
    children,
    className,
    title,
    titleClassName,
    Icon,
    option,
    optionClassName,
    goBack,
    style,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div
            style={style}
            className={clsx(
                "relative z-[1] rounded border-gray-500 bg-white p-[1rem] shadow-card-item dark:border-[#1c1f2b] dark:bg-[#262b3c]",
                className
            )}
        >
            {title && (
                <div className={clsx("flex items-center justify-between", optionClassName)}>
                    <h4
                        className={clsx(
                            "mb-2 flex items-center text-base font-semibold dark:text-[#f6f6f6]",
                            titleClassName,
                            option ? "sm:mb-0" : "sm:mb-2"
                        )}
                    >
                        {Icon && <Icon className="relative mr-2 text-base text-[#555b6d] dark:text-[#f6f6f6]" />}
                        {t(title)}
                    </h4>
                    {option}
                    {goBack && (
                        <h6
                            className="flex cursor-pointer font-semibold text-[#007bff] hover:underline dark:text-white"
                            onClick={() => navigate(-1)}
                        >
                            {t("Go back")}
                            <RiArrowGoBackFill className="ml-2" />
                        </h6>
                    )}
                </div>
            )}
            {children}
        </div>
    );
};
