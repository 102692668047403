import { createSlice } from "@reduxjs/toolkit";

import { extraReducers } from "./extra-reducers";
import { reducers } from "./reducers";

const initialState = {
    loadingStartZoom: false,
    loadingDataPostCreateEventNote: false,
    dataTypesList: null,
    dataResourceList: null,
    loadingCreateEvent: false,
    dataCheckAvailableResource: null,
    dataMeetingNext: null,
    loadingDataMeetingNext: false,
};

export const sliceEvent = createSlice({
    name: "event",
    initialState: initialState,
    reducers: reducers,
    extraReducers: extraReducers,
});

const { reducer, actions } = sliceEvent;
export const { deleteEventReducers, addEventReducers, confirmEventReducers } = actions;

export default reducer;
