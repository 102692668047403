import { lazy } from "react";

import { urlWebsite } from "constant";

const PageHome = lazy(() => import("./home"));

// Know Ledge
const PageKnowledge = lazy(() => import("./know-ledge"));
const PageKnowledgeId = lazy(() => import("./know-ledge/know-ledge-id"));
const PageKnowLedgeDetailId = lazy(() => import("./know-ledge/know-ledge-detail-id"));

//events
const PageEvents = lazy(() => import("./events"));
const PageEventsId = lazy(() => import("./events/events-id"));
const PageEventsAdd = lazy(() => import("./events/event-add"));
const PageEventEdit = lazy(() => import("./events/event-edit"));

// feedbacks
const PageFeedbacks = lazy(() => import("./feedbacks"));
const PageFeedbacksId = lazy(() => import("./feedbacks/feedbacks-id"));

// sales
const PageSalesProducts = lazy(() => import("./products/sales-products"));
const PageSalesProductsID = lazy(() => import("./products/sales-products/sales-products-id"));
const PageSalesProductsAdd = lazy(() => import("./products/sales-products/sales-products-add"));
const PageSalesProductsEdit = lazy(() => import("./products/sales-products/sales-products-edit"));

const PageSalesLeads = lazy(() => import("./products/sales-leads"));

const PageSalesQuotation = lazy(() => import("./products/sales-quotation"));
const PageSalesQuotationAdd = lazy(() => import("./products/sales-quotation/sales-quotation-add"));
const PageSalesQuotationEdit = lazy(() => import("./products/sales-quotation/sales-quotation-edit"));
const PageSalesQuotationId = lazy(() => import("./products/sales-quotation/sales-quotation-id"));

const PageSalesAmbassador = lazy(() => import("./products/sales-ambassador"));

// Next Meeting
const PageNextMeeting = lazy(() => import("./next-meeting"));
// tasks me
const PageTasksMe = lazy(() => import("./tasks-me"));
// ms-service
const PageMsService = lazy(() => import("./ms-service"));
// dashboard
const PageHrDashboard = lazy(() => import("./dashboard/hr"));
// 404
const Pages404 = lazy(() => import("./404"));
// profile
const PageMyKpi = lazy(() => import("./dashboard/my-kpi"));

const PageTreeKnowLedge = lazy(() => import("./tree-know-ledge"));

export const PageRouter = [
    {
        path: urlWebsite.TREE_KNOWLEDGE,
        component: PageTreeKnowLedge,
    },
    {
        path: "*",
        component: Pages404,
        layout: null,
    },
    {
        path: urlWebsite.HOME_PAGE,
        component: PageHome,
    },
    {
        path: urlWebsite.MY_KPI,
        component: PageMyKpi,
    },
    // Know Ledge
    {
        path: urlWebsite.PAGE_KNOWLEDGE,
        component: PageKnowledge,
    },
    {
        path: `${urlWebsite.PAGE_KNOWLEDGE}/:_uid`,
        component: PageKnowledgeId,
    },
    {
        path: `${urlWebsite.PAGE_KNOW_LEDGE_ID}/:_uid`,
        component: PageKnowLedgeDetailId,
    },
    // feedbacks
    {
        path: urlWebsite.FEED_BACKS,
        component: PageFeedbacks,
    },
    {
        path: `${urlWebsite.FEED_BACKS}/:id`,
        component: PageFeedbacksId,
    },
    // events
    {
        path: urlWebsite.EVENTS,
        component: PageEvents,
    },
    {
        path: `${urlWebsite.EVENTS}/:_uid`,
        component: PageEventsId,
    },
    {
        path: urlWebsite.EVENTS_ADD,
        component: PageEventsAdd,
    },
    {
        path: `${urlWebsite.EVENTS_EDIT}/:_uid`,
        component: PageEventEdit,
    },
    // sales products
    {
        path: urlWebsite.SALES_PRODUCTS,
        component: PageSalesProducts,
    },
    {
        path: `${urlWebsite.SALES_PRODUCTS}/:_uid`,
        component: PageSalesProductsID,
    },
    {
        path: urlWebsite.SALES_PRODUCTS_ADD,
        component: PageSalesProductsAdd,
    },
    {
        path: `${urlWebsite.SALES_PRODUCTS_EDIT}/:_uid`,
        component: PageSalesProductsEdit,
    },
    // sales leads
    {
        path: urlWebsite.SALES_LEADS,
        component: PageSalesLeads,
    },
    {
        path: urlWebsite.NEXT_MEETING,
        component: PageNextMeeting,
    },
    {
        path: urlWebsite.TASKS_ME,
        component: PageTasksMe,
    },
    // sales quotation
    {
        path: urlWebsite.SALES_QUOTATION,
        component: PageSalesQuotation,
    },
    {
        path: urlWebsite.SALES_QUOTATION_ADD,
        component: PageSalesQuotationAdd,
    },
    {
        path: `${urlWebsite.SALES_QUOTATION_EDIT}/:_uid`,
        component: PageSalesQuotationEdit,
    },
    {
        path: `${urlWebsite.SALES_QUOTATION}/:_uid/:_line_ids`,
        component: PageSalesQuotationId,
    },
    {
        path: urlWebsite.SALES_AMBASSADOR,
        component: PageSalesAmbassador,
    },
    //ms service
    {
        path: urlWebsite.MS_SERVICE,
        component: PageMsService,
    },
    {
        path: urlWebsite.HR_DASHBOARD,
        component: PageHrDashboard,
    },
];
