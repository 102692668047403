import * as React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, notification, Select } from "antd";

import { useModal, useSales } from "hooks";
import { LoadingItem } from "page/products/sales-products/components";
import { SelectSearch } from "layout";
import { Button } from "customize";
import { VscSaveAs } from "react-icons/vsc";

export const ModalEditAddAmbassador = ({ id, data }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [formAddAmbassador] = Form.useForm();

    const { ModalTypeEnum, toggleModal } = useModal();

    const {
        handleOnGetAbsProductLine,
        handleOnPatchAbsLead,
        handleOnPostAbsLead,
        handleOnGetAbsLead,
        dataAbsProductLine,
    } = useSales();

    React.useLayoutEffect(() => {
        if (!dataAbsProductLine) handleOnGetAbsProductLine();
    }, [handleOnGetAbsProductLine]);

    React.useLayoutEffect(() => {
        if (id) {
            formAddAmbassador.setFieldsValue({
                ...data,
            });
        }
    }, [id, data, formAddAmbassador]);

    const onSubmitAddAmbassador = async (value) => {
        setLoading(true);
        // update ambassador
        if (id) {
            const resultUpdate = await handleOnPatchAbsLead({
                id: id,
                payload: { ...value },
            });
            if (resultUpdate?.error) {
                setLoading(false);

                const error = JSON.stringify(resultUpdate?.error);
                const paresJon = JSON.parse(error);
                const message = JSON.stringify(paresJon.message);
                const messageParse = JSON.parse(message);
                const messageStringify = JSON.parse(messageParse);
                notification.error({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t(checkMessageError(messageStringify.message))}</p>,
                });
            } else {
                notification.success({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("Update successful")}</p>,
                });
                toggleModal({
                    type: ModalTypeEnum.NULL,
                });
                handleOnGetAbsLead();
            }
        }
        // create ambassador
        else {
            const resultCreate = await handleOnPostAbsLead(value);
            if (resultCreate?.error) {
                setLoading(false);
                notification.error({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("An error has occurred")}</p>,
                });
            } else {
                notification.success({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("Save successfully")}</p>,
                });
                toggleModal({
                    type: ModalTypeEnum.NULL,
                });
                handleOnGetAbsLead();
            }
        }
        setLoading(false);
    };

    return (
        <div>
            <Form layout="vertical" onFinish={onSubmitAddAmbassador} form={formAddAmbassador}>
                {dataAbsProductLine ? (
                    <SelectSearch
                        className="grid"
                        label={t("Product line")}
                        name="product_line_id"
                        required
                        messageError="Please enter this field!"
                        disabled={loading}
                    >
                        {dataAbsProductLine.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </SelectSearch>
                ) : (
                    <LoadingItem className="grid basis-3/12" title="Product Line" />
                )}

                <Form.Item
                    name="name"
                    label={t("Opportunity name")}
                    rules={[
                        {
                            required: true,
                            message: t("Please enter this field!"),
                        },
                    ]}
                    className="grid"
                    hasFeedback
                >
                    <Input disabled={loading} />
                </Form.Item>

                <Form.Item
                    name="contact_name"
                    label={t("Contact name")}
                    rules={[
                        {
                            required: true,
                            message: t("Please enter this field!"),
                        },
                    ]}
                    className="grid"
                    hasFeedback
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            pattern: /^[0-9]+$/,
                            message: t("Incorrect phone number!"),
                        },
                    ]}
                    name="mobile"
                    label={t("Mobile phone")}
                    className="grid"
                    hasFeedback
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            pattern: /^[0-9]+$/,
                            message: t("Incorrect phone number!"),
                        },
                    ]}
                    name="phone"
                    label={t("Phone")}
                    className="grid"
                    hasFeedback
                >
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item name="address" label={t("Address")} className="grid" hasFeedback>
                    <Input disabled={loading} />
                </Form.Item>
                <Form.Item name="description" label={t("Internal notes")} className="grid" hasFeedback>
                    <Input.TextArea disabled={loading} />
                </Form.Item>
                <Button label="Save" className="mt-4" BgSuccess Icon={VscSaveAs} loading={loading} />
            </Form>
        </div>
    );
};

const checkMessageError = (message) => {
    switch (message) {
        case "PERMISSION_DENY":
            return "You do not have permission to edit";
        case "EXPIRED_EDIT_TIME":
            return "Overdue for editing, only allowed to edit within 72 hours from the time of creation";
        case "PRODUCT_LINE_NOT_FOUND":
            return "Couldn't find a sales opportunity";
        default:
            return "An error has occurred";
    }
};
