import * as React from "react";
import { useTranslation } from "react-i18next";
import { Form, InputNumber, notification } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";

import { Button } from "customize";
import { useProducts, useModal } from "hooks";
import { VscSaveAs } from "react-icons/vsc";

export const ModalProductsSalesEdit = ({ fc_ratio, price_to_warehouse, ptw_expire_time, default_code, name, id }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { handleUpdateProductCost, updateProductCostReducers } = useProducts();
    const { toggleModal, ModalTypeEnum } = useModal();

    const [loading, setLoading] = React.useState(false);

    const onSubmitUpdateCost = async (value) => {
        setLoading(true);
        const request = await handleUpdateProductCost({ id: id, payload: value });

        if (request?.error) {
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("An error has occurred")}</p>,
            });
        }
        const result = unwrapResult(request);
        if (result) {
            setLoading(false);
            notification.success({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("Update product cost successfully")}</p>,
            });
            updateProductCostReducers({ cost: value, id });
            toggleModal({
                type: ModalTypeEnum.NULL,
            });
        }
    };

    React.useEffect(() => {
        form.setFieldsValue({
            fc_ratio: fc_ratio,
            price_to_warehouse: price_to_warehouse,
            ptw_expire_time: ptw_expire_time,
        });
    }, [fc_ratio, form, price_to_warehouse, ptw_expire_time]);

    return (
        <div>
            <div className="grid gap-1 rounded bg-[#eff2f7] p-2">
                <div className="flex items-center justify-between gap-1">
                    <h6 className="font-semibold">{t("Product code")}:</h6>
                    <p className="flex-1 text-right font-semibold text-[#007bff]">{default_code}</p>
                </div>
                <div className="flex items-center justify-between gap-1">
                    <h6 className="font-semibold">{t("Product's name")}:</h6>
                    <p className="flex-1 text-right">{name}</p>
                </div>
            </div>
            <Form form={form} layout="vertical" className="title-semibold mt-2" onFinish={onSubmitUpdateCost}>
                <Form.Item
                    rules={[{ required: true, message: t("Please enter this field!") }]}
                    className="items-input mt-2 grid"
                    label={t("Price to warehouse")}
                    name="price_to_warehouse"
                >
                    <InputNumber
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        className="items-input mt-2 w-full"
                        controls={false}
                    />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: t("Please enter this field!") }]}
                    className="items-input mt-2 grid"
                    label={t("Effective date")}
                    name="ptw_expire_time"
                >
                    <InputNumber
                        min={0}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        className="items-input mt-2 w-full"
                        controls={false}
                    />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: t("Please enter this field!") }]}
                    className="items-input mt-2 grid"
                    label="FC Ratio"
                    name="fc_ratio"
                >
                    <InputNumber
                        min={0}
                        controls={false}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        className="w-full"
                    />
                </Form.Item>
                <Button
                    loading={loading}
                    label="Update"
                    type="primary"
                    htmlType="submit"
                    className="mt-5"
                    BgSuccess
                    Icon={VscSaveAs}
                />
            </Form>
        </div>
    );
};
