import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    onLogin,
    onGetInfoUser,
    onLogOut,
    onGetProfileUSer,
    onGetKpiScore,
    onGetProfileUserById,
    onChangePassword,
    onGetNotification,
    onLoginPartner,
    onGetMenuSidebarUser,
    onGetBusinessTrip,
    onGetLeaves,
    onGetOverTime,
    onGetFinances,
    onPatchApproveLeaves,
    onPatchApproveOverTime,
    onPatchApproveFinances,
    onPatchApproveBusinessTrip,
    onGetProfileIcms,
    onGetKpiDashboard,
    onGetKpiDashboardTable,
    onGetKpiListConfig,
    onGetNotificationChannel,
    onPatchNotificationChannel,
} from "features";
import { storeAccount } from "./use-selector";

export const useAccount = () => {
    const dispatch = useDispatch();
    const resultStoreAccount = useSelector(storeAccount);

    const {
        access_token,
        infoUser,
        profile,
        kpiScore,
        profileUserById,
        dataNotification,
        MenuSidebarUser,
        dataBusinessTrip,
        dataLeaves,
        dataOverTime,
        dataFinances,
        profileIcms,
        dataKpiDashboard,
        dataKpiDashboardTable,
        dataKpiListConfig,
    } = resultStoreAccount;

    const handleLogin = React.useCallback((data) => dispatch(onLogin(data)), [dispatch]);

    const handleGetInfoUser = React.useCallback(
        () => access_token && dispatch(onGetInfoUser()),
        [dispatch, access_token]
    );

    const handleLogout = React.useCallback(() => access_token && dispatch(onLogOut()), [dispatch, access_token]);

    const handleGetProfileUSer = React.useCallback(
        () => access_token && infoUser && dispatch(onGetProfileUSer(infoUser.username)),
        [access_token, infoUser, dispatch]
    );

    const handleGetKpiScore = React.useCallback(
        () => access_token && dispatch(onGetKpiScore()),
        [access_token, dispatch]
    );

    const handleGetProfileUserById = React.useCallback(
        (id) => access_token && dispatch(onGetProfileUserById(id)),
        [access_token, dispatch]
    );

    const handleChangePassword = React.useCallback(
        (data) => access_token && dispatch(onChangePassword(data)),
        [access_token, dispatch]
    );

    const handleGetNotification = React.useCallback(
        () => access_token && dispatch(onGetNotification()),
        [access_token, dispatch]
    );

    const handleLoginPartner = React.useCallback((data) => dispatch(onLoginPartner(data)), [dispatch]);

    const handleGetMenuSidebarUser = React.useCallback(
        () => access_token && dispatch(onGetMenuSidebarUser()),
        [access_token, dispatch]
    );

    const handleGetBusinessTrip = React.useCallback(
        () => access_token && dispatch(onGetBusinessTrip()),
        [access_token, dispatch]
    );

    const handleGetLeaves = React.useCallback(() => access_token && dispatch(onGetLeaves()), [access_token, dispatch]);

    const handleGetOverTime = React.useCallback(
        () => access_token && dispatch(onGetOverTime()),
        [access_token, dispatch]
    );

    const handleGetFinances = React.useCallback(
        () => access_token && dispatch(onGetFinances()),
        [access_token, dispatch]
    );

    const handlePatchApproveLeaves = React.useCallback(
        (data) => access_token && dispatch(onPatchApproveLeaves(data)),
        [dispatch, access_token]
    );

    const handlePatchApproveOverTime = React.useCallback(
        (data) => access_token && dispatch(onPatchApproveOverTime(data)),
        [dispatch, access_token]
    );

    const handlePatchApproveFinances = React.useCallback(
        (data) => access_token && dispatch(onPatchApproveFinances(data)),
        [dispatch, access_token]
    );

    const handlePatchApproveBusinessTrip = React.useCallback(
        (data) => access_token && dispatch(onPatchApproveBusinessTrip(data)),
        [dispatch, access_token]
    );

    const handleGetProfileIcms = React.useCallback((username) => dispatch(onGetProfileIcms(username)), [dispatch]);

    const handleGetKpiDashboard = React.useCallback(
        (params) => access_token && dispatch(onGetKpiDashboard(params)),
        [access_token, dispatch]
    );

    const handleGetKpiDashboardTable = React.useCallback(
        (params) => access_token && dispatch(onGetKpiDashboardTable(params)),
        [access_token, dispatch]
    );

    const handleGetKpiListConfig = React.useCallback(
        () => access_token && dispatch(onGetKpiListConfig()),
        [access_token, dispatch]
    );

    const handleGetNotificationChannel = React.useCallback(
        () => access_token && dispatch(onGetNotificationChannel()),
        [access_token, dispatch]
    );

    const handlePatchNotificationChannel = React.useCallback(
        (params) => access_token && dispatch(onPatchNotificationChannel(params)),
        [access_token, dispatch]
    );

    return {
        handleLogin,
        handleLoginPartner,
        handleGetInfoUser,
        handleLogout,
        handleGetProfileUSer,
        handleGetKpiScore,
        handleGetProfileUserById,
        handleChangePassword,
        handleGetNotification,
        handleGetMenuSidebarUser,
        handleGetBusinessTrip,
        handleGetLeaves,
        handleGetOverTime,
        handleGetFinances,
        handlePatchApproveLeaves,
        handlePatchApproveOverTime,
        handlePatchApproveFinances,
        handlePatchApproveBusinessTrip,
        handleGetProfileIcms,
        handleGetKpiDashboard,
        handleGetKpiDashboardTable,
        handleGetKpiListConfig,
        handleGetNotificationChannel,
        handlePatchNotificationChannel,
        dataFinances,
        dataOverTime,
        dataLeaves,
        dataBusinessTrip,
        dataNotification,
        MenuSidebarUser,
        resultStoreAccount,
        profileUserById,
        infoUser,
        profile,
        access_token,
        kpiScore,
        profileIcms,
        dataKpiDashboard,
        dataKpiDashboardTable,
        dataKpiListConfig,
    };
};
