import * as React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { Button } from "customize";
import { useEvents, useModal, useAccount, useContextControllers } from "hooks";
import { WrapperTag } from "layout";
import { ContainerModal } from "modal";
import { ModalEventConfirmLater } from "modal/components";
import { FiUserCheck, FiUserMinus, FiUserX } from "react-icons/fi";

export const EventConfirmStatus = ({ dataEventDetail, setDataEventDetail }) => {
    const { t } = useTranslation();
    const { handlePatchConfirmEvent, handleConfirmEventReducers } = useEvents();
    const { toggleModal, ModalTypeEnum } = useModal();
    const { profile } = useAccount();

    const [loading, setLoading] = React.useState(false);
    const [isModal, setIsModal] = React.useState(false);
    const { settingAccount, setStatusEvent } = useContextControllers();
    const [checkType, setCheckType] = React.useState(null);

    const onClickConfirmEvent = async (type) => {
        if (type === "yes") {
            try {
                setLoading(true);
                const payload = {
                    eventUid: dataEventDetail.event.uid,
                    data: {
                        confirm_type: type,
                        confirm_code: dataEventDetail.confirm_code,
                    },
                };

                const eventMembers = [...dataEventDetail.event_members];
                const checkEventMember = eventMembers.find(({ user_id }) => Number(user_id) === profile.id);
                const checkIndexEventMember = eventMembers.findIndex(({ user_id }) => Number(user_id) === profile.id);
                eventMembers[checkIndexEventMember] = {
                    ...checkEventMember,
                    confirm_status: "1",
                    confirm_reason: "",
                };

                const resultResult = await handlePatchConfirmEvent(payload);
                const resultResultData = unwrapResult(resultResult);

                if (resultResultData) {
                    setStatusEvent({
                        status: dataEventDetail.event.uid,
                        uid: "1",
                    });
                    handleConfirmEventReducers({
                        id: dataEventDetail.event.id,
                        confirm_status: "1",
                        confirm_reason: "",
                    });
                    setLoading(false);
                    message.success(t("Successful confirmation"));
                    setDataEventDetail({
                        ...dataEventDetail,
                        confirm_status: "1",
                        confirm_reason: "",
                        event_members: eventMembers,
                    });
                }
            } catch (error) {
                message.error(t("An error occurred, please try again later"));
                setLoading(false);
            }
        } else {
            if (settingAccount.is_modal_event) {
                setIsModal(true);
                setCheckType(type);
            } else {
                toggleModal({
                    type: ModalTypeEnum.EVENT_CONFIRM_LATER,
                    title: type === "no" ? "Reason: Not attending" : "Reason: Confirm later",
                    data: {
                        eventUid: dataEventDetail.event.uid,
                        confirm_code: dataEventDetail.confirm_code,
                        confirm_type: type,
                        dataEventDetail: dataEventDetail,
                        setDataEventDetail: setDataEventDetail,
                        confirm_status: type === "no" ? "2" : "3",
                        user_id: profile.id,
                    },
                });
            }
        }
    };
    return (
        <>
            <div className="justify-between gap-4 sm:grid md:items-center xl:flex">
                {Number(dataEventDetail.confirm_status) !== 0 && (
                    <div className="basis-1/4">
                        <p>
                            {Number(dataEventDetail.confirm_status) === 1 && (
                                <WrapperTag
                                    transition
                                    rounded
                                    title="Attend"
                                    bgColor="success"
                                    className="text-white"
                                />
                            )}
                            {Number(dataEventDetail.confirm_status) === 2 && (
                                <WrapperTag
                                    transition
                                    rounded
                                    title="Not coming"
                                    bgColor="danger"
                                    className="text-white"
                                />
                            )}
                            {Number(dataEventDetail.confirm_status) === 3 && (
                                <WrapperTag
                                    transition
                                    rounded
                                    title="Confirm later"
                                    bgColor="info"
                                    className="text-white"
                                />
                            )}
                        </p>
                    </div>
                )}
                <div className="grid gap-2 sm:flex">
                    {Number(dataEventDetail.confirm_status) !== 1 && (
                        <Button
                            loading={loading}
                            Icon={FiUserCheck}
                            label="Attend"
                            className="h-8 w-full min-w-[150px] rounded-[0.25rem] sm:py-2 sm:px-8"
                            BgSuccess
                            onClick={() => onClickConfirmEvent("yes")}
                        />
                    )}
                    {Number(dataEventDetail.confirm_status) !== 3 && dataEventDetail.allow_tentative && (
                        <Button
                            disabled={loading}
                            label="Confirm later"
                            className="h-8 w-full min-w-[150px]"
                            BgInfo
                            onClick={() => onClickConfirmEvent("tentative")}
                            Icon={FiUserMinus}
                        />
                    )}
                    {Number(dataEventDetail.confirm_status) !== 2 && (
                        <Button
                            disabled={loading}
                            Icon={FiUserX}
                            label="Not coming"
                            className="h-8 w-full min-w-[150px]"
                            BgDanger
                            onClick={() => onClickConfirmEvent("no")}
                        />
                    )}
                </div>
            </div>
            <ContainerModal
                title={checkType === "no" ? "Reason: Not attending" : "Reason: Confirm later"}
                open={isModal}
                closeDoubleModal={() => setIsModal(!isModal)}
            >
                <ModalEventConfirmLater
                    eventUid={dataEventDetail.event.uid}
                    confirm_code={dataEventDetail.confirm_code}
                    confirm_type={checkType}
                    dataEventDetail={dataEventDetail}
                    setDataEventDetail={setDataEventDetail}
                    confirm_status={checkType === "no" ? "2" : "3"}
                    user_id={profile.id}
                    closeDoubleModal={setIsModal}
                />
            </ContainerModal>
        </>
    );
};
