import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { onGetSearchKnowLedges, onGetDetailKnowLedges } from "features";
import { storeSearch, useAccount, useContextControllers } from "hooks";

export const useSearch = () => {
    const typingTimeout = React.useRef(0);
    const dispatch = useDispatch();
    const [keyword, setKeyword] = React.useState("");

    const { openSearch, setOpenSearch } = useContextControllers();
    const { access_token } = useAccount();
    const resultSearchStore = useSelector(storeSearch);
    const { data, loading, dataDetail, loadingDetail } = resultSearchStore;

    const clearKeywords = React.useCallback(() => {
        setKeyword("");
        setOpenSearch(false);
    }, [setOpenSearch]);

    const handleGetSearchKnowLedges = React.useCallback(
        (keyword) => access_token && dispatch(onGetSearchKnowLedges(keyword)),
        [access_token, dispatch]
    );

    const onChangeKeyword = (e) => {
        handleOpenSearch();
        setKeyword(e.target.value);
    };

    const handleOpenSearch = React.useCallback(() => setOpenSearch(!openSearch), [openSearch, setOpenSearch]);

    const handleGetDetailKnowLedges = React.useCallback(
        (_id) => access_token && dispatch(onGetDetailKnowLedges(_id)),
        [access_token, dispatch]
    );

    React.useEffect(() => {
        typingTimeout.current = setTimeout(() => {
            if (keyword.trim().length >= 3) handleGetSearchKnowLedges(keyword);
        }, 700);
        return () => clearTimeout(typingTimeout.current);
    }, [handleGetSearchKnowLedges, keyword]);

    return {
        handleOpenSearch,
        clearKeywords,
        onChangeKeyword,
        handleGetDetailKnowLedges,
        resultSearchStore,
        keyword,
        dataSearch: data,
        loading,
        openSearch,
        dataDetail,
        loadingDetail,
    };
};
