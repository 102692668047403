import { axiosClient } from "./axios-client";

export const apiProducts = {
    getAllProducts: (payload) => {
        const { params, page } = payload;
        const url = `/products/page/${page}`;
        return axiosClient.get(url, { params });
    },
    getProductDetails: (productId) => {
        const url = `/products/${productId}`;
        return axiosClient.get(url);
    },
    searchAllProducts: (payload) => {
        const { page, params } = payload;
        const url = `/products/page/${page}`;
        return axiosClient.get(url, { params });
    },
    getCategoryProduct: () => {
        const url = "/products/category/list";
        return axiosClient.get(url);
    },
    getUomProduct: () => {
        const url = "/products/uom/list";
        return axiosClient.get(url);
    },
    getMadeInListProduct: () => {
        const url = "/products/sku/madein/list";
        return axiosClient.get(url);
    },
    getWarrantyListProduct: () => {
        const url = "/products/warranty/list";
        return axiosClient.get(url);
    },
    getCategorySkuProduct: () => {
        const url = "/products/sku/category/list";
        return axiosClient.get(url);
    },
    searchProduct: (keyword) => {
        const url = `/products/search/${keyword}?sku=true`;
        return axiosClient.get(url);
    },
    postCreateProductSKU: (payload) => {
        const url = "/products/sku-product";
        return axiosClient.post(url, payload);
    },
    updateProductSku: (data) => {
        const { id, payload } = data;
        const url = `/products/sku-product/${id}`;
        return axiosClient.patch(url, payload);
    },
    getProductsCost: (payload) => {
        const { params, page } = payload;
        const url = `/products/cost/page/${page}`;
        return axiosClient.get(url, { params });
    },
    updateProductCost: (data) => {
        const { id, payload } = data;
        const url = `/products/product-cost/${id}`;
        return axiosClient.patch(url, payload);
    },
    submitApproveProduct: (idProduct) => {
        const url = `/products/sku-product/${idProduct}/approve`;
        return axiosClient.patch(url);
    },
};
