import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { App } from "./App";
import { store } from "./store";
import "./i18n";

import { UseContextProvider } from "contexts";

// import "antd/dist/antd.css";
import "./styles/antd.less";
import "./styles/css/index.css";
import "./styles/scss/index.scss";

ReactDOM.render(
    <React.StrictMode>
        <React.Suspense fallback={<></>}>
            <Provider store={store}>
                <UseContextProvider>
                    <App />
                </UseContextProvider>
            </Provider>
        </React.Suspense>
    </React.StrictMode>,
    document.getElementById("root")
);
