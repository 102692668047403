import * as React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Form, Select, Checkbox, Input, notification, DatePicker, Modal } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { useEvents, useUser, useEmail, useModal, useTitlePage, useContextControllers } from "hooks";
import { Section, MainPage, PageHeader } from "layout";
import { Button, Image } from "customize";
import { EditorText } from "./index";
import { maxAllowRegister, urlWebsite } from "constant";
import { SelectMultipleSearch } from "layout";
import { Loading } from "./loading";
import { apiAccount, apiEvent } from "api";
import { ExclamationCircleOutlined, EditOutlined } from "@ant-design/icons";
import { VscOutput, VscSaveAs } from "react-icons/vsc";
import { ContainerModal } from "modal";
import { ModalCheckAvailableEvent, ModalResourceList } from "modal/components";

const { confirm } = Modal;
const { Option } = Select;
const formatVN = "DD-MM-YYYY HH:mm:ss";
const formatUS = "YYYY-MM-DD HH:mm:ss";

export const FormAddEditEvent = ({ _uid, dateUrl, subHeader, isEdit, style }) => {
    const [formEvent] = Form.useForm();
    const navigate = useNavigate();
    useTitlePage({ title: "Workplace | Calendar" });
    const { copyEvent } = useContextControllers();

    const { toggleModal, ModalTypeEnum } = useModal();
    const { t } = useTranslation();
    const {
        handleGetResourceList,
        handleGetTypeEventList,
        handlePostCreateEvent,
        handleCheckAvailableResource,
        handlePutUpdateEvent,
        handleGetEventDetailByUid,
        handleAddEventReducers,
        dataTypesList,
        dataResourceList,
        loadingCreateEvent,
        dataEventDetail,
        eventMembers,
        watcher,
    } = useEvents({ _uid, isEdit });
    const { handleSearchEmailGroup, dataEmailGroup, loading: loadingDataEmailGroup } = useEmail();
    const {
        handleSearchUserSelect,
        handleSearchUserSelectWatcher,
        loading: loadingSearchUser,
        dataUser,
        loadingDataUser,
        dataWatcher,
    } = useUser();

    const [allowRegister, setAllowRegister] = React.useState(false);
    const [description, setDescription] = React.useState(null);
    const [resourceList, setResourceList] = React.useState(null);
    const [loadingResourceList, setLoadingResourceList] = React.useState(false);
    const [loadingCheckAvailable, setLoadingCheckAvailable] = React.useState(false);
    const [isOnchangeMember, setIsOnchangeMember] = React.useState(isEdit);
    const [isOnChangeWatcher, setIsOnChangeWatcher] = React.useState(isEdit);
    const [checkResourceFirst, setCheckResourceFirst] = React.useState(true);
    const [checkTypeCalendar, setCheckTypeCalendar] = React.useState(null);
    const [modalRoomBusy, setModalRoomBusy] = React.useState(false);
    const [modalResourceList, setModalResourceList] = React.useState(false);
    const [resourceBusy, setResourceBusy] = React.useState(null);
    const [titleRoomBusy, setTitleRoomBusy] = React.useState(null);

    const initialStartDateTime = dateUrl ? `${dateUrl} ${moment().hours() + 1}:00:00` : moment();
    const initialEndDateTime = dateUrl ? `${dateUrl} ${moment().hours() + 2}:00:00` : moment();

    const [dateTime, setDateTime] = React.useState({
        startDateTime: isEdit ? null : initialStartDateTime,
        endDateTime: isEdit ? null : initialEndDateTime,
    });

    const initialValues = !isEdit && {
        start_date: moment(dateTime.startDateTime, formatVN),
        end_date: moment(dateTime.endDateTime, formatVN),
        max_allow_register: "5",
    };

    React.useLayoutEffect(() => {
        (async () => {
            if (copyEvent) {
                setIsOnChangeWatcher(true);
                setIsOnchangeMember(true);
                const event_members = [];
                copyEvent.event_members.forEach((event) =>
                    event_members.push(apiAccount.getProfileUserById(event.user_id))
                );
                const result_event_members = await Promise.all(event_members);

                const checkAllowRegister = copyEvent.allow_register && "allow_register";
                const checkExternalMeeting = copyEvent.is_external_meeting && "is_external_meeting";

                if (checkAllowRegister) {
                    setAllowRegister(true);
                }
                setDescription(copyEvent.detail);
                setCheckTypeCalendar(Number(copyEvent.type));
                formEvent.setFieldsValue({
                    name: copyEvent.name,
                    type: copyEvent.type,
                    address: copyEvent.address,
                    meeting_event_register: [checkAllowRegister, checkExternalMeeting],
                    max_allow_register: Number(copyEvent.max_allow_register),
                    member_ids: result_event_members?.map((user) => ({
                        value: user.id,
                        label: user.username,
                    })),
                    watcher_ids: copyEvent.watcher?.map((mail) => ({
                        value: mail.id,
                        label: mail.username,
                    })),
                    send_to_email_group: copyEvent?.send_to_group?.split(";").map((mail) => mail),
                });
            }
        })();
    }, [copyEvent, formEvent, eventMembers, watcher]);

    // function
    const onSubmitCreateEvent = async (fieldsValue) => {
        const {
            meeting_event_register,
            type,
            max_allow_register,
            member_ids,
            watcher_ids,
            send_to_email_group,
            start_date,
            end_date,
        } = fieldsValue;

        const start_time = moment(start_date).format(formatUS);
        const end_time = moment(end_date).format(formatUS);

        if (moment(start_time).valueOf() > moment(end_time).valueOf()) {
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("Invalid date and time")}</p>,
            });
        } else {
            const payload = {
                start_time: start_time,
                end_time: end_time,
                name: fieldsValue["name"],
                resource_ids: fieldsValue["resource_ids"] || [],
                member_ids: member_ids?.map((member) => (isOnchangeMember ? member.value : member)),
                watcher_ids: watcher_ids
                    ? watcher_ids.map((watcher) => (isOnChangeWatcher ? watcher.value : watcher))
                    : [],
                description: description,
                type: type,
                address: fieldsValue["address"],
                allow_register: meeting_event_register ? meeting_event_register.includes("allow_register") : false,
                max_allow_register: Number(max_allow_register) || null,
                send_to_email_group: send_to_email_group ? send_to_email_group.map((value) => value) : [],
                is_external_meeting: meeting_event_register
                    ? meeting_event_register?.includes("is_external_meeting")
                    : false,
            };

            const resource_list = [];
            let resultResultEvent;

            if (isEdit) {
                // check resource_ids if available
                let checkResource = false;
                if (payload.resource_ids.length > 0) {
                    setLoadingCheckAvailable(true);
                    payload.resource_ids.forEach((id) =>
                        resource_list.push(
                            handleCheckAvailableResource({
                                resourceId: id,
                                payload: {
                                    start_time: payload.start_time,
                                    end_time: payload.end_time,
                                    event_id: dataEventDetail.event.id,
                                },
                            })
                        )
                    );
                    const result_resource = await Promise.all(resource_list);
                    result_resource.forEach(async ({ available }) => {
                        // resource not use
                        if (available) checkResource = true;
                        // resource use set resource_ids = [];
                        else checkResource = false;
                    });
                    if (checkResource) {
                        resultResultEvent = await await handlePutUpdateEvent({
                            resourceId: dataEventDetail.event.id,
                            payload: payload,
                        });
                        callBackCreateEventSuccess({
                            payload: resultResultEvent,
                            title: "Successful event update",
                        });
                    } else {
                        resultResultEvent = await handlePutUpdateEvent({
                            resourceId: dataEventDetail.event.id,
                            payload: {
                                ...payload,
                                resource_ids: [],
                            },
                        });
                        callBackCreateEventSuccess({
                            payload: resultResultEvent,
                            title: "Successful event update",
                        });
                    }
                }
                // check result_resource not available
                else {
                    resultResultEvent = await handlePutUpdateEvent({
                        resourceId: dataEventDetail.event.id,
                        payload: payload,
                    });
                    callBackCreateEventSuccess({
                        payload: resultResultEvent,
                        title: "Successful event update",
                    });
                }
            }
            // create event
            else {
                // check resource_ids if available
                let checkResources = false;
                if (payload.resource_ids.length > 0) {
                    setLoadingCheckAvailable(true);
                    payload.resource_ids.forEach((id) =>
                        resource_list.push(
                            handleCheckAvailableResource({
                                resourceId: id,
                                payload: {
                                    start_time: payload.start_time,
                                    end_time: payload.end_time,
                                    event_id: 0,
                                },
                            })
                        )
                    );
                    const result_resource = await Promise.all(resource_list);
                    result_resource.forEach(async ({ available }) => {
                        // resource not use
                        if (available) checkResources = true;
                        // resource use set resource_ids = [];
                        else checkResources = false;
                    });
                    if (checkResources) {
                        resultResultEvent = await handlePostCreateEvent(payload);
                        callBackCreateEventSuccess({
                            payload: resultResultEvent,
                            title: "Create successful events",
                            type: "ADD_EVENT",
                        });
                    } else {
                        resultResultEvent = await handlePostCreateEvent({ ...payload, resource_ids: [] });
                        callBackCreateEventSuccess({
                            payload: resultResultEvent,
                            title: "Create successful events",
                            type: "ADD_EVENT",
                        });
                    }
                }
                // check result_resource not available
                else {
                    if (checkResourceFirst && checkTypeCalendar !== 99) {
                        confirm({
                            title: t("No resources selected, still save events?"),
                            icon: <ExclamationCircleOutlined />,
                            centered: true,
                            width: 500,
                            okText: (
                                <Button
                                    Icon={EditOutlined}
                                    label="Edit"
                                    BgSecondary
                                    onClick={() => setCheckResourceFirst(false)}
                                />
                            ),
                            cancelText: (
                                <Button
                                    label="Save event"
                                    Icon={VscSaveAs}
                                    BgSuccess
                                    onClick={async () => {
                                        resultResultEvent = await handlePostCreateEvent(payload);
                                        callBackCreateEventSuccess({
                                            payload: resultResultEvent,
                                            title: "Create successful events",
                                            type: "ADD_EVENT",
                                        });
                                        setCheckResourceFirst(false);
                                    }}
                                />
                            ),
                        });
                    } else {
                        resultResultEvent = await handlePostCreateEvent(payload);
                        callBackCreateEventSuccess({
                            payload: resultResultEvent,
                            title: "Create successful events",
                            type: "ADD_EVENT",
                        });
                    }
                }
            }
        }
    };
    React.useEffect(() => {
        if (checkTypeCalendar === 99) {
            formEvent.setFieldsValue({
                allow_register: false,
                max_allow_register: null,
                resource_ids: [],
            });
        }
    }, [checkTypeCalendar, formEvent]);

    const callBackCreateEventSuccess = async ({ payload, title, type }) => {
        if (payload?.error) {
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("An error has occurred")}</p>,
            });
        }
        const { event_uid } = unwrapResult(payload);
        if (event_uid) {
            notification.success({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t(title)}</p>,
            });
            formEvent.resetFields();
            setDescription(null);
            navigate(`${urlWebsite.EVENTS}/${event_uid}`);
            if (type === "ADD_EVENT") {
                const getEventId = await handleGetEventDetailByUid(event_uid);
                const resultGetEventId = unwrapResult(getEventId);
                if (resultGetEventId) {
                    handleAddEventReducers({ data: resultGetEventId });
                }
            }
        }
        toggleModal({
            type: ModalTypeEnum.NULL,
        });
    };

    React.useEffect(() => {
        if (allowRegister && dataEventDetail) {
            formEvent.setFieldsValue({
                max_allow_register: dataEventDetail.event.max_allow_register
                    ? dataEventDetail.event.max_allow_register
                    : "5",
            });
        }
    }, [allowRegister, dataEventDetail, formEvent]);

    // useEffect
    React.useLayoutEffect(() => {
        if (!dataTypesList) {
            handleGetResourceList();
        }
        if (!dataResourceList) {
            handleGetTypeEventList();
        }
    }, [handleGetResourceList, handleGetTypeEventList]);

    React.useLayoutEffect(() => {
        if (dataTypesList && !isEdit && !copyEvent) {
            formEvent.setFieldsValue({
                type: Object.keys(dataTypesList)[0],
            });
            setCheckTypeCalendar(Number(Object.keys(dataTypesList)[0]));
        }
    }, [dataTypesList, formEvent, isEdit, copyEvent]);
    React.useEffect(() => {
        if (isEdit) {
            if (dataEventDetail) {
                const checkAllowRegister = dataEventDetail.event.allow_register && "allow_register";
                const checkExternalMeeting = dataEventDetail.event.is_external_meeting && "is_external_meeting";

                if (checkAllowRegister) {
                    setAllowRegister(true);
                }
                setDescription(dataEventDetail.event.detail);
                setCheckTypeCalendar(Number(dataEventDetail.event.type));
                formEvent.setFieldsValue({
                    name: dataEventDetail.event.name,
                    type: dataEventDetail.event.type,
                    address: dataEventDetail.event.address,
                    start_date: moment(dataEventDetail.event.event_start_datetime, formatUS),
                    end_date: moment(dataEventDetail.event.event_end_datetime, formatUS),
                    meeting_event_register: [checkAllowRegister, checkExternalMeeting],
                    max_allow_register: dataEventDetail.event.max_allow_register,
                    member_ids: eventMembers?.map((mail) => ({
                        value: mail.id,
                        label: mail.username,
                    })),
                    watcher_ids: watcher?.map((mail) => ({
                        value: mail.id,
                        label: mail.username,
                    })),
                    send_to_email_group: dataEventDetail.event?.send_to_group?.split(";").map((mail) => mail),
                    resource_ids: dataEventDetail.resource && dataEventDetail.resource.map(({ id }) => Number(id)),
                });
            }
        }
    }, [dataEventDetail, eventMembers, formEvent, isEdit, watcher]);

    const handleChangeTypeCalendar = (value) => {
        setCheckTypeCalendar(Number(value));
    };

    React.useEffect(() => {
        (async () => {
            const resource_list = [];
            if (dataResourceList && dataEventDetail && isEdit) {
                setLoadingResourceList(true);

                const startTime = dateTime.startDateTime
                    ? moment(dateTime.startDateTime, formatVN).format(formatUS)
                    : dataEventDetail.event.event_start_datetime;

                const endTime = dateTime.endDateTime
                    ? moment(dateTime.endDateTime, formatVN).format(formatUS)
                    : dataEventDetail.event.event_end_datetime;

                dataResourceList.forEach(({ id }) =>
                    resource_list.push(
                        apiEvent.checkAvailableResource({
                            resourceId: id,
                            payload: {
                                start_time: startTime,
                                end_time: endTime,
                                event_id: 0,
                            },
                        })
                    )
                );

                const result_resource = await Promise.all(resource_list);
                if (result_resource) {
                    setLoadingResourceList(false);
                    const set_resource = result_resource.map((item, index) => {
                        return {
                            ...item,
                            ...dataResourceList[index],
                        };
                    });
                    setResourceList(set_resource);
                    set_resource.forEach((item) => {
                        setResourceList(set_resource);
                        if (dataEventDetail.resource) {
                            dataEventDetail.resource.forEach((resource) => {
                                if (item.id === Number(resource.id)) {
                                    setResourceList([...set_resource, (item.available = true), (item.events_use = [])]);
                                }
                            });
                        }
                    });
                }
            }
            if (dataResourceList && !isEdit) {
                const startTime = moment(dateTime.startDateTime, formatVN).format(formatUS);
                const endTime = moment(dateTime.endDateTime, formatVN).format(formatUS);
                setLoadingResourceList(true);
                dataResourceList.forEach(({ id }) =>
                    resource_list.push(
                        apiEvent.checkAvailableResource({
                            resourceId: id,
                            payload: {
                                start_time: startTime,
                                end_time: endTime,
                                event_id: 0,
                            },
                        })
                    )
                );
                const result_resource = await Promise.all(resource_list);
                if (result_resource) {
                    setLoadingResourceList(false);
                    const set_resource = result_resource.map((item, index) => {
                        return {
                            ...item,
                            ...dataResourceList[index],
                        };
                    });
                    setResourceList(set_resource);
                }
            }
        })();
    }, [dataResourceList, dataEventDetail, isEdit, dateTime]);

    React.useLayoutEffect(() => {
        if (dataEventDetail && isEdit) {
            if (!dataEventDetail.is_admin) {
                navigate(urlWebsite.EVENTS, { replace: true });
            }
        }
    }, [dataEventDetail, isEdit, navigate]);

    const handleResourceClick = ({ data, title }) => {
        if (data && data?.length > 0) {
            setResourceBusy(data);
            setModalRoomBusy(true);
            setTitleRoomBusy(title);
        }
    };

    if (!resourceList) {
        return (
            <Section>
                <Loading />
            </Section>
        );
    }

    return (
        <>
            <MainPage goBack={!!subHeader} pageHeader={<PageHeader sub={subHeader} className="hidden sm:flex" />}>
                <Section style={style}>
                    <Form
                        className="title-semibold"
                        onFinish={onSubmitCreateEvent}
                        scrollToFirstError
                        form={formEvent}
                        layout="vertical"
                        name="eventCreate"
                        initialValues={initialValues}
                    >
                        <div className="grid grid-cols-1 gap-x-6 lg:grid-cols-2">
                            <Form.Item
                                className="items-input grid"
                                hasFeedback
                                label={t("Title")}
                                name="name"
                                rules={[{ message: t("Please enter title!"), required: true }]}
                            >
                                <Input disabled={loadingCreateEvent || loadingCheckAvailable} />
                            </Form.Item>
                            <Form.Item
                                className="items-input grid"
                                name="type"
                                label={t("Calendar type")}
                                rules={[{ message: t("Please select a calendar type!"), required: true }]}
                                hasFeedback
                            >
                                <Select
                                    disabled={loadingCreateEvent || loadingCheckAvailable}
                                    onChange={handleChangeTypeCalendar}
                                >
                                    {dataTypesList ? (
                                        Object.keys(dataTypesList).map((item) => (
                                            <Option value={item} key={item}>
                                                {dataTypesList[item]}
                                            </Option>
                                        ))
                                    ) : (
                                        <div>loading...</div>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                        <div
                            className={clsx(
                                "grid grid-cols-1 gap-x-6",
                                checkTypeCalendar !== 99 ? "lg:grid-cols-2" : "lg:grid-cols-1"
                            )}
                        >
                            <Form.Item
                                className="items-input mt-2 grid"
                                hasFeedback
                                label={t("Address")}
                                name="address"
                                rules={[{ message: t("Please enter your address!"), required: true }]}
                            >
                                <Input disabled={loadingCreateEvent || loadingCheckAvailable} />
                            </Form.Item>
                            {checkTypeCalendar !== 99 && (
                                <Form.Item
                                    className="items-input mt-2 grid"
                                    label={t("Sign up for the meeting")}
                                    name="meeting_event_register"
                                >
                                    <Checkbox.Group
                                        className="w-full"
                                        onChange={(e) => setAllowRegister(e.includes("allow_register"))}
                                    >
                                        <div className="flex flex-col gap-2 sm:flex-row">
                                            <div className="basis-5/12">
                                                <Checkbox
                                                    value="is_external_meeting"
                                                    disabled={loadingCreateEvent || loadingCheckAvailable}
                                                >
                                                    {t("Meeting with outside guests")}
                                                </Checkbox>
                                            </div>
                                            <div className="grid basis-7/12 gap-2">
                                                <Checkbox
                                                    value="allow_register"
                                                    disabled={loadingCreateEvent || loadingCheckAvailable}
                                                >
                                                    {t("Allow self registration")}
                                                </Checkbox>
                                                {allowRegister && (
                                                    <Form.Item className="grid" name="max_allow_register">
                                                        <Select disabled={loadingCreateEvent || loadingCheckAvailable}>
                                                            {maxAllowRegister.map((item) => (
                                                                <Option value={item} key={item}>
                                                                    {item} {t("People")}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                )}
                                            </div>
                                        </div>
                                    </Checkbox.Group>
                                </Form.Item>
                            )}
                        </div>
                        <div className="grid grid-cols-1 gap-x-6 lg:grid-cols-2">
                            <Form.Item
                                className="items-input mt-2 grid"
                                name="start_date"
                                rules={[{ type: "object", required: true }]}
                                label={t("Start time(day, hour, minute)")}
                            >
                                <DatePicker
                                    dropdownClassName="globals-dropdown-datePicker"
                                    disabled={loadingCreateEvent || loadingCheckAvailable}
                                    format="DD/MM/YYYY HH:mm"
                                    allowClear={false}
                                    showTime
                                    className="w-full"
                                    disabledDate={(current) => current && current < moment().add(0, "days")}
                                    onChange={(date) => {
                                        if (!isEdit) {
                                            formEvent.setFieldsValue({
                                                resource_ids: [],
                                            });
                                        }
                                        setDateTime({
                                            ...dateTime,
                                            startDateTime: moment(date).format(formatVN),
                                        });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                className="items-input mt-2 grid"
                                name="end_date"
                                rules={[{ type: "object", required: true }]}
                                label={t("End (day, hour, minute)")}
                            >
                                <DatePicker
                                    dropdownClassName="globals-dropdown-datePicker"
                                    disabled={loadingCreateEvent || loadingCheckAvailable}
                                    format="DD/MM/YYYY HH:mm"
                                    allowClear={false}
                                    showTime
                                    className="w-full"
                                    disabledDate={(current) => current && current < moment().add(0, "days")}
                                    onChange={(date) => {
                                        if (!isEdit) {
                                            formEvent.setFieldsValue({
                                                resource_ids: [],
                                            });
                                        }
                                        setDateTime({
                                            ...dateTime,
                                            endDateTime: moment(date).format(formatVN),
                                        });
                                    }}
                                />
                            </Form.Item>
                        </div>
                        <SelectMultipleSearch
                            className="items-input mt-2 grid"
                            props={{ mode: "multiple" }}
                            loading={loadingSearchUser}
                            data={dataUser}
                            api={handleSearchUserSelect}
                            placeholder={`${t("Enter member name")}...`}
                            label="Participating member"
                            name="member_ids"
                            error="Please enter a member to attend!"
                            disabled={loadingCreateEvent || loadingCheckAvailable}
                            onChange={() => setIsOnchangeMember(false)}
                            required
                        >
                            {dataUser &&
                                dataUser.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        <div className="flex items-center gap-2">
                                            <Image
                                                src={item.avatar_url}
                                                className="h-6 w-6 rounded-full"
                                                wrapperClassName="h-6 w-6"
                                            />
                                            <h6>{item.username}</h6>
                                        </div>
                                    </Option>
                                ))}
                        </SelectMultipleSearch>
                        <div className="grid grid-cols-1 gap-x-6 lg:grid-cols-2">
                            <SelectMultipleSearch
                                className="items-input mt-2 grid"
                                props={{ mode: "multiple" }}
                                loading={loadingDataUser}
                                data={dataWatcher}
                                api={handleSearchUserSelectWatcher}
                                placeholder={`${t("Enter member name")}...`}
                                label="Followers"
                                name="watcher_ids"
                                disabled={loadingCreateEvent || loadingCheckAvailable}
                                onChange={() => setIsOnChangeWatcher(false)}
                            >
                                {dataWatcher &&
                                    dataWatcher.map((item) => (
                                        <Option value={item.id} key={item.id}>
                                            <div className="flex items-center gap-2">
                                                <Image
                                                    src={item.avatar_url}
                                                    className="h-6 w-6 rounded-full"
                                                    wrapperClassName="h-6 w-6"
                                                />
                                                <h6>{item.username}</h6>
                                            </div>
                                        </Option>
                                    ))}
                            </SelectMultipleSearch>
                            <SelectMultipleSearch
                                className="items-input mt-2 grid"
                                props={{ mode: "multiple" }}
                                loading={loadingDataEmailGroup}
                                data={dataEmailGroup}
                                api={handleSearchEmailGroup}
                                placeholder={`${t("Enter email group")}...`}
                                name="send_to_email_group"
                                label="Event notification to mail group"
                                error="Enter email group!"
                                disabled={loadingCreateEvent || loadingCheckAvailable}
                            >
                                {dataEmailGroup &&
                                    dataEmailGroup.map((item) => (
                                        <Option value={item.mail_group} key={item.id}>
                                            <h6 className="flex items-center gap-x-2">
                                                {item.mail_group}
                                                <p>[{item.id}]</p>
                                            </h6>
                                        </Option>
                                    ))}
                            </SelectMultipleSearch>
                        </div>
                        <WrapperEditor
                            className="items-input error-ant-custom mt-2 grid"
                            name="description"
                            rules={[
                                {
                                    message: t("Please enter content!"),
                                    required: description ? false : true,
                                },
                            ]}
                        >
                            <EditorText
                                checkTypeCalendar={checkTypeCalendar}
                                description={description}
                                setDescription={setDescription}
                                readOnly={loadingCreateEvent || loadingCheckAvailable}
                            />
                            {!description && (
                                <p className="error-ant-custom text-[#ff4d4f]">{t("Please enter content!")}</p>
                            )}
                        </WrapperEditor>
                        {checkTypeCalendar !== 99 && (
                            <WrapperResourceList
                                className="items-input mt-2 grid"
                                label={
                                    <div className="mb-3 flex w-full justify-between">
                                        <h6 className="font-semibold">{t("Booking resource(s)")}</h6>
                                        <div>
                                            <Button
                                                Icon={VscOutput}
                                                label="View resource"
                                                BgInfo
                                                size="sm"
                                                className="cursor-pointer"
                                                Element="p"
                                                onClick={() => setModalResourceList(true)}
                                            />
                                        </div>
                                    </div>
                                }
                                name="resource_ids"
                            >
                                <Checkbox.Group
                                    disabled={loadingCreateEvent || loadingCheckAvailable}
                                    className="w-full"
                                >
                                    <div
                                        className={
                                            resourceList && !loadingResourceList
                                                ? "grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2 xl:grid-cols-3"
                                                : "w-full"
                                        }
                                    >
                                        {resourceList && !loadingResourceList ? (
                                            resourceList.map(
                                                (item) =>
                                                    item.name && (
                                                        <Checkbox
                                                            style={{
                                                                marginLeft: 0,
                                                            }}
                                                            value={item.id}
                                                            key={item.id}
                                                            disabled={!item.available}
                                                        >
                                                            <p
                                                                onClick={() =>
                                                                    handleResourceClick({
                                                                        data: item.events_use,
                                                                        title: item.name,
                                                                    })
                                                                }
                                                            >
                                                                {item.name}
                                                                {item.events_use?.length > 0 && (
                                                                    <span className="ml-2 text-[#f57c96]">
                                                                        {t("Detail")}
                                                                    </span>
                                                                )}
                                                            </p>
                                                        </Checkbox>
                                                    )
                                            )
                                        ) : (
                                            <div className="grid w-full grid-cols-3 gap-4">
                                                {[...Array(12)].map((_, index) => (
                                                    <div
                                                        key={index.toString()}
                                                        className="h-7 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500"
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </Checkbox.Group>
                            </WrapperResourceList>
                        )}
                        <Form.Item className="grid">
                            <Button
                                loading={loadingCreateEvent || loadingCheckAvailable}
                                label={_uid ? "Update" : "Save event"}
                                type="primary"
                                htmlType="submit"
                                className="mx-auto mt-4 min-h-[35px] max-w-[300px]"
                                BgSuccess
                                disabled={loadingResourceList}
                                Icon={VscSaveAs}
                            />
                        </Form.Item>
                    </Form>
                </Section>
            </MainPage>
            <ContainerModal
                title={titleRoomBusy}
                open={modalRoomBusy}
                closeDoubleModal={() => setModalRoomBusy(false)}
                modal={{
                    width: 900,
                    bodyStyle: {
                        padding: "12px",
                    },
                }}
            >
                <ModalCheckAvailableEvent events_use={resourceBusy} />
            </ContainerModal>

            <ContainerModal
                title={t("The resource booking(s) info on {{day}}", { day: dateUrl })}
                open={modalResourceList}
                closeDoubleModal={() => setModalResourceList(false)}
                modal={{
                    width: 1200,
                    bodyStyle: {
                        padding: "12px",
                    },
                }}
            >
                <ModalResourceList dataResourceList={dataResourceList} day={dateUrl} />
            </ContainerModal>
        </>
    );
};

const WrapperEditor = styled(Form.Item)`
    & > div.ant-form-item-control div.ant-form-item-explain-connected {
        opacity: 0;
        min-height: 0;
    }

    & > div.ant-form-item-control div.ant-form-item-explain-connected div.ant-form-item-explain-error {
        display: none;
    }
`;

const WrapperResourceList = styled(Form.Item)`
    label {
        width: 100%;
    }
    .ant-col.ant-form-item-label {
        padding: 0;
        display: flex;
    }
`;
