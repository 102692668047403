import { axiosClient } from "./axios-client";

export const apiKnowLedGe = {
    getMenuKnowLedgesById: (menuId) => {
        const url = `/knowledges/pages/${menuId}`;
        return axiosClient.get(url);
    },
    getDetailKnowLedgesId: (postId) => {
        const url = `/knowledges/pages/detail/${postId}`;
        return axiosClient.get(url);
    },
    getMenuListKnowLedges: () => {
        const url = "/knowledges/menu/list";
        return axiosClient.get(url);
    },
};
