import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { onGetNameCompanyList } from "features";

import { useAccount, storeCompany } from "hooks";

export const useCompany = () => {
    const resultStoreCompony = useSelector(storeCompany);
    const dispatch = useDispatch();

    const { access_token } = useAccount();
    const { dataCompanyList, loading } = resultStoreCompony;

    const handleGetNameCompanyList = React.useCallback(
        () => access_token && dispatch(onGetNameCompanyList()),
        [access_token, dispatch]
    );

    return { handleGetNameCompanyList, dataCompanyList, loading };
};
