import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const WrapperError = ({ className, openSearch, data, keyword, errorClassName }) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            {openSearch && data?.length <= 0 && (
                <Error
                    message={t("Couldn't find posts with keyword {{value}}", { value: keyword })}
                    className={errorClassName}
                />
            )}
            {!data && openSearch && keyword?.trim()?.length < 3 && (
                <Error message={t("Enter at least 3 characters")} className={errorClassName} />
            )}
        </div>
    );
};

const Error = ({ message, className = "absolute shadow-lg left-0 mt-2 max-h-[500px] dark:bg-[#2a3042]" }) => {
    return (
        <div className={clsx("w-full space-y-4 rounded-lg bg-white p-4 dark:bg-[#2a3042]", className)}>
            <p className="text-center">{message}</p>
        </div>
    );
};
