export const Loading = () => {
    return (
        <div>
            <div className="grid gap-y-6">
                <div className="grid grid-cols-2 gap-4">
                    <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                    <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                </div>
                <div className="grid grid-cols-3 gap-4">
                    <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                    <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                    <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                </div>
                <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                <div className="grid grid-cols-2 gap-4">
                    <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                    <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                </div>
                <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                <div className="manimate-pulse h-7 w-full rounded bg-slate-300 dark:bg-slate-500" />
                <div className="manimate-pulse h-28 w-full rounded bg-slate-300 dark:bg-slate-500" />
                <div className="grid w-full grid-cols-3 gap-4">
                    {[...Array(12)].map((_, index) => (
                        <div
                            key={index.toString()}
                            className="h-7 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
