import { BackTop } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { UpCircleOutlined } from "@ant-design/icons";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useTypeProject } from "hooks";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const MainPage = ({
    children,
    className,
    title,
    goBack = true,
    classNameChildren,
    backTop = false,
    pageHeader,
    titleClassName,
    goBackClassName,
    classNamePageHeader,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isAppMobile } = useTypeProject();

    return (
        <section className={className}>
            <div
                className={clsx(
                    isAppMobile ? "flex justify-between py-2 px-3" : "flex justify-between",
                    pageHeader && "pb-4"
                )}
            >
                <div className={classNamePageHeader}>
                    {pageHeader && pageHeader}
                    {title && (
                        <h3 className={clsx("text-base font-semibold uppercase dark:text-[#f6f6f6]", titleClassName)}>
                            {t(capitalize(title))}
                        </h3>
                    )}
                </div>
                {goBack && (
                    <h6
                        className={clsx(
                            "flex cursor-pointer font-semibold text-[#007bff] hover:underline dark:text-white",
                            goBackClassName
                        )}
                        onClick={() => navigate(-1)}
                    >
                        {t("Go back")}
                        <RiArrowGoBackFill className="ml-2" />
                    </h6>
                )}
            </div>
            {backTop && (
                <BackTop>
                    <UpCircleOutlined
                        className="bg-[#2a3042] text-white dark:bg-[#222736] dark:text-white"
                        style={{
                            height: 40,
                            width: 40,
                            lineHeight: "40px",
                            borderRadius: "50%",
                            textAlign: "center",
                            fontSize: 22,
                        }}
                    />
                </BackTop>
            )}
            <div className={classNameChildren}>{children}</div>
        </section>
    );
};
