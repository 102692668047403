import { createSlice } from "@reduxjs/toolkit";

import { DrawerTypeEnum } from "constant";

const initialStateModal = {
    type: DrawerTypeEnum,
    open: false,
    title: "",
    drawer: {},
    data: {},
};

export const sliceDrawer = createSlice({
    name: "drawer",
    initialState: initialStateModal,
    reducers: {
        openDrawer: (state, action) => {
            const { type, title, data, drawer } = action.payload;
            return {
                ...state,
                open: true,
                type,
                title,
                data,
                drawer,
            };
        },
    },
});
const { actions, reducer } = sliceDrawer;
export const { openDrawer } = actions;
export default reducer;
