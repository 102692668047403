import moment from "moment";
import Countdown from "react-countdown";

import { useContextControllers } from "hooks";

export const CountDown = ({ day, className = "text-[#22a447]" }) => {
    const startDate = moment(new Date(), "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    const endDate = moment(day, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment(endDate).diff(moment(startDate), "days") || 0;

    const { translate } = useContextControllers();
    return translate.language === "vi-VN" ? (
        <Countdown
            date={day}
            renderer={({ hours, minutes }) => {
                return (
                    <div>
                        <div className="ml-1 flex">
                            <p className={className}>
                                {diff > 0 && <span className="mr-1">{diff} ngày</span>}
                                {hours} giờ {minutes} phút
                            </p>
                        </div>
                    </div>
                );
            }}
        />
    ) : (
        <Countdown
            date={day}
            renderer={({ hours, minutes }) => (
                <div>
                    <div className="ml-1 flex">
                        <p className={className}>
                            {diff > 0 && (
                                <span className="mr-1">
                                    {diff} {diff > 1 ? "days" : "day"}
                                </span>
                            )}
                            {hours} {hours > 1 ? "hours" : "hour"} {minutes} {minutes > 1 ? "minutes" : "minute"}
                        </p>
                    </div>
                </div>
            )}
        />
    );
};
