import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

import { WrapperScroll } from "layout";
import { DeleteOutlined } from "@ant-design/icons";

export const SelectedProducts = ({ data, onRemoveProductListProduct, onUpdateQuantityProductListProduct }) => {
    const { t } = useTranslation();

    return (
        <Form.Item
            label={t("Selected products {{value}}", { value: data.length })}
            rules={[{ required: data.length > 0 ? false : true, message: t("Please choose a product!") }]}
            name="product_lists"
            className="error-ant-custom items-input relative mt-2 grid"
        >
            <div className="flex divide-x divide-[#eaeaea] border-t border-l border-r border-[#d9d9d9] p-2 dark:divide-[#424b64] dark:border-[#424b64]">
                <h6 className="basis-[70%] md:basis-[80%]">{t("Product")}</h6>
                <h6 className="basis-[15%] text-center md:basis-[10%]">{t("Quantity")}</h6>
                <h6 className="basis-[15%] self-center text-center md:basis-[10%]">{t("Delete")}</h6>
            </div>
            <WrapperScroll className="hover h-full max-h-[155px] min-h-[155px] divide-y divide-[#eaeaea] rounded-[2px] border border-[#d9d9d9] dark:divide-[#424b64] dark:border-[#424b64]">
                {data.map((item) => (
                    <div key={item.product.id} className="flex items-center px-2">
                        <h6 className="lex-row flex basis-[70%] md:basis-[80%]">{item.product.name}</h6>
                        <h6 className="basis-[15%] text-center md:basis-[10%]">
                            <InputNumber
                                min={1}
                                max={10}
                                defaultValue={item.quantity}
                                bordered={false}
                                onChange={(quantity) =>
                                    onUpdateQuantityProductListProduct({ quantity, _id: item.product.id })
                                }
                            />
                        </h6>
                        <DeleteOutlined
                            className="basis-[15%] cursor-pointer text-center text-[1.25rem] text-[#dc3545] md:basis-[10%]"
                            onClick={() => onRemoveProductListProduct(item.product)}
                        />
                    </div>
                ))}
            </WrapperScroll>
            {data.length < 1 && (
                <p className="error-ant-custom absolute text-[#ff4d4f]">{t("Please choose a product!")}</p>
            )}
        </Form.Item>
    );
};
