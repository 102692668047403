import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { onSearchPartner } from "features";

import { useAccount, storePartner } from "hooks";

export const usePartner = () => {
    const dispatch = useDispatch();

    const resultStorePartner = useSelector(storePartner);
    const { access_token } = useAccount();

    const { loading, dataPartner } = resultStorePartner;

    const handleSearchPartner = React.useCallback(
        (payload) => access_token && dispatch(onSearchPartner(payload)),
        [access_token, dispatch]
    );

    return {
        handleSearchPartner,
        loading,
        dataPartner,
    };
};
