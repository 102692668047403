import * as React from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import clsx from "clsx";

import { Setting } from "components";
import { linkWebsite, storeLocalStorage } from "constant";
import { Image, LinkHref } from "customize";
import { useAccount, useContextControllers, useTypeProject } from "hooks";
import { DropdownTranslate, WrapperScroll } from "layout";
import { imageUseSetting } from "assets/images";

import { AppstoreAddOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { BsMoonStarsFill } from "react-icons/bs";
import { MdOutlineWbSunny } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";

export const Profile = ({ avatar, username, onClickLogout, loading }) => {
    const { t } = useTranslation();
    const [run] = React.useState(false);
    const { isAppMobile } = useTypeProject();

    const { settingAccount, setSettingAccount } = useContextControllers();
    const { dataNotification } = useAccount();
    const { theme } = settingAccount;

    const toggleDaks = () => {
        const payload = { ...settingAccount, theme: theme === "dark" ? "light" : "dark" };
        setSettingAccount(payload);
        localStorage.setItem(storeLocalStorage.SETTING_ACCOUNT, JSON.stringify(payload));
    };

    const steps = [
        {
            locale: { skip: <strong aria-label="skip">{t("Skip")}</strong> },
            placement: "center",
            target: "body",
            title: t("New feature"),
        },
        {
            title: t("Setting"),
            floaterProps: {
                disableAnimation: true,
            },
            spotlightPadding: 20,
            target: ".star-setting img",
            content: <Image src={imageUseSetting} className="h-full w-full" />,
        },
    ];

    const linkOutWeb = (
        <div className="relative top-[11px] rounded-[0.25rem] border border-white bg-white p-3 text-[#495057] shadow-dropdown-menu dark:border-[#32394e] dark:bg-[#262b3c] dark:text-slate-200">
            <div className="min-w-[200px]">
                <div className="grid grid-cols-3 gap-1">
                    {linkWebsite.map((item, index) => (
                        <LinkHref
                            key={index.toString()}
                            path={item.path}
                            outWeb={item.outWebsite}
                            className="line-height rounded border border-white text-center text-[#74788d] hover:border-inherit hover:text-[#4458b8] dark:border-[#1c1f2b] dark:text-slate-200 dark:hover:bg-[#1c1f2b]"
                        >
                            <item.icon className="mb-1 text-base" />
                            <p className="px-2 pb-2 text-[0.85rem] dark:text-slate-200">{t(item.title)}</p>
                        </LinkHref>
                    ))}
                </div>
            </div>
        </div>
    );

    const notification = (
        <div className="relative top-[11px] rounded-[0.25rem] border border-white bg-white text-[#495057] shadow-dropdown-menu dark:border-[#32394e] dark:bg-[#262b3c] dark:text-slate-200">
            <div className="w-[300px]">
                <div className="flex items-center justify-between gap-2 p-4">
                    <h6 className="font-semibold dark:text-slate-200">{t("Notifications")}</h6>
                    <p className="text-[#556ee6] dark:text-slate-200">{t("View all")}</p>
                </div>
                <WrapperScroll className="hover flex max-h-[240px] w-full flex-col gap-2">
                    {dataNotification.map((item, index) => (
                        <div
                            key={index.toString()}
                            className="grid gap-1 p-4 hover:bg-[#f6f6f6] dark:hover:bg-[#1c1f2b]"
                        >
                            <h6 className="font-semibold dark:text-slate-200">{item.title}</h6>
                            <div className="flex items-center gap-1">
                                <ClockCircleOutlined className="text-[10px] text-[#74788d] dark:text-slate-200" />
                                <p className="text-[#74788d] dark:text-slate-200">{item.time}</p>
                            </div>
                        </div>
                    ))}
                </WrapperScroll>
            </div>
        </div>
    );

    return (
        <div className="flex items-center justify-center gap-x-4 sm:gap-x-6">
            {/* {!run && pathname === urlWebsite.HOME_PAGE && username && (
                <div className="fixed right-[2rem] bottom-[4.5rem] z-[999]">
                    <span className="flex w-3 h-3 cursor-pointer" onClick={() => setRun(true)}>
                        <span className="absolute inline-flex w-full h-full rounded-full animate-ping bg-sky-400"></span>
                        <span className="relative inline-flex w-3 h-3 rounded-full bg-sky-500"></span>
                    </span>
                </div>
            )} */}
            <Joyride
                continuous
                hideBackButton
                hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
            />
            {/* <Badge
                size="small"
                count={dataNotification.length === 0 ? null : dataNotification.length}
                offset={[-2, 8]}
                color="#dc3545"
            >
                <BellOutlined
                    className={clsx(
                        "cursor-pointer text-lg",
                        settingAccount.horizontal ? "text-slate-200" : " text-[#555b6d] dark:text-slate-200"
                    )}
                />
            </Badge> */}
            {/* <Dropdown overlay={notification} placement="bottom">
                <Badge
                    size="small"
                    count={dataNotification.length === 0 ? null : dataNotification.length}
                    offset={[-2, 8]}
                    color="#dc3545"
                >
                    <BellOutlined className="cursor-pointer text-lg text-[#555b6d] dark:text-slate-200" />
                </Badge>
            </Dropdown> */}
            <DropdownTranslate />
            <Dropdown overlay={linkOutWeb} placement="bottom" trigger={[!isAppMobile ? "hover" : "click"]}>
                <AppstoreAddOutlined
                    className={clsx(
                        "cursor-pointer text-lg",
                        settingAccount.horizontal ? "text-slate-200" : " text-[#555b6d] dark:text-slate-200"
                    )}
                />
            </Dropdown>
            <div onClick={toggleDaks}>
                <div className="dark:hidden">
                    <MdOutlineWbSunny
                        className={clsx(
                            "cursor-pointer text-lg",
                            settingAccount.horizontal ? "text-slate-200" : " text-[#555b6d] dark:text-slate-200"
                        )}
                    />
                </div>
                <div className="hidden dark:inline">
                    <BsMoonStarsFill
                        className={clsx(
                            "cursor-pointer text-lg",
                            settingAccount.horizontal ? "text-slate-200" : " text-[#555b6d] dark:text-slate-200"
                        )}
                    />
                </div>
            </div>
            <Setting onClickLogout={onClickLogout} loading={loading}>
                <div className="star-setting flex cursor-pointer items-center justify-center gap-2">
                    <div className="hidden items-center md:flex">
                        <h6
                            className={clsx(
                                "font-semibold",
                                settingAccount.horizontal ? "text-slate-200" : " text-[#555b6d] dark:text-slate-200"
                            )}
                        >
                            {username}
                        </h6>
                        <RiArrowDropDownLine
                            className={clsx(
                                "text-[1.25rem]",
                                "font-semibold",
                                settingAccount.horizontal ? "text-slate-200" : " text-[#555b6d] dark:text-slate-200"
                            )}
                        />
                    </div>
                    <Image src={avatar} className="h-9 w-9 rounded-full" />
                </div>
            </Setting>
        </div>
    );
};
