import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import EditorToolbar, { modules, formats } from "./editor-toolbar";

export const EditorText = ({ description, setDescription, readOnly }) => {
    const { t } = useTranslation();

    const onChangeEditorText = (data) => {
        if (data.replace(/<(.|\n)*?>/g, "").trim().length === 0) setDescription(null);
        else setDescription(data);
    };

    return (
        <>
            <p className="mb-2 font-semibold">
                <span className="mr-1 text-[0.938rem] text-[#ff4d4f]">*</span>
                {t("Content")}
            </p>
            <div className={readOnly && "cursor-not-allowed bg-[#f5f5f5] dark:bg-[#373c4b]"}>
                <EditorToolbar />
                <EditorTextReactQuill
                    theme="snow"
                    value={description || ""}
                    onChange={onChangeEditorText}
                    placeholder={`${t("Meeting content")}...`}
                    modules={modules}
                    formats={formats}
                    readOnly={readOnly}
                />
            </div>
        </>
    );
};

const EditorTextReactQuill = styled(ReactQuill)`
    position: relative;
    & > div.ql-container.ql-snow > div.ql-editor {
        min-height: 130px;
        border-radius: 0 0 0.25rem 0.25rem;
    }
    & > div.ql-tooltip {
        left: 0px;
    }
    & > div.ql-toolbar.ql-snow {
        border-radius: 0.25rem 0.25rem 0px 0px;
    }
    & > div > div.ql-tooltip.ql-editing.ql-flip {
        left: 0 !important;
    }
    & > div.ql-container.ql-snow > div.ql-tooltip.ql-editing {
        left: 0 !important;
    }
`;
