import moment from "moment";
import { ItemTableMobile } from "layout";
import { useFormatNumberTranslate } from "hooks";

export const ModalQuotationReview = ({
    name,
    uom_id,
    default_code,
    sku_warranty_duration,
    sku_made_in,
    sku_max_sale_price,
    sku_min_sale_price,
    sku_approve_by,
    sku_description,
    sku_note,
    write_date,
}) => {
    const { formatNumberTranslate } = useFormatNumberTranslate();

    return (
        <div className="divide-y divide-[#eaeaea] dark:divide-[#424b64]">
            <ItemTableMobile title="Code" dot value={default_code} />
            <ItemTableMobile title="Product's name" dot value={name} />
            <ItemTableMobile title="Unit" dot value={uom_id && uom_id[1]} />
            <ItemTableMobile title="Warranty period" dot value={sku_warranty_duration} />
            <ItemTableMobile title="Origin" dot value={sku_made_in && sku_made_in[1]} />
            <ItemTableMobile title="Approved" dot value={sku_approve_by && sku_approve_by[1]} />
            <ItemTableMobile
                title="Maximum price"
                dot
                value={formatNumberTranslate({ number: sku_max_sale_price })}
                unit="VNĐ"
                valueClassName="flex gap-1 items-center"
            />
            <ItemTableMobile
                title="Minimum price"
                dot
                value={formatNumberTranslate({ number: sku_min_sale_price })}
                unit="VNĐ"
                valueClassName="flex gap-1 items-center"
            />
            <ItemTableMobile title="Description" dot value={sku_description} />
            <ItemTableMobile title="Note" dot value={sku_note} />
            <ItemTableMobile title="Date create" dot value={moment(write_date).format("DD/MM/YYYY")} />
        </div>
    );
};
