import React from "react";
import { useKnowLedge } from "hooks";
import { NoData, WrapperKnowLedge, WrapperScroll } from "layout";

export const ModalTreeKnowLedge = ({ id }) => {
    const { handleGetMenuKnowLedgesById, dataKnowledgeId, loadingDataKnowledgeId } = useKnowLedge();
    const [isError, setIsError] = React.useState(false);

    React.useLayoutEffect(() => {
        (async () => {
            if (id) {
                setIsError(false);
                const { error } = await handleGetMenuKnowLedgesById(id);
                if (error) {
                    setIsError(true);
                }
            }
        })();
    }, [handleGetMenuKnowLedgesById, id]);

    if (isError) return <NoData />;

    return (
        <WrapperScroll className="globals__display_block max-h-96">
            <WrapperKnowLedge data={dataKnowledgeId} loading={loadingDataKnowledgeId} />
        </WrapperScroll>
    );
};
