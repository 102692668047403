import { Select, Form } from "antd";
import vnmToAlphabet from "vnm-to-alphabet";
import { useTranslation } from "react-i18next";

export const SelectSearch = ({
    name,
    label,
    children,
    placeholder,
    required,
    messageError,
    disabled,
    className = "grid basis-3/12",
    props,
    allowClear = true,
}) => {
    const { t } = useTranslation();

    return (
        <Form.Item
            className={className}
            label={t(label)}
            name={name}
            rules={[{ required: required, message: t(messageError) }]}
            hasFeedback
        >
            <Select
                {...props}
                disabled={disabled}
                allowClear={allowClear}
                placeholder={t(placeholder)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    vnmToAlphabet(option.children.toLowerCase(), "capitalize").indexOf(
                        vnmToAlphabet(input.toLowerCase(), "capitalize")
                    ) >= 0
                }
            >
                {children}
            </Select>
        </Form.Item>
    );
};
