import { Table } from "antd";
import clsx from "clsx";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FiUserCheck, FiUserX } from "react-icons/fi";

import { urlWebsite } from "constant";
import { useEvents, useModal, useContextControllers, useTypeProject } from "hooks";

import { CountDown, ItemTableMobile, LoadingTableMobile, NoData, ViewMore, WrapperTable, WrapperTag } from "layout";

import { unwrapResult } from "@reduxjs/toolkit";
import { Button } from "customize";
import { TitleTable } from "./wrapper-table";

export const WrapperNextMeeting = ({ dataFull, PageHome }) => {
    const { t } = useTranslation();
    const { settingAccount } = useContextControllers();
    const { toggleModal, ModalTypeEnum } = useModal();
    const { borderClassNameMobile } = useTypeProject();

    const { dataMeetingNext, loadingDataMeetingNext } = useEvents();

    const columnsMeetingNext = [
        {
            title: <TitleTable title="ID" />,
            dataIndex: "meeting_note_issue",
            render: (_, record) =>
                !settingAccount.is_modal_event ? (
                    <Link
                        to={`${urlWebsite.EVENTS}/${record.uid}`}
                        className="block text-center font-semibold text-[#007bff] hover:text-[#007bff] hover:underline"
                    >
                        {record.id}
                    </Link>
                ) : (
                    <h3
                        className="cursor-pointer text-center font-semibold text-[#007bff] hover:text-[#007bff] hover:underline"
                        onClick={() => {
                            toggleModal({
                                title: t("Event Detail {{value}}", { value: `#${record.id}` }),
                                type: ModalTypeEnum.DETAIL_EVENT,
                                data: {
                                    _uid: record.uid,
                                    url: `${window.location.href}events/${record.uid}`,
                                    cancel: true,
                                    targetLink: true,
                                },
                                modal: {
                                    width: 1500,
                                },
                            });
                        }}
                    >
                        {record.id}
                    </h3>
                ),
        },
        {
            title: <TitleTable className="text-left" title="Title" />,
            dataIndex: "full",
            render: (_, record) =>
                !settingAccount.is_modal_event ? (
                    <Link
                        to={`${urlWebsite.EVENTS}/${record.uid}`}
                        className="block text-left hover:text-[#007bff] hover:underline dark:hover:text-[#f8f8fb]"
                    >
                        {record.name}
                    </Link>
                ) : (
                    <h3
                        className="block cursor-pointer text-left hover:text-[#007bff] hover:underline dark:hover:text-[#f8f8fb]"
                        onClick={() => {
                            toggleModal({
                                title: t("Event Detail {{value}}", { value: `#${record.id}` }),
                                type: ModalTypeEnum.DETAIL_EVENT,
                                data: {
                                    _uid: record.uid,
                                    url: `${window.location.href}events/${record.uid}`,
                                    cancel: true,
                                    targetLink: true,
                                },
                                modal: {
                                    width: 1500,
                                },
                            });
                        }}
                    >
                        {record.name}
                    </h3>
                ),
        },
        {
            title: <TitleTable title="Time" className="text-center" />,
            dataIndex: "full",
            render: (_, record) => (
                <div className="flex items-center justify-center gap-1">
                    <div className="flex flex-col items-center">
                        <p>{moment(record.event_start_datetime).format("DD/MM/YYYY")}</p>
                    </div>
                    <div className="flex items-center gap-1">
                        <span className="text-[#03942c]">{moment(record.event_start_datetime).format("HH:mm")}</span>
                        <span>-</span>
                        <span className="text-[#dc3545]">{moment(record.event_end_datetime).format("HH:mm")}</span>
                    </div>
                </div>
            ),
        },
        {
            title: <TitleTable title="Start in" className="text-center" />,
            dataIndex: "full",
            render: (_, record) => (
                <div className="flex items-center justify-center">
                    {new Date(record.event_start_datetime) > new Date() && (
                        <CountDown day={record.event_start_datetime} className="text-[#343a40] dark:text-[#a6b0cf]" />
                    )}
                    {new Date(record.event_start_datetime) < new Date() &&
                        new Date(record.event_end_datetime) > new Date() && (
                            <p className="text-[#22a447]">{t("Meeting in progress")}</p>
                        )}
                    {new Date() > new Date(record.event_end_datetime) && (
                        <p className="text-[#dc3545]">{t("The meeting is over")}</p>
                    )}
                </div>
            ),
        },
        {
            title: <TitleTable title="Status" />,
            dataIndex: "full",
            render: (_, record) =>
                new Date() > new Date(record.event_end_datetime) ? (
                    ""
                ) : (
                    <EventConfirmStatus
                        data={record}
                        className="flex justify-center"
                        wrapperClassNameBtb="grid 2xl:grid-cols-2 grid-cols-1 gap-2"
                    />
                ),
        },
    ];

    return (
        <>
            {/* laptop  */}
            <div className="hidden md:block 2xl:block">
                <Table
                    locale={{
                        emptyText: <NoData title="No data available yet" noData />,
                    }}
                    columns={columnsMeetingNext}
                    dataSource={dataMeetingNext
                        ?.slice(0, dataMeetingNext?.length)
                        ?.reverse()
                        .slice(0, dataFull ? dataMeetingNext?.length : 4)}
                    pagination={!PageHome}
                    loading={loadingDataMeetingNext}
                />
                {!dataFull && dataMeetingNext?.length > 4 && (
                    <div className="mt-2 flex justify-end">
                        <ViewMore href={urlWebsite.NEXT_MEETING} title="More" />
                    </div>
                )}
            </div>
            {/* mobile */}
            {loadingDataMeetingNext ? (
                <div className="block md:hidden 2xl:hidden">
                    <LoadingTableMobile items={3} />
                </div>
            ) : dataMeetingNext.length > 0 ? (
                <WrapperTable
                    className="block md:hidden 2xl:hidden"
                    option={
                        !dataFull &&
                        dataMeetingNext?.length > 4 && (
                            <div className="mt-2 flex justify-center">
                                <ViewMore href={urlWebsite.NEXT_MEETING} title="More" />
                            </div>
                        )
                    }
                >
                    {dataMeetingNext
                        ?.slice(0, dataMeetingNext?.length)
                        ?.reverse()
                        .slice(0, dataFull ? dataMeetingNext?.length : 4)
                        .map((item) => (
                            <div
                                key={item.id}
                                className={borderClassNameMobile}
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                            >
                                <ItemTableMobile
                                    dot
                                    inWeb
                                    url={`${urlWebsite.EVENTS}/${item.uid}`}
                                    title="ID"
                                    value={item.id}
                                    valueClassName="text-[#007bff] font-semibold hover:text-[#007bff] dark:hover:text-[#f8f8fb] hover:underline"
                                />
                                <ItemTableMobile
                                    dot
                                    title="Title"
                                    value={item.name}
                                    inWeb
                                    url={`${urlWebsite.EVENTS}/${item.uid}`}
                                    valueClassName="hover:text-[#007bff] dark:hover:text-[#f8f8fb] hover:underline"
                                />
                                <ItemTableMobile
                                    dot
                                    title="Time"
                                    value={moment(item.event_start_datetime).format("DD/MM/YYYY")}
                                    unit={
                                        <>
                                            <span className="text-[#03942c]">
                                                {moment(item.event_start_datetime).format("HH:mm")}
                                            </span>
                                            <span className="mx-[2px]">-</span>
                                            <span className="text-[#dc3545]">
                                                {moment(item.event_end_datetime).format("HH:mm")}
                                            </span>
                                        </>
                                    }
                                    valueClassName="flex gap-1"
                                />
                                <ItemTableMobile title="Start in" dot>
                                    {new Date(item.event_start_datetime) > new Date() && (
                                        <CountDown
                                            day={item.event_start_datetime}
                                            className="text-[#343a40] dark:text-[#a6b0cf]"
                                        />
                                    )}
                                    {new Date(item.event_start_datetime) < new Date() &&
                                        new Date(item.event_end_datetime) > new Date() && (
                                            <p className="text-[#22a447]">{t("Meeting in progress")}</p>
                                        )}
                                    {new Date() > new Date(item.event_end_datetime) && (
                                        <p className="text-[#dc3545]">{t("The meeting is over")}</p>
                                    )}
                                </ItemTableMobile>
                                {item.confirm_status ? (
                                    <ItemTableMobile title="Status" dot>
                                        <EventConfirmStatus data={item} className="flex flex-1 justify-end" isMobile />
                                    </ItemTableMobile>
                                ) : (
                                    <EventConfirmStatus data={item} className="justify-center" />
                                )}
                            </div>
                        ))}
                </WrapperTable>
            ) : (
                <div className="mb-2 block md:hidden">
                    <NoData title="No data available yet" noData />
                </div>
            )}
        </>
    );
};

const EventConfirmStatus = ({ data, className, wrapperClassNameBtb = "grid grid-cols-2 gap-2", isMobile = false }) => {
    const { handlePatchConfirmEventMeetingFast } = useEvents();
    const { toggleModal, ModalTypeEnum } = useModal();

    const [loading, setLoading] = React.useState(false);
    const [type, setType] = React.useState(null);

    const onClickConfirmEvent = async (type, event) => {
        setType(type);
        if (type === "yes") {
            const payload = {
                eventUid: event.uid,
                data: {
                    confirm_type: type,
                    confirm_code: event.confirm_code,
                },
            };
            setLoading(true);
            const resultResult = await handlePatchConfirmEventMeetingFast(payload);
            const resultResultData = unwrapResult(resultResult);
            if (resultResultData) {
                setLoading(false);
                setType(null);
            }
        } else {
            toggleModal({
                type: ModalTypeEnum.EVENT_CONFIRM_LATER,
                title: type === "no" ? "Reason: Not attending" : "Reason: Confirm later",
                data: {
                    eventUid: event.uid,
                    confirm_code: event.confirm_code,
                    confirm_type: type,
                    confirmEventMeetingFast: true,
                },
            });
        }
    };

    return (
        <div className={clsx("flex w-full items-center gap-2", className)}>
            {Number(data.confirm_status) !== 0 && (
                <p>
                    {Number(data.confirm_status) === 1 && (
                        <WrapperTag transition rounded title="Attend" bgColor="success" className="text-white" />
                    )}
                    {Number(data.confirm_status) === 2 && (
                        <WrapperTag transition rounded title="Not coming" bgColor="danger" className="text-white" />
                    )}
                    {Number(data.confirm_status) === 3 && (
                        <WrapperTag transition rounded title="Confirm later" bgColor="info" className="text-white" />
                    )}
                </p>
            )}
            {!data.confirm_status && (
                <div className={wrapperClassNameBtb}>
                    <Button
                        Icon={FiUserCheck}
                        className="min-w-[130px]"
                        BgSuccess
                        label="Attend"
                        size="sm"
                        loading={type === "yes" && loading}
                        disabled={loading}
                        onClick={() => onClickConfirmEvent("yes", data)}
                    />
                    <Button
                        Icon={FiUserX}
                        BgDanger
                        className="min-w-[130px] dark:text-white"
                        label="Not coming"
                        size="sm"
                        loading={type === "no" && loading}
                        disabled={loading}
                        onClick={() => onClickConfirmEvent("no", data)}
                    />
                </div>
            )}
        </div>
    );
};
