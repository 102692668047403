import { axiosClient } from "./axios-client";

export const apiEvent = {
    getEventInMonth: ({ year, month }) => {
        const url = `/events/month/${year}/${month}`;
        return axiosClient.get(url);
    },
    getEventDetailByUid: (uid) => {
        const url = `/events/uid/${uid}`;
        return axiosClient.get(url);
    },
    deleteEvent: (eventId) => {
        const url = `/events/${eventId}`;
        return axiosClient.delete(url);
    },
    getEventComments: (eventId) => {
        const url = `/events/${eventId}/comment/all`;
        return axiosClient.get(url);
    },
    postCreateEventNote: (eventId) => {
        const url = `/events/${eventId}/create-meeting-note`;
        return axiosClient.post(url);
    },
    postEventComment: (data) => {
        const { idEvent, comment } = data;
        const url = `/events/${idEvent}/comment`;
        return axiosClient.post(url, { comment });
    },
    patchConfirmEvent: (payload) => {
        const { eventUid, data } = payload;
        const url = `/events/confirm/${eventUid}`;
        return axiosClient.patch(url, data);
    },
    getTypeEventList: () => {
        const url = `events/type/list`;
        return axiosClient.get(url);
    },
    getResourceList: () => {
        const url = `events/resource/list`;
        return axiosClient.get(url);
    },
    postCreateEvent: (payload) => {
        const url = `/events/?`;
        return axiosClient.post(url, payload);
    },
    putUpdateEvent: (data) => {
        const { resourceId, payload } = data;
        const url = `/events/${resourceId}`;
        return axiosClient.put(url, payload);
    },
    checkAvailableResource: (data) => {
        const { resourceId, payload } = data;
        const url = `/events/resource/${resourceId}/check-available`;
        return axiosClient.post(url, payload);
    },
    getMeetingNext: () => {
        const url = "events/me/next-meeting";
        return axiosClient.get(url);
    },
    getMeetingNextToday: () => {
        const url = "events/me/today";
        return axiosClient.get(url);
    },
    getMeetingNextTomorrow: () => {
        const url = "events/me/tomorrow";
        return axiosClient.get(url);
    },
    getMeetingNextThisWeek: () => {
        const url = "events/me/this-week";
        return axiosClient.get(url);
    },
    getMeetingNextNextWeek: () => {
        const url = "events/me/next-week";
        return axiosClient.get(url);
    },
    postStartZoomUrl: (eventId) => {
        const url = `/events/${eventId}/get-start-zoom-url`;
        return axiosClient.post(url);
    },
    postRegisterEvent: (eventId) => {
        const url = `/events/${eventId}/register`;
        return axiosClient.post(url);
    },
};
