import { useTranslation } from "react-i18next";
import moment from "moment";

import { HeadingTitle } from "layout";

export const ModalCheckAvailableEvent = ({ events_use }) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="flex items-center rounded bg-[#eff2f7] dark:bg-[#32394e]">
                <HeadingTitle className="mb-0 basis-1/12 text-center" title="ID" style={{ fontSize: 13 }} />
                <HeadingTitle className="mb-0 basis-5/12 pl-2 sm:basis-7/12" title="Content" style={{ fontSize: 13 }} />
                <HeadingTitle
                    className="mb-0 basis-3/12 py-1 text-center sm:basis-2/12"
                    title="Start time"
                    option={<p>{t("(day, hour, minute)")}</p>}
                    style={{ fontSize: 13 }}
                />
                <HeadingTitle
                    className="mb-0 basis-3/12 py-1 text-center sm:basis-2/12"
                    title="End time"
                    option={<p>{t("(day, hour, minute)")}</p>}
                    style={{ fontSize: 13 }}
                />
            </div>
            {events_use.map((item) => (
                <div key={item.id} className="flex border-b dark:border-[#424b64]">
                    <p className="relative basis-1/12 pt-1 text-center text-[#007bff] hover:text-[#007bff] dark:hover:text-[#f8f8fb]">
                        {item.id}
                    </p>
                    <p className="basis-5/12 pt-1 pl-2 sm:basis-7/12">{item.name}</p>
                    <p className="basis-3/12 pt-1 text-center sm:basis-2/12">
                        {moment(item.start_time).format("DD/MM/YYYY HH:ss")}
                    </p>
                    <p className="basis-3/12 pt-1 text-center sm:basis-2/12">
                        {moment(item.end_time).format("DD/MM/YYYY HH:ss")}
                    </p>
                </div>
            ))}
        </div>
    );
};
