import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { onSearchUserSelect, onSearchUserWatcher, onChangeAvatar } from "features";
import { storeUser, useAccount } from "hooks";

export const useUser = () => {
    const dispatch = useDispatch();
    const resultStoreUser = useSelector(storeUser);
    const { loading, dataUser, loadingDataUser, dataWatcher } = resultStoreUser;

    const { access_token } = useAccount();

    const handleSearchUserSelect = React.useCallback(
        (user) => access_token && dispatch(onSearchUserSelect(user)),
        [access_token, dispatch]
    );

    const handleSearchUserSelectWatcher = React.useCallback(
        (user) => access_token && dispatch(onSearchUserWatcher(user)),
        [access_token, dispatch]
    );

    const handleChangeAvatar = React.useCallback(
        (data) => access_token && dispatch(onChangeAvatar(data)),
        [access_token, dispatch]
    );

    return {
        handleSearchUserSelect,
        handleSearchUserSelectWatcher,
        handleChangeAvatar,
        loading,
        dataUser,
        loadingDataUser,
        dataWatcher,
    };
};
