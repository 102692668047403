import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const HeadingTitle = ({ title, className, option, style }) => {
    const { t } = useTranslation();

    return (
        <h3 style={style} className={clsx("mb-2 text-[0.938rem] font-semibold", className)}>
            {t(title)}
            {option}
        </h3>
    );
};
