import * as React from "react";
import clsx from "clsx";

import { useContextControllers, useModal } from "hooks";
import { ConfirmStatus } from "./confirm-status";
import { useLocation, useNavigate } from "react-router-dom";
import { urlWebsite } from "constant";
import { useTranslation } from "react-i18next";

export const EventContentCalendar = ({ data }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const { toggleModal, ModalTypeEnum } = useModal();
    const { settingAccount } = useContextControllers();

    return (
        <div className="grid">
            {data.extendedProps.uid ? (
                <div
                    onClick={() => {
                        if (settingAccount.is_modal_event) {
                            toggleModal({
                                title: t("Event Detail {{value}}", { value: `#${data.extendedProps._id}` }),
                                type: ModalTypeEnum.DETAIL_EVENT,
                                data: {
                                    _uid: data.extendedProps.uid,
                                    url: `${window.location.href}/${data.extendedProps.uid}`,
                                    cancel: true,
                                    targetLink: true,
                                },
                                modal: {
                                    width: 1500,
                                },
                            });
                            if (location.pathname === urlWebsite.EVENTS) {
                                navigate(`${urlWebsite.EVENTS}?_uid=${data.extendedProps.uid}`);
                            }
                        } else {
                            navigate(`${urlWebsite.EVENTS}/${data.extendedProps.uid}`);
                        }
                    }}
                    className={clsx(
                        "ml-1 flex items-center gap-x-1 rounded hover:bg-[#ebeff3] dark:hover:bg-[#1c1f2b]",
                        data.extendedProps.isMember && "text-[#22a447] hover:text-[#22a447] dark:text-[#5bf814]",
                        data.extendedProps.type === "1" &&
                            !data.extendedProps.isMember &&
                            "text-[#0264af] hover:text-[#0264af] dark:text-[#0090ff]", //event
                        data.extendedProps.type === "2" &&
                            !data.extendedProps.isMember &&
                            "text-[#8f81fe] hover:text-[#8f81fe]", //training
                        data.extendedProps.type === "0" &&
                            !data.extendedProps.isMember &&
                            "text-[#f57c96] hover:text-[#f57c96]" //birthday
                    )}
                >
                    <div className="grid">
                        <p className="flex-1 truncate">{data.extendedProps.name}</p>
                        <span className="relative bottom-1 truncate text-[0.75rem] text-[#76828e] opacity-70">
                            {data.extendedProps.hourStart} - {data.extendedProps.hourEnd}
                        </span>
                    </div>
                    {data.extendedProps.status && (
                        <div className="flex h-full items-start pr-1 pt-[4px]">
                            <ConfirmStatus
                                status={data.extendedProps.confirm_status}
                                title={data.extendedProps.confirm_reason}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div
                    onClick={() => {
                        toggleModal({
                            type: ModalTypeEnum.HAPPY_BIRTHDAY,
                            title: "Happy Birthday",
                            data: {
                                username: data.extendedProps.username,
                            },
                            modal: {
                                width: 700,
                            },
                        });
                    }}
                    className="ml-1 flex items-center gap-x-2 truncate rounded text-[#f57c96] hover:text-[#f57c96] hover:underline"
                >
                    <p>{data.extendedProps.name}</p>
                </div>
            )}
        </div>
    );
};
