import { createSlice } from "@reduxjs/toolkit";
import { onGetNameCompanyList } from "./path-api";

const initialState = {
    dataCompanyList: null,
    loading: false,
};

export const sliceCompany = createSlice({
    name: "company",
    initialState: initialState,
    extraReducers: {
        // get company list
        [onGetNameCompanyList.pending]: (state) => {
            state.loading = true;
        },
        [onGetNameCompanyList.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataCompanyList = action.payload;
        },
    },
});

const { reducer } = sliceCompany;
export default reducer;
