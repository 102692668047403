import * as React from "react";
import clsx from "clsx";
import moment from "moment";
import { Tooltip, Dropdown, Badge } from "antd";

import { colorTooltip, urlWebsite } from "constant";
import { Image } from "customize";
import { WrapperProfile, WrapperTag } from "layout";
import { ConfirmStatus } from "./confirm-status";

import { FieldTimeOutlined } from "@ant-design/icons";
import { FaSuitcase } from "react-icons/fa";

import { useModal } from "hooks";
import { useTranslation } from "react-i18next";

export const CardMember = ({ data, memberBusyTime, eventId }) => {
    const dataMember = React.useMemo(() => {
        const memberListTotal = [];
        if (eventId) {
            data.forEach((member) => {
                for (const userId in memberBusyTime) {
                    if (member.id === Number(userId)) {
                        memberListTotal.push({
                            ...member,
                            busy: memberBusyTime[userId].events,
                            business_trips: memberBusyTime[userId].business_trips,
                            leaves: memberBusyTime[userId].leaves,
                        });
                    }
                }
            });
            return memberListTotal;
        } else {
            return data;
        }
    }, [data, eventId, memberBusyTime]);

    return (
        <div className="grid grid-cols-1 gap-1 sm:grid-cols-3">
            {dataMember ? (
                dataMember.map((member, index) => (
                    <InfoMember {...member} profile={member} eventId={eventId} key={index.toString()} />
                ))
            ) : (
                <div>loading...</div>
            )}
        </div>
    );
};

export const InfoMember = ({
    is_active,
    avatar_url,
    username,
    busy,
    business_trips,
    eventId,
    children,
    className,
    titleClassName,
    imageClassName,
    wrapperCmtClassName,
    time,
    event_info,
    profile,
    leaves,
}) => {
    const resultBusy = busy?.filter((item) => eventId && Number(item.id) !== Number(eventId));
    const resultBusinessTrips = business_trips?.filter((item) => eventId && Number(item.id) !== Number(eventId));

    const { toggleModal, ModalTypeEnum } = useModal();
    const { t } = useTranslation();
    const countBusy = resultBusy?.length + resultBusinessTrips?.length + leaves?.length || 0;

    return (
        <div className={clsx("flex items-center gap-4", className)}>
            <div className="flex w-full items-center gap-1">
                {children ? (
                    <div className="flex items-center gap-1">
                        <Dropdown
                            placement="bottomLeft"
                            overlay={() => (
                                <WrapperProfile
                                    showAvatar
                                    {...profile}
                                    wrapperClassNameItemInfo="pt-[2px] px-[0] pb-[2px]"
                                    className="rounded-[0.25rem] bg-white p-4 text-[#495057] shadow-2xl dark:bg-[#32394e]"
                                />
                            )}
                        >
                            <div className={clsx("flex", imageClassName)}>
                                <Image
                                    src={avatar_url}
                                    className="h-[30px] w-[30px] cursor-pointer rounded-full"
                                    border
                                    borderClassName="h-[30px] w-[30px]"
                                />
                            </div>
                        </Dropdown>
                        <div className={clsx("rounded-[12px]", wrapperCmtClassName)}>
                            <div className="flex items-center gap-1">
                                <h6 className={clsx(titleClassName, !is_active && "text-[#a8a8a8] line-through")}>
                                    {username}
                                </h6>
                                {event_info && (
                                    <ConfirmStatus
                                        status={event_info.confirm_status}
                                        title={event_info.confirm_reason}
                                    />
                                )}
                                {time && (
                                    <Tooltip
                                        title={moment(time).format("LLLL")}
                                        className="ml-1 flex items-center gap-x-1"
                                        color={colorTooltip}
                                    >
                                        <FieldTimeOutlined />
                                        <p className="text-[0.8rem] text-[#74788d] dark:text-[#bdc8f0]">
                                            {moment(time).fromNow()}
                                        </p>
                                    </Tooltip>
                                )}
                            </div>
                            {children}
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center gap-1">
                        <Dropdown
                            placement="bottomLeft"
                            overlay={() => (
                                <WrapperProfile
                                    showAvatar
                                    {...profile}
                                    wrapperClassNameItemInfo="pt-[2px] px-[0] pb-[2px]"
                                    className="rounded-[0.25rem] bg-white p-4 text-[#495057] shadow-2xl dark:bg-[#32394e]"
                                />
                            )}
                        >
                            <div className="flex items-center gap-1">
                                <div className={clsx("flex", imageClassName)}>
                                    <Image
                                        src={avatar_url}
                                        className="h-[30px] w-[30px] cursor-pointer rounded-full"
                                        border
                                        borderClassName="h-[30px] w-[30px]"
                                    />
                                </div>
                                <div className={clsx("rounded-[12px]", wrapperCmtClassName)}>
                                    <div className="flex items-center gap-1">
                                        <h6
                                            className={clsx(
                                                titleClassName,
                                                !is_active && "text-[#a8a8a8] line-through"
                                            )}
                                        >
                                            {username}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </Dropdown>
                        {event_info && (
                            <ConfirmStatus status={event_info.confirm_status} title={event_info.confirm_reason} />
                        )}
                        {time && (
                            <Tooltip
                                title={moment(time).format("LLLL")}
                                className="ml-1 flex items-center gap-x-1"
                                color={colorTooltip}
                            >
                                <FieldTimeOutlined />
                                <p className="text-[0.8rem] text-[#74788d] dark:text-[#bdc8f0]">
                                    {moment(time).fromNow()}
                                </p>
                            </Tooltip>
                        )}
                    </div>
                )}
                {eventId && countBusy > 0 && (
                    <Dropdown
                        className="flex h-[40px] cursor-pointer items-center gap-1"
                        overlay={() => (
                            <div className="relative flex flex-col space-y-2 rounded-[0.25rem] bg-white p-4 text-[#495057] shadow-dropdown-menu dark:border-[#11192a] dark:bg-[#11192a] dark:text-slate-200">
                                <div className="flex justify-center gap-5">
                                    <NoteBusy label="Leave" className="bg-[#dc3545]" />
                                    <NoteBusy label="Biz" className="bg-[#0d6efd]" />
                                    <NoteBusy label="Event" className="bg-[#03942c]" />
                                </div>
                                {resultBusy?.length > 0 &&
                                    resultBusy &&
                                    resultBusy?.map((item, index) => (
                                        <div className="flex items-center gap-2" key={index.toString()}>
                                            <a
                                                href={`${urlWebsite.EVENTS}/${item.uid}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <WrapperTag
                                                    bgColor="success"
                                                    title={`#${item.id}`}
                                                    rounded={false}
                                                    className="text-white underline"
                                                />
                                            </a>
                                            <p className="flex-1 text-[0.75rem] sm:text-[.938rem]">{item.name}</p>
                                        </div>
                                    ))}

                                {leaves &&
                                    leaves?.map((item, index) => (
                                        <div key={index.toString()} className="flex items-center gap-2">
                                            <WrapperTag
                                                bgColor="danger"
                                                title={`#${item.id}`}
                                                rounded={false}
                                                onClick={() => {
                                                    toggleModal({
                                                        type: ModalTypeEnum.MODAL_ACCOUNT_BUSY,
                                                        title: t("Leave {{value}}", { value: `#${item.id}` }),
                                                        data: {
                                                            data: item,
                                                            type: "LEAVE",
                                                        },
                                                        modal: {
                                                            width: 500,
                                                        },
                                                    });
                                                }}
                                            />
                                            <p className="flex-1 text-[0.75rem] sm:text-[.938rem]">{item.name}</p>
                                        </div>
                                    ))}

                                {resultBusinessTrips &&
                                    resultBusinessTrips?.map((item, index) => (
                                        <div key={index.toString()} className="flex items-center gap-2">
                                            <WrapperTag
                                                onClick={() => {
                                                    toggleModal({
                                                        type: ModalTypeEnum.MODAL_ACCOUNT_BUSY,
                                                        title: t("Biz {{value}}", { value: `#${item.id}` }),
                                                        data: {
                                                            data: item,
                                                            type: "BIZ",
                                                        },
                                                        modal: {
                                                            width: 700,
                                                        },
                                                    });
                                                }}
                                                bgColor="events"
                                                title={`#${item.id}`}
                                                rounded={false}
                                            />
                                            <p className="flex-1 text-[0.75rem] sm:text-[.938rem]">{item.name}</p>
                                        </div>
                                    ))}
                            </div>
                        )}
                        placement="bottom"
                    >
                        <div className="flex items-start">
                            <Badge count={countBusy} color="#ff7b38" offset={[3, 0]} size="small">
                                <FaSuitcase className="text-[0.9rem] text-[#ff7b38]" />
                            </Badge>
                        </div>
                    </Dropdown>
                )}
            </div>
        </div>
    );
};

const NoteBusy = ({ label, className }) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center gap-[6px]">
            <div className={clsx("h-2 w-2 rounded-full", className)} />
            <h3>{t(label)}</h3>
        </div>
    );
};
