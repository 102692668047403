import * as React from "react";
import { Modal } from "antd";

import { useModal, useTypeProject } from "hooks";
import { BiLinkExternal } from "react-icons/bi";
import { VscClose } from "react-icons/vsc";

import {
    ModalAddEvent,
    ModalAddQuotationDiscount,
    ModalAddSalesLead,
    ModalAddSkuQuotation,
    ModalBusinessTripsEvent,
    ModalChangePassword,
    ModalCheckAvailableEvent,
    ModalConfirmResource,
    ModalDeleteEvent,
    ModalDetailEvent,
    ModalEventConfirmLater,
    ModalHappyBirthday,
    ModalProductSalesCodeBook,
    ModalProductsSalesEdit,
    ModalQuickCreateEvent,
    ModalQuotationDelete,
    ModalQuotationReview,
    ModalSalesLeadsDetail,
    ModalSalesProductsDetail,
    ModalSettingAccount,
    ModalEditEvent,
    ModalAccountBusy,
    ModalDetailKpiPoint,
    ModalResourceList,
    ModalAmbassador,
    ModalEditAddAmbassador,
    ModalTreeKnowLedge,
    ModalKnowLedgeDetail,
} from "./components";

import { ModalTypeEnum, urlWebsite } from "constant";
import { LinkHref } from "customize";
import { IconAddEvent } from "layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const checkModal = (modalType) => {
    switch (modalType) {
        case ModalTypeEnum.HAPPY_BIRTHDAY:
            return ModalHappyBirthday;
        case ModalTypeEnum.EVENT_CONFIRM_LATER:
            return ModalEventConfirmLater;
        case ModalTypeEnum.DELETE_EVENT:
            return ModalDeleteEvent;
        case ModalTypeEnum.BUSINESS_TRIPS_EVENT:
            return ModalBusinessTripsEvent;
        case ModalTypeEnum.SALES_PRODUCTS_DETAIL:
            return ModalSalesProductsDetail;
        case ModalTypeEnum.CHECK_AVAILABLE_EVENTS:
            return ModalCheckAvailableEvent;
        case ModalTypeEnum.EDIT_PRODUCTS_COST_SALES:
            return ModalProductsSalesEdit;
        case ModalTypeEnum.PRODUCTS_SALES_CODE_BOOK:
            return ModalProductSalesCodeBook;
        case ModalTypeEnum.ADD_SALES_LEAD:
            return ModalAddSalesLead;
        case ModalTypeEnum.SALES_LEADS_DETAIL:
            return ModalSalesLeadsDetail;
        case ModalTypeEnum.ADD_SKU_QUOTATION:
            return ModalAddSkuQuotation;
        case ModalTypeEnum.ADD_QUOTATION_DISCOUNT:
            return ModalAddQuotationDiscount;
        case ModalTypeEnum.CHANGE_PASSWORD:
            return ModalChangePassword;
        case ModalTypeEnum.MODAL_CONFIRM:
            return ModalQuotationDelete;
        case ModalTypeEnum.REVIEW_QUOTATION:
            return ModalQuotationReview;
        case ModalTypeEnum.QUICK_CREATE_EVENT:
            return ModalQuickCreateEvent;
        case ModalTypeEnum.SETTING_ACCOUNT:
            return ModalSettingAccount;
        case ModalTypeEnum.DETAIL_EVENT:
            return ModalDetailEvent;
        case ModalTypeEnum.CONFIRM_RESOURCE:
            return ModalConfirmResource;
        case ModalTypeEnum.ADD_EVENT:
            return ModalAddEvent;
        case ModalTypeEnum.EDIT_EVENT:
            return ModalEditEvent;
        case ModalTypeEnum.MODAL_ACCOUNT_BUSY:
            return ModalAccountBusy;
        case ModalTypeEnum.MODAL_DETAIL_KPI_POINT:
            return ModalDetailKpiPoint;
        case ModalTypeEnum.MODAL_RESOURCE_LIST:
            return ModalResourceList;
        case ModalTypeEnum.MODAL_AMBASSADOR:
            return ModalAmbassador;
        case ModalTypeEnum.MODAL_ADD_AMBASSADOR:
            return ModalEditAddAmbassador;
        case ModalTypeEnum.MODAL_TREE_KNOWLEDGE:
            return ModalTreeKnowLedge;
        case ModalTypeEnum.MODAL_KNOWLEDGE_DETAIL:
            return ModalKnowLedgeDetail;
        default:
            return null;
    }
};

export const ModalComponent = () => {
    const { resultModal, ModalTypeEnum, toggleModal } = useModal();
    const { type, open, title, data, modal } = resultModal;
    const WrapperModal = React.useMemo(() => checkModal(type), [type]);

    React.useEffect(() => {
        const handleKeyDown = () => {
            document.addEventListener("keydown", (e) => {
                if (e.code === "Escape") {
                    toggleModal({
                        type: ModalTypeEnum.NULL,
                    });
                }
            });
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, []);

    if (!WrapperModal) return null;

    return (
        <ContainerModal open={open} data={data} title={title} modal={modal}>
            <WrapperModal {...data} />
        </ContainerModal>
    );
};

export const ContainerModal = ({ children, open, data, title, modal, closeDoubleModal }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const { toggleModal } = useModal();
    const { isAppMobile } = useTypeProject();

    return (
        <Modal
            keyboard={false}
            visible={open}
            centered
            title={
                <div className="flex items-center justify-between">
                    <h5 className="text-[1.2rem] font-semibold dark:text-white">{t(title)}</h5>
                    {data?.targetLink && !isAppMobile && (
                        <div className="mr-14 flex items-center text-[.75rem]">
                            <LinkHref
                                outWeb
                                onClick={() =>
                                    toggleModal({
                                        type: null,
                                    })
                                }
                                title="Open in new tab"
                                path={`${urlWebsite.EVENTS}/${data._uid}`}
                                className=" flex items-center gap-2 text-[#03942c] hover:text-[#03942c] hover:underline"
                            >
                                <BiLinkExternal />
                            </LinkHref>
                        </div>
                    )}
                </div>
            }
            onCancel={data?.cancel ? () => {} : closeDoubleModal ? closeDoubleModal : toggleModal}
            footer={null}
            {...modal}
            bodyStyle={{ padding: "0" }}
            closeIcon={
                <div className="flex h-[3.125rem] w-[3.125rem] items-center justify-center">
                    <IconAddEvent
                        title={t("Press Esc to close")}
                        Icon={VscClose}
                        className="hover:bg-[#6c757d] hover:text-white dark:hover:bg-[#6c757d]"
                        iconClassName=" hover:text-white"
                        onClick={() =>
                            closeDoubleModal
                                ? closeDoubleModal
                                : (toggleModal({
                                      type: null,
                                  }),
                                  pathname === urlWebsite.EVENTS && navigate(urlWebsite.EVENTS))
                        }
                    />
                </div>
            }
        >
            <div className="p-4 sm:p-6">{children}</div>
        </Modal>
    );
};
