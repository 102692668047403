import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { BsSearch } from "react-icons/bs";
import { useContextControllers } from "hooks";

export const InputSearch = ({ className, keyword, loading, onChangeKeyword, clearKeywords }) => {
    const { settingAccount } = useContextControllers();
    const { t } = useTranslation();
    return (
        <div className={className}>
            <div
                className={clsx(
                    "flex w-full items-center rounded-[0.25rem]",
                    settingAccount.horizontal
                        ? "bg-[#2e3446]"
                        : "border-[#ecf0f4] bg-[#ecf0f4] dark:border-[#2e3446] dark:bg-[#2e3446]"
                )}
            >
                <div className="ml-4">
                    {loading ? (
                        <div>
                            <LoadingOutlined className="text-[0.813rem] font-bold text-[#737579] dark:text-slate-200" />
                        </div>
                    ) : (
                        <div>
                            <BsSearch className="text-[0.813rem] font-bold text-[#737579] dark:text-slate-200" />
                        </div>
                    )}
                </div>
                <input
                    className="w-full bg-transparent py-[10px] px-[12px] text-sm font-normal text-[#737579] placeholder:text-xs placeholder:font-normal focus:outline-none dark:text-slate-200"
                    placeholder={`${t("Search")}...`}
                    onChange={onChangeKeyword}
                    value={keyword}
                />
                {keyword && (
                    <div className="relative bottom-[3px] mr-2 cursor-pointer" onClick={() => clearKeywords()}>
                        <CloseCircleOutlined className="text-[0.813rem] font-bold text-[#737579] dark:text-slate-200" />
                    </div>
                )}
            </div>
        </div>
    );
};
