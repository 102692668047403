import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { listTranslations, storeLocalStorage } from "constant";
import { Image } from "customize";
import { useCheckLanguage, useContextControllers } from "hooks";

export const DropdownTranslate = ({ is_page_login }) => {
    const { i18n } = useTranslation();
    const { checkTranslation } = useCheckLanguage();
    const { translate, setTranslate, settingAccount } = useContextControllers();

    const handleChangeLanguage = (language) => {
        localStorage.setItem(storeLocalStorage.LANGUAGE, language);
        setTranslate({ ...translate, language: language, locale: checkTranslation(language) });
        i18n.changeLanguage(language);
    };

    // const ListCountry = () => {
    //     return (
    //         <div className="relative top-[11px] rounded-[0.25rem] border border-white bg-white py-2 text-[#495057] shadow-dropdown-menu dark:border-[#32394e] dark:bg-[#262b3c] dark:text-slate-200">
    //             <div className="w-[160px]">
    //                 {listTranslations.map((transition) => (
    //                     <div
    //                         onClick={() => handleChangeLanguage(transition.id)}
    //                         key={transition.id}
    //                         className="align-center flex cursor-pointer items-center gap-2 py-[6px] px-6 hover:bg-[#d8dadc] dark:hover:bg-[#1c1f2b]"
    //                     >
    //                         <Image src={transition.icon} className="h-5 w-5" />
    //                         <h6 className="text-[#555b6d] dark:text-slate-200">{transition.name}</h6>
    //                     </div>
    //                 ))}
    //             </div>
    //         </div>
    //     );

    return (
        <div className="flex items-center">
            {listTranslations.map(
                (transition) =>
                    transition.id !== translate.language && (
                        <div
                            key={transition.id}
                            className="flex cursor-pointer items-center gap-2"
                            onClick={() => handleChangeLanguage(transition.id)}
                        >
                            <Image src={transition.icon} className="h-7 w-7" wrapperClassName="top-1" />
                            <h6
                                className={clsx(
                                    is_page_login && !settingAccount.horizontal && "text-white",
                                    is_page_login && settingAccount.horizontal && "text-white",
                                    !is_page_login &&
                                        !settingAccount.horizontal &&
                                        "text-[#555b6d] dark:text-slate-200",
                                    !is_page_login && settingAccount.horizontal && "text-slate-200"
                                )}
                            >
                                <span>{transition.name}</span>
                            </h6>
                        </div>
                    )
            )}
        </div>

        // <Dropdown overlay={ListCountry} placement="bottom">
        //     <div className="flex items-center">
        //         {listTranslations.map(
        //             (transition) =>
        //                 transition.id === translate.language && (
        //                     <div key={transition.id} className="flex cursor-pointer items-center gap-2">
        //                         <Image src={transition.icon} className="h-7 w-7" wrapperClassName="top-1" />
        //                         <h6
        //                             className={clsx(
        //                                 is_page_login && !settingAccount.horizontal && "text-white",
        //                                 is_page_login && settingAccount.horizontal && "text-white",
        //                                 !is_page_login &&
        //                                     !settingAccount.horizontal &&
        //                                     "text-[#555b6d] dark:text-slate-200",
        //                                 !is_page_login && settingAccount.horizontal && "text-slate-200"
        //                             )}
        //                         >
        //                             <span>{transition.name}</span>
        //                         </h6>
        //                     </div>
        //                 )
        //         )}
        //         <RiArrowDropDownLine
        //             className={clsx(
        //                 "text-[1.25rem]",
        //                 is_page_login && !settingAccount.horizontal && "text-white",
        //                 is_page_login && settingAccount.horizontal && "text-white",
        //                 !is_page_login && !settingAccount.horizontal && "text-[#555b6d] dark:text-slate-200",
        //                 !is_page_login && settingAccount.horizontal && "text-slate-200"
        //             )}
        //         />
        //     </div>
        // </Dropdown>
    );
};
