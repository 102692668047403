import * as React from "react";

import { useAccount, useCheckLanguage } from "hooks";
import { storeLocalStorage, TYPE_PROJECT } from "constant";
const UserContext = React.createContext(null);
const settingAccountLocal = JSON.parse(localStorage.getItem(storeLocalStorage.SETTING_ACCOUNT)) || {
    isCalendar: true,
    theme: "dark",
    is_modal_event: true,
    horizontal: TYPE_PROJECT === "APP" ? false : true,
    sub_menu: false,
};
const dropdown = JSON.parse(localStorage.getItem(storeLocalStorage.DROP_DOWN_MENU)) || { open: false };
const defaultVersion = "1.11.7";
const version = localStorage.getItem(storeLocalStorage.VERSION);

const stylesheets = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const createStylesheetLink = () => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.id = "antd-stylesheet";
    document.head.appendChild(link);
    return link;
};
const getStylesheetLink = () => document.head.querySelector("#antd-stylesheet") || createStylesheetLink();
const UseContextProvider = ({ children }) => {
    const { checkTranslation, checkLanguageByBrowser } = useCheckLanguage();
    const localCountry = localStorage.getItem(storeLocalStorage.LANGUAGE) || checkLanguageByBrowser;
    const [settingAccount, setSettingAccount] = React.useState(settingAccountLocal);

    const { theme } = settingAccount;

    const { handleGetInfoUser } = useAccount();
    const { access_token, handleGetProfileUSer, profile } = useAccount();

    const [openSearch, setOpenSearch] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [dropDownMenu, setDropDownMenu] = React.useState(dropdown.open);
    const [uidEvent, setUidEvent] = React.useState(null);
    const [copyEvent, setCopyEvent] = React.useState(null);

    const [statusEvent, setStatusEvent] = React.useState({
        uid: null,
        status: null,
    });

    const [translate, setTranslate] = React.useState({
        language: localCountry,
        locale: checkTranslation(localCountry),
    });

    React.useLayoutEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
            document.documentElement.classList.remove("light");
        } else {
            document.documentElement.classList.add("light");
            document.documentElement.classList.remove("dark");
        }
        getStylesheetLink().href = stylesheets[theme];
    }, [theme]);

    React.useEffect(() => {
        handleGetInfoUser();
    }, [handleGetInfoUser]);

    React.useLayoutEffect(() => {
        if (version !== defaultVersion) {
            localStorage.setItem(storeLocalStorage.VERSION, defaultVersion);
            window.location.reload();
        }
    }, []);

    React.useEffect(() => {
        if (!profile) {
            handleGetProfileUSer();
        }
    }, [handleGetProfileUSer, profile]);

    React.useEffect(() => {
        if (openMenu) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        if (!access_token) document.body.style.overflow = "auto";
    }, [openMenu, access_token]);

    React.useLayoutEffect(() => {
        if (!access_token) {
            document.body.style.backgroundColor = "#fff";
        } else {
            document.body.style.backgroundColor = theme === "dark" ? "#222736" : "#ecf0f4";
        }
    }, [access_token, theme]);

    const state = {
        isSearch: [openSearch, setOpenSearch],
        isMenu: [openMenu, setOpenMenu],
        translate: [translate, setTranslate],
        dropDownMenu: [dropDownMenu, setDropDownMenu],
        settingAccount: [settingAccount, setSettingAccount],
        uidEvent: [uidEvent, setUidEvent],
        statusEvent: [statusEvent, setStatusEvent],
        copyEvent: [copyEvent, setCopyEvent],
    };

    return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};
export { UserContext, UseContextProvider };
