import viVN from "antd/lib/locale/vi_VN";
import enUS from "antd/lib/locale/en_US";
const language = navigator.language;

export const useCheckLanguage = () => {
    const checkTranslation = (language) => {
        switch (language) {
            case "vi-VN":
                return viVN;
            case "en-US":
                return enUS;
            default:
                return viVN;
        }
    };

    const checkLanguageByBrowser = [language].filter((lg) => lg.includes("vi"));

    return { checkTranslation, checkLanguageByBrowser: checkLanguageByBrowser.length > 0 ? "vi-VN" : "en-US" };
};
