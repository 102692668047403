import {
    onGetTypeEventList,
    onGetResourceList,
    onPostCreateEvent,
    onPutUpdateEvent,
    onCheckAvailableResource,
    onPatchConfirmEventMeetingFast,
    onPostStartZoomUrl,
    onGetMeetingNext,
    onGetMeetingNextToday,
    onGetMeetingNextTomorrow,
    onGetMeetingNextThisWeek,
    onGetMeetingNextNextWeek,
} from "./path-api";

export const extraReducers = {
    // get type event list
    [onGetResourceList.fulfilled]: (state, action) => {
        state.dataResourceList = action.payload;
    },
    // get type event list
    [onGetTypeEventList.fulfilled]: (state, action) => {
        state.dataTypesList = action.payload;
    },
    // create a new event
    [onPostCreateEvent.pending]: (state) => {
        state.loadingCreateEvent = true;
    },
    [onPostCreateEvent.fulfilled]: (state) => {
        state.loadingCreateEvent = false;
    },
    [onPostCreateEvent.rejected]: (state) => {
        state.loadingCreateEvent = false;
    },
    // update event
    [onPutUpdateEvent.pending]: (state) => {
        state.loadingCreateEvent = true;
    },
    [onPutUpdateEvent.fulfilled]: (state) => {
        state.loadingCreateEvent = false;
    },
    [onPutUpdateEvent.rejected]: (state) => {
        state.loadingCreateEvent = false;
    },
    // check Available Resource
    [onCheckAvailableResource.fulfilled]: (state, action) => {
        state.dataCheckAvailableResource = action.payload;
    },
    // get meeting next
    [onGetMeetingNext.pending]: (state) => {
        state.loadingDataMeetingNext = true;
    },
    [onGetMeetingNext.fulfilled]: (state, action) => {
        state.dataMeetingNext = [].concat(action.payload).reverse();
        state.loadingDataMeetingNext = false;
    },
    [onGetMeetingNextToday.pending]: (state) => {
        state.loadingDataMeetingNext = true;
    },
    [onGetMeetingNextToday.fulfilled]: (state, action) => {
        state.dataMeetingNext = [].concat(action.payload).reverse();
        state.loadingDataMeetingNext = false;
    },
    [onGetMeetingNextTomorrow.pending]: (state) => {
        state.loadingDataMeetingNext = true;
    },
    [onGetMeetingNextTomorrow.fulfilled]: (state, action) => {
        state.dataMeetingNext = [].concat(action.payload).reverse();
        state.loadingDataMeetingNext = false;
    },
    [onGetMeetingNextThisWeek.pending]: (state) => {
        state.loadingDataMeetingNext = true;
    },
    [onGetMeetingNextThisWeek.fulfilled]: (state, action) => {
        state.dataMeetingNext = [].concat(action.payload).reverse();
        state.loadingDataMeetingNext = false;
    },
    [onGetMeetingNextNextWeek.pending]: (state) => {
        state.loadingDataMeetingNext = true;
    },
    [onGetMeetingNextNextWeek.fulfilled]: (state, action) => {
        state.dataMeetingNext = [].concat(action.payload).reverse();
        state.loadingDataMeetingNext = false;
    },
    // confirm event

    [onPatchConfirmEventMeetingFast.fulfilled]: (state, action) => {
        const { event_id, confirm_type } = action.payload;
        const { dataMeetingNext } = state;
        const index = dataMeetingNext.findIndex(({ id }) => Number(id) === Number(event_id));
        if (index !== -1) {
            if (confirm_type === "yes") {
                dataMeetingNext[index].confirm_status = "1";
            } else {
                dataMeetingNext[index].confirm_status = "2";
            }
        }
    },
    [onPostStartZoomUrl.pending]: (state) => {
        state.loadingStartZoom = true;
    },
    [onPostStartZoomUrl.fulfilled]: (state, action) => {
        state.loadingStartZoom = false;
        const { start_url } = action.payload;
        window.open(start_url);
    },
    [onPostStartZoomUrl.rejected]: (state) => {
        state.loadingStartZoom = false;
    },
};
