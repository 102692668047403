import moment from "moment";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { colorTooltip } from "constant";

export const ModalSalesLeadsDetail = ({
    display_name,
    create_date,
    date_open,
    date_closed,
    partner_id,
    partner_name,
    mobile,
    create_uid,
    company_id,
    team_id,
}) => {
    return (
        <div className="grid gap-2">
            <WrapperInfoItem>
                <InfoItem title="Name lead" value={display_name} />
                <InfoItem
                    title="Date create"
                    value={
                        <Tooltip title={moment(create_date).format("DD/MM/YYYY")} color={colorTooltip}>
                            {moment(create_date).fromNow()}
                        </Tooltip>
                    }
                />
                <InfoItem
                    title="Date open"
                    value={
                        <Tooltip title={moment(date_open).fromNow()} color={colorTooltip}>
                            {moment(date_open).format("DD/MM/YYYY")}
                        </Tooltip>
                    }
                />
                {date_closed && (
                    <InfoItem
                        title="Date closed"
                        value={
                            <Tooltip title={moment(date_closed).fromNow()} color={colorTooltip}>
                                {moment(date_open).format("DD/MM/YYYY")}
                            </Tooltip>
                        }
                    />
                )}
            </WrapperInfoItem>
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                <WrapperInfoItem>
                    <InfoItem title="ID" value={partner_id} />
                    <InfoItem title="Passenger's name" value={partner_name} />
                    <InfoItem title="Phone" value={mobile} />
                    <InfoItem title="Address" value={partner_name} />
                </WrapperInfoItem>
                <WrapperInfoItem>
                    <InfoItem title="Creator" value={create_uid ? `[${create_uid[0]}] ${create_uid[1]}` : ""} />
                    <InfoItem title="Company" value={company_id ? `[${company_id[0]}] ${company_id[1]}` : ""} />
                    <InfoItem title="Sales team" value={team_id ? `${team_id[1]}` : ""} />
                </WrapperInfoItem>
            </div>
        </div>
    );
};

const InfoItem = ({ title, value }) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center justify-between p-1 hover:bg-white dark:hover:bg-[#1c1f2b]">
            <h1 className="font-semibold dark:text-slate-200">{t(title)}:</h1>
            <h6 className="flex-1 text-right">{value}</h6>
        </div>
    );
};

const WrapperInfoItem = ({ children }) => {
    return (
        <div className="divide-y divide-[#eaeaea] rounded bg-[#eff2f7] p-2 dark:divide-[#424b64] dark:bg-[#262b3c]">
            {children}
        </div>
    );
};
