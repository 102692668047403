import { axiosClient } from "./axios-client";

export const apiSearch = {
    getSearchKnowLedges: (keyword) => {
        const url = `/knowledges/search/${keyword}`;
        return axiosClient.get(url);
    },
    getDetailKnowLedges: (_id) => {
        const url = `/knowledges/pages/detail/${_id}`;
        return axiosClient.get(url);
    },
};
