import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiEvent } from "api";

export const onGetEventInMonth = createAsyncThunk("onGetEventInMonth", async (data) => {
    const response = await apiEvent.getEventInMonth(data);
    return response;
});

export const onGetEventDetailByUid = createAsyncThunk("onGetEventDetailByUid", async (id) => {
    const response = await apiEvent.getEventDetailByUid(id);
    return response;
});

export const onGetEventComments = createAsyncThunk("onGetEventComments", async (id) => {
    const response = await apiEvent.getEventComments(id);
    return response;
});

export const onPostCreateEventNote = createAsyncThunk("onPostCreateEventNote", async (id) => {
    const response = await apiEvent.postCreateEventNote(id);
    return response;
});

export const onPostEventComment = createAsyncThunk("onPostEventComment", async (data) => {
    const response = await apiEvent.postEventComment(data);
    return response;
});

export const onDeleteEvent = createAsyncThunk("onDeleteEvent", async (id) => {
    const response = await apiEvent.deleteEvent(id);
    return response;
});

export const onPatchConfirmEvent = createAsyncThunk("onPatchConfirmEvent", async (payload) => {
    const response = await apiEvent.patchConfirmEvent(payload);
    return response;
});

export const onGetTypeEventList = createAsyncThunk("onGetTypeEventList", async () => {
    const response = await apiEvent.getTypeEventList();
    return response;
});

export const onGetResourceList = createAsyncThunk("onGetResourceList", async () => {
    const response = await apiEvent.getResourceList();
    return response;
});

export const onPostCreateEvent = createAsyncThunk("onPostCreateEvent", async (payload) => {
    const response = await apiEvent.postCreateEvent(payload);
    return response;
});

export const onPutUpdateEvent = createAsyncThunk("onPutUpdateEvent", async (data) => {
    const response = await apiEvent.putUpdateEvent(data);
    return response;
});

export const onCheckAvailableResource = createAsyncThunk("onCheckAvailableResource", async (payload) => {
    const response = await apiEvent.checkAvailableResource(payload);
    return response;
});

export const onGetMeetingNext = createAsyncThunk("onGetMeetingNext", async () => {
    const response = await apiEvent.getMeetingNext();
    return response;
});

export const onPatchConfirmEventMeetingFast = createAsyncThunk("onPatchConfirmEventMeetingFast", async (payload) => {
    const response = await apiEvent.patchConfirmEvent(payload);
    return response;
});

export const onPostStartZoomUrl = createAsyncThunk("onPostStartZoomUrl", async (eventId) => {
    const response = await apiEvent.postStartZoomUrl(eventId);
    return response;
});

export const onGetMeetingNextToday = createAsyncThunk("onGetMeetingNextToday", async () => {
    const response = await apiEvent.getMeetingNextToday();
    return response;
});

export const onGetMeetingNextTomorrow = createAsyncThunk("getMeetingNextTomorrow", async () => {
    const response = await apiEvent.getMeetingNextTomorrow();
    return response;
});

export const onGetMeetingNextThisWeek = createAsyncThunk("getMeetingNextThisWeek", async () => {
    const response = await apiEvent.getMeetingNextThisWeek();
    return response;
});

export const onGetMeetingNextNextWeek = createAsyncThunk("getMeetingNextNextWeek", async () => {
    const response = await apiEvent.getMeetingNextNextWeek();
    return response;
});

export const onPostRegisterEvent = createAsyncThunk("onPostRegisterEvent", async (payload) => {
    const response = await apiEvent.postRegisterEvent(payload);
    return response;
});
