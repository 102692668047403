import * as React from "react";
import { Upload, message, notification } from "antd";
import ImgCrop from "antd-img-crop";
import { useTranslation } from "react-i18next";

import { useUser } from "hooks";
import { CameraOutlined } from "@ant-design/icons";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const UploadAvatar = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);

    const { handleChangeAvatar } = useUser();

    const beforeUpload = async (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error(t("You can only upload JPG/PNG/JPEG files!"));
        }
        const isLt3M = file.size / 1024 / 1024 < 3;
        if (!isLt3M) {
            message.error(t("Image must be less than 3MB !"));
        }
        if (isLt3M && isJpgOrPng) {
            const imageData = new FormData();
            imageData.append("file", file);
            setLoading(true);
            const resultAvatar = await handleChangeAvatar(imageData);
            if (resultAvatar?.error) {
                notification.error({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("An error has occurred")}</p>,
                });
            }
            if (resultAvatar?.payload) {
                setLoading(false);
                notification.success({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("Successfully updated profile picture")}</p>,
                });
            }
        }
        return isJpgOrPng && isLt3M;
    };
    return (
        <div className="absolute right-0 bottom-0">
            {loading ? (
                <div className="flex h-[30px] w-[30px] animate-spin items-center justify-center rounded-full bg-[#e4e6eb] dark:bg-[#32394e]">
                    <AiOutlineLoading3Quarters />
                </div>
            ) : (
                <ImgCrop modalOk={t("Update")} modalCancel={t("Cancel")} rotate modalTitle={t("Update avatar")}>
                    <Upload
                        beforeUpload={beforeUpload}
                        fileList={[]}
                        name="file"
                        accept=".jpg, .jpeg, .png"
                        listType="listTyp"
                    >
                        <CameraOutlined
                            style={{ display: "flex" }}
                            className="h-[30px] w-[30px] items-center justify-center rounded-full bg-[#e4e6eb] dark:bg-[#32394e]"
                        />
                    </Upload>
                </ImgCrop>
            )}
        </div>
    );
};
