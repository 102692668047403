import * as React from "react";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "customize";
import { useModal, useSales } from "hooks";
import { urlWebsite } from "constant";
import { HiCheck } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

export const ModalQuotationDelete = ({ _id, goBack }) => {
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { toggleModal, ModalTypeEnum } = useModal();
    const { handleDeleteQuotation } = useSales();

    const onClickEvent = async () => {
        setLoading(true);
        const result = await handleDeleteQuotation(_id);

        if (result?.error) {
            setLoading(false);
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("An error has occurred")}</p>,
            });
        }
        if (result?.payload) {
            setLoading(false);
            notification.success({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("Delete quotation successfully")}</p>,
            });
            toggleModal({
                type: ModalTypeEnum.NULL,
            });
            if (goBack) {
                navigate(urlWebsite.SALES_QUOTATION, { replace: true });
            }
        }
    };

    return (
        <div className="grid grid-cols-2 justify-between gap-x-6">
            <Button label="Yes" Icon={HiCheck} BgDanger loading={loading} onClick={onClickEvent} />
            <Button
                label="Cancel"
                BgSecondary
                onClick={() => {
                    toggleModal({
                        type: ModalTypeEnum.NULL,
                    });
                }}
                disabled={loading}
                Icon={AiOutlineClose}
            />
        </div>
    );
};
