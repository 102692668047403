import * as React from "react";
import { useAccount } from "hooks";

import { Image } from "customize";
import { WrapperProfile } from "layout";
import { imagHappyBirthday } from "assets/images";

export const ModalHappyBirthday = ({ profile, username }) => {
    const { handleGetProfileIcms, profileIcms } = useAccount();

    React.useEffect(() => {
        handleGetProfileIcms(username);
    }, [handleGetProfileIcms, username]);
    if (!profileIcms)
        return (
            <div className="grid animate-pulse place-items-center gap-4 md:flex md:place-items-start">
                <div className="h-[400px] w-full max-w-[350px] rounded bg-slate-300 dark:bg-slate-500" />
                <div className="grid w-full gap-y-6">
                    {[...new Array(10)].map((_, i) => (
                        <div className="col-span-2 h-4 max-w-[400px] rounded bg-slate-300 dark:bg-slate-500" key={i} />
                    ))}
                </div>
            </div>
        );

    return (
        <div className="flex flex-col place-items-center gap-4 md:flex-row">
            <div className="relative">
                <div className="m-auto w-full max-w-[240px] rounded p-4 text-center shadow-xl dark:shadow-none">
                    <Image
                        src={profileIcms.avatar_url}
                        className="m-auto block h-[200px] w-[200px] rounded-lg border-4 border-[#f8f8fb] shadow-lg dark:border-0 dark:shadow-none"
                    />
                </div>
                {!profile && (
                    <div className="absolute bottom-0 m-auto w-full max-w-[240px]">
                        <Image src={imagHappyBirthday} className="relative h-full w-full" />
                    </div>
                )}
            </div>
            <WrapperProfile {...profileIcms} className="w-full" titleClassName="max-w-[140px]" />
        </div>
    );
};
