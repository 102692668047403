import * as React from "react";
import { Form, Input, Select, notification, InputNumber } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MainPage, PageHeader, Section, SelectSearch } from "layout";
import { useProducts, useSales, useTitlePage } from "hooks";
import { Button } from "customize";

import { AvailableProducts, SelectedProducts, LoadingItem } from ".";
import { Loading } from "./loading";

import { urlWebsite } from "constant";
import { VscSaveAs } from "react-icons/vsc";

export const FormAddEditSalesProducts = ({ edit, _uid, dataSalesProductEdit, initialValues, titlePage }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const typingTimeout = React.useRef(0);
    const [keyword, setKeyword] = React.useState("");
    useTitlePage({ title: "Workplace | Product" });

    const {
        handleGetCategoryProduct,
        handleGetUomProduct,
        handleGetMadeInListProduct,
        handleGetWarrantyListProduct,
        handleGetCategorySkuProduct,
        handleSearchProduct,
        handlePostCreateProductSKU,
        handleAddToProductListProduct,
        handleRemoveProductListProduct,
        handleUpdateProductSku,
        handleUpdateQuantityProductListProduct,
        resultStoreProducts,
    } = useProducts();

    const { handleGetQuoteGroupListSales, dataListSales } = useSales();
    const {
        dataCategory,
        dataUom,
        dataMadeIn,
        dataWarranty,
        dataCategorySku,
        dataSearchProducts,
        productListProduct,
        loadingDataSearchProducts,
        loadingCreateSKU,
    } = resultStoreProducts;

    React.useEffect(() => {
        typingTimeout.current = setTimeout(() => {
            if (keyword.trim().length >= 3) {
                handleSearchProduct(keyword.trim());
            }
            if (keyword.trim().length > 0 && keyword.trim().length < 3) {
                notification.error({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("Enter at least 3 characters")}</p>,
                    placement: "topLeft",
                });
            }
        }, 700);
        return () => clearTimeout(typingTimeout.current);
    }, [handleSearchProduct, keyword, t]);

    React.useEffect(() => {
        if (!dataCategory) {
            handleGetCategoryProduct();
        }
        if (!dataUom) {
            handleGetUomProduct();
        }
        if (!dataMadeIn) {
            handleGetMadeInListProduct();
        }
        if (!dataWarranty) {
            handleGetWarrantyListProduct();
        }
        if (!dataCategorySku) {
            handleGetCategorySkuProduct();
        }
        if (!dataListSales) {
            handleGetQuoteGroupListSales();
        }
    }, [
        handleGetCategoryProduct,
        handleGetCategorySkuProduct,
        handleGetMadeInListProduct,
        handleGetQuoteGroupListSales,
        handleGetUomProduct,
        handleGetWarrantyListProduct,
    ]);

    React.useEffect(() => {
        if (edit && dataSalesProductEdit) {
            form.setFieldsValue({
                ...dataSalesProductEdit,
                sku_categ_id: dataSalesProductEdit.sku_categ_id && dataSalesProductEdit.sku_categ_id[0],
                sku_made_in: dataSalesProductEdit.sku_made_in && dataSalesProductEdit.sku_made_in[0],
                uom_id: dataSalesProductEdit.uom_id && dataSalesProductEdit.uom_id[0],
                quote_group_id: dataSalesProductEdit.quote_group_id && dataSalesProductEdit.quote_group_id[0],
            });
        }
    }, [dataSalesProductEdit, edit, form]);

    const onSubmitCreateSKU = async (values) => {
        const payload = {
            name: values.name,
            uom_id: values.uom_id,
            sku_made_in: values.sku_made_in,
            sku_brand_name: values.sku_brand_name,
            sku_categ_id: values.sku_categ_id,
            sku_warranty_duration: values.sku_warranty_duration,
            sku_description: values.sku_description.trim(),
            sku_note: values.sku_note?.trim(),
            product_list: productListProduct.map(({ quantity, product }) => ({ quantity, product_id: product.id })),
            quote_group_id: values.quote_group_id,
            sku_cm_min: values.sku_cm_min,
            sku_cm_max: values.sku_cm_max,
        };
        if (edit) {
            const response = await handleUpdateProductSku({
                id: _uid,
                payload: payload,
            });
            const result = unwrapResult(response);
            if (result) {
                navigate(`${urlWebsite.SALES_PRODUCTS}/${result.id}`);
            }
        } else {
            const resultRequest = await handlePostCreateProductSKU(payload);
            const result = unwrapResult(resultRequest);
            if (result) {
                navigate(`${urlWebsite.SALES_PRODUCTS}/${result.id}`);
            }
        }
    };
    if (edit && !dataSalesProductEdit) return <Loading />;
    return (
        <MainPage
            classNamePageHeader="md:block hidden"
            goBackClassName="md:w-auto w-full justify-end"
            pageHeader={
                <PageHeader
                    sub={[
                        {
                            title: "Sales & CRM",
                        },
                        {
                            title: "Product",
                            url: urlWebsite.SALES_PRODUCTS,
                        },
                        {
                            title: titlePage,
                            font: true,
                        },
                    ]}
                />
            }
        >
            <Section>
                <Form
                    onFinish={onSubmitCreateSKU}
                    layout="vertical"
                    initialValues={initialValues}
                    form={form}
                    scrollToFirstError
                    className="title-semibold"
                >
                    <div className="grid flex-row gap-2 md:flex md:gap-4">
                        <div className="basis-6/12">
                            <Form.Item
                                className="items-input grid"
                                label={t("Name")}
                                name="name"
                                rules={[{ required: true, message: t("Please enter a name!") }]}
                            >
                                <Input placeholder={t("Product's name")} disabled={loadingCreateSKU} />
                            </Form.Item>
                        </div>
                        <div className="basis-3/12">
                            <Form.Item className="items-input grid" label={t("Trademark")} name="sku_brand_name">
                                <Input placeholder={`${t("Enter brand")}...`} disabled={loadingCreateSKU} />
                            </Form.Item>
                        </div>
                        <div className="basis-3/12">
                            {dataUom ? (
                                <SelectSearch
                                    className="items-input grid basis-3/12"
                                    label={t("Unit")}
                                    name="uom_id"
                                    required
                                    placeholder="Select unit of measure"
                                    messageError="Please select the unit of measure!"
                                    disabled={loadingCreateSKU}
                                >
                                    {dataUom.map((item) => (
                                        <Select.Option value={item.value} key={item.value}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </SelectSearch>
                            ) : (
                                <LoadingItem className="grid basis-3/12" title="Unit" />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 sm:flex-row">
                        <div className="basis-6/12">
                            <Form.Item name="sku_cm_min" label="Cm min (%)" className="items-input mt-2 grid">
                                <InputNumber
                                    disabled={loadingCreateSKU}
                                    min={0}
                                    className="w-full"
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                />
                            </Form.Item>
                        </div>
                        <div className="basis-6/12">
                            <Form.Item name="sku_cm_max" label="Cm max (%)" className="items-input mt-2 grid">
                                <InputNumber
                                    disabled={loadingCreateSKU}
                                    min={0}
                                    className="w-full"
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-2 md:flex md:gap-4">
                        {dataListSales ? (
                            <SelectSearch
                                className="items-input mt-2 grid basis-3/12"
                                label="QuoteID"
                                name="quote_group_id"
                                placeholder="Select QuoteID"
                                messageError="Please select QuoteID!"
                                disabled={loadingCreateSKU}
                            >
                                {dataListSales.map((item) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {`[${item.id}] ${item.name}`}
                                    </Select.Option>
                                ))}
                            </SelectSearch>
                        ) : (
                            <LoadingItem className="grid basis-3/12" title="Select QuoteID" />
                        )}
                        {dataMadeIn ? (
                            <SelectSearch
                                className="items-input mt-2 grid basis-3/12"
                                label="Origin"
                                name="sku_made_in"
                                required
                                placeholder="Select origin"
                                messageError="Please select origin!"
                                disabled={loadingCreateSKU}
                            >
                                {dataMadeIn.map((item) => (
                                    <Select.Option value={item.value} key={item.value}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </SelectSearch>
                        ) : (
                            <LoadingItem className="grid basis-3/12" title="Origin" />
                        )}
                        {dataWarranty ? (
                            <SelectSearch
                                className="items-input mt-2 grid basis-3/12"
                                label="Warranty"
                                name="sku_warranty_duration"
                                required
                                placeholder="Choose warranty"
                                messageError="Please choose warranty!"
                                disabled={loadingCreateSKU}
                            >
                                {dataWarranty.map((item) => {
                                    const title = item.name.split(" ");
                                    return (
                                        <Select.Option value={item.value} key={item.value}>
                                            {title[0]} {t(title[1])}
                                        </Select.Option>
                                    );
                                })}
                            </SelectSearch>
                        ) : (
                            <LoadingItem className="grid basis-3/12" title="Warranty" />
                        )}
                        {dataCategorySku ? (
                            <SelectSearch
                                className="items-input mt-2 grid basis-3/12"
                                label="Classify"
                                name="sku_categ_id"
                                required
                                placeholder="Choose a category"
                                messageError="Please choose a category!"
                                disabled={loadingCreateSKU}
                            >
                                {dataCategorySku.map((item) => (
                                    <Select.Option value={item.value} key={item.value}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </SelectSearch>
                        ) : (
                            <LoadingItem className="grid basis-3/12" title="Classify" />
                        )}
                    </div>
                    <Form.Item
                        className="items-input mt-2 grid"
                        name="sku_description"
                        label={t("Description")}
                        rules={[{ required: true, message: t("Enter product description") }]}
                    >
                        <Input.TextArea
                            placeholder={t("Product Description")}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            size="large"
                            allowClear
                            className="text-[0.813rem] placeholder:text-[0.813rem]"
                            disabled={loadingCreateSKU}
                        />
                    </Form.Item>
                    <Form.Item name="sku_note" label={t("Note")} className="items-input mt-2 grid">
                        <Input.TextArea
                            placeholder={t("Internal note information")}
                            autoSize={{ minRows: 1, maxRows: 3 }}
                            size="large"
                            allowClear
                            className="text-[0.813rem] placeholder:text-[0.813rem]"
                            disabled={loadingCreateSKU}
                        />
                    </Form.Item>
                    <div className="relative grid grid-cols-1 gap-4 2xl:grid-cols-2">
                        <AvailableProducts
                            keyword={keyword}
                            data={dataSearchProducts}
                            onSetKeyword={setKeyword}
                            onAddToProductListProduct={handleAddToProductListProduct}
                            loading={loadingDataSearchProducts}
                        />
                        <SelectedProducts
                            data={productListProduct}
                            onRemoveProductListProduct={handleRemoveProductListProduct}
                            onUpdateQuantityProductListProduct={handleUpdateQuantityProductListProduct}
                        />
                        {loadingCreateSKU && (
                            <div className="absolute  bottom-0 h-full w-full cursor-not-allowed bg-[#f5f5f5b8]" />
                        )}
                    </div>
                    <div className="mt-6 flex">
                        <div className="m-auto basis-6/12 lg:basis-2/12">
                            <Button
                                label={edit ? "Update" : "Create new"}
                                loading={loadingCreateSKU}
                                BgSuccess
                                Icon={VscSaveAs}
                            />
                        </div>
                    </div>
                </Form>
            </Section>
        </MainPage>
    );
};
