import { Table } from "antd";
import moment from "moment";

import { ItemTableMobile, WrapperTable, NoData, WrapperTag } from "layout";
import { urlWebsite } from "constant";
import { ViewMore } from "./view-more";
import { TitleTable } from "./wrapper-table";
import clsx from "clsx";
import { useTypeProject } from "hooks";

const itemDisplay = 5;

export const WrapperTasksMe = ({ data, dataFull }) => {
    const { borderClassNameMobile } = useTypeProject();
    return (
        <>
            {/* laptop  >=640px */}
            <div className="hidden md:block">
                <Table
                    locale={{
                        emptyText: <NoData title="No data available yet" noData />,
                    }}
                    columns={columnsTableTaskList}
                    dataSource={data?.slice(0, dataFull ? data?.length : itemDisplay)}
                    pagination={false}
                />
                {!dataFull && data?.length > itemDisplay && (
                    <div className="mt-2 flex justify-end">
                        <ViewMore href={urlWebsite.TASKS_ME} title="More" />
                    </div>
                )}
            </div>
            {/* mobile  <=640px */}
            {data.length > 0 ? (
                <WrapperTable
                    className="block md:hidden"
                    option={
                        !dataFull &&
                        data?.length > itemDisplay && (
                            <div className="mt-2 flex justify-center">
                                <ViewMore href={urlWebsite.TASKS_ME} title="More" />
                            </div>
                        )
                    }
                >
                    {data?.slice(0, dataFull ? data?.length : itemDisplay).map((item) => (
                        <div
                            key={item.id}
                            className={borderClassNameMobile}
                            data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom"
                        >
                            <ItemTableMobile title="Project" dot>
                                <a
                                    className="flex items-center gap-1"
                                    href={item.url}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <WrapperTag
                                        title={item.source}
                                        bgColor={clsx(item.source === "erp" ? "erp" : "danger")}
                                        rounded={false}
                                        className="text-center text-white"
                                        style={{
                                            textTransform: item.source === "erp" ? "uppercase" : "capitalize",
                                        }}
                                    />
                                    <span>{item.project}</span>
                                </a>
                            </ItemTableMobile>
                            <ItemTableMobile
                                title="ID"
                                dot
                                value={item.id}
                                valueClassName="hover:underline font-semibold text-[#007bff] hover:text-[#007bff]"
                                url={item.url}
                                outWeb
                            />
                            <ItemTableMobile title="Content" value={item.name} dot url={item.url} outWeb />
                            <ItemTableMobile
                                title="End time"
                                value={moment(item.end_date, "DD/MM/YYYY").format("DD/MM/YYYY")}
                                dot
                            />
                            <ItemTableMobile
                                title="Delay"
                                value={item.days_late && item.days_late}
                                valueClassName="text-[#cd201f]"
                                dot
                            />
                        </div>
                    ))}
                </WrapperTable>
            ) : (
                <div className="mb-4 block md:hidden">
                    <NoData title="No data available yet" noData />
                </div>
            )}
        </>
    );
};

const columnsTableTaskList = [
    {
        title: <TitleTable title="ID" />,
        dataIndex: "full",
        width: 70,
        render: (_, record) => (
            <a
                href={record.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block rounded text-center font-semibold text-[#007bff] hover:text-[#007bff] hover:underline"
            >
                {record.id}
            </a>
        ),
    },
    {
        title: <TitleTable title="Content" className="text-left" />,
        dataIndex: "full",
        render: (_, record) => (
            <a href={record.url} target="_blank" rel="noopener noreferrer">
                <p className="hover:text-[#007bff] hover:underline dark:hover:text-white"> {record.name}</p>
                <div
                    style={{
                        lineHeight: "initial",
                        position: "relative",
                        bottom: "2px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <WrapperTag
                        title={record.source}
                        bgColor={clsx(record.source === "erp" ? "erp" : "danger")}
                        rounded={false}
                        className="text-white opacity-90"
                        style={{
                            textTransform: record.source === "erp" ? "uppercase" : "capitalize",
                            padding: "0 4px",
                            fontSize: "0.65rem",
                            height: "1rem",
                            lineHeight: "1rem",
                        }}
                    />
                    <span className="ml-1 text-[0.7rem] opacity-90">{record.project}</span>
                </div>
            </a>
        ),
    },
    {
        title: <TitleTable title="End time" />,
        dataIndex: "end_date",
        width: 100,
        render: (text) => text && <h6 className="text-center">{moment(text, "DD/MM/YYYY").format("DD/MM/YYYY")}</h6>,
    },
    {
        title: <TitleTable title="Delay" />,
        dataIndex: "days_late",
        width: 100,
        render: (text) =>
            text && (
                <div className="text-center">
                    <span className="text-[#cd201f]">{text}</span>
                </div>
            ),
    },
];
