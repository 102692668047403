import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiSales } from "api";

export const onGetQuoteGroupListSales = createAsyncThunk("getQuoteGroupListSales", async () => {
    const response = await apiSales.getQuoteGroupListSales();
    return response;
});

export const onPostCreateLead = createAsyncThunk("postCreateLead", async (payload) => {
    const response = await apiSales.postCreateLead(payload);
    return response;
});

export const onGetLeads = createAsyncThunk("getLeads", async (data) => {
    const response = await apiSales.getLeads(data);
    return response;
});

export const onGetSearchLeads = createAsyncThunk("getSearchLeads", async (keyword) => {
    const response = await apiSales.getSearchLeads(keyword);
    return response;
});

export const onGetSearchApproverPrice = createAsyncThunk("getSearchApproverPrice", async (keyword) => {
    const response = await apiSales.getSearchApproverPrice(keyword);
    return response;
});

export const onGetSearchApproverTech = createAsyncThunk("getSearchApproverTech", async (keyword) => {
    const response = await apiSales.getSearchApproverTech(keyword);
    return response;
});

export const onGetQuotationCategory = createAsyncThunk("getQuotationCategory", async () => {
    const response = await apiSales.getQuotationCategory();
    return response;
});

export const onGetSubQuoteCategory = createAsyncThunk("getSubQuoteCategory", async (idCat) => {
    const response = await apiSales.getSubQuoteCategory(idCat);
    return response;
});

export const onGetDiscountList = createAsyncThunk("getDiscountList", async () => {
    const response = await apiSales.getDiscountList();
    return response;
});

export const onPostCreateSkuQuotation = createAsyncThunk("postCreateSkuQuotation", async (payload) => {
    const response = await apiSales.postCreateSkuQuotation(payload);
    return response;
});

export const onGetOrderLineQuotation = createAsyncThunk("getOrderLineQuotation", async (payload) => {
    const response = await apiSales.getOrderLineQuotation(payload);
    return response;
});

export const onGetSkuQuotation = createAsyncThunk("getSkuQuotation", async (_id) => {
    const response = await apiSales.getSkuQuotation(_id);
    return response;
});

export const onPatchUpdateSkuQuotation = createAsyncThunk("patchUpdateSkuQuotation", async (data) => {
    const response = await apiSales.patchUpdateSkuQuotation(data);
    return response;
});

export const onDeleteQuotation = createAsyncThunk("deleteQuotation", async (id) => {
    const response = await apiSales.deleteQuotation(id);
    return response;
});

export const onPatchApprovedQuotationState = createAsyncThunk("patchApprovedQuotationState", async (data) => {
    const response = await apiSales.patchApprovedQuotationState(data);
    return response;
});

export const onGetAbsLead = createAsyncThunk("getAbsLead", async () => {
    const response = await apiSales.getAbsLead();
    return response;
});

export const onGetAbsProductLine = createAsyncThunk("getAbsLeadDetail", async () => {
    const response = await apiSales.getAbsProductLine();
    return response;
});

export const onPostAbsLead = createAsyncThunk("postAbsLead", async (payload) => {
    const response = await apiSales.postAbsLead(payload);
    return response;
});

export const onPatchAbsLead = createAsyncThunk("patchAbsLead", async (payload) => {
    const response = await apiSales.patchAbsLead(payload);
    return response;
});
