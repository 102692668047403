import clsx from "clsx";

export const BackgroundImage = ({ className, src, children }) => {
    return (
        <div
            style={{
                backgroundImage: `url(${src})`,
            }}
            className={clsx("bg-cover", className)}
        >
            {children}
        </div>
    );
};
