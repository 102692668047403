import queryString from "query-string";
import clsx from "clsx";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { defaultFilterProducts, urlWebsite } from "constant";
import { IconAddEvent } from "layout";

import { VscFilter } from "react-icons/vsc";

export const FilterProducts = ({ cost, sku, _default }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onChangeFilter = (value) => {
        const resultCheckFilter = checkFilter(value);
        const params = {
            _page: 1,
            _limit: 20,
            ...resultCheckFilter,
        };
        navigate(`${urlWebsite.SALES_PRODUCTS}?${queryString.stringify(params)}`);
    };
    const Content = () => {
        return (
            <div className="relative top-1 right-[10px] rounded-[0.25rem] border bg-white py-2 text-[#495057] shadow-dropdown-menu dark:border-[#32394e] dark:bg-[#262b3c] dark:text-slate-200">
                <div className="min-w-[120px]">
                    <div className="grid gap-2">
                        {defaultFilterProducts.map((item) => (
                            <div
                                onClick={() => onChangeFilter(item.id)}
                                key={item.id}
                                className={clsx(
                                    "align-center flex cursor-pointer items-center gap-2 py-[6px] px-6 hover:bg-[#d8dadc] dark:hover:bg-[#1c1f2b]",
                                    cost.name === item.id &&
                                        cost.value === item.value &&
                                        "bg-[#d8dadc] text-[#556ee6] dark:bg-[#1c1f2b] dark:text-slate-200 dark:hover:bg-[#1c1f2b]",
                                    sku.name === item.id &&
                                        sku.value === item.value &&
                                        "bg-[#d8dadc] text-[#556ee6] dark:bg-[#1c1f2b] dark:text-slate-200 dark:hover:bg-[#1c1f2b]",
                                    _default.name === item.id &&
                                        _default.value === item.value &&
                                        "bg-[#d8dadc] text-[#556ee6] dark:bg-[#1c1f2b] dark:text-slate-200 dark:hover:bg-[#1c1f2b]"
                                )}
                            >
                                <h6>{t(item.name)}</h6>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <Dropdown overlay={Content} trigger={["click"]}>
            <IconAddEvent Icon={VscFilter} title="Filter" iconClassName="cursor-pointer" className="cursor-pointer" />
        </Dropdown>
    );
};

const checkFilter = (value) => {
    switch (value) {
        case "_sku":
            return {
                _sku: "true",
                _cost: "false",
                _default: "false",
            };
        case "_cost":
            return {
                _sku: "false",
                _cost: "true",
                _default: "false",
            };
        case "_default":
            return {
                _sku: "false",
                _cost: "false",
                _default: "true",
            };
        default:
            return {
                _sku: "false",
                _cost: "false",
                _default: "false",
            };
    }
};
