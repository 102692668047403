import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiProducts } from "api";

export const onGetAllProducts = createAsyncThunk("getAllProducts", async (payload) => {
    const response = await apiProducts.getAllProducts(payload);
    return response;
});

export const onGetProductDetails = createAsyncThunk("getProductDetails", async (productId) => {
    const response = await apiProducts.getProductDetails(productId);
    return response;
});

export const onSearchAllProducts = createAsyncThunk("searchAllProducts", async (params) => {
    const response = await apiProducts.searchAllProducts(params);
    return response;
});

export const onGetCategoryProduct = createAsyncThunk("getCategoryProduct", async () => {
    const response = await apiProducts.getCategoryProduct();
    return response;
});

export const onGetUomProduct = createAsyncThunk("getUomProduct", async () => {
    const response = await apiProducts.getUomProduct();
    return response;
});

export const onGetMadeInListProduct = createAsyncThunk("getMadeInListProduct", async () => {
    const response = await apiProducts.getMadeInListProduct();
    return response;
});

export const onGetWarrantyListProduct = createAsyncThunk("getWarrantyListProduct", async () => {
    const response = await apiProducts.getWarrantyListProduct();
    return response;
});

export const onGetCategorySkuProduct = createAsyncThunk("getCategorySkuProduct", async () => {
    const response = await apiProducts.getCategorySkuProduct();
    return response;
});

export const onSearchProduct = createAsyncThunk("searchProduct", async (keyword) => {
    const response = await apiProducts.searchProduct(keyword);
    return response;
});

export const onPostCreateProductSKU = createAsyncThunk("postCreateProductSKU", async (payload) => {
    const response = await apiProducts.postCreateProductSKU(payload);
    return response;
});

export const onUpdateProductSku = createAsyncThunk("updateProductSku", async (data) => {
    const response = await apiProducts.updateProductSku(data);
    return response;
});

export const onGetProductsCost = createAsyncThunk("getProductsCost", async (payload) => {
    const response = await apiProducts.getProductsCost(payload);
    return response;
});

export const onUpdateProductCost = createAsyncThunk("updateProductCost", async (data) => {
    const response = await apiProducts.updateProductCost(data);
    return response;
});

export const onSubmitApproveProduct = createAsyncThunk("submitApproveProduct", async (id) => {
    const response = await apiProducts.submitApproveProduct(id);
    return response;
});
