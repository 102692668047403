import * as React from "react";
import { Form, InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useSales, useProducts, useModal } from "hooks";
import { SelectSearch, SelectMultipleSearch } from "layout";
import { Button } from "customize";
import { VscSaveAs } from "react-icons/vsc";

export const ModalAddSkuQuotation = () => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [categoryId, setCategoryId] = React.useState(null);

    const {
        handleGetQuotationCategory,
        handleGetSubQuoteCategory,
        handleAddSKUQuotationReducers,
        dataQuotationCategory,
        loadingSubQuoteCategory,
        dataSubQuoteCategory,
    } = useSales();
    const { toggleModal, ModalTypeEnum } = useModal();
    const { handleSearchProduct, dataSearchProducts, loadingDataSearchProducts } = useProducts();

    React.useEffect(() => {
        if (!dataQuotationCategory) {
            handleGetQuotationCategory();
        } else {
            form.setFieldsValue({
                quotation_category: dataQuotationCategory[0].id,
            });
            setCategoryId(dataQuotationCategory[0].id);
        }
    }, [dataQuotationCategory, handleGetQuotationCategory, form]);

    React.useEffect(() => {
        if (dataSubQuoteCategory) {
            form.setFieldsValue({
                subQuote_category: dataSubQuoteCategory[0].id,
            });
        }
    }, [dataSubQuoteCategory, form]);

    React.useEffect(() => {
        if (categoryId) {
            handleGetSubQuoteCategory(categoryId);
        }
    }, [categoryId, handleGetSubQuoteCategory]);

    const onSubMitAddSkuQuotation = ({ product, quantity, quotation_category, subQuote_category }) => {
        const productFilter = dataSearchProducts.find(({ item }) => item.id === product);
        const payload = {
            product: productFilter,
            quotation_category: dataQuotationCategory.find((item) => item.id === quotation_category),
            subQuote_category: dataSubQuoteCategory.find((item) => item.id === subQuote_category),
        };

        handleAddSKUQuotationReducers({
            type: "ADD",
            quantity,
            data: payload,
            product_id: product,
            quotation_category_id: quotation_category,
            subQuote_category_id: subQuote_category,
        });
        toggleModal({
            type: ModalTypeEnum.NULL,
        });
    };

    return (
        <Form layout="vertical" initialValues={{ quantity: 1 }} form={form} onFinish={onSubMitAddSkuQuotation}>
            <SelectMultipleSearch
                props={{ showSearch: true }}
                loading={loadingDataSearchProducts}
                data={dataSearchProducts}
                api={handleSearchProduct}
                placeholder={`${t("Enter product name")}...`}
                label="Product's name"
                error="Please enter this field!"
                required
                name="product"
            >
                {dataSearchProducts &&
                    dataSearchProducts.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                            <div className="flex h-full items-center justify-between gap-x-2">
                                <h6>{item.name}</h6>
                                <p className="mr-5 font-semibold text-[#007bff]">#{item.id}</p>
                            </div>
                        </Select.Option>
                    ))}
            </SelectMultipleSearch>
            <Form.Item
                label={t("Quantity")}
                name="quantity"
                rules={[{ required: true, message: t("Please enter this field!") }]}
            >
                <InputNumber min={1} max={20} className="grid w-full" />
            </Form.Item>
            <SelectSearch
                allowClear={false}
                label="Phase"
                name="quotation_category"
                messageError="Please enter this field!"
                required
                props={{
                    onChange: (value) => setCategoryId(value),
                }}
            >
                {dataQuotationCategory ? (
                    dataQuotationCategory.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                            {item.name}
                        </Select.Option>
                    ))
                ) : (
                    <div>loading...</div>
                )}
            </SelectSearch>
            <SelectSearch
                allowClear={false}
                label="Category"
                name="subQuote_category"
                messageError="Please enter this field!"
                disabled={loadingSubQuoteCategory}
                required
                props={{
                    loading: loadingSubQuoteCategory,
                }}
            >
                {dataSubQuoteCategory &&
                    dataSubQuoteCategory.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
            </SelectSearch>
            <Button label="Add to quotation" className="mt-3" BgSuccess Icon={VscSaveAs} />
        </Form>
    );
};
