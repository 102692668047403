export const Loading = () => {
    return (
        <div className="grid min-h-screen">
            <div className="grid h-fit flex-row gap-2 md:flex md:gap-4">
                <div className="basis-6/12">
                    <div className="h-8 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                </div>
                <div className="basis-3/12">
                    <div className="h-8 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                </div>
                <div className="basis-3/12">
                    <div className="h-8 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                </div>
            </div>
            <div className="flex flex-row gap-2">
                {[...Array(2)].map((_, index) => (
                    <div className="basis-6/12" key={index.toString()}>
                        <div className="h-8 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-2 gap-x-2 md:flex md:gap-4">
                {[...Array(4)].map((_, index) => (
                    <div className="basis-3/12" key={index.toString()}>
                        <div className="h-8 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                    </div>
                ))}
            </div>
            <div className="h-14 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="h-8 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
            <div className="relative grid grid-cols-1 gap-4 2xl:grid-cols-2">
                {[...Array(2)].map((_, index) => (
                    <div key={index.toString()}>
                        <div className="h-24 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500" />
                    </div>
                ))}
            </div>
        </div>
    );
};
