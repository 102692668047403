export const reducers = {
    deleteEventReducers: (state, action) => {
        const { dataMeetingNext } = state;
        if (dataMeetingNext) {
            const index = dataMeetingNext.findIndex(({ id }) => id === action.payload);
            if (index !== -1) {
                dataMeetingNext.splice(index, 1);
            }
        }
    },
    addEventReducers: (state, action) => {
        const { data } = action.payload;
        const { dataMeetingNext } = state;
        if (dataMeetingNext) {
            dataMeetingNext.unshift(data.event);
        }
    },
    confirmEventReducers: (state, action) => {
        const { id, confirm_status } = action.payload;
        const { dataMeetingNext } = state;
        if (dataMeetingNext) {
            const index = dataMeetingNext.findIndex((item) => item.id === id);
            if (index !== -1) {
                dataMeetingNext[index].confirm_status = confirm_status;
            }
        }
    },
};
