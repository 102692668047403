import * as React from "react";
import { Select, Spin, Form } from "antd";
import { useTranslation } from "react-i18next";

export const SelectMultipleSearch = ({
    api,
    data,
    loading,
    children,
    placeholder,
    label,
    error,
    name,
    disabled,
    required = false,
    onChange,
    className,
    props,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");

    return (
        <Form.Item
            label={t(label)}
            name={name}
            rules={[{ required: required, message: t(error) }]}
            hasFeedback
            className={className}
        >
            <Select
                {...props}
                fieldNames={{ label: "", value: "" }}
                tokenSeparators={[","]}
                value={value}
                placeholder={placeholder}
                filterOption={false}
                onSearch={(e) => {
                    if (e.length >= 3) {
                        api(e);
                    }
                    setSearchValue(e);
                }}
                notFoundContent={
                    loading ? (
                        <Spin size="small" className="ml-2" />
                    ) : data?.length === 0 && searchValue.length >= 3 ? (
                        <p className="text-center">{t("No matching results found")}</p>
                    ) : (
                        <p className="text-center">{t("Enter at least 3 characters")}</p>
                    )
                }
                allowClear
                onChange={(value) => {
                    setValue(value);
                    onChange && onChange(value);
                }}
                disabled={disabled}
            >
                {children}
            </Select>
        </Form.Item>
    );
};
