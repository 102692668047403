import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUser } from "api";

export const onSearchUserSelect = createAsyncThunk("onSearchUserSelect", async (text) => {
    const response = await apiUser.searchUserSelect(text);
    return response;
});

export const onSearchUserWatcher = createAsyncThunk("onSearchUserWatcher", async (text) => {
    const response = await apiUser.searchUserSelect(text);
    return response;
});
