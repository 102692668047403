import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";
import clsx from "clsx";

import { useModal, useDrawer, useTypeProject } from "hooks";
import { IoSettingsOutline } from "react-icons/io5";
import { VscLock, VscAccount, VscSignOut, VscLoading } from "react-icons/vsc";

export const Setting = ({ onClickLogout, loading, children }) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { toggleDrawer, DrawerTypeEnum } = useDrawer();
    const { isAppMobile } = useTypeProject();

    const content = (
        <div className="relative top-[8px] rounded-[0.25rem] border border-white bg-white py-2 text-[#495057] shadow-dropdown-menu dark:border-[#32394e] dark:bg-[#262b3c] dark:text-slate-200">
            <div className="w-[210px]">
                <ItemSettings
                    Icon={VscAccount}
                    title="Profile"
                    onClick={() =>
                        toggleDrawer({
                            type: DrawerTypeEnum.VIEW_PROFILE,
                            title: "Account information",
                        })
                    }
                />
                <ItemSettings
                    Icon={VscLock}
                    title="Change password"
                    onClick={() =>
                        toggleModal({
                            title: "Change password",
                            type: ModalTypeEnum.CHANGE_PASSWORD,
                        })
                    }
                />
                <ItemSettings
                    Icon={IoSettingsOutline}
                    title="Setting"
                    onClick={() =>
                        toggleModal({
                            title: "Setting",
                            type: ModalTypeEnum.SETTING_ACCOUNT,
                        })
                    }
                />
                <ItemSettings
                    Icon={VscSignOut}
                    title="Log out"
                    onClick={onClickLogout}
                    loading={loading}
                    color="text-[#dc3545]"
                />
            </div>
        </div>
    );
    return (
        <div className="flex items-center gap-x-2">
            <Dropdown overlay={content} trigger={[!isAppMobile ? "hover" : "click"]}>
                {children}
            </Dropdown>
        </div>
    );
};

const ItemSettings = ({ Icon, title, onClick, loading, color = "text-[#212529] dark:text-slate-200" }) => {
    const { t } = useTranslation();
    return (
        <div
            className={clsx(
                "align-center flex items-center gap-2 py-[6px] px-6 hover:bg-[#d8dadc] dark:hover:bg-[#1c1f2b]",
                onClick && !loading ? "cursor-pointer" : "cursor-not-allowed opacity-50"
            )}
            onClick={!loading && onClick}
        >
            {loading ? <VscLoading className="animate-spin" /> : <Icon className={clsx("font-medium", color)} />}
            <p className={clsx("text-[0.938rem] font-medium", color)}>{t(title)}</p>
        </div>
    );
};
