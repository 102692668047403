import * as React from "react";
import { useTranslation } from "react-i18next";
import { Form, Select, Input } from "antd";

import { useSales, useModal } from "hooks";
import { Button } from "customize";
import { SelectSearch } from "layout";
import { VscSaveAs } from "react-icons/vsc";

export const ModalAddQuotationDiscount = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [discountType, setDiscountType] = React.useState(null);

    const { toggleModal, ModalTypeEnum } = useModal();
    const { handleGetDiscountList, dataDiscountList, handleAddQuotationDiscountReducers, dataQuotationDiscount } =
        useSales();

    React.useEffect(() => {
        if (!dataDiscountList.length) {
            handleGetDiscountList();
        } else {
            if (dataDiscountList.length > 0) {
                form.setFieldsValue({
                    discount_type: dataDiscountList[0].value,
                });
                setDiscountType(dataDiscountList[0].value);
            }
        }
    }, [handleGetDiscountList, dataDiscountList, form]);

    const onAddQuotationDiscountReducers = (value) => {
        const { discount_type } = value;
        const discount = dataDiscountList.find((item) => item.value === discount_type);
        handleAddQuotationDiscountReducers({
            item: discount,
            data: value,
            type: "ADD",
        });
        toggleModal({
            type: ModalTypeEnum.NULL,
        });
    };

    return (
        <Form layout="vertical" form={form} onFinish={onAddQuotationDiscountReducers}>
            <Form.Item
                label={t("Content")}
                name="content"
                rules={[{ required: true, message: t("Please enter this field!") }]}
                hasFeedback
            >
                <Input allowClear />
            </Form.Item>
            <SelectSearch
                allowClear
                label="Category"
                name="discount_type"
                messageError="Please enter this field!"
                required
                props={{
                    loading: dataDiscountList.length === 0,
                    onChange: (e) => setDiscountType(e),
                }}
            >
                {dataDiscountList.length > 0 ? (
                    dataDiscountList.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                            {t(item.name)}
                        </Select.Option>
                    ))
                ) : (
                    <h6>loading...</h6>
                )}
            </SelectSearch>
            <Form.Item
                label={t(discountType === "money_on_total" ? "Amount (VND)" : "Enter value (%)")}
                name="money"
                rules={[{ required: true, message: t("Please enter this field!") }]}
                hasFeedback
            >
                <Input allowClear />
            </Form.Item>
            <Button
                label="Save"
                className="mt-3"
                BgSuccess
                disabled={dataQuotationDiscount.length === 2}
                Icon={VscSaveAs}
            />
        </Form>
    );
};
