import { createSlice } from "@reduxjs/toolkit";

import { extraReducers } from "./extra-reducers";
import { reducers } from "./reducers";

const initialState = {
    dataProducts: null,
    pagination: {
        page: null,
        limit: null,
        total: null,
        sortable: null,
    },
    loading: true,
    dataProductsDetails: null,
    loadingCreateSKU: false,
    dataCategory: null,
    dataUom: null,
    dataMadeIn: null,
    dataWarranty: null,
    dataCategorySku: null,
    loadingDataSearchProducts: false,
    loadingSubmitApproveProduct: false,
    dataSearchProducts: [],
    productListProduct: [],
};

export const sliceProducts = createSlice({
    name: "products",
    initialState: initialState,
    reducers: reducers,
    extraReducers: extraReducers,
});

const { reducer, actions } = sliceProducts;
export const {
    addToProductListProduct,
    removeProductListProduct,
    updateQuantityProductListProduct,
    updateEditSalesProduct,
    reducersUpdateProductCost,
} = actions;
export default reducer;
