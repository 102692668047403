import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { onGetStatic, onGetKpiReport, onGetLeavesBizStatic, onGetFeedbackStatic } from "features";
import { useAccount, storeHrDashboard } from "hooks";

export const useHrDashboard = () => {
    const dispatch = useDispatch();
    const resultStoreHrDashboard = useSelector(storeHrDashboard);
    const { dataStatic, dataKpiReport, dataLeavesBizStatic, dataFeedbackStatic } = resultStoreHrDashboard;
    const { access_token } = useAccount();

    const handleGetStatic = React.useCallback(() => access_token && dispatch(onGetStatic()), [dispatch, access_token]);

    const handleGetKpiReport = React.useCallback(
        (params) => access_token && dispatch(onGetKpiReport(params)),
        [dispatch, access_token]
    );

    const handleGetLeavesBizStatic = React.useCallback(
        () => access_token && dispatch(onGetLeavesBizStatic()),
        [dispatch, access_token]
    );

    const handleGetFeedbackStatic = React.useCallback(
        (params) => access_token && dispatch(onGetFeedbackStatic(params)),
        [dispatch, access_token]
    );

    return {
        handleGetStatic,
        handleGetKpiReport,
        handleGetLeavesBizStatic,
        handleGetFeedbackStatic,
        dataStatic,
        dataKpiReport,
        dataLeavesBizStatic,
        dataFeedbackStatic,
    };
};
