import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { iconAvatarDefault } from "assets/icon";

export const Image = ({
    border,
    className = "w-6 h-6 rounded cursor-pointer",
    wrapperClassName,
    src,
    alt,
    effect = "blur",
    base64,
    onClick,
    children,
    props,
    borderClassName = "h-[38px] w-[38px]",
}) => {
    return (
        <div className={clsx("relative", wrapperClassName)}>
            <div
                onClick={onClick && onClick}
                className={
                    border &&
                    `flex items-center justify-center rounded-full border border-[#ecf0f4] dark:border-[#32394e] ${borderClassName}`
                }
            >
                <LazyLoadImage
                    {...props}
                    effect={effect}
                    src={base64 ? `data:image;base64,${src}` : src || iconAvatarDefault}
                    alt={alt || src}
                    key={alt || src}
                    className={clsx("object-contain", className)}
                    wrapperClassName={clsx("object-contain", className)}
                />
            </div>
            {children}
        </div>
    );
};
