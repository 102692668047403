import { useTranslation } from "react-i18next";

import { Image, LinkHref } from "customize";
import { imageHelp } from "assets/images";
import { iconLogo } from "assets/icon";

export const BackgroundLeft = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full rounded-l-md bg-white p-5 lg:max-w-[560px]">
            <LinkHref outWeb path="https://solarbk.vn/">
                <Image src={iconLogo} className="h-12 w-32" />
            </LinkHref>
            <Image src={imageHelp} className="h-auto w-full py-0 lg:my-8" />
            <div className="text-center">
                <h1 className="mb-4 text-xl font-bold text-[#545454]">Workplace</h1>
                <h6 className="text-[#6A6A6A]">
                    {t("Aspire to differentiate, differentiate to lead, lead to succeed")}
                </h6>
            </div>
        </div>
    );
};
