import clsx from "clsx";
import { useTypeProject } from "hooks";

export const LoadingTableMobile = ({ items = 4, className, itemClassName }) => {
    const { borderClassNameMobile } = useTypeProject();
    return (
        <div className={className}>
            <div className={clsx("grid grid-cols-1 gap-2 md:grid-cols-2", itemClassName)}>
                {[...Array(items)].map((_, index) => (
                    <div className={clsx(borderClassNameMobile, "gap-3")} key={index.toString()}>
                        {[...Array(6)].map((_, index) => (
                            <div
                                className="h-5 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500"
                                key={index.toString()}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
