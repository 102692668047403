import { useTranslation } from "react-i18next";
import { FileSearchOutlined } from "@ant-design/icons";
import { iconEmptyBox } from "assets/icon";
import { Image } from "customize";

export const NoData = ({
    title = "Sorry, no results were found matching your selection!",
    Icon = FileSearchOutlined,
    noData,
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex h-full w-full flex-col items-center justify-center gap-1">
            {noData ? <Image src={iconEmptyBox} className="h-12 w-12" /> : <Icon className="text-2xl text-[#555b6d]" />}
            <h3 className="text-[0.75rem]">{t(title)}</h3>
        </div>
    );
};
