import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ViewMore = ({ href = "/", className, title, onClick, ...props }) => {
    const { t } = useTranslation();
    return onClick ? (
        <h6
            {...props}
            onClick={onClick && onClick}
            className={clsx(
                "flex cursor-pointer items-center justify-between gap-x-2 rounded-[0.2rem] bg-[#556ee6] p-2 text-xs text-white hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#556ee6]/50",
                className
            )}
        >
            {t(title)}
        </h6>
    ) : (
        <Link
            {...props}
            to={href}
            className={clsx(
                "flex items-center justify-between gap-x-2 rounded-[0.2rem] bg-[#556ee6] p-2 text-xs text-white hover:text-white hover:underline hover:opacity-75 dark:hover:opacity-100 dark:hover:shadow-lg dark:hover:shadow-[#556ee6]/50",
                className
            )}
        >
            {t(title)}
        </Link>
    );
};
