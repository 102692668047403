import * as React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useLocation } from "react-router-dom";
import { message } from "antd";

import { urlWebsite } from "constant";
import { useEvents, useModal, useContextControllers } from "hooks";
import { Button } from "customize";
import { HiCheck } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";

export const ModalDeleteEvent = ({ idEvent, uidEvent }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();

    const { handleDeleteEvent, handleDeleteEventReducers } = useEvents();
    const { toggleModal, ModalTypeEnum } = useModal();
    const { setUidEvent } = useContextControllers();

    const onClickDeleteEvent = async () => {
        setLoading(true);
        try {
            const request = await handleDeleteEvent(idEvent);
            const resultResultData = unwrapResult(request);
            if (resultResultData) {
                setLoading(false);
                setUidEvent(uidEvent);
                message.success(t("Delete successful event"));
                toggleModal({
                    type: ModalTypeEnum.NULL,
                });
                handleDeleteEventReducers(idEvent);
                if (pathname?.split("/").length > 2) {
                    // page detail id
                    navigate(urlWebsite.EVENTS, { replace: true });
                }
                if (pathname === urlWebsite.EVENTS) {
                    // page list event
                    navigate(urlWebsite.EVENTS, { replace: true });
                }
            }
        } catch (error) {
            setLoading(false);
            message.error(t("Delete failed event"));
            navigate(urlWebsite.EVENTS, { replace: true });
        }
        setLoading(false);
    };

    return (
        <div className="grid grid-cols-2 justify-between gap-x-6">
            <Button label="Yes" BgDanger loading={loading} onClick={onClickDeleteEvent} Icon={HiCheck} />
            <Button
                Icon={AiOutlineClose}
                label="Cancel"
                BgSecondary
                onClick={() => {
                    toggleModal({
                        type: ModalTypeEnum.NULL,
                    });
                }}
                disabled={loading}
            />
        </div>
    );
};
