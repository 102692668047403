import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAccount, storeTasks } from "hooks";
import { onGetTaskList } from "features";

export const useTasks = () => {
    const dispatch = useDispatch();

    const resultStoreTasks = useSelector(storeTasks);
    const { dataTaskList } = resultStoreTasks;

    const { access_token } = useAccount();

    const handleGetTaskList = React.useCallback(
        () => access_token && dispatch(onGetTaskList()),
        [access_token, dispatch]
    );

    return {
        handleGetTaskList,
        dataTaskList,
    };
};
