import { useTranslation } from "react-i18next";
import clsx from "clsx";

export const WrapperTable = ({ className, children, option }) => {
    return (
        <div className={className}>
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">{children}</div>
            {option}
        </div>
    );
};

export const TitleTable = ({ className = "text-center", title, style }) => {
    const { t } = useTranslation();
    return (
        <h6 className={clsx("text-[0.9375rem] font-semibold", className)} style={style}>
            {t(title)}
        </h6>
    );
};
