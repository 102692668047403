import { NavLink } from "react-router-dom";

import { iconLogoWords } from "assets/icon";
import { urlWebsite } from "constant";
import { Image } from "customize";

export const Logo = ({ className, imageClassName, src = iconLogoWords }) => {
    return (
        <div className={className}>
            <NavLink to={urlWebsite.HOME_PAGE}>
                <Image src={src} className={imageClassName} />
            </NavLink>
        </div>
    );
};
