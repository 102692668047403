import { createSlice } from "@reduxjs/toolkit";

import { onGetTaskList } from "./path-api";

const initialState = {
    dataTaskList: null,
};

export const sliceTasks = createSlice({
    name: "tasks",
    initialState: initialState,
    extraReducers: {
        [onGetTaskList.fulfilled]: (state, action) => {
            state.dataTaskList = action.payload.items;
        },
    },
});

const { reducer } = sliceTasks;
export default reducer;
