import {
    sliceModal,
    sliceAccount,
    sliceSearch,
    sliceTasks,
    sliceFeedback,
    sliceEvent,
    sliceUser,
    sliceEmail,
    sliceProducts,
    sliceSales,
    sliceKnowLedge,
    sliceQuotation,
    sliceCompany,
    slicePartner,
    sliceDrawer,
    sliceHrDashboard,
} from "features";

export const reducer = {
    storeModal: sliceModal.reducer,
    storeAccount: sliceAccount.reducer,
    storeSearch: sliceSearch.reducer,
    storeTasks: sliceTasks.reducer,
    storeFeedback: sliceFeedback.reducer,
    storeEvent: sliceEvent.reducer,
    storeUser: sliceUser.reducer,
    storeEmail: sliceEmail.reducer,
    storeProducts: sliceProducts.reducer,
    storeSales: sliceSales.reducer,
    storeKnowLedge: sliceKnowLedge.reducer,
    storeQuotation: sliceQuotation.reducer,
    storeCompany: sliceCompany.reducer,
    storePartner: slicePartner.reducer,
    storeDrawer: sliceDrawer.reducer,
    storeHrDashboard: sliceHrDashboard.reducer,
};
