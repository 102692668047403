import * as React from "react";
import { imageBg } from "assets/images";
import { BackgroundImage, LinkHref } from "customize";

import { useTitlePage, useTypeProject } from "hooks";
import { DropdownTranslate } from "layout";
import { BackgroundLeft } from "./background-left";
import { FormLogin } from "./form-login";
import { MobileApp } from "./mobile-app";

export const Login = () => {
    useTitlePage({ title: "Workplace | Login" });
    const { isAppMobile } = useTypeProject();

    return isAppMobile ? (
        <MobileApp />
    ) : (
        <BackgroundImage src={imageBg} className="min-h-screen">
            <div className="group-tab-login relative z-40 flex w-full items-center justify-end space-x-8 py-5 px-9 lg:absolute">
                <LinkHref title="Home" path="https://solarbk.vn/" outWeb className="title-login text-white" />
                <DropdownTranslate is_page_login />
            </div>
            <div className="m-auto flex min-h-screen w-full max-w-[1170px] items-center p-3 lg:py-[58px] lg:px-[97px]">
                <div className="block w-full lg:flex">
                    <BackgroundLeft />
                    <FormLogin />
                </div>
            </div>
        </BackgroundImage>
    );
};
