import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";

import { storeEvent, useAccount, useModal } from "hooks";
import { apiEvent, apiAccount } from "api";

import {
    onGetEventInMonth,
    onGetEventDetailByUid,
    onGetEventComments,
    onPostCreateEventNote,
    onPostEventComment,
    onDeleteEvent,
    onPatchConfirmEvent,
    onPatchConfirmEventMeetingFast,
    onGetTypeEventList,
    onGetResourceList,
    onPostCreateEvent,
    onPutUpdateEvent,
    onGetMeetingNext,
    onPostStartZoomUrl,
    onGetMeetingNextToday,
    onGetMeetingNextTomorrow,
    onGetMeetingNextThisWeek,
    onGetMeetingNextNextWeek,
    onPostRegisterEvent,
    deleteEventReducers,
    addEventReducers,
    confirmEventReducers,
} from "features";
import { urlWebsite } from "constant";
import { useTranslation } from "react-i18next";

export const useEvents = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { access_token } = useAccount();
    const resultStoreEvent = useSelector(storeEvent);
    const { toggleModal, ModalTypeEnum } = useModal();
    // store
    const {
        dataTypesList,
        dataResourceList,
        loadingCreateEvent,
        dataCheckAvailableResource,
        dataMeetingNext,
        loadingStartZoom,
        loadingDataMeetingNext,
    } = resultStoreEvent;
    // state
    const [eventMembers, setEventMembers] = React.useState(null);
    const [eventUserCreate, setEventUserCreate] = React.useState(null);
    const [watcher, setWatcher] = React.useState(null);
    const [commentListEvent, setCommentListEvent] = React.useState(null);
    const [dataEventDetail, setDataEventDetail] = React.useState(null);
    const [dataEventComments, setDataEventComments] = React.useState(null);
    //  api
    const handleGetEventInMonth = React.useCallback(
        (data) => access_token && dispatch(onGetEventInMonth(data)),
        [access_token, dispatch]
    );

    const handleGetEventDetailByUid = React.useCallback(
        (id) => id && access_token && dispatch(onGetEventDetailByUid(id)),
        [access_token, dispatch]
    );

    const handleGetEventComments = React.useCallback(
        (id) => access_token && dispatch(onGetEventComments(id)),
        [access_token, dispatch]
    );

    const handlePostCreateEventNote = React.useCallback(
        (id) => access_token && id && dispatch(onPostCreateEventNote(id)),
        [access_token, dispatch]
    );

    const handlePostEventComment = React.useCallback(
        (data) => access_token && dispatch(onPostEventComment(data)),
        [access_token, dispatch]
    );

    const handleDeleteEvent = React.useCallback(
        (idEvent) => access_token && idEvent && dispatch(onDeleteEvent(idEvent)),
        [access_token, dispatch]
    );

    const handlePatchConfirmEvent = React.useCallback(
        (data) => access_token && dispatch(onPatchConfirmEvent(data)),
        [access_token, dispatch]
    );

    const handleGetTypeEventList = React.useCallback(
        () => access_token && dispatch(onGetTypeEventList()),
        [access_token, dispatch]
    );

    const handleGetResourceList = React.useCallback(
        () => access_token && dispatch(onGetResourceList()),
        [access_token, dispatch]
    );

    const handlePostCreateEvent = React.useCallback(
        (data) => access_token && dispatch(onPostCreateEvent(data)),
        [access_token, dispatch]
    );
    const handleCheckAvailableResource = React.useCallback(
        (payload) => access_token && apiEvent.checkAvailableResource(payload),
        [access_token]
    );

    const handlePutUpdateEvent = React.useCallback(
        (data) => access_token && dispatch(onPutUpdateEvent(data)),
        [access_token, dispatch]
    );

    const handleGetMeetingNext = React.useCallback(
        () => access_token && dispatch(onGetMeetingNext()),
        [access_token, dispatch]
    );

    const handlePatchConfirmEventMeetingFast = React.useCallback(
        (data) => access_token && dispatch(onPatchConfirmEventMeetingFast(data)),
        [access_token, dispatch]
    );

    const handlePostStartZoomUrl = React.useCallback(
        (id) => access_token && dispatch(onPostStartZoomUrl(id)),
        [access_token, dispatch]
    );

    const handleGetMeetingNextToday = React.useCallback(
        () => access_token && dispatch(onGetMeetingNextToday()),
        [access_token, dispatch]
    );

    const handleGetMeetingNextTomorrow = React.useCallback(
        () => access_token && dispatch(onGetMeetingNextTomorrow()),
        [access_token, dispatch]
    );

    const handleGetMeetingNextThisWeek = React.useCallback(
        () => access_token && dispatch(onGetMeetingNextThisWeek()),
        [access_token, dispatch]
    );

    const handleGetMeetingNextNextWeek = React.useCallback(
        () => access_token && dispatch(onGetMeetingNextNextWeek()),
        [access_token, dispatch]
    );

    const handlePostRegisterEvent = React.useCallback(
        (payload) => access_token && dispatch(onPostRegisterEvent(payload)),
        [access_token, dispatch]
    );

    const handleDeleteEventReducers = React.useCallback(
        (_id) => access_token && dispatch(deleteEventReducers(_id)),
        [access_token, dispatch]
    );

    const handleAddEventReducers = React.useCallback(
        (payload) => access_token && dispatch(addEventReducers(payload)),
        [access_token, dispatch]
    );

    const handleConfirmEventReducers = React.useCallback(
        (payload) => dispatch(confirmEventReducers(payload)),
        [dispatch]
    );

    // useEffect
    React.useEffect(() => {
        (async () => {
            try {
                if (props?._uid) {
                    setDataEventDetail(null);
                    setDataEventComments(null);
                    setCommentListEvent(null);
                    const request_data_events_detail = await handleGetEventDetailByUid(props?._uid);
                    const data_events_detail = unwrapResult(request_data_events_detail);
                    if (data_events_detail) {
                        setDataEventDetail(data_events_detail);
                    }
                }
            } catch (error) {
                navigate(urlWebsite.EVENTS, { replace: true });
                toggleModal({
                    type: ModalTypeEnum.NULL,
                });
                notification.error({
                    message: <h6>{t("Notification")}</h6>,
                    description: <p>{t("Event not found or event deleted")}</p>,
                });
            }
        })();
    }, [props?._uid, handleGetEventDetailByUid, navigate, toggleModal, ModalTypeEnum.NULL]);

    React.useEffect(() => {
        (async () => {
            if (dataEventDetail) {
                const event_members = [],
                    watcher_list = [];
                // event_members
                dataEventDetail.event_members.forEach((event) =>
                    event_members.push(apiAccount.getProfileUserById(event.user_id))
                );
                const result_event_members = await Promise.all(event_members);
                const resultCover = dataEventDetail.event_members.map((members) => {
                    const user = result_event_members.find((event) => event.id === Number(members.user_id));
                    return {
                        ...user,
                        event_info: members,
                    };
                });
                setEventMembers(resultCover);
                // watcher
                dataEventDetail.watcher.forEach(({ id }) =>
                    watcher_list.push(apiAccount.getProfileUserById(parseInt(id)))
                );
                const result_watcher_list = await Promise.all(watcher_list);
                setWatcher(result_watcher_list);
                // user create
                const result_user_create = await apiAccount.getProfileUserById(
                    parseInt(dataEventDetail.event.create_by)
                );
                setEventUserCreate(result_user_create);
            }
        })();
    }, [dataEventDetail, handleGetEventComments]);

    React.useEffect(() => {
        (async () => {
            if (!props?.isEdit && dataEventDetail) {
                // get comment
                const request_comment_event = await handleGetEventComments(dataEventDetail.event.id);
                const result_comment_event = unwrapResult(request_comment_event);
                if (result_comment_event.length > 0) setDataEventComments(result_comment_event);
            }
        })();
    }, [dataEventDetail, handleGetEventComments]);

    React.useEffect(() => {
        (async () => {
            if (dataEventComments) {
                const memberCommentApi = [];
                const memberCommentUserIdList = [];

                dataEventComments.forEach(
                    ({ user_id }) =>
                        !memberCommentUserIdList.includes(Number(user_id)) &&
                        memberCommentUserIdList.push(Number(user_id))
                );
                memberCommentUserIdList.forEach((id) => memberCommentApi.push(apiAccount.getProfileUserById(id)));
                const result_member = await Promise.all(memberCommentApi);
                const result = dataEventComments.map((comment) => {
                    const user = result_member.find(({ id }) => id === Number(comment.user_id));
                    return {
                        ...comment,
                        user_info: user,
                    };
                });
                setCommentListEvent(result.reverse());
            }
        })();
    }, [dataEventComments]);

    return {
        handleGetEventInMonth,
        handleGetEventComments,
        handlePostCreateEventNote,
        handlePostEventComment,
        setCommentListEvent,
        handlePatchConfirmEvent,
        handlePatchConfirmEventMeetingFast,
        handleDeleteEvent,
        setDataEventDetail,
        handleGetResourceList,
        handleGetTypeEventList,
        handlePostCreateEvent,
        handleCheckAvailableResource,
        handlePutUpdateEvent,
        handleGetEventDetailByUid,
        handleGetMeetingNext,
        handlePostStartZoomUrl,
        handleGetMeetingNextToday,
        handleGetMeetingNextTomorrow,
        handleGetMeetingNextThisWeek,
        handleGetMeetingNextNextWeek,
        handlePostRegisterEvent,
        handleDeleteEventReducers,
        handleAddEventReducers,
        setEventMembers,
        handleConfirmEventReducers,
        dataCheckAvailableResource,
        loadingDataMeetingNext,
        dataTypesList,
        dataResourceList,
        eventMembers,
        dataEventDetail,
        watcher,
        eventUserCreate,
        commentListEvent,
        loadingCreateEvent,
        dataMeetingNext,
        loadingStartZoom,
    };
};
