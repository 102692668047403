import { useAccount } from "hooks";
import { Profile } from "components";

export const HeaderRight = () => {
    const { infoUser, handleLogout, resultStoreAccount } = useAccount();
    return (
        <div className="relative flex h-full items-center gap-x-4 place-self-end">
            {infoUser ? (
                <>
                    <Profile
                        avatar={infoUser.avatar_url}
                        username={infoUser.username}
                        onClickLogout={handleLogout}
                        loading={resultStoreAccount.loading}
                    />
                </>
            ) : (
                <div className="flex animate-pulse items-center space-x-4">
                    <div className="h-9 w-9 rounded-full bg-slate-300 dark:bg-slate-500" />
                    <div className="h-3 w-14 bg-slate-300 dark:bg-slate-500" />
                    <div className="mr-2 h-9 w-9 rounded-full bg-slate-300 dark:bg-slate-500" />
                    <div className="mr-2 h-9 w-9 rounded-full bg-slate-300 dark:bg-slate-500" />
                </div>
            )}
        </div>
    );
};
