import { axiosClient } from "./axios-client";

export const apiSales = {
    getQuoteGroupListSales: () => {
        const url = "/sales/quotation/quote-group/list";
        return axiosClient.get(url);
    },
    postCreateLead: (payload) => {
        const url = "/sales/lead";
        return axiosClient.post(url, payload);
    },
    getLeads: (data) => {
        const { params, page } = data;
        const url = `/sales/lead/page/${page}`;
        return axiosClient.get(url, { params });
    },
    getSearchLeads: (keyword) => {
        const url = `/sales/lead/search/${keyword}`;
        return axiosClient.get(url);
    },
    getSearchApproverPrice: (keyword) => {
        const url = `/sales/quotation/approver2/search/${keyword}`;
        return axiosClient.get(url);
    },
    getSearchApproverTech: (keyword) => {
        const url = `/sales/quotation/approver/search/${keyword}`;
        return axiosClient.get(url);
    },
    getQuotationCategory: () => {
        const url = "/sales/quotation/category/list";
        return axiosClient.get(url);
    },
    getSubQuoteCategory: (idCat) => {
        const url = `/sales/quotation/sub-category/${idCat}/line`;
        return axiosClient.get(url);
    },
    getDiscountList: () => {
        const url = "/sales/sku-quotation/discount/list";
        return axiosClient.get(url);
    },
    postCreateSkuQuotation: (payload) => {
        const url = "/sales/sku-quotation";
        return axiosClient.post(url, payload);
    },
    getOrderLineQuotation: (payload) => {
        const { quotationId, orderId } = payload;
        const url = `/sales/sku-order-line/${quotationId}/${orderId}`;
        return axiosClient.get(url);
    },
    getSkuQuotation: (quotationId) => {
        const url = `/sales/sku-quotation/${quotationId}`;
        return axiosClient.get(url);
    },
    getDiscountLineQuotation: (payload) => {
        const { quotationId, orderId } = payload;
        const url = `/sales/sku-discount-line/${quotationId}/${orderId}`;
        return axiosClient.get(url);
    },
    patchUpdateSkuQuotation: (data) => {
        const { id, payload } = data;
        const url = `/sales/sku-quotation/${id}`;
        return axiosClient.patch(url, payload);
    },
    deleteQuotation: (id) => {
        const url = `/sales/sku-quotation/${id}`;
        return axiosClient.delete(url);
    },
    patchApprovedQuotationState: (data) => {
        const { id, payload } = data;
        const url = `/sales/sku-quotation/${id}/state`;
        return axiosClient.patch(url, payload);
    },
    getAbsLead: () => {
        const url = "/sales/abs/lead";
        return axiosClient.get(url);
    },
    getAbsProductLine: () => {
        const url = "/sales/abs/product-line";
        return axiosClient.get(url);
    },
    postAbsLead: (payload) => {
        const url = "/sales/abs/lead";
        return axiosClient.post(url, payload);
    },
    patchAbsLead: (data) => {
        const { id, payload } = data;
        const url = `sales/abs/lead/${id}`;
        return axiosClient.patch(url, payload);
    },
};
