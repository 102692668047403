import clsx from "clsx";

export const LoadingTable = ({ items = 4, className, itemClassName = "h-4" }) => {
    return (
        <div className={className}>
            <div className="grid gap-2">
                {[...Array(items)].map((_, index) => (
                    <div className="flex gap-2" key={index.toString()}>
                        <div
                            className={clsx(
                                "w-full basis-20 animate-pulse rounded bg-slate-300 dark:bg-slate-500",
                                itemClassName
                            )}
                        />
                        <div
                            className={clsx(
                                "w-full basis-10/12 animate-pulse rounded bg-slate-300 dark:bg-slate-500",
                                itemClassName
                            )}
                        />
                        <div
                            className={clsx(
                                "w-full basis-28 animate-pulse rounded bg-slate-300 dark:bg-slate-500",
                                itemClassName
                            )}
                        />
                        <div
                            className={clsx(
                                "w-full basis-28 animate-pulse rounded bg-slate-300 dark:bg-slate-500",
                                itemClassName
                            )}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
