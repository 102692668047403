import { FormatTime, ItemTableMobile } from "layout";

export const ModalAccountBusy = ({ data, type }) => {
    return (
        <div>
            {type === "LEAVE" && (
                <div>
                    <ItemTableMobile title="Content" value={data.name} />
                    <ItemTableMobile title="Time" dot>
                        <FormatTime start_time={data.date_from} end_time={data.date_to} className="" />
                    </ItemTableMobile>
                </div>
            )}
            {type === "BIZ" && (
                <div>
                    <ItemTableMobile title="Content" value={data.name} valueClassName="self-start" />
                    <ItemTableMobile title="Time" dot>
                        <FormatTime start_time={data.est_start_date} end_time={data.est_end_date} className="" />
                    </ItemTableMobile>
                    <ItemTableMobile title="Location" value={data.location_type} dot />
                </div>
            )}
        </div>
    );
};
