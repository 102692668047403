import { Drawer } from "antd";

import { WrapperError, InputSearch, SearchResult } from "../components";
import { HiArrowSmRight } from "react-icons/hi";

export const DrawerSearch = ({
    isOpen,
    onSetOpen,
    keyword,
    loading,
    onChangeKeyword,
    clearKeywords,
    data,
    onOpenSearch,
    handleOpenSearch,
}) => {
    return (
        <Drawer
            title={
                <>
                    <InputSearch
                        keyword={keyword}
                        loading={loading}
                        onChangeKeyword={onChangeKeyword}
                        clearKeywords={clearKeywords}
                    />
                </>
            }
            placement="right"
            onClose={onSetOpen}
            visible={isOpen}
            closeIcon={
                <div className="ml-2">
                    <HiArrowSmRight lassName="cursor-pointer" style={{ fontSize: 22, color: "#737579" }} />
                </div>
            }
            headerStyle={{ padding: "8px 12px 8px 8px" }}
            bodyStyle={{ padding: 0 }}
            width="100%"
        >
            <div className="h-full w-full dark:bg-[#262b3c]">
                <SearchResult
                    className="h-full divide-y divide-[#eaeaea] dark:divide-[#424b64]"
                    keyword={keyword}
                    data={data}
                    handleOpenSearch={handleOpenSearch}
                    onOpenSearch={onOpenSearch}
                />
                <WrapperError openSearch={onOpenSearch} keyword={keyword} data={data} errorClassName="relative" />
            </div>
        </Drawer>
    );
};
