import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { onGetAllQuotations, onGetSearchQuotations } from "features";

import { useAccount, storeQuotation } from "hooks";

export const useQuotation = () => {
    const dispatch = useDispatch();

    const { access_token } = useAccount();

    const resultStoreQuotation = useSelector(storeQuotation);
    const { pagination, dataQuotation, loading } = resultStoreQuotation;
    const handleGetAllQuotations = React.useCallback(
        (payload) => access_token && dispatch(onGetAllQuotations(payload)),
        [access_token, dispatch]
    );

    const handleGetSearchQuotations = React.useCallback(
        (keyword) => access_token && dispatch(onGetSearchQuotations(keyword)),
        [access_token, dispatch]
    );

    return {
        handleGetAllQuotations,
        handleGetSearchQuotations,
        pagination,
        dataQuotation,
        loading,
    };
};
