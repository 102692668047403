import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiFeedback } from "api";

export const onGetAllFeedback = createAsyncThunk("onGetAllFeedback", async (page) => {
    const response = await apiFeedback.getAllFeedbacks(page);
    return response;
});

export const onGetFeedbackDetailsId = createAsyncThunk("onGetFeedbackDetailsId", async (id) => {
    const response = await apiFeedback.getFeedbackDetailsId(id);
    return response;
});

export const onPatchSaveFeedback = createAsyncThunk("onPatchSaveFeedback", async (payload) => {
    const response = await apiFeedback.patchSaveFeedback(payload);
    return response;
});

export const onPatchIgnoreFeedback = createAsyncThunk("onPatchIgnoreFeedback", async (payload) => {
    const response = await apiFeedback.patchIgnoreFeedback(payload);
    return response;
});
