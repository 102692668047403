import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Image } from "customize";
import { WarningOutlined } from "@ant-design/icons";
import { useContextControllers } from "hooks";

export const WrapperProfile = ({
    mobile_phone,
    department,
    job_title_en,
    job_title_vi,
    className,
    titleClassName,
    wrapperClassNameItemInfo,
    fullname,
    dob,
    phone,
    email,
    company,
    showAvatar,
    avatar_url,
    is_active,
}) => {
    const { translate } = useContextControllers();
    const { t } = useTranslation();
    return (
        <div className={clsx("divide-y divide-[#eaeaea] dark:divide-[#424b64] dark:border-[#424b64]", className)}>
            {showAvatar ? (
                <>
                    <div className="flex gap-2">
                        <div className="flex flex-col items-center justify-center">
                            <Image src={avatar_url} className="h-24 w-24 rounded" />
                        </div>
                        <div className="divide-y divide-[#eaeaea] dark:divide-[#424b64]">
                            <ItemInfo
                                titleClassName={titleClassName}
                                wrapperClassNameItemInfo={wrapperClassNameItemInfo}
                                title="Full name"
                                value={fullname}
                            />
                            <ItemInfo
                                titleClassName={titleClassName}
                                wrapperClassNameItemInfo={wrapperClassNameItemInfo}
                                title="E-mail"
                                value={email}
                            />
                            <ItemInfo
                                titleClassName={titleClassName}
                                wrapperClassNameItemInfo={wrapperClassNameItemInfo}
                                title="Phone"
                                value={mobile_phone}
                            />
                            <ItemInfo
                                titleClassName={titleClassName}
                                wrapperClassNameItemInfo={wrapperClassNameItemInfo}
                                title="Position"
                                value={translate.language === "vi-VN" ? job_title_vi : job_title_en}
                            />
                        </div>
                    </div>
                    {!is_active && (
                        <div className="flex items-center justify-center gap-1 pt-4">
                            <WarningOutlined style={{ color: "#dc3545" }} />
                            <p className="text-[#dc3545]">{t("This account is not activated or locked")}</p>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <ItemInfo titleClassName={titleClassName} title="Full name" value={fullname} />
                    <ItemInfo
                        titleClassName={titleClassName}
                        title="Date of birth"
                        value={moment(dob).format("DD/MM/YYYY")}
                    />
                    <ItemInfo titleClassName={titleClassName} title="Phone" value={mobile_phone} />
                    <ItemInfo titleClassName={titleClassName} title="Office number" value={phone} />
                    <ItemInfo titleClassName={titleClassName} title="E-mail" value={email} />
                    <ItemInfo titleClassName={titleClassName} title="Company" value={company.name} />
                    <ItemInfo
                        titleClassName={titleClassName}
                        title="Department"
                        value={`[${department.ref}] ${department.name}`}
                    />
                    <ItemInfo
                        titleClassName={titleClassName}
                        title="Position"
                        value={translate.language === "vi-VN" ? job_title_vi : job_title_en}
                    />
                </>
            )}
        </div>
    );
};

const ItemInfo = ({ title, value, titleClassName, wrapperClassNameItemInfo }) => {
    const { t } = useTranslation();
    return (
        value && (
            <div className={clsx("flex items-center justify-between gap-x-4 p-2", wrapperClassNameItemInfo)}>
                <h6 className={clsx("font-semibold dark:text-[#f6f6f6]", titleClassName)}>{t(title)}:</h6>
                <p className="flex-1 text-right dark:text-[#bdc8f0]">{value}</p>
            </div>
        )
    );
};
