import * as React from "react";
import clsx from "clsx";
import { message, notification, Tooltip, Modal, Spin } from "antd";
import moment from "moment";

import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FiUserPlus } from "react-icons/fi";
import { AiOutlineClose, AiOutlineUsergroupAdd } from "react-icons/ai";
import { VscDebugStart } from "react-icons/vsc";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useEvents, useAccount } from "hooks";

import {
    CardMember,
    InfoMember,
    ItemInfo,
    FormComment,
    ListComment,
    EventConfirmStatus,
    UserControl,
    LoadingEvent,
} from "page/events/components";
import { Image, Button } from "customize";
import { Section, MainPage, WrapperScroll, CountDown, WrapperTag, Coating, ButtonCopyEvent } from "layout";
import { colorTooltip, urlWebsite } from "constant";

import { iconZoom } from "assets/icon";
import { GoCalendar } from "react-icons/go";
import { HiCheck, HiOutlineMail } from "react-icons/hi";
import { BsFiles, BsGeoAlt } from "react-icons/bs";

const { confirm } = Modal;

export const CommonDetailEvent = ({ _uid, handleGetTitleEvent }) => {
    const { t } = useTranslation();

    const {
        handlePostCreateEventNote,
        setCommentListEvent,
        setDataEventDetail,
        handlePostStartZoomUrl,
        handleGetEventDetailByUid,
        handlePostRegisterEvent,
        eventMembers,
        dataEventDetail,
        watcher,
        eventUserCreate,
        commentListEvent,
        loadingStartZoom,
    } = useEvents({ _uid });
    const { handleGetProfileUserById } = useAccount();

    const [loadingCreateEvent, setLoadingCreateEvent] = React.useState(false);
    const [meetingNoteIssue, setMeetingNoteIssue] = React.useState(null);
    const [eventUserEdit, setEventUserEdit] = React.useState(null);
    const [stateEvent, setStateEvent] = React.useState(null);
    const [loadingJoinEvent, setLoadingJoinEvent] = React.useState(false);

    const onClickPostCreateEventNote = async (id) => {
        try {
            setLoadingCreateEvent(true);
            const resultRequest = await handlePostCreateEventNote(id);

            const request = unwrapResult(resultRequest);
            if (request) {
                setLoadingCreateEvent(false);
                setMeetingNoteIssue(request.issue_id);
            }
        } catch (error) {
            setLoadingCreateEvent(false);
            notification["error"]({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("Only registered members can see the minutes")}</p>,
            });
        }
    };

    React.useEffect(() => {
        (async () => {
            if (dataEventDetail && dataEventDetail.event.edit_by) {
                const { payload } = await handleGetProfileUserById(dataEventDetail.event.edit_by);
                setEventUserEdit(payload);
            }
        })();
    }, [_uid, dataEventDetail, handleGetProfileUserById]);

    React.useEffect(() => {
        if (dataEventDetail) {
            const dateStartEvent = dataEventDetail.event.event_start_datetime;
            const dateEndEvent = dataEventDetail.event.event_end_datetime;
            if (handleGetTitleEvent) {
                handleGetTitleEvent(dataEventDetail.event.name);
            }
            if (new Date(dateStartEvent) > new Date()) {
                setStateEvent("COMMING");
            }
            if (new Date(dateStartEvent) < new Date() && new Date(dateEndEvent) > new Date()) {
                setStateEvent("STARTING");
            }
            if (new Date() > new Date(dateEndEvent)) {
                setStateEvent("EXPIRED");
            }
        }
    }, [dataEventDetail]);

    const submitPostRegisterEvent = async () => {
        try {
            setLoadingJoinEvent(true);
            const requestRegisterEvent = await handlePostRegisterEvent(dataEventDetail.event.id);
            const resultRegisterEvent = unwrapResult(requestRegisterEvent);
            if (resultRegisterEvent) {
                const requestGetEventDetail = await handleGetEventDetailByUid(dataEventDetail.event.uid);
                const resultGetEventDetail = unwrapResult(requestGetEventDetail);
                if (resultGetEventDetail) {
                    setDataEventDetail(resultGetEventDetail);
                    setLoadingJoinEvent(false);
                    message.success(t("Join successful event"));
                }
            }
        } catch (error) {
            message.error(t("Join failed event"));
            setLoadingJoinEvent(false);
        }
    };

    const handleJoinEvent = () => {
        confirm({
            title: t("Confirm registration for this event?"),
            icon: <ExclamationCircleOutlined />,
            centered: true,
            width: 500,
            okText: <Button Icon={HiCheck} label="Yes" BgSuccess onClick={submitPostRegisterEvent} />,
            cancelText: <Button label="Cancel" Icon={AiOutlineClose} BgSecondary />,
        });
    };

    if (!eventMembers || !dataEventDetail) {
        return (
            <MainPage goBack={false}>
                <Section>
                    <LoadingEvent />
                </Section>
            </MainPage>
        );
    }
    const dateStartEvent = dataEventDetail.event.event_start_datetime;
    const dateEndEvent = dataEventDetail.event.event_end_datetime;

    return (
        <React.Fragment>
            {loadingJoinEvent && (
                <Coating className="z-[999]">
                    <div className="flex min-h-screen items-center justify-center">
                        <Spin size="large" />
                    </div>
                </Coating>
            )}
            <div className="mb-4 grid items-center justify-center gap-2 text-center md:flex">
                <div className="flex h-full w-full basis-4/12 flex-col items-center justify-center place-self-end border-r-[0px] border-[#d1d3d49c] dark:border-[#32394e] md:border-r-[2px]">
                    <div className="flex gap-3 text-left">
                        <div className="self-center">
                            <GoCalendar className="text-[40px] text-[#1890ff]" />
                        </div>
                        <div>
                            <div className="flex flex-col items-center">
                                <div className="flex items-center justify-center gap-2">
                                    <div className="block">
                                        {moment(dataEventDetail.event.event_begin_day, "YYYY-MM-DD").valueOf() !==
                                        moment(dataEventDetail.event.event_end_day, "YYYY-MM-DD").valueOf() ? (
                                            <div className="flex">
                                                <div className="flex gap-1">
                                                    <span className="text-lg text-[#1890ff]">
                                                        {moment(dateStartEvent).format("dddd")}{" "}
                                                        {moment(dateStartEvent).format("DD")}
                                                    </span>
                                                    <span className="text-lg text-[#1890ff]">-</span>
                                                    <span className="text-lg text-[#1890ff]">
                                                        {moment(dateEndEvent).format("DD")}
                                                    </span>
                                                </div>
                                                <span className="text-lg text-[#1890ff]">/</span>
                                                <p className="text-lg text-[#1890ff]">
                                                    {moment(dateStartEvent).format("MM/YYYY")}
                                                </p>
                                            </div>
                                        ) : (
                                            <p className="text-lg capitalize text-[#1890ff]">
                                                {moment(dateStartEvent).format("dddd")}{" "}
                                                {moment(dateStartEvent).format("DD/MM/YYYY")}
                                            </p>
                                        )}
                                        <div className="flex items-center gap-1 text-left">
                                            <span className="text-[1rem] text-[#1890ff]">
                                                {moment(dateStartEvent).format("HH:mm")}
                                            </span>
                                            -
                                            <span className="text-[1rem] text-[#1890ff]">
                                                {moment(dateEndEvent).format("HH:mm")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {stateEvent === "COMMING" && <CountDown day={dateStartEvent} />}
                        {stateEvent === "STARTING" && <p className="text-[#22a447]">{t("Meeting in progress")}</p>}
                        {stateEvent === "EXPIRED" && <p className="text-[#dc3545]">{t("The meeting is over")}</p>}
                    </div>
                </div>
                <h6 className="basis-8/12 self-center text-xl font-semibold text-[#03942c] dark:text-[#4fe00e]">
                    {dataEventDetail.event.name}
                </h6>
            </div>
            <div className="divide-y divide-[#eaeaea] border-t border-[#d1d3d49c] dark:divide-[#32394e] dark:border-[#32394e]">
                <ItemInfo className="sm:max-w-[170px]" title="Content">
                    <WrapperContent
                        className="text-[0.938rem]"
                        dangerouslySetInnerHTML={{ __html: dataEventDetail.event.detail }}
                    />
                </ItemInfo>
                <ItemInfo className="sm:max-w-[170px]" title="Event Type">
                    <WrapperTag
                        title={dataEventDetail.event.type_txt}
                        bgColor={clsx(
                            dataEventDetail.event.type_txt === "Meeting" && "events",
                            dataEventDetail.event.type_txt === "Training" && "training",
                            dataEventDetail.event.type_txt === "Personal" && "success"
                        )}
                        rounded={false}
                    />
                </ItemInfo>
                {moment(dataEventDetail.event.event_begin_day, "YYYY-MM-DD").valueOf() !==
                    moment(dataEventDetail.event.event_end_day, "YYYY-MM-DD").valueOf() && (
                    <ItemInfo className="sm:max-w-[170px]" title="Time">
                        <div className="grid gap-4 sm:flex">
                            <div className="flex items-center gap-x-2">
                                <p className="text-[#03942c]">{t("Begin")}:</p>
                                <span>{moment(dateStartEvent).format("HH:mm DD/MM/YYYY")}</span>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <p className="text-[#dc3545]">{t("End")}:</p>
                                <span>{moment(dateEndEvent).format("HH:mm DD/MM/YYYY")}</span>
                            </div>
                        </div>
                    </ItemInfo>
                )}
                <ItemInfo className="sm:max-w-[170px]" title="Address">
                    <p className="break-words">{dataEventDetail.event.address}</p>
                </ItemInfo>
                <ItemInfo className="sm:max-w-[170px]" title="Meeting Minutes">
                    {dataEventDetail.event.meeting_note_issue || meetingNoteIssue ? (
                        <a
                            href={`${urlWebsite.REDMINE}/issues/${
                                meetingNoteIssue || dataEventDetail.event.meeting_note_issue
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <WrapperTag title="Redmine" rounded={false} bgColor="danger" hover />
                        </a>
                    ) : (
                        <Button
                            style={{
                                padding: "7px 0",
                                display: "flex",
                                maxWidth: "225px",
                            }}
                            BgSuccess
                            className="h-8 max-w-[250px]"
                            loading={loadingCreateEvent}
                            label="Click to create meeting minutes"
                            onClick={() => onClickPostCreateEventNote(dataEventDetail.event.id)}
                        />
                    )}
                </ItemInfo>
                {dataEventDetail.event.is_external_meeting && (
                    <ItemInfo className="sm:max-w-[170px]" title="Meeting">
                        <p>{t("Meeting with outside guests")}</p>
                    </ItemInfo>
                )}
                {dataEventDetail.event.max_allow_register && (
                    <ItemInfo
                        className="sm:max-w-[170px]"
                        title="Meeting room registration"
                        childrenClassName="flex items-center"
                    >
                        <p>
                            {dataEventDetail.event.max_allow_register} {t("People")}
                        </p>
                    </ItemInfo>
                )}
                <ItemInfo
                    className="sm:max-w-[170px]"
                    title={t("Participants {{value}}", { value: eventMembers.length })}
                    option={
                        stateEvent !== "EXPIRED" &&
                        !dataEventDetail.is_member &&
                        dataEventDetail.event.allow_register &&
                        (!dataEventDetail.event.max_allow_register ||
                            dataEventDetail.event.max_allow_register === "0" ||
                            dataEventDetail.event.total_member < Number(dataEventDetail.event.max_allow_register)) && (
                            <Button
                                Icon={FiUserPlus}
                                label="Registration"
                                size="sm"
                                className="mt-2 max-w-[145px]"
                                BgPrimary
                                onClick={handleJoinEvent}
                            />
                        )
                    }
                >
                    <WrapperScroll className="hover max-h-[200px]">
                        <CardMember
                            data={eventMembers}
                            memberBusyTime={dataEventDetail.member_busy_time}
                            eventId={dataEventDetail.event.id}
                        />
                    </WrapperScroll>
                </ItemInfo>
                {watcher?.length > 0 && (
                    <ItemInfo className="sm:max-w-[170px]" title={t("Followers {{value}}", { value: watcher.length })}>
                        <WrapperScroll className="hover max-h-[200px]">
                            <CardMember data={watcher} />
                        </WrapperScroll>
                    </ItemInfo>
                )}
                {dataEventDetail.resource && (
                    <ItemInfo className="flex gap-2 sm:max-w-[170px] sm:self-start" title="Resources used">
                        <div className="grid gap-2 2xl:flex">
                            {dataEventDetail.event.zoom_account && (
                                <div className="grid basis-6/12 items-center gap-2 sm:flex">
                                    <div className="m-auto">
                                        <Image src={iconZoom} className="h-full w-28" />
                                    </div>
                                    <div className="flex w-full flex-col">
                                        <div className="mb-2 flex justify-between gap-1 sm:justify-start">
                                            <h6 className="w-full basis-4/12 place-self-end">Zoom ID:</h6>
                                            <span
                                                className="cursor-pointer place-self-start font-semibold"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        dataEventDetail.event.zoom_meeting_id
                                                    );
                                                    message.success(t("Copied"));
                                                }}
                                            >
                                                {dataEventDetail.event.zoom_meeting_id}
                                            </span>
                                        </div>
                                        <div className="mb-2 flex justify-between gap-1 sm:justify-start">
                                            <h6 className="w-full basis-4/12">{t("Password")}:</h6>
                                            <span
                                                className="cursor-pointer place-self-start font-semibold"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(dataEventDetail.event.zoom_password);
                                                    message.success(t("Copied"));
                                                }}
                                            >
                                                {dataEventDetail.event.zoom_password}
                                            </span>
                                        </div>
                                        <div className="mb-2 flex justify-between gap-1 sm:justify-start">
                                            <h6 className="w-full basis-4/12">URL:</h6>
                                            <div
                                                className="flex cursor-pointer items-center gap-2"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(dataEventDetail.event.zoom_join_url);
                                                    message.success(t("Copied"));
                                                }}
                                            >
                                                <Tooltip
                                                    title={dataEventDetail.event.zoom_join_url}
                                                    color={colorTooltip}
                                                >
                                                    <p className="w-[200px] truncate text-[#03942c]">
                                                        {dataEventDetail.event.zoom_join_url}
                                                    </p>
                                                </Tooltip>
                                                <BsFiles className="text-[1rem]" />
                                            </div>
                                        </div>
                                        {new Date().getTime() -
                                            new Date(dataEventDetail.start_time.split(" ").join("T")).getTime() <
                                            30 * 60 * 1000 && (
                                            <div className="mb-2 flex justify-between gap-1 sm:justify-start">
                                                <h6 className="w-full basis-4/12">{t("Options")}:</h6>
                                                <div className="flex">
                                                    <div>
                                                        {new Date().getTime() -
                                                            new Date(
                                                                dataEventDetail.start_time.split(" ").join("T")
                                                            ).getTime() <
                                                            30 * 60 * 1000 && (
                                                            <a
                                                                href={dataEventDetail.event.zoom_join_url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Button
                                                                    Icon={AiOutlineUsergroupAdd}
                                                                    className="rounded-l-[0.25rem]"
                                                                    border={false}
                                                                    BgOrange
                                                                    style={{
                                                                        fontSize: "0.7rem",
                                                                        lineHeight: "1",
                                                                    }}
                                                                    label="Join event"
                                                                />
                                                            </a>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <Button
                                                            Icon={VscDebugStart}
                                                            className="rounded-r-[0.25rem]"
                                                            border={false}
                                                            loading={loadingStartZoom}
                                                            BgSuccess
                                                            style={{
                                                                fontSize: "0.7rem",
                                                                lineHeight: "1",
                                                            }}
                                                            label="Start event"
                                                            onClick={async () => {
                                                                const { error } = await handlePostStartZoomUrl(
                                                                    dataEventDetail.event.id
                                                                );
                                                                if (error) {
                                                                    const resultError = JSON.parse(error.message);
                                                                    notification.error({
                                                                        message: <h6>{t("Notification")}</h6>,
                                                                        description: (
                                                                            <p>
                                                                                {t(
                                                                                    resultError.message.error_code ===
                                                                                        "START_TIME_TOO_SOON"
                                                                                        ? "Start time too soon"
                                                                                        : "Start time expired"
                                                                                )}
                                                                            </p>
                                                                        ),
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="basis-6/12">
                                {dataEventDetail.resource.map((source) => (
                                    <div key={source.id} className="flex items-center gap-2">
                                        <BsGeoAlt />
                                        <p>{source.name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </ItemInfo>
                )}
                {dataEventDetail.event.send_to_group && (
                    <ItemInfo className="sm:max-w-[170px]" title="Mail group">
                        <div className="flex flex-wrap gap-2">
                            {dataEventDetail.event.send_to_group.split(";").map((group) => (
                                <div key={group} className="flex items-center gap-2">
                                    <HiOutlineMail />
                                    <p className="text-[0.938rem]">{group}</p>
                                </div>
                            ))}
                        </div>
                    </ItemInfo>
                )}
                <ItemInfo className="sm:max-w-[170px]" title="Creator">
                    {eventUserCreate ? (
                        <div className="grid items-center justify-between gap-4 xl:flex">
                            <div className="grid w-full items-center justify-between gap-2 sm:flex">
                                <div className="flex items-center">
                                    <InfoMember {...eventUserCreate} profile={eventUserCreate} />
                                    <span>,</span>
                                    <div className="flex items-center gap-x-1">
                                        <p>
                                            <span className="mx-1 lowercase">{t("At")}</span>
                                            {moment(dataEventDetail.event.create_date).format("HH:mm")}
                                            <span className="ml-1">
                                                {moment(dataEventDetail.event.create_date).format("DD/MM/YYYY")}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                {eventUserEdit && dataEventDetail.event.edit_date && (
                                    <ItemInfo title="Edited by" style={{ padding: 0 }}>
                                        <div className="grid grid-cols-2 items-center justify-between gap-4 xl:flex">
                                            <div className="flex min-w-[300px] items-center">
                                                <InfoMember {...eventUserEdit} profile={eventUserEdit} />
                                                <span>,</span>
                                                <div className="ml-1 flex items-center gap-x-1">
                                                    <p>
                                                        <span className="mr-1 lowercase">{t("At")}</span>
                                                        {moment(dataEventDetail.event.edit_date).format("HH:mm")}
                                                        <span className="ml-1">
                                                            {moment(dataEventDetail.event.edit_date).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </ItemInfo>
                                )}
                                {new Date(dataEventDetail.start_time.split(" ").join("T")).getTime() <
                                    new Date().getTime() && (
                                    <div>
                                        <ButtonCopyEvent
                                            className="h-8 min-w-[110px] rounded-[0.25rem]"
                                            detailEvent={dataEventDetail}
                                        />
                                    </div>
                                )}
                            </div>
                            {dataEventDetail.is_admin &&
                                new Date(dataEventDetail.start_time.split(" ").join("T")).getTime() >
                                    new Date().getTime() && (
                                    <UserControl
                                        idEvent={dataEventDetail.event.id}
                                        uidEvent={dataEventDetail.event.uid}
                                        detailEvent={dataEventDetail}
                                    />
                                )}
                        </div>
                    ) : (
                        <div>loading...</div>
                    )}
                </ItemInfo>

                {dataEventDetail.is_member && new Date(dataEventDetail.start_time) > new Date() && (
                    <ItemInfo className="sm:max-w-[170px]" title="Event confirmation">
                        <EventConfirmStatus dataEventDetail={dataEventDetail} setDataEventDetail={setDataEventDetail} />
                    </ItemInfo>
                )}
                <ItemInfo
                    className="sm:max-w-[170px] lg:self-start"
                    title={
                        commentListEvent ? t("Feedbacks {{value}}", { value: commentListEvent?.length }) : "Feedbacks"
                    }
                >
                    <div className="flex flex-col gap-y-4">
                        <FormComment
                            idEvent={dataEventDetail.event.id}
                            dataCommentListEvent={commentListEvent}
                            setCommentListEvent={setCommentListEvent}
                        />
                        <ListComment
                            dataCommentListEvent={commentListEvent}
                            setCommentListEvent={setCommentListEvent}
                        />
                    </div>
                </ItemInfo>
            </div>
        </React.Fragment>
    );
};
const WrapperContent = styled.div`
    & iframe {
        width: 100%;
        max-width: 400px;
        position: relative;
        height: 226px;
    }
    & iframe > #document html {
        width: 100%;
    }
`;
