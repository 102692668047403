import * as React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Tooltip } from "antd";

import { apiProducts } from "api";
import { Image } from "customize";

import { colorTooltip } from "constant";
import { AiOutlineDownload } from "react-icons/ai";

export const ModalProductSalesCodeBook = ({ id, quantity }) => {
    const [detail, setDetail] = React.useState(null);
    const { t } = useTranslation();
    React.useEffect(() => {
        (async () => {
            if (id) {
                const result = await apiProducts.getProductDetails(id);
                if (result) {
                    setDetail(result);
                }
            }
        })();
    }, [id]);

    if (!detail) return <Loading />;

    return (
        <div className="grid items-center md:flex">
            {detail.image_512 && (
                <div className="mr-3 flex flex-col items-center justify-center">
                    <Image base64 src={detail.image_512} className="h-auto w-full" />
                    <a
                        href={`data:image;base64,${detail.image_512}`}
                        download={`${detail.name}.jpg`}
                        className="flex items-center gap-1 text-[#03942c] hover:text-[#03942c] hover:underline"
                    >
                        <AiOutlineDownload />
                        {t("Download image")}
                    </a>
                </div>
            )}
            <div className="flex w-full flex-col gap-1 divide-y divide-[#eaeaea] self-start dark:divide-[#424b64]">
                <InfoItem title="CodeBook" value={detail.default_code} />
                <InfoItem title="Name" value={detail.name} />
                <InfoItem title="Quantity" value={quantity} />
                {detail.fc_ratio && <InfoItem title="%FC" value={detail.fc_ratio} />}
                {detail.ptw_expire_time && <InfoItem title="Expire time" value={detail.ptw_expire_time} />}
                <InfoItem title="Remain day" value={detail.ptw_remain_day} />
                <InfoItem title="Unit" value={detail.uom_id && detail.uom_id[1]} />
                {detail.sku_cm_max && <InfoItem title="Cm max" value={detail.sku_cm_max} />}
                {detail.sku_cm_min && <InfoItem title="Cm mix" value={detail.sku_cm_min} />}
                <InfoItem title="Creator" value={detail.create_uid && detail.create_uid[1]} />
                <InfoItem
                    title="Date create"
                    value={
                        <Tooltip title={moment(detail.create_date).format("DD/MM/YYYY")} color={colorTooltip}>
                            {moment(detail.create_date).fromNow()}
                        </Tooltip>
                    }
                />
            </div>
        </div>
    );
};

const InfoItem = ({ title, value }) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center justify-between gap-2">
            <h6 className="font-semibold">{t(title)}:</h6>
            <p>{value}</p>
        </div>
    );
};

const Loading = () => {
    return (
        <div className="grid gap-4">
            {[...Array(7)].map((_, index) => (
                <div
                    className="h-3 w-full animate-pulse rounded bg-slate-300 dark:bg-slate-500"
                    key={index.toString()}
                />
            ))}
        </div>
    );
};
