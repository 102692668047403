export const reducers = {
    addSKUQuotationReducers: (state, action) => {
        const { dataAddSKUQuotationReducers } = state;
        const payload = action.payload;
        const {
            product_id,
            quotation_category_id,
            subQuote_category_id,
            quantity,
            data,
            type,
            max_price,
            sku_max_sale_price,
        } = payload;
        const index = dataAddSKUQuotationReducers.findIndex(
            (item) =>
                item.product_id === product_id &&
                item.quotation_category_id === quotation_category_id &&
                item.subQuote_category_id === subQuote_category_id
        );
        if (type === "EDIT") {
            state.dataAddSKUQuotationReducers = data;
        }

        if (index === -1 && type !== "EDIT") {
            const sku_max_sale_price = data.product.item.sku_max_sale_price;
            const min_sale_price = data.product.item.sku_min_sale_price;
            const money = sku_max_sale_price * quantity;
            dataAddSKUQuotationReducers.unshift({ ...payload, money, sku_max_sale_price, min_sale_price });
        } else {
            if (type === "ADD") {
                const oldQuantity = dataAddSKUQuotationReducers[index].quantity + quantity;
                dataAddSKUQuotationReducers[index].quantity += quantity;
                dataAddSKUQuotationReducers[index].money =
                    oldQuantity * dataAddSKUQuotationReducers[index].sku_max_sale_price;
            }
            if (type === "UPDATE_QUANTITY") {
                dataAddSKUQuotationReducers[index].quantity = quantity;
                dataAddSKUQuotationReducers[index].money = quantity * sku_max_sale_price;
            }
            if (type === "DELETE") {
                dataAddSKUQuotationReducers.splice(index, 1);
            }
            if (type === "UPDATE_MAX_PRICE") {
                dataAddSKUQuotationReducers[index].sku_max_sale_price = max_price;
                dataAddSKUQuotationReducers[index].money = quantity * max_price;
            }
        }
    },
    addQuotationDiscountReducers: (state, action) => {
        const { dataDiscountList, dataQuotationDiscount } = state;
        const { data, item, type } = action.payload;

        if (type === "EDIT") {
            state.dataQuotationDiscount = data;
        }

        if (type === "ADD") {
            const index = dataDiscountList.findIndex(({ value }) => value === data.discount_type);
            dataQuotationDiscount.unshift(action.payload);
            if (index !== -1) {
                dataDiscountList.splice(index, 1);
            }
        }

        if (type === "DELETE") {
            const index = dataQuotationDiscount.findIndex((discount) => discount.item.value === item.value);
            if (index !== -1) {
                dataQuotationDiscount.splice(index, 1);
                dataDiscountList.unshift(item);
            }
        }
    },
    patchApprovedQuotationStateReducers: (state, action) => {
        state.dataGetSkuQuotation.state = action.payload;
    },
};
