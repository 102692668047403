import {
    onGetQuoteGroupListSales,
    onGetLeads,
    onGetSearchLeads,
    onGetSearchApproverPrice,
    onGetSearchApproverTech,
    onGetQuotationCategory,
    onGetSubQuoteCategory,
    onGetDiscountList,
    onGetOrderLineQuotation,
    onGetSkuQuotation,
    onGetAbsLead,
    onGetAbsProductLine,
} from "./path-api";

export const extraReducers = {
    [onGetQuoteGroupListSales.fulfilled]: (state, action) => {
        state.dataListSales = action.payload.items;
    },
    // getLeads
    [onGetLeads.pending]: (state, action) => {
        state.isLoadingSales = true;
    },
    [onGetLeads.fulfilled]: (state, action) => {
        const { items, limit, page, sortable, total } = action.payload;
        state.dataLeads = items;
        state.pagination = {
            page: page,
            limit: limit,
            total: total,
            sortable: sortable,
        };
        state.isLoadingSales = false;
    },
    // getSearchLeads
    [onGetSearchLeads.pending]: (state) => {
        state.isLoadingSales = true;
    },
    [onGetSearchLeads.fulfilled]: (state, action) => {
        const { items, limit, page, sortable, total } = action.payload;
        state.dataLeads = items;
        state.pagination = {
            page: page,
            limit: limit,
            total: total,
            sortable: sortable,
        };
        state.isLoadingSales = false;
    },
    // getSearchApproverPrice
    [onGetSearchApproverPrice.pending]: (state) => {
        state.loadingSearchApproverPrice = true;
    },
    [onGetSearchApproverPrice.fulfilled]: (state, action) => {
        state.loadingSearchApproverPrice = false;
        state.dataSearchApproverPrice = action.payload;
    },
    //
    [onGetSearchApproverTech.pending]: (state) => {
        state.loadingSearchApproverTech = true;
    },
    [onGetSearchApproverTech.fulfilled]: (state, action) => {
        state.loadingSearchApproverTech = false;
        state.dataSearchApproverTech = action.payload;
    },
    // getQuotationCategory
    [onGetQuotationCategory.pending]: (state) => {
        state.loadingQuotationCategory = true;
    },
    [onGetQuotationCategory.fulfilled]: (state, action) => {
        state.loadingQuotationCategory = false;
        state.dataQuotationCategory = action.payload.items;
    },
    // getSubQuoteCategory
    [onGetSubQuoteCategory.pending]: (state) => {
        state.loadingSubQuoteCategory = true;
        state.dataSubQuoteCategory = null;
    },
    [onGetSubQuoteCategory.fulfilled]: (state, action) => {
        state.loadingSubQuoteCategory = false;
        state.dataSubQuoteCategory = action.payload.items;
    },

    // getDiscountList
    [onGetDiscountList.fulfilled]: (state, action) => {
        const result = action.payload.map((item) => {
            return {
                name: item[1],
                value: item[0],
            };
        });
        state.dataDiscountList = result;
    },

    // getOrderLineQuotation
    [onGetOrderLineQuotation.pending]: (state) => {
        state.dataOrderLineQuotation = null;
    },
    [onGetOrderLineQuotation.fulfilled]: (state, action) => {
        state.dataOrderLineQuotation = action.payload;
    },
    // getSkuQuotation
    [onGetSkuQuotation.pending]: (state) => {
        state.dataGetSkuQuotation = null;
    },
    [onGetSkuQuotation.fulfilled]: (state, action) => {
        state.dataGetSkuQuotation = action.payload;
    },
    [onGetAbsLead.pending]: (state) => {
        const { dataAbsLead } = state;
        state.dataAbsLead = {
            loading: true,
            dataLead: dataAbsLead.dataLead,
        };
    },
    [onGetAbsLead.fulfilled]: (state, action) => {
        state.dataAbsLead = {
            dataLead: action.payload,
            loading: false,
        };
    },
    [onGetAbsProductLine.fulfilled]: (state, action) => {
        state.dataAbsProductLine = action.payload;
    },
};
