import * as React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, notification } from "antd";
import styled from "styled-components";

import { Button } from "customize";
import { useAccount, useModal } from "hooks";
import { VscSaveAs } from "react-icons/vsc";

export const ModalChangePassword = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const { toggleModal, ModalTypeEnum } = useModal();
    const { handleChangePassword } = useAccount();

    const onSubmitChangePassword = async (values) => {
        setLoading(true);
        const result = await handleChangePassword(values);
        if (result?.error) {
            setLoading(false);
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t("An error has occurred")}</p>,
            });
        } else if (result.payload?.message === "Password change success") {
            setLoading(false);
            notification.success({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t(result.payload?.message)}</p>,
            });
            toggleModal({
                type: ModalTypeEnum.NULL,
            });
        } else {
            setLoading(false);
            notification.error({
                message: <h6>{t("Notification")}</h6>,
                description: <p>{t(result.payload?.message)}</p>,
            });
        }
    };

    return (
        <Form layout="vertical" onFinish={onSubmitChangePassword}>
            <Form.Item
                name="old_password"
                label={t("Old password")}
                rules={[
                    {
                        required: true,
                        message: t("Please input your old password!"),
                    },
                ]}
                className="grid"
                hasFeedback
            >
                <InputPassword />
            </Form.Item>
            <Form.Item
                name="new_password"
                label={t("New password")}
                rules={[
                    {
                        required: true,
                        message: t("Please input your password!"),
                    },
                    {
                        min: 8,
                        message: t("Password too short at least 8 characters !"),
                    },
                ]}
                className="grid"
                hasFeedback
            >
                <InputPassword />
            </Form.Item>

            <Form.Item
                name="confirm"
                label={t("Confirm password")}
                dependencies={["new_password"]}
                hasFeedback
                className="grid"
                rules={[
                    {
                        required: true,
                        message: t("Please confirm your password!"),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("new_password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
                        },
                    }),
                ]}
            >
                <InputPassword />
            </Form.Item>
            <Button label="Update" className="mt-4" BgSuccess loading={loading} Icon={VscSaveAs} />
        </Form>
    );
};

const InputPassword = styled(Input.Password)`
    padding: 6px 12px;
`;
