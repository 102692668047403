import axios from "axios";
import queryString from "query-string";

import { storeLocalStorage } from "constant";

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    headers: { "Content-Type": "application/json" },
    paramsSerializer: (params) => queryString.stringify(params, { foo: "bar" }),
});

axiosClient.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem(storeLocalStorage.ACCESS_TOKEN);
        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    },
    (error) => Promise.reject(error)
);

let refreshTokenRequest = null;

axiosClient.interceptors.response.use(
    (response) => response && response.data,
    (error) => {
        const { data, status } = error.response;
        if (status === 401 && data.detail !== "LOGIN_FAILED") {
            const refreshToken = localStorage.getItem(storeLocalStorage.REFRESH_TOKEN);
            refreshTokenRequest = refreshTokenRequest ? refreshTokenRequest : handleTokenExpired(refreshToken);
            const result = refreshTokenRequest;
            result
                .then(({ refresh_token, access_token }) => {
                    localStorage.setItem(storeLocalStorage.REFRESH_TOKEN, refresh_token);
                    localStorage.setItem(storeLocalStorage.ACCESS_TOKEN, access_token);
                    window.location.reload();
                })
                .catch((e) => {
                    localStorage.removeItem(storeLocalStorage.ACCESS_TOKEN);
                    localStorage.removeItem(storeLocalStorage.REFRESH_TOKEN);
                    window.location.reload();
                });
        } else {
            const payload = {
                message: data.detail,
                status,
            };
            throw new Error(JSON.stringify(payload));
        }
    }
);

const handleTokenExpired = (refresh_token) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BACKEND_API}/refresh-token`,
                headers: { Authorization: `Bearer ${refresh_token}` },
            });
            resolve(data);
        } catch (error) {
            reject(JSON.stringify(error));
        }
    });
};
